import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Image } from '@core-mkt/interfaces/image';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import type { Bullet } from './rsa';
import { Rsa } from './rsa';

@Component({
  selector: 'rsa',
  templateUrl: './rsa.component.html',
  styleUrls: ['./rsa.component.scss'],
})
export class RsaComponent implements OnInit {
  @Input() data: Rsa;
  faCheck = faCheck;
  defaultTitle = `<h2>Save 25% when you select 1 FREE month of Allstate Roadside Services.</h2>`;
  defaultDescription = `<p>20% of crashes happen while stuck on the side of the road according to the Federal Highway Administration. Make sure you're covered with Allstate Roadside Services®. Only $5/month after your free trial. Cancel anytime.</p>`;
  defaultBullets = [
    {
      bulletItem: 'Flat tire service',
    },
    {
      bulletItem: 'Battery service',
    },
    {
      bulletItem: 'Towing',
    },
    {
      bulletItem: 'Fuel delivery',
    },
    {
      bulletItem: 'Lockout service',
    },
  ];
  defaultImage = [
    {
      url: 'https://xgrit-ecom.imgix.net/ace/ee9f08bf-bfb3-4dfd-8f1f-ef25747fea4e.png?v=1678723018',
      altText: 'roadside-assistance',
    },
  ];

  constructor(private redactor: WysiwygRedactorService, public imgixService: ImgixService) {}

  get title(): string {
    return this.data.rsaTitle;
  }

  get description(): string {
    return this.data.rsaDescription;
  }

  get bullets(): Bullet[] {
    return this.data.rsaBullets?.length ? this.data.rsaBullets : this.defaultBullets;
  }

  get buttonStyle(): string {
    return this.data.rsaCtaStyle;
  }

  get ctaButtonLabel(): string {
    return this.data.rsaCtaLabel;
  }

  get rsaCtaTag(): string {
    return this.data.rsaCtaTag ? this.data.rsaCtaTag : '';
  }

  get ctaButtonLink(): string {
    return this.data.rsaCtaLink;
  }

  get ctaButtonLinkNewTab(): boolean {
    return this.data.rsaCtaLinkNewTab;
  }

  get ctaButtonLinkNofollow(): boolean {
    return this.data.rsaCtaLinkNofollow;
  }

  get ctaButtonData(): VisionButton {
    return {
      buttonStyle: this.buttonStyle,
      bgTheme: '',
      buttonText: this.ctaButtonLabel,
      buttonLink: this.ctaButtonLink,
      fullWidth: false,
      nofollow: this.ctaButtonLinkNofollow,
      noreferrer: this.ctaButtonLinkNewTab,
      noopener: this.ctaButtonLinkNewTab,
      newTab: this.ctaButtonLinkNewTab,
      ctaTag: this.data.rsaCtaTag,
    };
  }

  get hasCta(): boolean {
    return (
      (this.ctaButtonLabel && this.ctaButtonLabel !== null && this.ctaButtonLabel.length > 0) ||
      (this.ctaButtonLink && this.ctaButtonLink !== null && this.ctaButtonLink.length > 0)
    );
  }

  get image(): Image {
    if (this.data.rsaImage && !!this.data.rsaImage[0]) {
      return this.data.rsaImage[0];
    }
    return this.defaultImage[0];
  }

  ngOnInit(): void {
    this.data.rsaDescription = this.data.rsaDescription
      ? this.redactor.bypassSanitizer(this.data.rsaDescription)
      : this.redactor.bypassSanitizer(this.defaultDescription);

    this.data.rsaTitle = this.data.rsaTitle
      ? this.redactor.bypassSanitizer(this.data.rsaTitle)
      : this.redactor.bypassSanitizer(this.defaultTitle);
  }
}
