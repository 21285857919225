import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HubspotComponent } from './hubspot/hubspot.component';

@NgModule({
  declarations: [HubspotComponent],
  exports: [HubspotComponent],
  imports: [CommonModule],
})
export class HubspotModule {}
