<div class="flex flex-row flex-wrap overflow-y-hidden">
  <div class="text-left w-1/2">
    <a class="footer-headline text-ace-gray-400" data-test="footerCategories" href="{{ headlineLink }}">{{
      headlineText
    }}</a>
  </div>
  <div class="text-right w-1/2">
    <span class="footer-arrow text-ace-gray-400 pr-2" data-test="footerArrows">
      <ng-template [ngIf]="sectionOpen" [ngIfElse]="sectionClosed">
        <span (click)="sectionOpen = !sectionOpen">
          <fa-icon [icon]="faCaretDown" [styles]="sectionIconStyle"></fa-icon>
        </span>
      </ng-template>
      <ng-template #sectionClosed>
        <span (click)="sectionOpen = !sectionOpen">
          <fa-icon [icon]="faCaretRight" [styles]="sectionIconStyle"></fa-icon>
        </span>
      </ng-template>
    </span>
  </div>
  <div
    class="footer-link-section text-left text-xs underline text-dec-gray-400"
    [ngClass]="{ collapsed: !sectionOpen }"
  >
    <ul class="pt-4" data-test="footerLists">
      <li *ngFor="let subLink of subLinks; let i = index">
        <ng-template [ngIf]="subLink.text === 'Privacy Policy'" [ngIfElse]="openInSameTab">
          <a target="_blank" rel="noopener noreferrer" href="{{ subLink.subLink }}">{{ subLink.text }}</a>
        </ng-template>
        <ng-template #openInSameTab>
          <a href="{{ subLink.subLink }}" [attr.rel]="shouldHaveNofollow(subLink.nofollow)">{{ subLink.text }}</a>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
<hr class="my-2" style="height: 1px" />
