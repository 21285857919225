import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BrandConfigurationService } from '@core-mkt/services/configuration/brand-configuration.service';
import { ScriptLoaderService } from '@core-mkt/services/script-loader/script-loader.service';
import { TrustpilotService } from '@core-mkt/services/trustpilot/trustpilot.service';
import { VerticalService } from '@core-mkt/services/vertical/vertical.service';
import { Trustpilot } from './trustpilot';

@Component({
  selector: 'trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.scss'],
})
export class TrustpilotComponent implements OnInit, AfterViewInit {
  @Input() data: Trustpilot;
  @Input() lazy = true;
  @ViewChild('trustpilot') container;
  pilotType: string;
  hasLoaded = false;
  brandUrl: string;
  businessId: string;
  widgets = {
    slider: {
      template: '54ad5defc6454f065c28af8b',
      height: '240px',
    },
    grid: {
      template: '539adbd6dec7e10e686debee',
      height: '500px',
    },
    productReview: {
      template: '5717796816f630043868e2e8',
      height: '400px',
    },
    horizontal: {
      template: '5406e65db0d04a09e042d5fc',
      height: '30px',
    },
    microCombo: {
      template: '5419b6ffb0d04a076446a9af',
      height: '30px',
    },
    microTrustScore: {
      template: '5419b637fa0340045cd0c936',
      height: '30px',
    },
    mini: {
      template: '53aa8807dec7e10d38f59f32',
      height: '130px',
    },
    carousel: {
      template: '53aa8912dec7e10d38f59f36',
      height: '240px',
    },
  };

  constructor(
    private bcs: BrandConfigurationService,
    private sls: ScriptLoaderService,
    private tps: TrustpilotService,
    private vs: VerticalService,
  ) {
    this.brandUrl = `https://www.trustpilot.com/review/${this.bcs.url}`;
    this.businessId = this.bcs.trustpilotId;
  }

  get trustPilotDisplayType(): object {
    return this.widgets[this.data.trustpilotDisplayType]
      ? this.widgets[this.data.trustpilotDisplayType]
      : this.widgets.grid;
  }

  get showHideTrustpilot(): boolean {
    return this.data.showHideTrustpilot;
  }

  get dataSku(): string {
    return this.data.dataSku;
  }

  get dataName(): string {
    return this.data.dataName;
  }

  ngOnInit(): void {
    this.pilotType = 'trust-' + this.data.trustpilotDisplayType;
  }

  ngAfterViewInit(): void {
    if (!this.data.showHideTrustpilot) return;
    // If trustpilot is meant to load lazily,
    // wait until this component runs in the browser to set up an intersection observer.
    // If trustpilot is not meant to load lazily, simply load the script using the script loader service.
    if (this.lazy) {
      this.tps.loadTrustpilot(this.container);
    } else if (!this.lazy) {
      this.sls.injectScriptByType('trustpilot');
    }
  }
}
