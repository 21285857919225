import { EnvService } from '@core-mkt/services/env/env.service';
import type { Header } from './header';

const env = new EnvService().get;
export const headerData: Header = {
  aarpMainLinks: [
    {
      linkUrl: '/defensive-driving-course/',
      linkLabel: 'Defensive Driving',
      formElementId: 'DRIVER-SAFETY-LNK-CLK-NAV-DEFENSIVE_DRIVING',
      openNewTab: false,
      isDropDown: false,
    },
    {
      linkUrl: '/why-take-our-course/',
      linkLabel: 'Why Take Our Course',
      formElementId: 'DRIVER-SAFETY-LNK-CLK-NAV-WHY_TAKE_COURSE',
      openNewTab: false,
      isDropDown: false,
    },
    {
      linkUrl: '/faq/',
      linkLabel: 'FAQs',
      formElementId: 'DRIVER-SAFETY-LNK-CLK-NAV-FAQ',
      openNewTab: false,
      isDropDown: false,
    },
    {
      linkUrl: '/pricing/',
      linkLabel: 'Pricing',
      formElementId: 'DRIVER-SAFETY-LNK-CLK-NAV-PRICING',
      openNewTab: false,
      isDropDown: false,
    },
  ],
  aarpRegisterLink: {
    linkUrl: env.xgritRedirectUrl,
    linkLabel: 'Register Now',
    formElementId: 'DRIVER-SAFETY-BTN-CLK-NAV-REGISTER_NOW',
    openNewTab: true,
    isDropDown: false,
  },
  aarpHelpLink: {
    linkUrl: '/support/',
    formElementId: 'DRIVER-SAFETY-BTN-CLK-NAV-HELP',
    linkLabel: 'Help',
    openNewTab: true,
    isDropDown: false,
  },
  aarpLoginLink: {
    linkUrl: 'https://app.aarpdriversafety.org/user/signin',
    linkLabel: 'Log in to Course',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-NAV-LOGIN_TO_COURSE',
    openNewTab: false,
    isDropDown: false,
  },
};
