import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CategoryWidgetComponent } from './category-widget/category-widget.component';

@NgModule({
  declarations: [CategoryWidgetComponent],
  exports: [CategoryWidgetComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule],
})
export class CategoryWidgetModule {}
