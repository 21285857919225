/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const PriceSelectorFragment = {
  PriceSelector: gql`
    fragment PriceSelectorQuery on baseComponents_aarpPriceSelector_BlockType {
      cardText
      cardTitle
      productCardBackgroundColor
      productCardColor
      selectorInstructions
      typeHandle
    }
  `,
};
