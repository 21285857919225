import { Component, Input, OnInit } from '@angular/core';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { SafeHtml } from '@angular/platform-browser';
import { Referral, ReferralDropdown } from './ambassador-referral';
import { DropdownData } from '@core-mkt/shared/components/vision-dropdown/vision-dropdown/vision-dropdown';
import { Image } from '@core-mkt/interfaces/image';

@Component({
  selector: 'ambassador-referral',
  templateUrl: './ambassador-referral.component.html',
  styleUrls: ['./ambassador-referral.component.scss'],
})
export class AmbassadorReferralComponent implements OnInit {
  @Input() data: Referral;
  theme: ThemeInterface;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private imgixService: ImgixService,
  ) {}

  get image(): Image | undefined {
    return this.data.image?.[0];
  }

  get referralTitle(): SafeHtml {
    return this.data.referralTitle;
  }

  get referralContent(): SafeHtml {
    return this.data.referralContent;
  }

  get dropdownTitle(): string {
    return this.data.dropdownTitle;
  }

  get dropdown(): ReferralDropdown[] {
    return this.data.referralDropdown;
  }

  get dropdownData(): DropdownData {
    return {
      dropdownItems: this.dropdown,
      dropdownPlaceholder: this.dropdownTitle || 'Claim Your Course',
      dropdownAlignment: '',
    };
  }

  ngOnInit(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnDistribution: undefined,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    this.data.referralTitle = this.redactor.bypassSanitizer(this.data.referralTitle);
    this.data.referralContent = this.redactor.bypassSanitizer(this.data.referralContent);
  }
}
