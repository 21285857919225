import { Component, Input, OnInit } from '@angular/core';
import { Image } from '@core-mkt/interfaces/image';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { CategoryWidgets, TopSection } from '../../top-section/top-section/top-section';

@Component({
  selector: 'category-widget',
  templateUrl: './category-widget.component.html',
  styleUrls: ['./category-widget.component.scss'],
})
export class CategoryWidgetComponent implements OnInit {
  @Input() categoryWidgets: any;
  @Input() data: TopSection;
  categoryWidgetContent: CategoryWidgets;
  theme: ThemeInterface;
  content: string;

  constructor(
    public imgixService: ImgixService,
    private themeParser: ThemeParserService,
    private redactor: WysiwygRedactorService,
  ) {}

  get title(): string {
    return this.categoryWidgets[0].categoryWidgetContent[0].categoryTitle;
  }

  get image(): Image {
    return this.categoryWidgets[0].categoryWidgetContent[0].categoryImage[0];
  }

  ngOnInit(): void {
    this.categoryWidgetContent = this.categoryWidgets[0].categoryWidgetContent[0];

    if (this.categoryWidgets[0].categoryWidgetContent[0].categoryContent) {
      this.content = this.redactor.bypassSanitizer(this.categoryWidgets[0].categoryWidgetContent[0].categoryContent);
    }

    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }
}
