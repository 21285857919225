import { Component, Input } from '@angular/core';
import { reviewData } from '../review-data';

@Component({
  selector: 'column-type2-reviews',
  templateUrl: './column-type2-reviews.component.html',
})
export class ColumnType2ReviewsComponent {
  @Input() backgroundInfo: any;
  @Input() textColorValue: string;
  @Input() textColorOverride: string;
  @Input() containerClasses: string;
  @Input() title: string;
  column2Reviews = reviewData[3];

  constructor() {}
}
