import type { HeaderNavLink } from './header';

export const setAccountHeaderData = (anonID: string): HeaderNavLink[] => {
  return [
    {
      linkUrl: '#',
      linkLabel: 'My Account',
      isDropDown: true,
      items: [
        {
          linkUrl: `https://app.aceable.com/user/signin?v=RE&ajs_aid=${anonID}`,
          linkLabel: 'Sign In',
          newTab: false,
          nofollow: false,
        },
        {
          linkUrl: 'https://www.aceableagent.com/support/',
          linkLabel: 'Help Center',
          newTab: false,
          nofollow: false,
        },
      ],
    },
  ];
};

export const headerData: HeaderNavLink[] = [
  {
    linkUrl: '/real-estate-license/',
    linkLabel: 'Get My License',
    isDropDown: true,
    items: [
      {
        linkUrl: '/real-estate-license/arizona/',
        linkLabel: 'Arizona Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/california/',
        linkLabel: 'California Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/colorado/',
        linkLabel: 'Colorado Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/florida/',
        linkLabel: 'Florida Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/georgia/',
        linkLabel: 'Georgia Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/michigan/',
        linkLabel: 'Michigan Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/new-york/',
        linkLabel: 'New York Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/north-carolina/',
        linkLabel: 'North Carolina Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/pennsylvania/',
        linkLabel: 'Pennsylvania Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/south-carolina/',
        linkLabel: 'South Carolina Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/tennessee/',
        linkLabel: 'Tennessee Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/texas/',
        linkLabel: 'Texas Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/virginia/',
        linkLabel: 'Virginia Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/washington/',
        linkLabel: 'Washington Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/',
        linkLabel: 'Browse All States',
        newTab: false,
        nofollow: false,
        arrowLink: true,
      },
    ],
  },
  {
    linkUrl: '#',
    linkLabel: 'More Courses',
    isDropDown: true,
    items: [
      {
        linkUrl: '/real-estate-exam-prep/',
        linkLabel: 'Exam Prep',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/post-licensing/',
        linkLabel: 'Post Licensing',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-continuing-education/',
        linkLabel: 'Continuing Education',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: '/about/',
    linkLabel: 'About',
    isDropDown: true,
    items: [
      {
        linkUrl: '/blog/',
        linkLabel: 'Blog',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/career-center/',
        linkLabel: 'Career Center',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-partnerships/',
        linkLabel: 'Partnerships',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/reviews/',
        linkLabel: 'Reviews',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/about/',
        linkLabel: 'About AceableAgent',
        newTab: false,
        nofollow: false,
      },
    ],
  },
];
