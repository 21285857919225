import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarketingComponent } from './marketing/marketing.component';

@NgModule({
  declarations: [MarketingComponent],
  exports: [MarketingComponent],
  imports: [CommonModule],
})
export class MarketingModule {}
