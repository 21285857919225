import { Question } from './quiz';

export const QuizQuestions: Question[] = [
  {
    id: 1,
    question: 'Which of the following best describes you:',
    answers: [
      { category: 'green', text: 'Measure twice; cut once' },
      { category: 'yellow', text: 'YOLO (you only live once)' },
    ],
  },
  {
    id: 2,
    question: 'Which of the following would you be most likely to say:',
    answers: [
      { category: 'red', text: 'Winning isn’t everything; it’s the only thing' },
      { category: 'purple', text: 'There’s no “i” in team' },
    ],
  },
  {
    id: 3,
    question: 'Which of these mantras do you live by?',
    answers: [
      { category: 'green', text: 'Better safe than sorry' },
      { category: 'red', text: 'Fortune favors the bold' },
    ],
  },
  {
    id: 4,
    question: 'Someone from corporate is coming to make an announcement to your team. You’re curious about:',
    answers: [
      { category: 'yellow', text: 'Who is coming to make the announcement' },
      { category: 'purple', text: 'How the announcement will impact your team' },
    ],
  },
  {
    id: 5,
    question: 'The new iPhone has come to market. You want to know:',
    answers: [
      {
        category: 'red',
        text: 'What can it do that other phones can’t',
      },
      { category: 'yellow', text: 'If your friends and colleagues going to get one' },
    ],
  },
  {
    id: 6,
    question: 'Betty has joined your Thursday night spades club. You hope:',
    answers: [
      { category: 'purple', text: 'She has a supportive playing partner' },
      { category: 'green', text: 'She knows the rules' },
    ],
  },
  {
    id: 7,
    question: 'Your ideal role on a local politician’s campaign team is to:',
    answers: [
      { category: 'yellow', text: 'Come up with some catchy campaign slogans' },
      { category: 'green', text: 'Prepare the candidate for an upcoming debate' },
    ],
  },
  {
    id: 8,
    question: 'A new coworker asks the same question for the third time. You wonder:',
    answers: [
      { category: 'red', text: 'If he’s getting the help he needs' },
      { category: 'purple', text: 'If he’ll ever get it' },
    ],
  },
  {
    id: 9,
    question: 'The approach to problem-solving you prefer is:',
    answers: [
      { category: 'red', text: 'Trial and error' },
      { category: 'green', text: 'The scientific method' },
    ],
  },
  {
    id: 10,
    question: 'They hired someone from the outside to run your department. You fear:',
    answers: [
      {
        category: 'purple',
        text: 'Things won’t run as smoothly as before',
      },
      { category: 'yellow', text: 'The new boss won’t listen to you' },
    ],
  },
  {
    id: 11,
    question: 'You and a friend have entered a dance contest fundraiser. You spend more time:',
    answers: [
      {
        category: 'yellow',
        text: 'Designing your matching costumes',
      },
      { category: 'red', text: 'Practicing your dance routines' },
    ],
  },
  {
    id: 12,
    question:
      'Your surf instructor has divided the class into pairs, giving each pairing a board and a stick of wax. You are more inclined to ask:',
    answers: [
      {
        category: 'green',
        text: 'Whether the wax is absolutely necessary',
      },
      { category: 'purple', text: 'How we should apply the wax' },
    ],
  },
];
