import { Injectable } from '@angular/core';
import { SeoData } from './seo-data';
import { ServerSeoDataService } from './server-seo-data.service';

@Injectable()
export class SeoDataService extends ServerSeoDataService {
  setSeoProperties(seoData: SeoData): void {
    return;
  }
}
