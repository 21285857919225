import { Component, OnInit, Input } from '@angular/core';
import { Image } from '@core-mkt/interfaces/image';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { Reviews } from '../reviews';
import { ComponentTheme, ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';

@Component({
  selector: 'reviews',
  templateUrl: './reviews.component.html',
  styleUrls: [],
})
export class ReviewsComponent implements OnInit {
  @Input() data: Reviews;
  textColorOverride = '';
  textColorValue = '';
  backgroundColor = '';
  backgroundColorValue = '';
  containerClasses = '';

  constructor(private redactor: WysiwygRedactorService, private themeParser: ThemeParserService) {}

  get reviewType(): string {
    return this.data.reviewType;
  }

  get reviewTitle(): string {
    return this.data.reviewTitle;
  }

  get backgroundImage(): Image {
    return this.data.backgroundImage?.[0];
  }

  get textColor(): string {
    return this.data.textColor;
  }

  ngOnInit(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnDistribution: undefined,
    };
    const theme = this.themeParser.getThemeClasses(themeInput);
    this.data.reviewTitle = this.redactor.bypassSanitizer(this.data.reviewTitle);
    this.textColorOverride = this.data.textColor;
    if (this.data.styles && this.data.styles !== null) {
      this.backgroundColor = theme.root;
      this.textColorOverride = this.themeParser.getLastClassForType('text-', this.data.styles);
      this.containerClasses = theme.container;
    } else {
      this.backgroundColor = this.data.backgroundColor;
    }
  }
}
