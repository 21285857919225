<!-- Carousel Reviews -->
<div
  class="relative"
  [class]="
    backgroundInfo && backgroundInfo.url ? textColorOverride + ' bg-no-repeat bg-center bg-cover' : backgroundInfo
  "
  [style.backgroundImage]="backgroundInfo && backgroundInfo.url && 'url(' + backgroundInfo.url + ')'"
>
  <div class="container mx-auto {{ containerClasses }}">
    <div *ngIf="title" class="wysiwyg carousel-title tab:px-v-spacing max-w-vision mx-auto" [innerHTML]="title"></div>
    <swiper class="mx-auto" [config]="swiperConfig">
      <ng-template swiperSlide *ngFor="let slide of sliderReviews">
        <div class="slide-container">
          <p class="mb-v-spacing text-base md:text-lg font-light">"{{ slide.review }}"</p>
          <p class="mb-v-spacing text-base md:text-md sm:px-24 md:px-0">
            <span class="font-bold">{{ slide.name }}</span
            >, <span class="font-light">{{ slide.date }}</span>
          </p>
        </div>
      </ng-template>
    </swiper>
    <div class="{{ prevSlide }} swiper-button-prev"></div>
    <div class="{{ nextSlide }} swiper-button-next"></div>
  </div>
</div>
