import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VerticalService } from '@core-mkt/services/vertical/vertical.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class AmbassadorInviteService {
  GETAMBASSADOR_USER = 'aceable';
  GETAMBASSADOR_API_KEY = '9b3f53bae191e27d1ffb5316b58a53f7';

  addToGroupId = '';
  autoCreate = 1;
  emailNewAmbassador = 1;
  ambassadorUrl = `https://api.getambassador.com/api/v2/${this.GETAMBASSADOR_USER}/${this.GETAMBASSADOR_API_KEY}/json`;
  url = '';

  headers: {
    'Content-Type': 'application/json';
  };

  constructor(readonly httpClient: HttpClient, private readonly vertical: VerticalService) {}

  useAmbassador(email: string): Observable<any> {
    this.addToGroupId = this.vertical.getVerticalInfo().ambassadorGroupId;
    this.url = `${this.ambassadorUrl}/ambassador/get/?email=${email}&add_to_group_id=${this.addToGroupId}&auto_create=${this.autoCreate}&email_new_ambassador=${this.emailNewAmbassador}`;
    return this.httpClient.get(this.url, { headers: this.headers });
  }

  getAmbassador(email: string): Observable<any> {
    this.addToGroupId = this.vertical.getVerticalInfo().ambassadorGroupId;
    this.url = `${this.ambassadorUrl}/ambassador/get/?email=${email}&add_to_group_id=${this.addToGroupId}&auto_create=${this.autoCreate}&email_new_ambassador=${this.emailNewAmbassador}`;
    return this.httpClient.get(this.url, { headers: this.headers });
  }
}
