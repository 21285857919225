import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { MultiCardComponent } from './multi-card/multi-card.component';

@NgModule({
  declarations: [MultiCardComponent],
  exports: [MultiCardComponent],
  imports: [CommonModule, QueryParamModule, VisionButtonModule],
})
export class MultiCardModule {}
