<!-- Nav Link -->
<span
  class="header__link relative text-whitehover:text-ace-teal-800 flex align-middle px-4 cursor-pointer"
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  *ngIf="data.isDropDown"
  [href]="data.linkUrl"
  (keydown.enter)="enterPress()"
  tabindex="0"
>
  {{ data.linkLabel }}
  <fa-icon class="ml-3 fa-1x" [icon]="hovered ? faCaretUp : faCaretDown"></fa-icon>
  <!-- MouseOver Bridge to DropDown -->
  <div
    class="absolute h-12 top-4 w-full -ml-8"
    (mouseenter)="hovered = true"
    [class.hidden]="!data.isDropDown || !hovered"
  ></div>
  <!-- Drop down Caret -->
  <div
    class="absolute ml-auto mr-auto z-500 triangle-pointer-up left-1/4"
    [class.hidden]="!data.isDropDown || !hovered"
  ></div>
</span>
<a
  class="header__link relative text-white flex align-middle px-8"
  [href]="data.linkUrl"
  target="{{ data.openNewTab ? '_blank' : '_self' }}"
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  *ngIf="!data.isDropDown"
>
  {{ data.linkLabel }}
</a>
<!-- Simple DropDown -->
<div
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  class="header__dd-simple z-30 absolute top-18 py-v-spacing sm:px-v-spacing bg-white"
  [class.hidden]="(!data.items || !hovered) && !showMenu"
>
  <div
    *ngFor="let link of data.items; let last = last; let first = first"
    [class]="'px-4 py-2 flex-grow w-full ' + (link.arrowLink ? 'border-t border-ace-grey-400' : '')"
  >
    <a class="text-sm hover:text-ace-teal-800 font-medium text-ace-grey-900 {{ last ? 'last-simple-item' : first ? 'first-simple-item' : '' }}" 
      [href]="link.linkUrl"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
      tabindex="0"
      >{{ link.linkLabel }}
    </a>
  </div>
</div>
