/* eslint-disable prettier/prettier */
import { v3SEOFragment } from '@core-mkt/shared/craft/seo-fragment-query';
import { BreadcrumbFragment } from '@core-mkt/shared/components/breadcrumb/breadcrumb/breadcrumb-query-fragment';
import { AccordionFragment } from '@core-mkt/shared/components/accordion/accordion/accordion-query-fragment';
import { AmbassadorInvFragment } from '@core-mkt/shared/components/ambassador-invite/ambassador-invite/ambassador-invite-query-fragment';
import { AmbassadorRefFragment } from '@core-mkt/shared/components/ambassador-referral/ambassador-referral/ambassador-referral-query-fragment';
import { AnchorFragment } from '@core-mkt/shared/components/anchor/anchor/anchor-query-fragment';
import * as gql from 'fake-tag';
import { AnchorButtonFragment } from '@core-mkt/shared/components/anchor-button/anchor-button/anchor-button-query-fragment';
import { BundleFragment } from '@core-mkt/shared/components/bundle/bundle/bundle-query-fragment';
import { CareersFragment } from '@core-mkt/shared/components/careers/careers/careers-query-fragment';
import { EmailFormFragment } from '@core-mkt/shared/components/email-form/email-form/email-form-query-fragment';
import { FaqFragment } from '@core-mkt/shared/components/faq/faq/faq-query-fragment';
import { GladlyFragment } from '@core-mkt/shared/components/gladly-help-center/gladly-help-center/gladly-help-center-query-fragment';
import {
  GlobalGraphicBannerFragment,
  GraphicBannerFragment,
} from '@core-mkt/shared/components/graphic-banner/graphic-banner/graphic-banner-query-fragment';
import { ProductFragment } from '@core-mkt/shared/craft/product-entry-fragment';
import { HeroFragment } from '@core-mkt/shared/components/hero-homepage/hero-homepage/hero-homepage-query-fragment';
import { HubspotFragment } from '@core-mkt/shared/components/hubspot/hubspot/hubspot-query-fragment';
import { LayoutFragment } from '@core-mkt/shared/components/layout/layout/layout-query-fragment';
import { LearnMoreFragment } from '@core-mkt/shared/components/learn-more/learn-more/learn-more-query-fragment';
import { MultiColumnFragment } from '@core-mkt/shared/components/multi-column/muti-column/multi-column-query-fragment';
import { PriceSelectorFragment } from '@core-mkt/shared/components/price-selector/price-selector/price-selector-query-frament';
import { ProductCardFragment } from '@core-mkt/shared/components/product-card/product-card/product-card-query-fragment';
import { QuizWidgetFragment } from '@core-mkt/shared/components/quiz-widget/quiz-widget/quiz-widget-query-fragment';
import { ResourcesFragment } from '@core-mkt/shared/components/resources/resources/resources-query-fragment';
import { ReviewsFragment } from '@core-mkt/shared/components/reviews/reviews/reviews-query-fragment';
import { RsaProductFragment } from '@core-mkt/shared/components/rsa-product/rsa-product/rsa-product-query-fragment';
import { SliderFragment } from '@core-mkt/shared/components/slider/slider/slider-query-fragment';
import { SliderCardFragment } from '@core-mkt/shared/components/slider-card/slider-card/slider-card-query-fragment';
import { StandAloneCtaFragment } from '@core-mkt/shared/components/stand-alone-cta/stand-alone-cta/stand-alone-cta-query-fragment';
import { TabFragment } from '@core-mkt/shared/components/tabbed-content/tabbed-content/tabbed-content-query-fragment';
import { TopBundleFragment } from '@core-mkt/shared/components/top-product/top-product/top-bundle-query-fragment';
import { TopSectionFragment } from '@core-mkt/shared/components/top-section/top-section/top-section-query-fragment';
import { TrustPilotFragment } from '@core-mkt/shared/components/trustpilot/trustpilot/trustpilot-query-fragment';
import { BannerFragment } from '@core-mkt/shared/components/banner/banner/banner-query-fragment';

export const TemplateSingleQuery = {
  name: 'TemplateSingleQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        typeHandle
        ... on pageEntries_visionTemplateEntry_Entry {
          pageTitle
          pageType
          trustPilotSKU
          baseComponents {
            ...BreadcrumbQuery
            ...AccordionQuery
            ...AmInvQuery
            ...AmRefQuery
            ...AnchorQuery
            ...AnchorButtonQuery
            ...BundleQuery
            ...CareersQuery
            ...EmailFormQuery
            ...FaqQuery
            ...GladlyHCQuery
            ...GraphicBannerQuery
            ...HeroQuery
            ...HubspotQuery
            ...LayoutQuery
            ...LearnMoreQuery
            ...MultiColumnQuery
            ...PriceSelectorQuery
            ...ProductCardQuery
            ...QuizWidgetQuery
            ...ResourcesQuery
            ...ReviewsQuery
            ...RsaProductQuery
            ...SliderQuery
            ...SliderCardQuery
            ...StandAloneCtaQuery
            ...TabQuery
            ...TopBundleQuery
            ...TopSectionQuery
            ...TrustPilotQuery
          }
        }
      }

      pageOptions: entries(site: $site, slug: $slug) {
        ... on pageEntries_visionTemplateEntry_Entry {
          title
          pageType
          ogPageVersion
          hideFooter
          hideNav
          hideStickyBanner
          trustPilotSKU
        }
      }

      structuredData: entries(site: $site, slug: $slug) {
        ... on pageEntries_visionTemplateEntry_Entry {
          structuredData
        }
      }

      seoContent: entries(site: $site, slug: $slug) {
        ... on pageEntries_visionTemplateEntry_Entry {
          ...v3SEOFragment
        }
      }

      styles: entries(site: $site, slug: $slug) {
        ... on pageEntries_visionTemplateEntry_Entry {
          embedStyles
        }
      }

      product: entries(site: $site, slug: $slug) {
        ...ProductEntry
      }

      optimizely: entries(site: $site, slug: $slug) {
        ... on pageEntries_visionTemplateEntry_Entry {
          optimizelyEnabled
          optimizelyTestEndDate
        }
      }

      globalOptimizely: globalSets {
        ... on aceHeader_GlobalSet {
          globalOptimizely
        }
      }

      banners: globalSets {
        ... on aceHeader_GlobalSet {
          graphicPromoBanner {
            ...GlobalGraphicBannerQuery
          }
          topBanner {
            ...BannerQuery
          }
        }
      }
    }
    ${ProductFragment.product}
    ${v3SEOFragment.seoContent}
    ${BreadcrumbFragment.Breadcrumb}
    ${AccordionFragment.Accordion}
    ${AmbassadorInvFragment.AmInv}
    ${AmbassadorRefFragment.AmRef}
    ${AnchorFragment.Anchor}
    ${AnchorButtonFragment.AnchorButton}
    ${BundleFragment.Bundle}
    ${CareersFragment.Careers}
    ${EmailFormFragment.EmailForm}
    ${FaqFragment.FAQ}
    ${GladlyFragment.GHC}
    ${GlobalGraphicBannerFragment.GlobalGraphicBanner}
    ${GraphicBannerFragment.GraphicBanner}
    ${HeroFragment.Hero}
    ${HubspotFragment.Hubspot}
    ${LayoutFragment.Layout}
    ${LearnMoreFragment.LearnMore}
    ${MultiColumnFragment.MultiColumn}
    ${PriceSelectorFragment.PriceSelector}
    ${ProductCardFragment.ProductCard}
    ${QuizWidgetFragment.Quiz}
    ${ResourcesFragment.Resources}
    ${ReviewsFragment.Reviews}
    ${RsaProductFragment.RsaProduct}
    ${SliderFragment.Slider}
    ${SliderCardFragment.SliderCard}
    ${StandAloneCtaFragment.StandAloneCta}
    ${TabFragment.TabComponent}
    ${TopBundleFragment.TopBundle}
    ${TopSectionFragment.TopSection}
    ${TrustPilotFragment.TrustPilot}
    ${BannerFragment.Banner}
  `,
};
