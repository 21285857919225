import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePreloadDirective } from './image-preload.directive';

@NgModule({
  declarations: [ImagePreloadDirective],
  exports: [ImagePreloadDirective],
  imports: [CommonModule],
  providers: [],
})
export class ImagePreloadModule {}
