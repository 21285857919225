import { Injectable } from '@angular/core';
import { Observable, timer, Subject, BehaviorSubject } from 'rxjs';
import { map, shareReplay, takeWhile } from 'rxjs/operators';
import { DateProxy } from './date-proxy';

@Injectable({
  providedIn: 'root',
})
export class CountdownService {
  private isSaleActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _start: Subject<any> = new Subject<any>();
  public startObs = this._start.asObservable();
  private _end: Subject<any> = new Subject<any>();
  public endObs = this._end.asObservable();
  now: number;

  /**
   * method used by components to update isSaleActive value
   * @param value - boolean indicating if a sale is active or not
   * This value is used to determine if the sticky email banner should
   * be shown or not on Aceable Agent
   */
  setSaleValue(value: boolean): void {
    this.isSaleActive.next(value);
  }

  /**
   * used to get the value of an active sale (true or false)
   * this value can be subscribed to in components
   * @returns {BehaviorSubject<boolean>} is sale active or not
   */
  getSaleValue(): BehaviorSubject<boolean> {
    return this.isSaleActive;
  }

  /**
   * create an observable that immediately emits the remaining milliseconds
   * between Date.now() and the expired time, then continues to do so on a
   * schedule until. The observable will automatically complete when the
   * expiration time has elapsed.
   * @param {number} [endMs] - the timestamp in epoch millis to end. Will default
   * to the next midnight if null or undefined.
   * @param {number} [intervalMs=1000] - the period to emit
   * @param {DateProxy} [dateProxy=new DateProxy()] - used for test
   * @returns {Observable<number>} the countdown observable
   */
  create(endMs?: number, startMs?: number, intervalMs = 1000, dateProxy = new DateProxy()): Observable<number> {
    if (!endMs) {
      const date = dateProxy.date;
      endMs = date.setHours(24, 0, 0, 0);
    }
    return timer(0, intervalMs).pipe(
      map(() => {
        const remaining = endMs - dateProxy.now;
        const upcoming = startMs - dateProxy.now;
        this._start.next(upcoming);
        this._end.next(remaining);
        return remaining < 0 ? 0 : remaining;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
      takeWhile(() => {
        return dateProxy.now < endMs;
      }, true),
    );
  }

  /**
   * if we receive start and end dates for promotion, we need to calculate the range of time between them.
   * @param {number} [startTime] - start time millisecond string
   * @returns {number} formatted start time millisecond number
   */
  getOfferStartTime = (startTime: number): number => {
    const startDate = new Date(startTime);
    startTime = startDate.valueOf();

    return startTime;
  };

  /**
   * if we receive start and end dates for promotion, we need to calculate the range of time between them.
   * @param {string} [startTime] - start time millisecond number
   * @param {number} [endTime] - end time millisecond number
   * @returns {number} formatted end time millisecond number
   */
  getOfferEndTime = (startTime: string, endTime: string): number => {
    let formattedEndTime = null;

    if (startTime && endTime) {
      const endDate = new Date(endTime);
      formattedEndTime = endDate.valueOf();
    }
    return formattedEndTime;
  };

  /**
   * Display banner based on start and end time.
   * @param {number} [startTime] - start time millisecond number
   * @param {number} [endTime] - end time millisecond number
   * @returns {boolean} true or false if no start or end time
   */
  displayBanner = (startTime: number, endTime: number): boolean => {
    const startDateTime = new Date(startTime).getTime();
    const fiveMins = 5 * 60 * 1000;
    const endDateTime = new Date(endTime).getTime();
    this.now = new Date().getTime();
    if (!startTime && !endTime) {
      return true;
    } else if (startTime <= this.now && endTime >= this.now) {
      return true;
    } else if (this.now > startDateTime - fiveMins) {
      this.startObs.subscribe((start) => {
        if (start <= 0) {
          return true;
        }
      });
    } else if (this.now > endDateTime - fiveMins) {
      this.endObs.subscribe((end) => {
        if (end < 0) {
          return true;
        }
      });
    }
    return false;
  };
}
