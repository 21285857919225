import { Component, Input } from '@angular/core';
import { CareerCenterArticleCard } from '../career-center/career-center';
import { Image } from '@core-mkt/interfaces/image';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { VisionButton } from '../../vision-button/vision-button/vision-button';

@Component({
  selector: 'career-center-article-card',
  templateUrl: './career-center-article-card.component.html',
  styleUrls: ['./career-center-article-card.component.scss'],
})
export class CareerCenterArticleCardComponent {
  @Input() data: CareerCenterArticleCard;
  resourceTextContent = undefined;

  constructor(private redactor: WysiwygRedactorService, private imgixService: ImgixService) {}

  get image(): Image | undefined {
    return this.data.image?.[0];
  }

  get relatedResourceContent(): string | undefined {
    return this.data.relatedResourceContent;
  }

  get relatedResourcesCategory(): string | undefined {
    if (this.data.relatedResourcesCategory.length === 0) {
      return '';
    } else {
      return this.data.relatedResourcesCategory[0].title;
    }
  }

  get relatedResourceLink(): string | undefined {
    return this.data.relatedResourceLink;
  }

  get ctaButtonData(): VisionButton {
    return {
      buttonStyle: 'tertiary',
      bgTheme: 'bg-white',
      buttonText: 'Read Full Article',
      buttonLink: this.relatedResourceLink,
      fullWidth: false,
      nofollow: false,
      noreferrer: false,
      noopener: false,
      newTab: false,
      useIcon: true,
    };
  }

  articleRoute(): void {
    if (this.relatedResourceLink) {
      window.location.href = this.relatedResourceLink;
    }
  }
}
