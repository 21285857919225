<div *ngIf="theme" class="slider-section {{ theme?.root }}">
  <div class="container {{ theme?.container }}">
    <div
      *ngIf="sliderTitle"
      class="wysiwyg py-v-spacing tab:px-v-spacing max-w-vision mx-auto multi-column__title"
      [innerHtml]="sliderTitle"
    ></div>
    <div [class]="containerClasses" class="items-center">
      <div
        *ngIf="showAsideContent('right') && asideContent"
        class="aside-content-left lg:w-1/2 py-v-spacing sm:px-v-spacing md:mr-v-spacing"
      >
        <span *ngIf="image && image.url !== null" [class.float-left]="containsEyebrow">
          <img [src]="image.url" class="mr-4 top-slider__seal" width="48" height="48" />
        </span>
        <div class="wysiwyg" [class.flex-eyebrow]="image && image.url !== null" [innerHtml]="asideContent"></div>
      </div>
      <div class="slider {{ carouselClasses }}">
        <swiper
          [config]="swiperConfig"
          [class.md:mr-v-spacing]="showAsideContent('left') && asideContent"
          class="h-full"
        >
          <ng-template swiperSlide *ngFor="let slide of sliderSlides" class="slide">
            <div *ngIf="slide.slideContent" class="w-full items-center">
              <div class="wysiwyg py-v-spacing sm:px-v-spacing" [innerHtml]="slide.slideContent"></div>
            </div>
            <a
              *ngIf="
                !slide.slideContent && slide.slideImage.length > 0 && slide.slideImage[0].url && slide.slideImageLink
              "
              class="w-full h-full contents"
              [href]="slide.slideImageLink"
              [attr.target]="slide.slideLinkNewTab ? '_blank' : ''"
              [attr.rel]="
                slide.slideLinkNewTab && slide.slideLinkNofollow
                  ? 'nofollow noopener noreferrer'
                  : slide.slideLinkNewTab && !slide.slideLinkNofollow
                  ? 'noopener noreferrer'
                  : slide.slideLinkNofollow
                  ? 'nofollow'
                  : ''
              "
            >
              <img
                class="slider-full-image carousel-cell-image mx-auto"
                [src]="imgixService.buildImgixUrl(slide.slideImage[0].url)"
                [alt]="slide.slideImage[0].altText ? slide.slideImage[0].altText : ''"
                [height]="slide.slideImage[0].height"
                [width]="slide.slideImage[0].width"
            /></a>
            <img
              *ngIf="
                !slide.slideContent && slide.slideImage.length > 0 && slide.slideImage[0].url && !slide.slideImageLink
              "
              class="slider-full-image carousel-cell-image mx-auto"
              [src]="imgixService.buildImgixUrl(slide.slideImage[0].url)"
              [alt]="slide.slideImage[0].altText ? slide.slideImage[0].altText : ''"
              [height]="slide.slideImage[0].height"
              [width]="slide.slideImage[0].width"
            />
          </ng-template>
        </swiper>
        <div class="{{ pagination }} mt-6 flex justify-center"></div>
      </div>
      <div
        *ngIf="showAsideContent('left') && asideContent"
        class="aside-content-right lg:w-1/2 py-v-spacing sm:px-v-spacing"
      >
        <span *ngIf="image && image.url !== null" [class.float-left]="containsEyebrow">
          <img [src]="image.url" class="mr-4 top-slider__seal" width="48" height="48" />
        </span>
        <div class="wysiwyg" [class.flex-eyebrow]="image && image.url !== null" [innerHtml]="asideContent"></div>
      </div>
    </div>
  </div>
</div>
