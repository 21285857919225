<div
  [style]="theme?.bgImage"
  [className]="theme?.root + ' mx-auto bg-no-repeat bg-center bg-cover ' + componentPadding"
>
  <div class="flex items-center max-w-vision {{ theme?.container }} mx-auto px-v-spacing md:px-0 {{ flexLayout }} ">
    <div [className]="'w-full wysiwyg pr-0 md:pr-4 ' + contentLayout" [innerHtml]="content"></div>
    <div *ngIf="data.ctaLink" [className]="'md:p-0 w-full md:w-auto ' + buttonLayout">
      <div class="cta-container">
        <vision-button [data]="buttonData"></vision-button>
      </div>
    </div>
  </div>
</div>
