import * as gql from 'fake-tag';

const categoryFields = `
              aceCategoryCode
              title`;

export const categoryString = `
              ... on drivingCategories_Category {
                ${categoryFields}
              }
              ... on aarpCategories_Category {
                ${categoryFields}
              }
              ... on realEstateCategories_Category {
                ${categoryFields}
              }`;

export const ProductFragment = {
  product: gql` fragment ProductEntry on productEntries_xgritProduct_Entry {
                  aceRealEstateProductCategory {
                    ... on realEstateCategories_Category {
                      ${categoryFields}
                    }
                  }
                  productCategory {
                    ${categoryString}
                  }
                  productPageUrl
                  productState
                  xgritBundleCoupon
                  xgritCoupon
                  xgritProductDisableRsa
                  xgritProductId
                }`,
};
