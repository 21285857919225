import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryParamDirective } from './query-param.directive';

@NgModule({
  declarations: [QueryParamDirective],
  exports: [QueryParamDirective],
  imports: [CommonModule],
})
export class DecQueryParamModule {}
