import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
  imports: [CommonModule, QueryParamModule, FontAwesomeModule],
})
export class BreadcrumbModule {}
