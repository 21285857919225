/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const HeaderFragment = {
  header: gql`
    fragment HeaderFragmentEntry on aceHeader_GlobalSet {
      aaGraphicPromoBanner {
        ... on aaGraphicPromoBanner_graphicBanner_BlockType {
          showHideGraphicPromoBanner
          backgroundColor
          backgroundImage {
            url
            ... on aceImagesS3_Asset {
              altText
            }
          }
          textColor
          image {
            url
            ... on aceImagesS3_Asset {
              altText
            }
          }
          imageAlignment
          graphicBannerContent
          promotionEndsDateTime
          promotionStartDateTime
        }
      }
      realEstateHeaderPromoBanner {
        ... on realEstateHeaderPromoBanner_realEstatePromoBanner_BlockType {
          showHidePromoBanner
          promotionText
          promotionReset {
            ... on promotionReset_BlockType {
              promotionEndsDateTime
              resetText
            }
          }
          promotionStartDateTime
          promotionEndsDateTime
          overwriteCoupon
        }
      }
      emailCapture {
        ... on emailCapture_emailCapture_BlockType {
          showEmailCapture
          coupon
          displayContent
          displayContentAfterSubmit
          buttonLabel
          saleStart
          saleEnd
        }
      }
    }
  `,
};

export const SettingsFragment = {
  settings: gql`
    fragment SettingsFragment on aceHeaderPageSettings_overrides_BlockType {
      customNav {
        ... on customNav_BlockType {
          linkText
          linkUrl
          newTab
          nofollow
        }
      }
      hideNav
      isNavSticky
      navAlignment
      replacementLogos {
        ... on replacementLogos_BlockType {
          logoLink
          newTab
          nofollow
          logoImage {
            url
            ... on aceImagesS3_Asset {
              altText
            }
          }
        }
      }
    }
  `,
};
