import { Inject, Optional, Injectable } from '@angular/core';
import { Env } from './env';
import { environment } from '@dec/environments/environment';
import { cloneDeep } from 'lodash';

/**
 * The EnvService creates an abstraction between the angular environment system and users of environment variables.
 * EnvService will by default use the angular environment.ts file but an object can be passed in as well.
 *
 * @example
 *   env.get.optimizelyId
 */

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  private readonly env;

  constructor(@Inject('env') @Optional() _env?: Env) {
    if (!_env) {
      this.env = environment;
    } else {
      this.env = _env;
    }
  }

  get get(): Env {
    return cloneDeep(this.env);
  }
}
