import { UrlSegment } from '@angular/router';

//hlText is used to override the h1 text on the page, and should not be merged.
//adding as an array to allow for more banned params in the future.
const bannedParams = ['hlText'];

export function getQueryParamString(queryParamObject: QueryParam): string {
  let queryString = '';
  if (queryParamObject) {
    const keyCount = Object.keys(queryParamObject).length;
    Object.keys(queryParamObject).forEach((query, idx) => {
      if (!bannedParams.includes(query)) {
        queryString += query + '=' + queryParamObject[query];
        if (idx < keyCount - 1) {
          queryString += '&';
        }
      }
    });
  }
  return queryString;
}

function recodeCommas(paramString: URLSearchParams): string {
  return paramString.toString().replace(/%2C/g, ',');
}

export function mergeSearchParams(link: string, paramString: string): string {
  const urlSegments = link.split('?');
  const queryParams = new URLSearchParams(urlSegments[1]);
  const newQueryParams = new URLSearchParams(paramString);
  newQueryParams.forEach((value, key) => {
    if (!bannedParams.includes(key)) {
      if (queryParams.has(key) && queryParams.get(key) !== value) {
        queryParams.set(key, queryParams.get(key) + ',' + value);
      }
      if (!queryParams.has(key)) {
        queryParams.set(key, value);
      }
    }
  });
  return recodeCommas(queryParams);
}

export function getParsedLinkString(link: string, paramString: string, hostName: string): string {
  //step 0, return null links
  if (link === null) {
    return link;
  }
  //first, return any links that won't be parsed; external links, file links
  if (isFileLink(link) || (!isRelativeLink(link) && !link.includes(hostName))) {
    return link;
  }
  // second, remove duplicate query params
  const passedParams = new URLSearchParams(paramString);
  const thinnedParams = new URLSearchParams();
  passedParams.forEach((value, key) => {
    if (bannedParams.includes(key) === false) {
      value = [...new Set(value.split(','))].toString();
      if ((thinnedParams.has(key) && thinnedParams.get(key) !== value) || !thinnedParams.has(key)) {
        thinnedParams.set(key, value);
      }
    }
  });
  paramString = recodeCommas(thinnedParams);
  // third, merge new query params with existing query params
  if (link.includes('?')) {
    paramString = mergeSearchParams(link, paramString);
  }
  //third, perform special link adjustments for anchors.
  if (link.includes('#')) {
    return addQueryParamstoAnchor(paramString, link);
  }
  //Everything else gets the standard queryparam tack-on, as long as there's a queryParam.
  if (paramString.length > 0) {
    const linkStart = link.split('?')[0];
    const parsedLink = linkStart + '?' + paramString;
    return parsedLink;
  }
  return link;
}

export function addQueryParamstoAnchor(queryParamString: string, link: string): string {
  if (queryParamString.length === 0) {
    return link;
  }
  const appendString = link.includes('?') ? '&' : '?';
  const slashString = link.includes('?') ? '' : '/';
  const parsedString =
    link.slice(0, link.indexOf('#') - 1) +
    slashString +
    appendString +
    queryParamString +
    link.slice(link.indexOf('#'));
  return parsedString;
}

export function testPathList(url: string, currentPathList: UrlSegment[]): boolean {
  for (const linkSegment of currentPathList) {
    if (linkSegment.path === url) {
      return true;
    }
  }
  return false;
}

export function isRelativeLink(link: string): boolean {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!/-]))?/;
  return !regexp.test(link);
}

export function isFileLink(link: string): boolean {
  const regexp = /\.(txt|pdf|doc|rtf|xml)/;
  return regexp.test(link);
}

interface QueryParam {
  [key: string]: string;
}
