import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { getQueryParamString, isRelativeLink, addQueryParamstoAnchor } from '../query-param/query-helpers';
import { WistiaService } from '../wistia/wistia.service';

@Injectable({
  providedIn: 'root',
})
export class WysiwygRedactorService {
  player: any;
  queryParams: any = {};
  renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private brand: BrandConfigurationService,
    private wistia: WistiaService,
  ) {
    if (this.route.snapshot) {
      this.queryParams = this.route.snapshot.queryParams;
    }
  }

  /**
   * YouTube iframes by default have a static width and height, which causes a horizontal scroll
   * to appear on mobile due to the static width being larger than the mobile width.
   *
   * This method adds a class to force the iframe to be responsive while keeping the standard
   * YouTube aspect ratio of 16:9.
   *
   * It's important to note that Redactor by default automatically wraps all iframes with a
   * <figure> element, which is great because that's where we must add our .aspect-ratio-16-9 class.
   */
  async makeYouTubeIframeResponsive(renderer): Promise<void> {
    this.renderer = renderer;
    const selector = 'iframe[src*="youtube.com"]';
    const youtubeIframes = Array.from(this.document.querySelectorAll(selector));
    if (youtubeIframes.length > 0) {
      // If we have youtube iframes, then dynamically load our youtube-player to help
      // us load and autoplay+sound youtube iframes if a user clicks a poster
      this.player = await import('youtube-player');

      youtubeIframes.forEach((el) => {
        const parentEl = this.renderer.parentNode(el);
        const grandparentEl = this.renderer.parentNode(parentEl);
        // Make responsive
        parentEl.classList.add('aspect-ratio-16-9');
        grandparentEl.parentElement.classList.add('my-auto');

        // Hide the iframe until clicked
        this.makeYouTubePoster(el);
      });
    }
  }

  /**
   * Removes the YouTube iframe, and replaces it with a poster. This improves performance by
   * reducing network calls on page load, and only loading them when the user clicks the poster
   * to load and play the video.
   */
  private makeYouTubePoster(el: Element): void {
    const embedId = this.youtubeIdParser(el.getAttribute('src'));

    if (embedId?.length) {
      const posterEl = this.createPosterEl(embedId);
      const elParent = this.renderer.parentNode(el);
      this.renderer.appendChild(elParent, posterEl);
      el.remove(); // Remove the original iframe so we don't load YouTube and its scripts
    }
  }

  /**
   * Creates the node element that will contain the poster and play icon.
   */
  private createPosterEl = (embedId: string): Element => {
    const absoluteCenter = 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
    const posterClass = 'poster';
    const poster = this.renderer.createElement('div');
    poster.classList.add(posterClass, 'group');

    poster.innerHTML = `
      <img class="${posterClass}__img pointer-events-none" src="https://img.youtube.com/vi/${embedId}/hqdefault.jpg" loading="lazy" width="480px" height="360px" alt="YouTube video poster" />
      <div class="pointer-events-none ${absoluteCenter}">
        <svg class="fill-current text-ace-pink-600 group-hover:text-ace-pink-400 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/>
        </svg>
      </div>
    `;
    poster.onclick = (event) => {
      const target = event.target as Element;
      if (target.classList.contains(posterClass)) {
        const videoContainer = this.document.createElement('div');
        target?.parentElement.append(videoContainer);
        const embed = this.player.default(event.target, { videoId: embedId });
        embed.playVideo();
      }
    };

    return poster;
  };

  /**
   * Extract the YouTube ID from the embed URL. Regex source came from:
   * https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
   */
  private youtubeIdParser(url: string): string {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    }
    return;
  }

  private hasBypassAction(actionBypass: string): boolean {
    return actionBypass !== undefined && (actionBypass === 'lazy' || actionBypass === 'nofollow');
  }

  /* Angular strips iframes by default. this must be overridden to show content.
   */
  bypassSanitizer(content: string, actionBypass?: string): string {
    if (typeof content !== 'string') return content;
    if (content === null || content === undefined) return '';
    content = this.addQueryParams(content);
    content = this.addXgritImgFormatting(content);
    if (!actionBypass || !this.hasBypassAction(actionBypass)) {
      content = this.addLazyLoading(content);
      content = this.addNofollow(content);
    }
    content = this.wistia.wistiaParser(content);
    const bypassed = this.sanitizer.bypassSecurityTrustHtml(content) as string;
    return bypassed;
  }

  addLazyLoading(content: string): string {
    const imgRegex = /<img[^<]*\/>/g;
    if (content && typeof content === 'string') {
      return content.replace(imgRegex, (match) => {
        match = match.substring(0, match.length - 2);
        match += 'loading="lazy" />';
        return match;
      });
    }
    return content;
  }

  addXgritImgFormatting(content: string): string {
    const srcRegex = /src="(https:\/\/xgrit-ecom.imgix.net[^"]*)"/g;
    if (content && typeof content === 'string') {
      return content.replace(srcRegex, (srcMatch) => {
        let srcStrBeforeEnd = srcMatch.substring(0, srcMatch.length - 1);
        if (!srcStrBeforeEnd.includes('?')) {
          srcStrBeforeEnd += '?auto=format,compress"';
        } else if (srcMatch.includes('auto=format"')) {
          srcStrBeforeEnd += ',compress"';
        } else {
          srcStrBeforeEnd += '&auto=format,compress"';
        }
        return srcStrBeforeEnd;
      });
    }
    return content;
  }

  addNofollow(content: string): string {
    const linkRegex = /<a[^>]*[ >]/g;
    if (content.includes('nofollow-link')) {
      return content.toString().replace(linkRegex, (match) => {
        const relIndex = match.indexOf('rel=') + 5;
        if (relIndex < 2) {
          return match.substring(0, match.length - 2) + ' rel="nofollow" ';
        } else {
          return match.substring(0, relIndex) + 'nofollow ' + match.substring(relIndex, match.length);
        }
      });
    }
    return content;
  }

  addQueryParams(content: string): string {
    const linkRegex = /href="[^ >]*"[ >]/g;
    const queryString = getQueryParamString(this.queryParams);
    const hostName = this.brand.hostName;
    if (content && queryString.length > 1) {
      return content.toString().replace(linkRegex, (match) => {
        const hasEndTag = match.includes('>');
        if (!match.includes(hostName) && !isRelativeLink(match)) {
          return match;
        } else if (match.includes('#')) {
          match = addQueryParamstoAnchor(queryString, match);
          return match;
        } else {
          match = match.substring(0, match.length - 2);
          const endString = hasEndTag ? '>' : ' ';
          if (match.includes('?')) {
            const newMatchString = match + '&' + queryString + '"' + endString;
            return newMatchString;
          }
          const newMatchString = match + '?' + queryString + '"' + endString;
          return newMatchString;
        }
      });
    }
    return content;
  }
}
