<div class="wysiwyg table w-full {{ theme?.root }}" [style]="theme?.bgImage">
        <div class="right-hero-headline">
            <div *ngIf="title" class="mb-v-spacing">
                <span class="text-center" [innerHTML]="title"></span>
            </div>
            <img
                *ngIf="image && image.url"
                class="h-48 object-cover category-widget-image rounded-t-md pb-v-spacing"
                src="{{ imgixService.buildImgixUrl(image.url) }}"
                [alt]="image.altText ? image.altText : ''"
                />
            
        </div>
    <div class="detail-stats" [innerHtml]="content"></div>
</div>