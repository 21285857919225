/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const AmbassadorRefFragment = {
  AmRef: gql`
    fragment AmRefQuery on baseComponents_ambassadorReferral_BlockType {
      ctaStyle
      dropdownTitle
      image {
        alt
        url
      }
      lineBreak_content
      lineBreak_styles
      referralContent
      referralDropdown {
        ... on referralDropdown_BlockType {
          dropdownLabel
          dropdownLink
          dropdownLinkNewTab
          dropdownLinkNofollow
        }
      }
      referralTitle
      styles
      typeHandle
    }
  `,
};
