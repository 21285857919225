/* eslint-disable prettier/prettier */
import { GenericFragment } from '@core-mkt/shared/craft/generic-fragment';
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOGenericFragment } from './fragments/seo-fragment-query';

export const TemplateGenericQuery = {
  name: 'TemplateGenericQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceBaseTemplates_genericTemplate_Entry {
          hideStickyBanner
          genericTemplate {
            ...genericTemplate_fragment
          }
        }
        ... on aceRealEstateTemplates_genericTemplate_Entry {
          hideStickyBanner
          genericTemplate {
            ...genericTemplate_fragment
          }
        }
      }

      seoContent: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_genericTemplate_Entry {
          ...BaseSEOFragmentEntry
        }
        ... on aceRealEstateTemplates_genericTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }

      styles: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_genericTemplate_Entry {
          embedStyles
        }
        ... on aceRealEstateTemplates_genericTemplate_Entry {
          embedStyles
        }
      }

      overrides: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_genericTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
        ... on aceRealEstateTemplates_genericTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_genericTemplate_Entry {
          optimizelyEnabled
        }
        ... on aceRealEstateTemplates_genericTemplate_Entry {
          optimizelyEnabled
        }
      }

      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: genericAATemplateOpti
            }
          }
        }
      }

      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_genericTemplate_Entry {
          structuredData
        }
      }

      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }

    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOGenericFragment.seoContent}
    ${GenericFragment.genericTemplate}
  `,
};
