export const SingleEntryQuery = {
  name: 'All Entries',
  query: `
  query($site: [String], $slug: [String]) {
    entries(site: $site, slug: $slug) {
      slug
      sectionHandle
      typeHandle
    }
  },
`
};
