import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, Menu } from '../../header/header';
import { faCaretRight, faCaretDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { stateList } from '../../header/state-nav-data';

@Component({
  selector: 'dec-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  @Input() data: Menu;
  @Input() _showLogoHref = true;
  _menuGroups: MenuItem[][];
  hidden = true;
  statesHidden = false;
  faCaretRight: IconDefinition = faCaretRight;
  faCaretRightStyles = { width: '7.5px', height: '30px' };
  faCaretDown: IconDefinition = faCaretDown;
  faCaretDownStyles = { width: '12.5px', height: '30px' };

  get menuGroupList(): MenuItem[][] {
    return this._menuGroups;
  }

  get displayMenu(): boolean {
    if (this.menuGroupList === undefined) {
      return false;
    }
    const menuGroupListLength = this.menuGroupList.length;
    return menuGroupListLength === 0 ? false : true;
  }

  get stateSelectionList(): MenuItem[] {
    return stateList;
  }

  get showLogoHref(): boolean {
    return this._showLogoHref;
  }

  constructor() {}

  ngOnInit() {
    this.formatMenuGroups();
  }

  mobileMenuToggle() {
    this.hidden = !this.hidden;
  }

  mobileStatesToggle() {
    this.statesHidden = !this.statesHidden;
  }

  formatMenuGroups(): void {
    if (!this.data.menuItems) {
      this._menuGroups = undefined;
    } else {
      let menuGroupList: MenuItem[][] = [];
      let groupIndex = -1;
      // Build the list of menu groups by pushing to the same index until a new position of "top" is seen;
      // until "top" is seen, the menu item is assumed to be a sub-item ("bottom") so is pushed to the same
      // group. Once a new "top" is seen, a new group gets formed at the following index and any sub-items
      // will be pushed there, etc., until the whole array has been iterated over.
      for (let i = 0; i < this.data.menuItems.length; i++) {
        if (this.data.menuItems[i].position === 'top') {
          groupIndex++;
          menuGroupList[groupIndex] = [];
        }
        menuGroupList[groupIndex].push(this.data.menuItems[i]);
      }

      this._menuGroups = menuGroupList;
    }
  }

  onEnter(): void {
    this.mobileMenuToggle();
  }
}
