<a
  *ngIf="link.isDropDown"
  [class]="
    'font-body collapse-link flex align-middle p-v-spacing text-ace-grey-800 ' +
    (collapsed ? 'border-b border-ace-grey-400' : '')
  "
  href="#"
  (click)="collapsed = !collapsed"
>
  {{ link.linkLabel }}
  <fa-icon class="ml-2 fa-sm" [icon]="collapsed ? faCaretDownLegacy : faCaretUpLegacy"></fa-icon>
</a>
<a
  *ngIf="!link.isDropDown"
  [class]="
    'font-body collapse-link flex align-middle p-v-spacing text-ace-grey-800 ' +
    (collapsed ? 'border-b border-ace-grey-400' : '')
  "
  href="{{ link.linkUrl }}"
  target="{{ link.openNewTab ? '_blank' : '_self' }}"
>
  {{ link.linkLabel }}
</a>
<div class="pl-10 collapse-link__item border-b border-ace-grey-400 pb-v-spacing" *ngIf="!collapsed">
  <a
    *ngFor="let linkItem of link.items"
    [class]="'block text-ace-grey-800 ' + (linkItem.arrowLink ? 'border-t border-ace-grey-400 py-4' : '')"
    [href]="linkItem.linkUrl"
  >
    <span [innerHTML]="linkItem.linkLabel"></span>
  </a>
</div>
