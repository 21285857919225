<div *ngIf="displayPromo" 
  [ngClass]="!displayRE ? 'hidden' : ''"
  [className]="theme?.root + ' bg-no-repeat bg-center bg-cover'" 
  id="graphicBanner" 
  [style]="theme?.bgImage"
  >
  <div class="container">
    <div class="flex {{imageAlignment}} items-center">
      <div *ngIf="image"
        class="{{ imageAlignment.includes('justify-center') ? 'max-w-[25%] xs:max-w-full' : '' }}">
        <img 
          [src]="pageData.image[0].url" 
          [alt]="pageData.image[0].altText" 
          class="w-full" 
          width="210" 
          height="160" 
        />
      </div>
      <div 
        class="py-v-spacing sm:p-v-spacing flex flex-col graphic-banner__content 
          {{ image && imageAlignment.includes('justify-center') ? 'max-w-[75%] sm:max-w-full pl-v-spacing' : 'w-full' }}
          {{ image && imageAlignment.includes('reverse') ? 'pr-v-spacing' : '' }}
          {{ image && imageAlignment === 'flex-row justify-start' ? 'pl-v-spacing' : '' }}" 
        [innerHTML]="content"
        >
      </div>
    </div>
  </div>
</div>