/* eslint-disable prettier/prettier */
import { ProductFragment } from '@core-mkt/shared/craft/product-entry-fragment';
import * as gql from 'fake-tag';

export const TopBundleFragment = {
  TopBundle: gql`
    fragment TopBundleQuery on baseComponents_topBundle_BlockType {
      backgroundImage {
        url
      }
      buttonScrollArrowDirection
      buttonScrollLabel
      buttonScrollStyle
      buttonScrollTo
      displayPrice
      hideGuaranteeLink
      lineBreak_content
      lineBreak_modals
      lineBreak_styles
      modalContent
      product {
        ...ProductEntry
      }
      productSeal {
        alt
        url
      }
      starCaption
      starRating
      styles
      topBundleBottomRightContent
      topBundleTopRightContent
      topProductContent
      topProductTitle
      typeHandle
    }
    ${ProductFragment.product}
  `,
};
