/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const GenericFragment = {
  genericTemplate: gql`
    fragment genericTemplate_fragment on genericTemplate_MatrixField {
      ... on genericTemplate_gladlyHelpCenter_BlockType {
        showGladlyHelpCenter
        typeHandle
      }
      ... on genericTemplate_ambassadorReferral_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        image {
          url
          ... on aceImagesS3_Asset {
            id
            altText
          }
        }
        referralTitle
        referralContent
        dropdownTitle
        referralDropdown {
          ... on referralDropdown_BlockType {
            dropdownLabel
            dropdownLink
            dropdownLinkNewTab
            dropdownLinkNofollow
          }
        }
        textColor
        typeHandle
      }
      ... on genericTemplate_graphicBanner_BlockType {
        typeHandle
      }
      ... on genericTemplate_sliderCard_BlockType {
        typeHandle
        backgroundColor
        textColor
        sliderTitle
        sliderDescription
        displayAllCardMobile
        slidesCards {
          ... on slidesCards_BlockType {
            slideImage {
              url
              ... on aceImagesS3_Asset {
                altText
                height
                width
              }
            }
            slideContent
            slideButtonStyle
            slideButtonLabel
            slideButtonLink
            slideButtonNewTab
            slideButtonNofollow
          }
        }
      }
      ... on genericTemplate_oneThreeColumn_BlockType {
        id
        background
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        textColor
        columnLayout
        oneThreeTitle
        threeColumnsContent
        oneColumnText
        oneColumnImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        typeHandle
      }
      ... on genericTemplate_breadcrumbs_BlockType {
        typeHandle
        backgroundColor
        textColor
        hideOnMobile
        breadcrumbsList {
          ... on breadcrumbsList_BlockType {
            breadcrumbLink
            breadcrumbText
          }
        }
      }
      ... on genericTemplate_trustpilot_BlockType {
        id
        showHideTrustpilot
        trustpilotDisplayType
        dataSku
        dataName
        typeHandle
      }
      ... on genericTemplate_careers_BlockType {
        id
        showHideCareers
        typeHandle
      }
      ... on genericTemplate_quizWidget_BlockType {
        typeHandle
        backgroundColor
        quizWidgetId
      }
      ... on genericTemplate_layout_BlockType {
        typeHandle
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        textColor
        image {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        layoutTitle
        layoutContent
        layoutRightContent
        dropdownTopLabel
        dropdownPlaceholder
        dropdown {
          ... on dropdown_BlockType {
            dropdownLabel
            dropdownLink
            dropdownLinkNewTab
            dropdownLinkNofollow
          }
        }
        buttonLayout
        buttonAlignment
        buttons {
          ... on buttons_BlockType {
            buttonLabel
            buttonLink
            buttonLinkNewTab
            buttonLinkNofollow
            buttonType
          }
        }
      }
      ... on genericTemplate_layoutCard_BlockType {
        typeHandle
        backgroundColor
        textColor
        layoutContent
        contentCardTitle
        cardAlignment
        cardType
        cardImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        cardContent
        cardButtonType
        cardButtonLabel
        cardButtonLink
        cardButtonLinkNewTab
        cardButtonLinkNofollow
      }
      ... on genericTemplate_multiColumn_BlockType {
        tailwindClasses
        typeHandle
        backgroundColor
        backgroundImage {
          url
        }
        textColor
        componentTitle
        columnList: columnList {
          ... on columnList_BlockType {
            columnContent
            dropdownTitle
            dropdownAlignment
            dropdown {
              dropdownLabel
              dropdownLink
              dropdownLinkNewTab
              dropdownLinkNofollow
            }
          }
        }
        twoColumnDistribution
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on genericTemplate_reviews_BlockType {
        typeHandle
        reviewTitle
        reviewType
        textColor
        backgroundColor
        backgroundImage {
          url
        }
      }
      ... on genericTemplate_multiCard_BlockType {
        typeHandle
        textColor
        backgroundColor
        textColor
        backgroundImage {
          url
        }
        componentTitle
        cardList {
          ... on cardList_BlockType {
            cardImage {
              url
            }
            imageShape
            cardContent
            cardButtonType
            cardButtonLabel
            cardButtonLink
            cardButtonLinkNewTab
            cardButtonLinkNofollow
          }
        }
      }
      ... on genericTemplate_accordion_BlockType {
        typeHandle
        accordionDisplayType
        accordionItems {
          ... on accordionItems_BlockType {
            itemTitle
            itemDescription
            showDescription
            accordionSectionTitle {
              ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                sectionTitle
              }
            }
            accordionImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
          }
        }
        accordionTitle
        accordionLeftContent
        buttonLabel
        buttonLink
        buttonLinkNewTab
        buttonLinkNofollow
        buttonType
      }
      ... on genericTemplate_anchor_BlockType {
        typeHandle
        anchorName
      }
      ... on genericTemplate_anchorButton_BlockType {
        typeHandle
        backgroundColor
        buttonAlignment
        buttonLabel
        scrollTo
        buttonStyle
        buttonArrowDirection
      }
      ... on genericTemplate_layout_BlockType {
        typeHandle
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        textColor
        image {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        layoutTitle
        layoutContent
        layoutRightContent
        dropdownTopLabel
        dropdownPlaceholder
        dropdown {
          ... on dropdown_BlockType {
            dropdownLabel
            dropdownLink
            dropdownLinkNewTab
            dropdownLinkNofollow
          }
        }
        buttonLayout
        buttonAlignment
        buttons {
          ... on buttons_BlockType {
            buttonLabel
            buttonLink
            buttonLinkNewTab
            buttonLinkNofollow
            buttonType
          }
        }
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on genericTemplate_hubspot_BlockType {
        typeHandle
        hubspotType
        hubspotContent
      }
      ... on genericTemplate_resources_BlockType {
        typeHandle
        resourcesBackgroundColor
        resourcesBackgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        resourcesTextColor
        resourcesTitle
        resourceCards {
          ... on resourceCards_BlockType {
            resourceImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            resourceBackgroundColor
            resourceTextContent
            resourceCtaStyle
            resourceCtaLabel
            resourceCtaLink
            resourceCtaLinkNewTab
            resourceCtaLinkNofollow
          }
        }
      }
      ... on genericTemplate_careerCenter_BlockType {
        typeHandle
        backgroundColor
        textColor
        careerCenterTitle
        careerCenterSideContent
        filterTitle
        filterDefaultLabel
        careerCenterArticleCards {
          ... on careerCenterArticleCards_BlockType {
            image {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            relatedResourceContent
            relatedResourceLink
            relatedResourcesCategory {
              ... on careerCenterCategories_Category {
                aceCategoryCode
                title
              }
            }
          }
        }
      }
      ... on genericTemplate_tabComponent_BlockType {
        backgroundColor
        tabbedContentTitle
        typeHandle
        tab {
          ... on tab_BlockType {
            isActive
            tabContentList {
              ... on tabContentList_accordionItem_BlockType {
                itemDescription
                itemTitle
                showDescription
                accordionSectionTitle {
                  ... on accordionSectionTitle_BlockType {
                    sectionTitle
                  }
                }
              }
            }
            tabTitle
          }
        }
      }
      ... on genericTemplate_ambassadorInvite_BlockType {
        typeHandle
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        ctaStyle
        ctaLabel
      }
      ... on genericTemplate_learnMore_BlockType {
        typeHandle
        backgroundColor
        textColor
        learnMoreContent
        componentPadding
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        ctaStyle
        ctaLabel
        ctaNofollow
        ctaNewTab
        ctaLink
        layout
      }

      ... on genericTemplate_emailForm_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        ctaLabel
        ctaType
        formContent
        formType
        hideContentOnMobile
        formVertical
        showNameField
        showParentEmailField
        subscriptionListIds
        textColor
        typeHandle
      }
    }
  `,
};
