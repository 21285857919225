import { CommonModule } from '@angular/common';
import type { ComponentFactory } from '@angular/core';
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CollapseLinkComponent } from './collapse-link/collapse-link.component';
import { HeaderNavLinkComponent } from './header-nav-link/header-nav-link.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [HeaderComponent, HeaderNavLinkComponent, CollapseLinkComponent],
  exports: [HeaderComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, LazyImgModule],
})
export class HeaderModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(): ComponentFactory<HeaderComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(HeaderComponent);
  }
}
