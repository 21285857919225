/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const SliderFragment = {
  Slider: gql`
    fragment SliderQuery on baseComponents_slider_BlockType {
      asideContent
      lineBreak_content
      lineBreak_styles
      sideContentStateSeal {
        alt
        url
      }
      sliderAlignment
      sliderSlides {
        ... on sliderSlides_BlockType {
          slideContent
          slideImage {
            alt
            url
          }
          slideImageLink
          slideLinkNewTab
          slideLinkNofollow
        }
      }
      sliderTitle
      styles
      typeHandle
    }
  `,
};
