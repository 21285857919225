import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import type { Question } from './quiz';
import { Quiz } from './quiz';
import { QuizQuestions } from './quiz-questions';

@Component({
  selector: 'quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @Input() data: Quiz;
  categoryTotals = {};
  currentQuestion = 0;
  selectedAnswer = '';
  selectedAnswers: string[] = [];
  questions: Question[];
  theme: ThemeInterface;
  faInstagram = faInstagram;
  faFacebookSquare = faFacebookSquare;

  ngOnInit(): void {
    this.questions = QuizQuestions;
  }

  get background(): string {
    return this.data.backgroundColor;
  }

  get q(): Question {
    if (this.questions.length > this.currentQuestion) return this.questions[this.currentQuestion];
    return null;
  }

  get qMarker(): string {
    return this.questions.length > this.currentQuestion
      ? `${this.currentQuestion}/${this.questions.length}`
      : 'Complete';
  }

  get getWidthPercentage(): string {
    const percentage = 100 / this.questions.length;
    return `width: ${this.currentQuestion * percentage}%`;
  }

  selectAnswerChange(color: string): void {
    this.selectedAnswer = color;
  }

  nextQuestion(): void {
    this.selectedAnswers.push(this.selectedAnswer);
    this.sortAnswers();
    this.currentQuestion++;
    this.selectedAnswer = '';
  }

  sortAnswers(): void {
    this.categoryTotals = this.selectedAnswers.reduce((acc, o) => ((acc[o] = (acc[o] || 0) + 1), acc), {});
  }
}
