import { Component } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { CraftTemplateType } from '@core-mkt/craft/craft-template-types';
import { EnvService } from '@core-mkt/services/env/env.service';
import { VerticalService } from '@core-mkt/services/vertical/vertical.service';

@Component({
  selector: 'template-dynamic',
  templateUrl: './template-dynamic.component.html',
  styleUrls: ['./template-dynamic.component.scss'],
})
export class TemplateDynamicComponent {
  private _templateData;
  private pageEntryType: CraftTemplateType;
  segmentIdOverride: string;

  constructor(private route: ActivatedRoute, private env: EnvService, private vertical: VerticalService) {
    this._templateData = {
      pageData: this.route.snapshot.data?.pageData?.craftData,
      apiData: this.route.snapshot.data?.pageData?.apiData,
    };
    this.pageEntryType = this.templateData?.pageData?.entryData?.typeHandle;
    this.segmentIdOverride = this.vertical.getVerticalInfo().segmentId;
  }

  get templateData() {
    return this._templateData;
  }

  get templateName(): string {
    if (this.pageEntryType === 'genericTemplate') {
      return 'template-generic';
    } else if (this.pageEntryType === 'categoryTemplate') {
      return 'template-category';
    } else if (this.pageEntryType === 'productTemplate') {
      return 'template-product';
    } else if (this.pageEntryType === 'disambigTemplate') {
      return 'template-disambig';
    } else if (this.pageEntryType === 'bundlesTemplate') {
      return 'template-bundles';
    } else if (this.pageEntryType === 'practiceTestTemplate') {
      return 'template-practice';
    } else if (this.pageEntryType === 'careerCenterTemplate') {
      return 'template-career-center';
    } else if (this.pageEntryType === 'aceHomepageTemplate') {
      return 'template-homepage';
    } else if (this.pageEntryType === 'baseTemplate') {
      return 'template-aarp';
    } else if (this.pageEntryType === 'visionTemplateEntry') {
      return 'template-single';
    } else {
      return 'template-404';
    }
  }
}
