import { Injectable } from '@angular/core';
import type { IconConfig } from '@core-mkt/interfaces/icons';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private brandIcons: IconConfig[];

  constructor(private env: EnvService) {
    this.brandIcons = this.env.get.brandConfig.iconConfig;
  }

  //return specific icon
  public getBrandIcon(iconName: string): IconConfig {
    return this.brandIcons?.find((icon) => icon.name === iconName);
  }

  //return all icons
  public getAllIcons(): IconConfig[] {
    return this.brandIcons;
  }
}
