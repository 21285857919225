import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateDisambigComponent } from './template-component/template-disambig.component';

const routes: Routes = [{ path: '', component: TemplateDisambigComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateDisambigRoutingModule {}
