import { DOCUMENT } from '@angular/common';
import { Directive, Inject, ElementRef, OnInit, Renderer2 } from '@angular/core';

import { ImgixService } from '../imgix/imgix.service';

@Directive({
  selector: '[preloadImg]',
})
export class ImagePreloadDirective implements OnInit {
  constructor(
    @Inject(DOCUMENT) public doc: Document,
    private el: ElementRef,
    public imgixService: ImgixService,
    public renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const src = this.el.nativeElement.getAttribute('src');
    if (src) {
      const imgixImage = this.imgixService.buildImgixUrl(src);
      const link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('as', 'image');
      link.setAttribute('rel', 'preload');
      link.setAttribute('href', imgixImage);
      this.renderer.appendChild(this.doc.head, link);
    }
  }
}
