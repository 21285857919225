import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'any'
})
export class GreenhouseService {
  private ghAPI: string = 'https://boards-api.greenhouse.io/v1/boards/aceable/jobs?content=true';

  constructor(private httpClient: HttpClient) { }

  getJobs(): Observable<Object> {
    return this.httpClient.get<Object>(`${this.ghAPI}`)
  }

  // Error handling
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
