import type { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import type { CraftXGritProductEntry } from '@core-mkt/interfaces/craft-xgrit-product-entry';
import { IconService } from '@core-mkt/services/iconService/icon.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import type { BundleAccordionItem, BundleItem } from './bundle';
import { Bundle } from './bundle';

@Component({
  selector: 'bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.scss'],
})
export class BundleComponent implements OnInit, AfterViewInit {
  @Input() data: Bundle;
  @ViewChildren('bundlePrice') bundlePrice: QueryList<ElementRef>;
  theme: ThemeInterface;
  faPlus = faPlus;
  faMinus = faMinus;
  faTimes = faTimes;
  showModal = false;
  couponApplied = false;
  activeCoupon = '';
  additionalCoupon: string; // Used to store additional coupon code from the cookie
  closeIcon: SafeHtml | null;
  openIcon: SafeHtml | null;

  constructor(
    private themeParser: ThemeParserService,
    private redactor: WysiwygRedactorService,
    private ps: ProductService,
    private pricingService: PricingService,
    private IconService: IconService,
  ) {}

  get bundles(): BundleItem[] {
    return this.data.bundles;
  }

  get accordions(): BundleAccordionItem[][] {
    return [this.data.bundleAccordionItems1, this.data.bundleAccordionItems2, this.data.bundleAccordionItems3];
  }

  ngOnInit(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    this.openIcon = this.redactor.bypassSanitizer(this.IconService.getBrandIcon('accordion-open')?.icon);
    this.closeIcon = this.redactor.bypassSanitizer(this.IconService.getBrandIcon('accordion-close')?.icon);

    this.data.bundles.forEach((b) => {
      b.principalContent = this.redactor.bypassSanitizer(b.principalContent);
      b.bottomContent = this.redactor.bypassSanitizer(b.bottomContent);
    });

    this.getProducts(this.data.bundles);
    this.data.bundleAccordionItems1.forEach((i) => {
      i.itemDescription = this.redactor.bypassSanitizer(i.itemDescription);
    });
    this.data.bundleAccordionItems2.forEach((i) => {
      i.itemDescription = this.redactor.bypassSanitizer(i.itemDescription);
    });
    this.data.bundleAccordionItems3.forEach((i) => {
      i.itemDescription = this.redactor.bypassSanitizer(i.itemDescription);
    });

    this.pricingService.couponCodeEvent.subscribe((coupon: string) => {
      this.activeCoupon = '&couponCode=' + coupon;
      this.additionalCoupon = coupon;
      this.adjustPricing(coupon);
    });
  }

  ngAfterViewInit(): void {
    /**
     * Detect when ViewChildren bundlePrice changes and
     * adjust pricing if coupon is already applied
     */

    this.bundlePrice.changes.subscribe(() => {
      if (this.activeCoupon) {
        // If activeCoupon is already set, adjust pricing
        this.adjustPricing(this.additionalCoupon);
      }
    });
  }

  public adjustPricing(coupon: string): void {
    this.bundlePrice.forEach((el, index) => {
      const bundle = this.data.bundles[index];
      const product = this.ps.extractProduct(bundle.product);
      product.xgritCoupon = coupon;
      this.ps.getProductData(product).then((product: XGritCompleteProduct) => {
        const start = product.apiData.originalPrice;
        const end = product.apiData.discountPrice;
        const maxPrice = product.apiData.maxPrice;
        const discountPrice = product.apiData.discountPrice;
        bundle.savePercentage = Math.floor(((maxPrice - discountPrice) / maxPrice) * 100);
        bundle.maxPrice = maxPrice;
        bundle.originalPrice = discountPrice;

        this.couponApplied = true;
        if (start === end) return;
        let current = start;
        const stepTime = 14;
        const timer = setInterval(function () {
          current -= 1;
          el.nativeElement.innerHTML = '$' + current;
          if (current <= end) {
            clearInterval(timer);
            return;
          }
        }, stepTime);
      });
    });
  }

  toggle(accordionItem: BundleAccordionItem): void {
    accordionItem.showDescription = !accordionItem.showDescription;
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
  }

  showAccordion(index: number): boolean {
    return !!this.accordions[index]?.[0]?.itemTitle;
  }

  getThemeForTopBanner(backgroundColor: string, textColor: string): ThemeInterface {
    if (backgroundColor === '') {
      const theme: ThemeInterface = {
        root: '',
        container: '',
        columnOrder: '',
        bgImage: '',
      };
      return theme;
    } else {
      const themeInput: ComponentTheme = {
        styles: [],
        backgroundColor: backgroundColor,
        backgroundImage: null,
        textColor: textColor,
        columnCount: 0,
        columnDistribution: '',
      };
      return this.themeParser.getThemeClasses(themeInput);
    }
  }

  getButtonData(bundleItem: BundleItem): VisionButton {
    const buttonLink = bundleItem.ctaLink ? bundleItem.ctaLink : bundleItem.productCheckoutUrl;
    return {
      bgTheme: this.theme.root,
      buttonLink: buttonLink + this.activeCoupon,
      buttonText: bundleItem.ctaLabel || 'Get My License',
      buttonStyle: bundleItem.ctaStyle,
      newTab: bundleItem.ctaLinkNewTab,
      nofollow: bundleItem.ctaLinkNofollow,
      noopener: bundleItem.ctaLinkNewTab,
      noreferrer: bundleItem.ctaLinkNewTab,
      fullWidth: false,
    };
  }

  getProducts(bundles: BundleItem[]): void {
    const craftProducts: CraftXGritProductEntry[] = [];
    bundles.forEach((bundle) => {
      if (this.ps.isProductValid(bundle.product)) {
        craftProducts.push(this.ps.extractProduct(bundle.product));
      }
    });
    this.ps.getMultiProductData(craftProducts).then((xgritProducts) => {
      if (xgritProducts.length !== 0) {
        bundles.forEach((bundle, index) => {
          const maxPrice = xgritProducts[index].apiData.maxPrice;
          const originalPrice = xgritProducts[index].apiData.originalPrice;
          const discountPrice = xgritProducts[index].apiData.discountPrice;
          bundle.productCheckoutUrl = xgritProducts[index].apiData.checkoutLink;
          if (
            xgritProducts[index].apiData.maxPrice &&
            xgritProducts[index].apiData.maxPrice !== xgritProducts[index].apiData.originalPrice
          ) {
            // Show Save % and both prices
            bundle.savePercentage = Math.floor(((maxPrice - originalPrice) / maxPrice) * 100);
            bundle.maxPrice = maxPrice;
            bundle.originalPrice = originalPrice;
          } else if (
            xgritProducts[index].apiData.maxPrice &&
            xgritProducts[index].apiData.discountPrice &&
            xgritProducts[index].apiData.maxPrice === xgritProducts[index].apiData.originalPrice
          ) {
            // Show Save % and both prices
            bundle.savePercentage = Math.floor(((maxPrice - discountPrice) / maxPrice) * 100);
            bundle.maxPrice = maxPrice;
            bundle.originalPrice = discountPrice;
          } else {
            // Only final price
            bundle.originalPrice = originalPrice;
          }

          // Use discountPrice to calculate monthly payments, as it is the final price after all discounts
          bundle.affirmPrice = Math.ceil(discountPrice * (1.085 / 12));
          bundle.klarnaPrice = +(discountPrice / 4).toFixed(2);
        });
      }
    });
  }
}
