/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const TabFragment = {
  TabComponent: gql`
    fragment TabQuery on baseComponents_tabComponent_BlockType {
      tabbedContentTitle
      tab {
        ... on tab_BlockType {
          tabContentList {
            ... on tabContentList_accordionItem_BlockType {
              accordionSectionTitle {
                ... on accordionSectionTitle_BlockType {
                  sectionTitle
                }
              }
              itemDescription
              itemTitle
              showDescription
            }
          }
          tabTitle
        }
      }
      styles
      typeHandle
    }
  `,
};
