import { Component, Input } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { reviewData } from '../review-data';

@Component({
  selector: 'star-reviews',
  templateUrl: './star-reviews.component.html',
  styleUrls: ['./star-reviews.component.scss'],
})
export class StarReviewsComponent {
  @Input() backgroundInfo: any;
  @Input() textColorValue: string;
  @Input() textColorOverride: string;
  @Input() containerClasses: string;
  @Input() title: string;
  faStar = faStar;
  fiveStars = [1, 2, 3, 4, 5];
  starsReviews = reviewData[0];

  constructor() {}
}
