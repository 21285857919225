import { Component, OnInit, Input, AfterContentInit, Inject } from '@angular/core';
import { Slide, SliderSection } from './slider';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { SafeHtml } from '@angular/platform-browser';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';
import Swiper, { SwiperOptions, Pagination, Navigation, Autoplay } from 'swiper';
import { Image } from '@core-mkt/interfaces/image';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, AfterContentInit {
  @Input() data: SliderSection;
  theme: ThemeInterface;
  containerClasses = '';
  carouselClasses = '';
  containsEyebrow: boolean;
  sliderId = 'slider-' + Math.random().toString(16).slice(2);
  pagination = 'swiper-pagination-' + Math.random().toString(16).slice(2);
  public swiperConfig: SwiperOptions = {
    pagination: {
      type: 'bullets',
      el: `.${this.pagination}`,
      clickable: true,
    },
  };

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private imgixService: ImgixService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  get sliderTitle(): string {
    return this.data.sliderTitle;
  }

  get asideContent(): SafeHtml {
    return this.data.asideContent;
  }

  get sliderSlides(): Slide[] {
    return this.data.sliderSlides;
  }

  get image(): Image {
    if (this.data.sideContentStateSeal && !!this.data.sideContentStateSeal[0]) {
      return this.data.sideContentStateSeal[0];
    }
    return null;
  }

  ngOnInit(): void {
    // Check to see if aside content contains an eyebrow
    if (this.data.asideContent && typeof this.data.asideContent == 'string') {
      this.containsEyebrow = this.data.asideContent.includes('class="eyebrow"');
    }
    this.data.asideContent = this.redactor.bypassSanitizer(this.data.asideContent);
    this.data.sliderTitle = this.redactor.bypassSanitizer(this.data.sliderTitle);
    this.data.sliderSlides.forEach((slide) => {
      slide.slideContent = this.redactor.bypassSanitizer(slide.slideContent);
    });
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: null,
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    Swiper.use([Pagination, Navigation, Autoplay]);
  }

  ngAfterContentInit(): void {
    if (this.data.asideContent) {
      this.containerClasses = 'lg:flex';
      this.carouselClasses = 'lg:w-1/2';
    }
  }

  getImgStyle(img): string {
    return `height:${img.height}px;width:auto`;
  }

  private showAsideContent(sliderPlacement: string): boolean {
    return this.data.sliderAlignment === sliderPlacement;
  }

  private addFullHeightToCarouselCell(): void {
    // This is for the wysiwyg content to be aligned on the middle
    const carouselCells = document.querySelectorAll('.carousel-cell');
    carouselCells.forEach((cell) => {
      cell.classList.add('h-full');
    });
  }
}
