import { Directive, Input, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: 'div[cond-class]',
})
export class ConditionalClassDirective implements OnInit, OnChanges {
  /* There are instances where a class may need to be added to a different element on the page when an element is introduced via ngIf. This takes an id or queryselector string, and adds a class string to the target element.*/
  @Input('target') target: string;
  @Input('addClass') addClass: string;
  queryParams;

  constructor() {}

  ngOnInit(): void {
    this.setStyle();
  }

  ngOnChanges(): void {
    this.setStyle();
  }

  setStyle(): void {
    let targetEl;
    let addClass;
    if (this.target) {
      targetEl = this.target;
    }
    if (this.addClass) {
      addClass = this.addClass.split(' ');
    }
    if (document && targetEl && addClass) {
      document.querySelector(targetEl).classList.add(...addClass);
    }
  }
}
