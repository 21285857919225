<div class="legacy-bgColor text-white text-sm bg-dark" *ngIf="showDefaultNav">
  <header class="font-body container mx-auto flex-wrap flex align-top py-4 sm:px-4 relative">
    <a href="/" class="mr-0 sm:mr-8 min-h-[44px] min-w-[171px]">
      <img
        class="ace__nav-logo hidden lg:flex max-h-10"
        [src]="aaLogo"
        width="250"
        height="50"
        alt="Aceable Logo"
        loading="lazy"
      />

      <img
        class="ace__nav-logo flex lg:hidden max-h-10"
        [src]="aaLogo"
        width="170"
        height="50"
        alt="Aceable Logo"
        loading="lazy"
      />
    </a>
    <nav class="ace__nav flex flex-grow align-middle">
      <ace-header-navlink *ngFor="let navLink of headerData" [data]="navLink" class="items-center hidden lg:flex">
      </ace-header-navlink>
    </nav>
    <a href="tel:18556371886" class="items-center flex mr-4 text-white">
      <fa-icon class="mr-0 xl:mr-2 text-sm fa-1x" [icon]="faPhoneAlt"></fa-icon>
      <span class="phone-number hidden xl:flex">+1 (855) 637 1886</span>
    </a>
    <nav class="ace__nav hidden lg:flex lg:flex-grow lg:align-middle" data-test="accountNav">
      <ace-header-navlink *ngFor="let navLink of accountData" [data]="navLink" class="items-center hidden lg:flex">
      </ace-header-navlink>
    </nav>
    <button (click)="activateMobileMenu = !activateMobileMenu" class="text-white text-xs lg:hidden items-center">
      <fa-icon class="mr-4 text-sm fa-1x" [icon]="faHamburger"></fa-icon>
    </button>
  </header>
</div>
<div *ngIf="activateMobileMenu" class="ace__nav-mobile_legacy flex flex-col justify-items-start content-start fixed top-0 left-0 flex w-full h-full bg-[#21333f80] z-[1022]">
  <div class="ace__nav-mobile_menu_legacy w-[310px] h-full overflow-y-auto bg-white relative">
    <div class="p-5 text-lg bg-[#e8eaeb]">
      <span>Menu </span>
      <fa-icon
        class="text fa-1x text-ace-teal-600 absolute right-5 cursor-pointer"
        (click)="activateMobileMenu = !activateMobileMenu"
        [icon]="faTimes"
      ></fa-icon>
    </div>
    <collapse-link *ngFor="let vertLink of mobileHeaderData" [link]="vertLink"></collapse-link>
  </div>
</div>
