/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const TrustPilotFragment = {
  TrustPilot: gql`
    fragment TrustPilotQuery on baseComponents_trustpilot_BlockType {
      dataName
      dataSku
      seo
      showHideTrustpilot
      trustpilotDisplayType
      typeHandle
    }
  `,
};
