import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RsaComponent } from './rsa/rsa.component';

@NgModule({
  declarations: [RsaComponent],
  exports: [RsaComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, LazyImgModule, VisionButtonModule],
})
export class RsaModule {}
