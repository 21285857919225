import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowService } from './window.service';
import { ServerWindowService } from './server-window.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ServerWindowService, WindowService],
})
export class WindowModule {}
