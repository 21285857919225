import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeefoComponent } from './feefo/feefo.component';

@NgModule({
  declarations: [FeefoComponent],
  exports: [FeefoComponent],
  imports: [CommonModule],
})
export class FeefoModule {}
