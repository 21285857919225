import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CareerCenterArticleCardComponent } from './career-center-article-card/career-center-article-card.component';
import { CareerCenterComponent } from './career-center/career-center.component';

@NgModule({
  declarations: [CareerCenterComponent, CareerCenterArticleCardComponent],
  exports: [CareerCenterComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, LazyImgModule, FormsModule, VisionButtonModule],
})
export class CareerCenterModule {}
