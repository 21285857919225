/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { params } from '@core-mkt/shared/craft/seo-fragment-query';

export const SEODisambigFragment = {
  seoContent: gql`
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_disambigTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOGenericFragment = {
  seoContent: gql`
    fragment BaseSEOFragmentEntry on aceBaseTemplates_genericTemplate_Entry {
      ${params}
    }
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_genericTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOCategoryFragment = {
  seoContent: gql`
  fragment BaseSEOFragmentEntry on aceBaseTemplates_categoryTemplate_Entry {
    ${params}
  }
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_categoryTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOProductFragment = {
  seoContent: gql`
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_productTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOProductREFragment = {
  seoContent: gql`
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_productTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOCareerCenterREFragment = {
  seoContent: gql`
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_careerCenterTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOBundlesREFragment = {
  seoContent: gql`
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_bundlesTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOPracticeTestREFragment = {
  seoContent: gql`
    fragment RealEstateSEOFragmentEntry on aceRealEstateTemplates_practiceTestTemplate_Entry {
      ${params}
    }
  `,
};
