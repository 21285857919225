/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const BreadcrumbFragment = {
  Breadcrumb: gql`
    fragment BreadcrumbQuery on baseComponents_breadcrumbs_BlockType {
      hideOnMobile
      styles
      breadcrumbsList {
        ... on breadcrumbsList_BlockType {
          breadcrumbLink
          breadcrumbText
        }
      }
      typeHandle
    }
  `,
};
