import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImgixService {
  constructor() {}

  buildImgixUrl = (img: string, param?: string): string => {
    const params = 'auto=format' + (param ? `&${param}` : '');
    return img.includes('?') ? `${img}&${params}` : `${img}?${params}`;
  };
}
