import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingIndicatorModule } from '@core-mkt/shared/components/loading-indicator/loading-indicator.module';
import { VisionSelectComponent } from './vision-select/vision-select.component';

@NgModule({
  declarations: [VisionSelectComponent],
  exports: [VisionSelectComponent],
  imports: [CommonModule, FontAwesomeModule, LoadingIndicatorModule],
})
export class VisionSelectModule {}
