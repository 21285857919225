<div *ngIf="displayPromo && !hideStickyBanner" [class]="!displayRE ? 'hidden' : ''" class="bg-ace-pink-600 text-white sticky top-0 z-50">
  <div class="container">
    <div class="flex flex-col md:flex-row justify-between items-center">
      <div class="text-center">
        <span class="promo-text" [innerHTML]="promotionText"></span>
      </div>
      <div class="flex flex-wrap items-center justify-center text-center">
        <span>SALE ENDS IN</span>
        <div class="timer-container">
          <div *ngIf="daysRemaining" class="timer" [ngClass]="{ '--first': daysRemaining }">
            <span> {{ countdown | async | durationText : 'days' }}</span>
            <small>DAY</small>
          </div>
          <div class="timer" [ngClass]="{ '--first': !daysRemaining }">
            <span> {{ countdown | async | durationText : 'hours' }}</span>
            <small>HRS</small>
          </div>
          <div class="timer">
            <span> {{ countdown | async | durationText : 'minutes' }}</span>
            <small>MIN</small>
          </div>
          <div *ngIf="!daysRemaining" class="timer">
            <span> {{ countdown | async | durationText : 'seconds' }}</span>
            <small>SEC</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
