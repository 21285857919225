import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConditionalClassModule } from '@core-mkt/services/conditional-class/conditional-class.module';
import { DurationModule } from '@core-mkt/services/duration/duration.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { VisionVideoButtonModule } from '@core-mkt/shared/components/vision-video-button/vision-video-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';
import { CategoryWidgetModule } from '../category-widget/category-widget.module';
import { FeaturedContentComponent } from './top-section/featured-content/featured-content.component';
import { TopSectionSliderComponent } from './top-section/slider/top-section-slider.component';
import { TopSectionComponent } from './top-section/top-section.component';
import { TopSectionWysiwygComponent } from './top-section/wysiwyg/top-section-wysiwyg.component';

@NgModule({
  declarations: [TopSectionComponent, TopSectionSliderComponent, TopSectionWysiwygComponent, FeaturedContentComponent],
  exports: [TopSectionComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    DurationModule,
    QueryParamModule,
    ConditionalClassModule,
    SwiperModule,
    VisionButtonModule,
    VisionVideoButtonModule,
    CategoryWidgetModule,
  ],
})
export class TopSectionModule {}
