/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { HeaderFragment } from './fragments/header-fragment-query';

export const Template404Query = {
  name: 'Template404Query',
  query: gql`
    query ($site: [String], $slug: [String]) {
      styles: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_aceHomepageTemplate_Entry {
          embedStyles
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }
    ${HeaderFragment.header}
  `,
};
