/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const RsaProductFragment = {
  RsaProduct: gql`
    fragment RsaProductQuery on baseComponents_rsaProduct_BlockType {
      darkMode
      rsaCtaLabel
      rsaCtaLink
      rsaCtaLinkNewTab
      rsaCtaLinkNofollow
      rsaCtaStyle
      titleHeader
      typeHandle
      rsaDescription
      rsaTitle
      rsaBullets {
        ... on rsaBullets_BlockType {
          bulletItem
        }
      }
      rsaImage {
        url
        ... on images_Asset {
          alt
        }
      }
      rsaImageFooterText
    }
  `,
};
