<ng-container>
  <template-homepage *ngIf="templateName === 'template-homepage'"></template-homepage>
  <template-generic
    *ngIf="templateName === 'template-generic'"
    [segmentIdOverride]="segmentIdOverride"
  ></template-generic>
  <template-category
    *ngIf="templateName === 'template-category'"
    [segmentIdOverride]="segmentIdOverride"
  ></template-category>
  <template-product
    *ngIf="templateName === 'template-product'"
    [segmentIdOverride]="segmentIdOverride"
  ></template-product>
  <template-disambig
    *ngIf="templateName === 'template-disambig'"
    [segmentIdOverride]="segmentIdOverride"
  ></template-disambig>
  <template-bundles
    *ngIf="templateName === 'template-bundles'"
    [segmentIdOverride]="segmentIdOverride"
  ></template-bundles>
  <template-practice
    *ngIf="templateName === 'template-practice'"
    [segmentIdOverride]="segmentIdOverride"
  ></template-practice>
  <template-career-center
    *ngIf="templateName === 'template-career-center'"
    [segmentIdOverride]="segmentIdOverride"
  ></template-career-center>
  <template-aarp *ngIf="templateName === 'template-aarp'" [segmentIdOverride]="segmentIdOverride"></template-aarp>
  <template-single *ngIf="templateName === 'template-single'" [segmentIdOverride]="segmentIdOverride"></template-single>
  <template-404 *ngIf="templateName === 'template-404'"></template-404>
</ng-container>
