import type { InsertableOptions } from '@aceable/core';
import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { UserLocationResponse } from '@core-mkt/interfaces/geolocation';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { AdobeAnalyticService } from '@core-mkt/services/adobe-analytics/adobe-analytics.service';
import { MktComponentRegistryService } from '@core-mkt/services/dynamic-component/dynamic-component-registry.service';
import { EmbedStylesService } from '@core-mkt/services/embed-styles/embed-styles.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { GeolocationService } from '@core-mkt/services/geolocation/geolocation.service';
import { SeoDataService } from '@core-mkt/services/seo-data/seo-data.service';
import { WistiaService } from '@core-mkt/services/wistia/wistia.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { cloneDeep } from 'lodash-es';
import { map } from 'rxjs';

@Component({
  selector: 'template-aarp',
  templateUrl: './template-aarp.component.html',
})
export class TemplateAarpComponent implements AfterViewInit, OnInit {
  @ViewChild('head', { read: ViewContainerRef })
  public head: ViewContainerRef;
  @ViewChild('foot', { read: ViewContainerRef })
  public foot: ViewContainerRef;
  resolverData: PageDataResponse;
  pageData: any;
  formattedData: InsertableOptions[];
  includeOptimizely: boolean;
  optimizelyHandle: string;

  constructor(
    private route: ActivatedRoute,
    private seoDataService: SeoDataService,
    private embedStylesService: EmbedStylesService,
    public registryService: MktComponentRegistryService,
    private redactor: WysiwygRedactorService,
    private renderer: Renderer2,
    private wistia: WistiaService,
    private env: EnvService,
    private aarpAnalytics: AdobeAnalyticService,
    private geolocation: GeolocationService,
  ) {
    this.formattedData = [];
    this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    this.pageData = this.resolverData.craftData;
    if (this.pageData.styles.embedStyles) {
      this.embedStylesService.addEmbedStyles(this.pageData.styles.embedStyles);
    }
    this.optimizelyHandle = this.env.get.optimizelyHandle;
    this.includeOptimizely =
      this.pageData.optimizely.optimizelyEnabled ||
      this.pageData.globalOptimizely?.[this.optimizelyHandle][0]?.optimizelyEnabled;
    if (this.pageData.adobe) {
      this.aarpAnalytics.initializeAnalytics(this.pageData);
    }
    this.pageData.entryData.baseTemplate.forEach((section) => {
      if (Object.keys(section).length > 1) {
        this.formattedData.push({
          className: section.typeHandle,
          data: section,
        });
      }
    });
    this.seoDataService.setSeoProperties(this.pageData.seoContent);
  }

  ngOnInit() {
    if (!this.geolocation.getLocationCookie()) {
      this.getUserLocation();
    } else {
      this.geolocation.setStateAbbr(this.geolocation.getLocationCookie().toString());
    }
  }

  getUserLocation(): void {
    this.geolocation.setIsLoadingState(true);

    this.geolocation
      .getUserLocation()
      .pipe(
        map((location: UserLocationResponse) => {
          return location.stateabbr;
        }),
      )
      .subscribe((state) => {
        this.geolocation.setLocationCookie(state);
        this.geolocation.setStateAbbr(state);

        this.geolocation.setIsLoadingState(false);
      });
  }

  ngAfterViewInit(): void {
    this.redactor.makeYouTubeIframeResponsive(this.renderer);
    this.wistia.setRendererForWistiaPosters(this.renderer);
  }
}
