import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VisionButtonModule } from '../vision-button/vision-button.module';
import { VisionSelectModule } from '../vision-select/vision-select.module';
import { ProductPickerComponent } from './product-picker/product-picker.component';

@NgModule({
  declarations: [ProductPickerComponent],
  exports: [ProductPickerComponent],
  imports: [CommonModule, VisionSelectModule, VisionButtonModule],
})
export class ProductPickerModule {}
