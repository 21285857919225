<div class="tabbed-content font-body bg-cover {{ theme?.root }}">
  <div class="container {{ theme?.container }}">
    <div
      *ngIf="tabbedContentTitle"
      class="wysiwyg py-v-spacing sm:p-v-spacing max-w-vision mx-auto text-center"
      [innerHTML]="tabbedContentTitle"
    ></div>
    <div
      *ngIf="data.tab && data.tab.length > 1"
      class="tabbed-content__cta-wrapper py-v-spacing sm:p-v-spacing max-w-vision flex mx-auto justify-center items-stretch"
    >
      <button
        *ngFor="let tabItem of data.tab; let index = index; let first = first; let last = last"
        class="
          flex-grow
          justify-center
          items-center
          tabbed-content-cta
          py-v-spacing
          sm:p-v-spacing
          wysiwyg
          {{ index !== activeIndex && data.tab.length > 1 ? data.backgroundColor + ' hover:font-bold' : '' }}
          {{ data.tab.length > 2 ? 'hidden lg:block' : '' }}
          {{ data.backgroundColor.includes('text-white') ? 'text-white' : 'text-ace-teal-800' }}
        "
        (click)="activeIndex = index"
        [ngClass]="{
          '!font-extrabold': index === activeIndex && data.tab.length > 1,
          'text-white dark-active-tab':
            index === activeIndex && data.tab.length > 1 && data.backgroundColor.includes('text-white'),
          'text-ace-teal-800 active-tab':
            index === activeIndex && data.tab.length > 1 && !data.backgroundColor.includes('text-white'),
          'cursor-pointer rounded-md': data.tab.length > 1
        }"
        [innerHTML]="tabItem.tabTitle"
      ></button>
      <div *ngIf="data.tab.length > 2" class="p-v-spacing w-full select-container lg:hidden bg-ace-grey-400 rounded-md">
        <div class="rounded-md border border-ace-grey-500 bg-ace-grey-100">
          <select
            class="text-sm w-full rounded-md cursor-pointer select-none h-auto bg-ace-grey-100 text-ace-grey-900"
            (change)="onChange($event.target.value)"
            [(ngModel)]="activeIndex"
          >
            <option *ngFor="let tabItem of data.tab; let i = index" [value]="i" [innerHTML]="tabItem.tabTitle"></option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="tabbed-content__tab-wrapper py-v-spacing sm:p-v-spacing max-w-vision mx-auto"
      *ngFor="let tabItem of data.tab; let index = index"
      [class.hidden]="index !== activeIndex"
    >
      <accordion
        class="tabbed-content__tab -mt-6 block"
        *ngIf="tabItem.showContent"
        [data]="tabItem.accordion"
        isChild="true"
      ></accordion>
    </div>
  </div>
</div>
