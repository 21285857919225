<card-reviews
  [title]="reviewTitle"
  [backgroundInfo]="backgroundImage ? backgroundImage : backgroundColor"
  [textColorOverride]="textColorOverride"
  [containerClasses]="containerClasses"
  *ngIf="reviewType === 'cards'"
>
</card-reviews>
<carousel-reviews
  [title]="reviewTitle"
  [backgroundInfo]="backgroundImage ? backgroundImage : backgroundColor"
  [backgroundColorValue]="backgroundColorValue"
  [textColorValue]="textColorValue"
  [textColorOverride]="textColorOverride"
  [containerClasses]="containerClasses"
  *ngIf="reviewType === 'slider'"
>
</carousel-reviews>
<column-reviews
  [title]="reviewTitle"
  [backgroundInfo]="backgroundImage ? backgroundImage : backgroundColor"
  [backgroundColorValue]="backgroundColorValue"
  [textColorValue]="textColorValue"
  [containerClasses]="containerClasses"
  [textColorOverride]="textColorOverride"
  *ngIf="reviewType === 'columns'"
>
</column-reviews>
<column-type2-reviews
  [title]="reviewTitle"
  [backgroundInfo]="backgroundImage ? backgroundImage : backgroundColor"
  [textColorValue]="textColorValue"
  [textColorOverride]="textColorOverride"
  [containerClasses]="containerClasses"
  *ngIf="reviewType === 'columnsType2'"
>
</column-type2-reviews>
<star-reviews
  [title]="reviewTitle"
  [backgroundInfo]="backgroundImage ? backgroundImage : backgroundColor"
  [textColorOverride]="textColorOverride"
  [containerClasses]="containerClasses"
  *ngIf="reviewType === 'stars'"
>
</star-reviews>
<single-review
  [title]="reviewTitle"
  [backgroundInfo]="backgroundImage ? backgroundImage : backgroundColor"
  [textColorValue]="textColorValue"
  [textColorOverride]="textColorOverride"
  [containerClasses]="containerClasses"
  *ngIf="reviewType === 'single'"
>
</single-review>
