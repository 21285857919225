<div class="relative">
  <select [ngClass]="extendClassStyles" class="select-dropdown" (change)="handleChange($event)" [disabled]="disabled"
    tabindex="0">
    <option *ngIf="placeholder" value="" disabled selected>{{isLoadingState ? isLoadingPlaceholder : placeholder }}
    </option>
    <option *ngFor="let item of data" [value]="item.value" [selected]="item.value === defaultSelectedOption">{{ item.label
      }}</option>
  </select>

  <span class="select-icon" *ngIf="!isLoadingState">
    <loading-indicator *ngIf="isLoadingState" label=""></loading-indicator>
  </span>
  </div>
