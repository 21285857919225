/* eslint-disable prettier/prettier */
import { HomeFragment } from '@core-mkt/shared/craft/homepage-fragment';
import { SEOHomeFragment } from '@core-mkt/shared/craft/seo-fragment-query';
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';

export const TemplateHomepageQuery = {
  name: 'TemplateHomepageQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceBaseTemplates_aceHomepageTemplate_Entry {
          hideStickyBanner
          aceHomepageTemplate {
            ...homepageTemplate_fragment
          }
        }
      }
      seoContent: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_aceHomepageTemplate_Entry {
          ...SEOFragmentEntry
        }
      }
      styles: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_aceHomepageTemplate_Entry {
          embedStyles
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_aceHomepageTemplate_Entry {
          optimizelyEnabled
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
      structuredData: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_aceHomepageTemplate_Entry {
          structuredData
        }
      }
      overrides: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_aceHomepageTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }
    }
    fragment productEntry_fragment on EntryInterface {
      ... on aceDrivingProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        productCategory {
          ... on drivingCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOHomeFragment.seoContent}
    ${HomeFragment.aceHomepageTemplate}
  `,
};
