import { Component, Input, OnInit } from '@angular/core';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import type { VisionButton } from '../../vision-button/vision-button/vision-button';
import { AnchorButton } from './anchor-button';

@Component({
  selector: 'anchor-button',
  templateUrl: './anchor-button.component.html',
  styleUrls: ['./anchor-button.scss'],
})
export class AnchorButtonComponent implements OnInit {
  @Input() data: AnchorButton;
  theme: ThemeInterface;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  get style(): string {
    return this.data.buttonStyle;
  }

  get label(): string {
    return this.data.buttonLabel ? this.data.buttonLabel : 'Start Now';
  }

  get alignment(): string {
    return this.data.buttonAlignment;
  }

  get direction(): IconDefinition {
    return this.data.buttonArrowDirection === 'up' ? faChevronUp : faChevronDown;
  }

  get ctaTag(): string {
    return this.data.ctaTag !== null ? this.data.ctaTag : '';
  }

  get anchor(): string {
    return this.data.scrollTo;
  }

  get buttonData(): VisionButton {
    return {
      bgTheme: this.theme.root,
      buttonLink: this.anchor,
      buttonText: this.label,
      buttonStyle: this.style,
      ctaTag: this.ctaTag,
      fullWidth: true,
      newTab: false,
      nofollow: false,
      noopener: false,
      noreferrer: false,
    };
  }
  constructor(private themeParser: ThemeParserService) {}

  ngOnInit(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: null,
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }
}
