import { Injectable } from '@angular/core';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  constructor(private redactor: WysiwygRedactorService) {}
  private _couponCodeEvent: Subject<string> = new Subject<string>();

  crossedOutPrice;
  finalPrice;

  get couponCodeEvent(): Observable<string> {
    return this._couponCodeEvent.asObservable();
  }

  public sendCouponEvent(coupon: string): void {
    this._couponCodeEvent.next(coupon);
  }

  getPricingText(product: XGritCompleteProduct, craftPricingText: string, priceAdjustment?: number): string {
    let pricingText: string;
    this.setPricing(product);

    //split final price for check if decimals are equal to "00"
    const splitFinalPrice = this.finalPrice.split('.');
    const splitCrossedPrice = this.crossedOutPrice.split('.');

    //if a price adjustment is passed in, add it to the final price. Price adjustment is assumed to be a whole number.
    if (priceAdjustment) {
      splitFinalPrice[0] = parseInt(splitFinalPrice[0]) + priceAdjustment;
    }

    this.finalPrice = splitFinalPrice[1] === '00' ? splitFinalPrice[0] : splitFinalPrice.join('.');
    this.crossedOutPrice = splitCrossedPrice[1] === '00' ? splitCrossedPrice[0] : splitCrossedPrice.join('.');

    if (craftPricingText) {
      const crossedOutString = '${this.crossedOutPrice}';
      const finalPriceString = '${this.finalPrice}';
      let crossedOutIndex = craftPricingText.indexOf(crossedOutString);

      if (crossedOutIndex !== -1) {
        craftPricingText =
          craftPricingText.slice(0, crossedOutIndex) +
          '<span class="orig-price">$' +
          craftPricingText.slice(crossedOutIndex);

        crossedOutIndex = craftPricingText.indexOf(crossedOutString);
        const crossedOutEndIndex = crossedOutIndex + crossedOutString.length;

        craftPricingText =
          craftPricingText.slice(0, crossedOutIndex) +
          eval('`' + craftPricingText.slice(crossedOutIndex, crossedOutEndIndex) + '`') +
          '</span>' +
          craftPricingText.slice(crossedOutEndIndex);
      }

      let finalPriceIndex = craftPricingText.indexOf(finalPriceString);

      if (finalPriceIndex !== -1) {
        craftPricingText =
          craftPricingText.slice(0, finalPriceIndex) +
          '<span class="disc-price">$' +
          craftPricingText.slice(finalPriceIndex);

        finalPriceIndex = craftPricingText.indexOf(finalPriceString);
        const finalPriceEndIndex = finalPriceIndex + finalPriceString.length;

        craftPricingText =
          craftPricingText.slice(0, finalPriceIndex) +
          eval('`' + craftPricingText.slice(finalPriceIndex, finalPriceEndIndex) + '`') +
          '</span>' +
          craftPricingText.slice(finalPriceEndIndex);
      }

      craftPricingText = this.redactor.bypassSanitizer(craftPricingText);

      return craftPricingText;
    } else {
      if (product.apiData.checkoutLink.includes('coupon')) {
        if (product.apiData.app === 'RE') {
          pricingText =
            'Starting at <span class="orig-price">' +
            (this.crossedOutPrice !== this.finalPrice ? '$' + this.crossedOutPrice : '') +
            '</span> <span class="disc-price">$' +
            this.finalPrice +
            '</span>';
        } else {
          pricingText =
            '<span class="orig-price-label">Regular</span> <span class="orig-price">$' +
            this.crossedOutPrice +
            ' </span>' +
            '<span>Sale Price</span> <span class="disc-price">$' +
            this.finalPrice +
            '</span>';
        }
      } else if (product.apiData.app === 'RE') {
        pricingText =
          'Starting at <span class="orig-price">' +
          (this.crossedOutPrice !== this.finalPrice ? '$' + this.crossedOutPrice : '') +
          '</span> <span class="disc-price">$' +
          this.finalPrice +
          '</span>';
      } else {
        pricingText = '<span class="disc-price">$' + this.finalPrice + '</span>';
      }
      pricingText = this.redactor.bypassSanitizer(pricingText);

      return pricingText;
    }
  }

  private setPricing(product: XGritCompleteProduct): void {
    if (product.apiData.checkoutLink.includes('coupon')) {
      this.crossedOutPrice = product.apiData.originalPrice.toFixed(2);
      this.finalPrice = product.apiData.discountPrice.toFixed(2);
    } else if (product.apiData.maxPrice && product.apiData.maxPrice !== product.apiData.originalPrice) {
      this.crossedOutPrice = product.apiData.maxPrice.toFixed(2);
      this.finalPrice = product.apiData.discountPrice.toFixed(2);
    } else {
      this.crossedOutPrice = product.apiData.discountPrice.toFixed(2);
      this.finalPrice = product.apiData.discountPrice.toFixed(2);
    }
  }
}
