import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { CjAffiliateService } from '@core-mkt/services/cj-affiliate/cj-affiliate.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import type { SeoConfig } from '@core-mkt/services/script-loader/script-loader-interfaces';
import { ScriptLoaderService } from '@core-mkt/services/script-loader/script-loader.service';
import { RequestService } from '@core-mkt/services/url/url.service';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'marketing',
  template: '',
})
export class MarketingComponent implements OnInit {
  @Input() includeOptimizely: boolean;
  @Input() optimizelyIdOverride?: string;
  @Input() segmentIdOverride?: string;
  resolverData: PageDataResponse;
  pageData: any;
  isOptimizelyEnabled: boolean;
  isSegmentEnabled: boolean;
  private seoUrl: string;

  public constructor(
    private sls: ScriptLoaderService,
    private cjAffiliateService: CjAffiliateService,
    private route: ActivatedRoute,
    private request: RequestService,
    private env: EnvService,
  ) {
    this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    this.pageData = this.resolverData.craftData;
  }

  ngOnInit(): void {
    // Ensure that the Optimizely and Segment scripts are only injected if the IDs are present in the environment
    this.isOptimizelyEnabled = !!this.env.get.optimizelyId;
    this.isSegmentEnabled =
      !!this.env.get.segmentId && this.env.get.segmentId !== null && this.env.get.segmentId !== '';
    if (this.includeOptimizely && this.isOptimizelyEnabled) {
      this.sls.injectScriptByType('optimizely', this.optimizelyIdOverride);
    }
    if (this.isSegmentEnabled) {
      this.sls.injectScriptByType('segment', this.segmentIdOverride);
    }

    // Conditionally set CJEVENTID cookie
    this.cjAffiliateService.conditionallySetCookie();

    // Schema
    this.sls.injectScriptByType('schema');

    // Dynamic Structured Data
    const seoConfig: SeoConfig = {};

    if (this.pageData?.seoContent?.pageTitle !== undefined) {
      seoConfig.pageTitle = this.pageData.seoContent.pageTitle;
    }

    if (this.pageData?.seoContent?.ogUrl !== undefined) {
      seoConfig.pageUrl = this.pageData.seoContent.ogUrl;
    }

    if (this.pageData?.seoContent?.description !== undefined) {
      seoConfig.pageDescription = this.pageData.seoContent.description;
    }

    if (Object.keys(seoConfig).length > 0) {
      this.sls.injectScriptByType('dynamicStructuredData', undefined, undefined, seoConfig);
    }

    // Structured Data
    const script: string | undefined = this.pageData?.structuredData?.structuredData;

    if (script) {
      try {
        const parsedObject = JSON.parse(script);
        const formattedScript = JSON.stringify(parsedObject, null, 2);
        this.sls.injectScriptByType('structuredData', undefined, formattedScript);
      } catch (error) {
        // If there's an error, inject the original script
        this.sls.injectScriptByType('structuredData', undefined, script);
      }
    }
  }
}
