import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VisionDropdownModule } from '@core-mkt/shared/components/vision-dropdown/vision-dropdown.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AmbassadorReferralComponent } from './ambassador-referral/ambassador-referral.component';

@NgModule({
  declarations: [AmbassadorReferralComponent],
  exports: [AmbassadorReferralComponent],
  imports: [CommonModule, FontAwesomeModule, VisionDropdownModule],
})
export class AmbassadorReferralModule {}
