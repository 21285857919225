import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateCareerCenterComponent } from './template-component/template-career-center.component';

const routes: Routes = [{ path: '', component: TemplateCareerCenterComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateCareerCenterRoutingModule {}
