import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { IterableService } from '@core-mkt/services/iterable/iterable.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { faCircleNotch, faCopyright, faTimes } from '@fortawesome/free-solid-svg-icons';
import type { ButtonProps } from './ambassador-invite';
import { AmbassadorInvite } from './ambassador-invite';
import { AmbassadorInviteService } from './ambassador-invite.service';

@Component({
  selector: 'ambassador-invite',
  templateUrl: './ambassador-invite.component.html',
})
export class AmbassadorInviteComponent implements OnInit {
  @Input() data: AmbassadorInvite;
  theme: ThemeInterface;
  faTimes = faTimes;
  faCopy = faCopyright;
  faCircleNotch = faCircleNotch;
  showModal = false;
  isEmailInvalid = false;
  isLoading = false;
  referalForm: FormGroup;
  step = 1;
  modalTitle = 'Refer your friends';
  modalBody = 'Enter your email to join our ambassador program.';
  referalLink = '';
  apiError = '';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly service: AmbassadorInviteService,
    private readonly iterable: IterableService,
    private readonly themeParser: ThemeParserService,
  ) {
    this.referalForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get buttonProps(): ButtonProps {
    return {
      style: this.data.ctaStyle,
      label: this.data.ctaLabel || 'GET MY REFERRAL LINK',
    };
  }

  ngOnInit(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }

  closeModal(): void {
    this.apiError = '';
    this.showModal = false;
    this.isEmailInvalid = false;
    this.referalForm.setValue({ email: '' });
    this.step = 1;
    this.isLoading = false;
    this.modalTitle = 'Refer your friends';
    this.modalBody = 'Enter your email to join our ambassador program.';
  }

  async signUp(): Promise<void> {
    this.apiError = '';
    const emailRegEx = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!emailRegEx.test(this.referalForm.value.email)) {
      this.isEmailInvalid = true;
      return;
    }
    this.isEmailInvalid = false;
    this.isLoading = true;

    try {
      this.service.useAmbassador(this.referalForm.value.email);
      setTimeout(() => {
        this.service.getAmbassador(this.referalForm.value.email).subscribe(async (result) => {
          const { campaign_links } = result.response.data.ambassador;
          this.referalLink = campaign_links[0].url;

          const dataIterable = {
            dataFields: {
              referral_link: this.referalLink,
            },
            email: this.referalForm.value.email,
          };
          await this.iterable
            .updateUser(dataIterable)
            .catch((err) => {
              this.apiError = err;
              this.isLoading = false;
            })
            .then(() => {
              this.signUpComplete();
            });
        });
      }, 1000);
    } catch (exception) {
      this.isLoading = false;
      this.apiError = exception;
    }
  }

  signUpComplete(): void {
    this.isLoading = false;
    this.step = 2;
    this.modalTitle = 'Start sharing now!';
    this.modalBody = 'Start sharing your link below';
  }

  getYear(): number {
    const date = new Date();
    return date.getFullYear();
  }
}
