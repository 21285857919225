import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { ProductService } from '@core-mkt/services/product/product.service';
import { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { IterableService } from '@core-mkt/services/iterable/iterable.service';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { takeWhile } from 'rxjs';
import { FormPayload, EmailForm, UserType } from './email-form';
import { nul } from '@aceable/ward';
import { EnvService } from '@core-mkt/services/env/env.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';

@Component({
  selector: 'email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
  @Input() data: EmailForm;
  ctaLayout = '';
  faTimesCircle = faTimesCircle;
  deListIds: number[];
  formBuilder: FormBuilder;
  formContent: SafeHtml;
  formErr = false;
  formSubmitted = false;
  hasContent = false;
  reListIds: number[];
  signUpForm;
  theme: ThemeInterface;
  product: XGritCompleteProduct;
  postErr = false;
  emailRegEx =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    formBuilder: FormBuilder,
    private ps: ProductService,
    private iterable: IterableService,
    private env: EnvService,
    private PricingService: PricingService,
  ) {
    this.formBuilder = new FormBuilder();

    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: '',
      parentEmail: ['', [Validators.email]],
    });
  }

  get canSubmit(): boolean {
    if (this.data.showParentEmailField && this.data.formVertical === 'DE') {
      if (this.parentEmail.value !== '') {
        return this.canSubmitEmail && this.canSubmitParent;
      }
    }
    return this.canSubmitEmail;
  }

  get canSubmitEmail(): boolean {
    const emailRegEx = this.emailRegEx;
    return this.email.valid && emailRegEx.test(this.email.value);
  }

  get canSubmitParent(): boolean {
    const emailRegEx = this.emailRegEx;
    if (this.data.showParentEmailField && this.parentEmail.value !== '' && this.data.formVertical === 'DE') {
      return this.parentEmail.valid && emailRegEx.test(this.parentEmail.value);
    } else if (this.parentEmail.value == '') {
      return true;
    }
  }

  get ctaLabel(): string {
    if (this.data.ctaLabel && this.data.ctaLabel.length > 0) {
      return this.data.ctaLabel;
    }
    if (this.data.formVertical === 'RE') {
      return 'Subscribe';
    }
    return 'Send';
  }

  get ctaType(): string {
    return this.data.ctaType === 'secondary' || this.data.ctaType === 'tetriary' ? this.data.ctaType : 'primary';
  }

  get email(): FormControl {
    return this.signUpForm.get('email');
  }

  get idArray(): number[] {
    if (this.data.formVertical === 'RE') {
      return this.reListIds;
    }
    return this.deListIds;
  }

  get name(): FormControl {
    return this.signUpForm.get('name');
  }

  get parentEmail(): FormControl {
    return this.signUpForm.get('parentEmail');
  }

  get payloadEmail(): string {
    if (this.data.formVertical !== 'RE' && this.data.showParentEmailField && this.parentEmail.value.length > 0) {
      return this.parentEmail.value;
    }
    return this.email.value;
  }

  get thinForm(): boolean {
    return this.data.formType;
  }

  get hideContentOnMobile(): boolean {
    return this.data.hideContentOnMobile;
  }

  ngOnInit(): void {
    this.setComponentData();
    this.setTheme();
  }

  get formClasses(): string {
    let classes = '';
    if (this.thinForm) {
      classes = 'sm:flex-row thin-form md:ml-auto';
    } else {
      classes = 'mx-auto flex-wrap';
      if (!this.data.showNameField && !this.data.showParentEmailField) {
        classes += 'flex-row';
      }
    }
    return classes;
  }

  getProduct(): void {
    const product = this.ps.extractProduct(this.data.product);
    this.ps.getProductData(product).then((product) => {
      this.product = product;
    });
  }

  getFormPayload(listId: number): FormPayload {
    const userType = this.getUserType(listId);
    const productCategoryHandle = this.env.get.brandConfig.productCategoryHandle;
    if (this.data.formVertical === 'RE') {
      return {
        listId: listId,
        subscribers: [
          {
            dataFields: {
              ...(this.product && { courseState: this.product.craftData.productState }),
              ...(this.product && { courseType: this.product.apiData.filterType }),
              ...(this.product && {
                productType:
                  this.product.craftData[productCategoryHandle].length > 0
                    ? this.product.craftData[productCategoryHandle][0].aceCategoryCode
                    : '',
              }),
            },
            email: userType === 'student' ? this.email.value : this.parentEmail.value,
          },
        ],
        type: this.data.formVertical,
      };
    }
    return {
      listId: listId,
      subscribers: [
        {
          dataFields: {
            studentFirstName: this.name.value,
            studentEmail: this.email.value,
            courseState: this.product ? this.product.craftData.productState : '',
            courseType: this.product ? this.product.apiData.filterType : '',
            userType: userType,
          },
          email: userType === 'student' ? this.email.value : this.parentEmail.value,
        },
      ],
      type: this.data.formVertical,
    };
  }

  getUserType(listId: number): string {
    const idArray = this.idArray;
    if (!this.data.showParentEmailField && this.data.formVertical === 'DE') {
      return UserType.Student;
    }
    if (this.data.showParentEmailField && this.data.formVertical === 'DE' && idArray.indexOf(listId) > 0) {
      return UserType.Student;
    }
    if (this.data.showParentEmailField && this.data.formVertical === 'DE' && idArray.indexOf(listId) === 0) {
      return UserType.Parent;
    }
    if (!this.data.showParentEmailField && this.data.formVertical === 'RE') {
      return UserType.Student;
    }
    if (this.data.showParentEmailField && this.data.formVertical === 'RE') {
      return UserType.Parent;
    }
    return UserType.Unknown;
  }

  /**
   * Checks if user exists, to determine if courseState should be updated
   * @param {listId} number list id to get form payload
   */
  async iterableSubscribe(listId: number): Promise<void> {
    const subscribeData = this.getFormPayload(listId);
    this.iterable.getUser(this.email.value).then(async (response) => {
      const res = response.data;
      if (Object.keys(res).length != 0 && res.user.dataFields.courseState != null) {
        subscribeData.subscribers[0].dataFields.courseState = res.user.dataFields.courseState;
      }
      await this.iterable
        .addToMailList(subscribeData)
        .catch((error) => {
          this.postErr = true;
        })
        .then((response) => {
          if (!this.postErr) {
            this.formSubmitted = true;
          }
        });
    });
  }

  setComponentData(): void {
    this.hasContent = this.data.formContent && this.data.formContent.length > 0;
    this.formContent = this.redactor.bypassSanitizer(this.data.formContent);
    this.ctaLayout = this.data.ctaType;
    if (this.data.formVertical === 'RE') {
      this.reListIds = this.data.subscriptionListIds
        ? this.data.subscriptionListIds.split(',').map((item) => parseInt(item))
        : [92256];
    } else {
      this.deListIds = this.data.subscriptionListIds
        ? this.data.subscriptionListIds.split(',').map((item) => parseInt(item))
        : [916471, 916472];
      if (!this.data.showParentEmailField && this.deListIds[0] === 916471) {
        this.deListIds.shift();
      }
    }
  }

  setTheme(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    if (this.ps.isProductValid(this.data.product)) {
      this.getProduct();
    } else {
      this.ps.currentData.pipe(takeWhile((x) => x === null || Object.keys(x).length > 1)).subscribe((data): void => {
        if (data) {
          this.product = data;
        }
      });
    }
  }

  onSubmit(): void {
    this.postErr = false;
    if (this.canSubmit) {
      this.formErr = false;
      const idArray = this.idArray;
      idArray.forEach((id: number) => {
        this.iterableSubscribe(id);
      });
    } else {
      this.formErr = true;
    }
  }
}
