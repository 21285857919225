import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

@Pipe({
  name: 'durationText',
})
export class DurationTextPipe implements PipeTransform {
  constructor() {
    dayjs.extend(duration);
  }

  /***
   * transform the millisecond duration into something human readable
   * @param {number} millis - the millisecond duration.
   * @param {string} timeMeasure - the time measure we want to return e.g only hours
   * @return {string} the formatted duration value readable by humans
   */
  transform = (millis: number, timeMeasure: string): string => {
    if (millis == null) {
      millis = 0;
    }

    // Get the total seconds and format them into an object with days, hours, minutes and seconds.
    const duration = dayjs.duration(millis);
    let durationFormat = {
      days: duration.get('days'),
      hours: duration.get('hours'),
      minutes: duration.get('minutes'),
      seconds: duration.get('seconds'),
      daysToHours: duration.get('hours'),
    };

    // If we get days we need to convert them to hours and sum them to the current ones in the formated object.
    if (durationFormat.days) {
      durationFormat.daysToHours = durationFormat.days * 24 + durationFormat.hours;
    }

    return this.formatTwoDigits(durationFormat[timeMeasure]);
  };

  //Format value so we can add 0's to the left of single numbers.
  formatTwoDigits = (value: [number]): string => {
    if (value.toString().length <= 1) {
      return `0${value}`;
    }
    return value.toString();
  };
}
