import { Component, Input, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { MktComponentRegistryService } from '@core-mkt/services/dynamic-component/dynamic-component-registry.service';
import { InsertableOptions } from '@aceable/core';
import { EmbedStylesService } from '@core-mkt/services/embed-styles/embed-styles.service';
import { SeoDataService } from '@core-mkt/services/seo-data/seo-data.service';
import { HeaderOverrideService } from '@core-mkt/services/header-override/header-override.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { WistiaService } from '@core-mkt/services/wistia/wistia.service';

@Component({
  selector: 'template-bundles',
  templateUrl: './template-bundles.component.html',
})
export class TemplateBundlesComponent implements AfterViewInit {
  @Input() segmentIdOverride?: string;
  resolverData: PageDataResponse;
  pageData: any;
  formattedData: InsertableOptions[];
  includeOptimizely: boolean;
  h1Override: string;
  optimizelyHandle: string;
  isShowingTrustPilot: boolean;

  constructor(
    private route: ActivatedRoute,
    private embedStylesService: EmbedStylesService,
    private seoDataService: SeoDataService,
    private registryService: MktComponentRegistryService,
    private elementRef: ElementRef,
    private env: EnvService,
    private wistia: WistiaService,
    private renderer: Renderer2,
    private headerOverrideService: HeaderOverrideService,
  ) {
    this.formattedData = [];
    this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    this.pageData = this.resolverData.craftData;
    if (this.pageData.styles.embedStyles) {
      this.embedStylesService.addEmbedStyles(this.pageData.styles.embedStyles);
    }
    this.optimizelyHandle = this.env.get.optimizelyHandle;
    this.includeOptimizely =
      this.pageData.optimizely.optimizelyEnabled ||
      this.pageData.globalOptimizely?.[this.optimizelyHandle][0]?.optimizelyEnabled;
    this.h1Override = this.route.snapshot.queryParamMap.get('hlText')?.split('-').join(' ');
    this.isShowingTrustPilot = this.pageData.entryData?.bundlesTemplate?.find(pti => pti.typeHandle === 'trustpilot')?.showHideTrustpilot ?? false;

    this.pageData.entryData.bundlesTemplate.forEach((section) => {
      if (Object.keys(section).length > 1) {
        this.formattedData.push({
          className: section.typeHandle,
          data: {
            ...section,
          },
        });
      }
    });

    this.seoDataService.setSeoProperties(this.pageData.seoContent);
  }

  ngAfterViewInit(): void {
    this.headerOverrideService.overrideH1Tag(this.renderer, this.elementRef, this.h1Override);
    this.wistia.setRendererForWistiaPosters(this.renderer);
  }
}
