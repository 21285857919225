import { Injectable } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { ServerTrustpilotService } from './server-trustpilot.service';

@Injectable({
  providedIn: 'root',
})
export class TrustpilotService extends ServerTrustpilotService {
  constructor(public sls_: ScriptLoaderService, public bfs_: BrandConfigurationService, state: TransferState) {
    super(sls_, bfs_, state);
  }

  loadTrustpilot = (container: any) => {
    const obs = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.sls_.injectScriptByType('trustpilot');
        }
      });
    });
    obs.observe(container.nativeElement);
  };
}
