export class DateProxy {

  get date(): Date {
    return new Date();
  }

  get now(): number {
    return Date.now();
  }
}
