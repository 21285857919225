import type { Footer } from './footer';

export const footerData: Footer = {
  aceFooterLogo: [
    {
      typeHandle: 'logo',
      logoLink: 'https://www.aceableagent.com/',
      logoImage: [
        {
          url: 'https://xgrit-ecom.imgix.net/ace/Aceable-Logo.png?v=1717003916',
          altText: 'Aceable Agent logo',
        },
      ],
    },
  ],
  aceGeneralLinkGroup: [
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/',
      linkText: 'Home',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/real-estate-license/',
      linkText: 'Get My License',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/real-estate-continuing-education/',
      linkText: 'Continuing Education',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/real-estate-training/',
      linkText: 'Training',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/career-center/',
      linkText: 'Career Center',
      newTab: false,
      nofollow: false,
    },
  ],
  aceGeneralLinkGroup2: [
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/about/',
      linkText: 'About',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/blog/',
      linkText: 'Blog',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/support/',
      linkText: 'Get Help',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/terms-and-conditions/',
      linkText: 'Terms & Conditions',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/privacy-policy/',
      linkText: 'Privacy Policy',
      newTab: false,
      nofollow: false,
    },
  ],
  aceSocialLinkGroup: [
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.tiktok.com/@aceableagent',
      linkText: 'TikTok',
      newTab: true,
      nofollow: false,
      icon: 'tiktok',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.instagram.com/aceableagent/',
      linkText: 'Instagram',
      newTab: true,
      nofollow: false,
      icon: 'instagram',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://x.com/aceable',
      linkText: 'X',
      newTab: true,
      nofollow: false,
      icon: 'x-twitter',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.youtube.com/channel/UC5JBaTSOw3cQY54xsW_Dclw',
      linkText: 'YouTube',
      newTab: true,
      nofollow: false,
      icon: 'youtube',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.facebook.com/AceableAgent/',
      linkText: 'Facebook',
      newTab: true,
      nofollow: false,
      icon: 'facebook-square',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.linkedin.com/showcase/aceableagent/',
      linkText: 'LinkedIn',
      newTab: true,
      nofollow: false,
      icon: 'linkedin',
    },
  ],
  aceShareGroup: [
    {
      label: 'Partner with Us',
      link: '/real-estate-partnerships/',
    },
    {
      label: 'Refer a Friend',
      link: '/referral/invite/',
    },
  ],
  aceShowHideFooterNav: true,
  emailCapture: [],
};
