import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Image } from '@core-mkt/interfaces/image';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { takeWhile } from 'rxjs';
import type { Bullet } from './rsa-product';
import { RsaProduct } from './rsa-product';

@Component({
  selector: 'rsa-product',
  templateUrl: './rsa-product.component.html',
  styleUrls: ['./rsa-product.component.scss'],
})
export class RsaProductComponent implements OnInit {
  @Input() data: RsaProduct;
  faCheck = faCheck;
  product: XGritCompleteProduct;
  titleAppend: string;
  defaultTitle = 'when you select 1 FREE month of Allstate Roadside Services.';
  defaultDescription = `<p>20% of crashes happen while stuck on the side of the road according to the Federal Highway Administration. Make sure you're covered with Allstate Roadside Services®. Only $5/month after your free trial. Cancel anytime.</p>`;
  defaultBullets = [
    {
      bulletItem: 'Flat tire service',
    },
    {
      bulletItem: 'Battery service',
    },
    {
      bulletItem: 'Towing',
    },
    {
      bulletItem: 'Fuel delivery',
    },
    {
      bulletItem: 'Lockout service',
    },
  ];
  defaultImage: Image = {
    url: `https://xgrit-ecom.imgix.net/ace/ee9f08bf-bfb3-4dfd-8f1f-ef25747fea4e.png?v=1678723018&auto=compress,format`,
    altText: 'rsa-logo',
  };

  constructor(private ps: ProductService) {}

  get regularPrice(): number {
    return this.product?.apiData.originalPrice;
  }

  get promotionPrice(): number {
    return this.product?.apiData.discountPrice;
  }

  get percentDiscount(): string {
    if (this.promotionPrice === 0) return '100%';
    if (this.regularPrice && this.promotionPrice) {
      return Math.round(100 - (this.promotionPrice * 100) / this.regularPrice).toString();
    }
    return '0';
  }

  get backgroundColor(): string {
    return this.data.darkMode ? 'bg-ace-grey-900 text-white' : 'bg-gray-100 text-ace-grey-900';
  }

  get textColorPromotionPrice(): string {
    return this.data.darkMode ? 'text-white' : 'text-ace-pink-600';
  }

  get title(): string {
    return this.data.rsaTitle;
  }

  get description(): string {
    return this.defaultDescription;
  }

  get bullets(): Bullet[] {
    return this.defaultBullets;
  }

  get buttonStyle(): string {
    return this.data.rsaCtaStyle;
  }

  get ctaButtonLabel(): string {
    return this.data.rsaCtaLabel ?? this.product?.apiData.title;
  }

  get ctaButtonLink(): string {
    return this.data.rsaCtaLink ?? this.product?.apiData.checkoutLink;
  }

  get ctaButtonLinkNewTab(): boolean {
    return this.data.rsaCtaLinkNewTab;
  }

  get ctaButtonLinkNofollow(): boolean {
    return this.data.rsaCtaLinkNofollow;
  }

  get ctaButtonCtaTag(): string {
    return this.data.rsaCtaTag;
  }

  get ctaButtonData(): VisionButton {
    return {
      buttonStyle: this.buttonStyle,
      bgTheme: this.backgroundColor,
      buttonText: this.ctaButtonLabel,
      buttonLink: this.ctaButtonLink,
      fullWidth: false,
      nofollow: this.ctaButtonLinkNofollow,
      noreferrer: this.ctaButtonLinkNewTab,
      noopener: this.ctaButtonLinkNewTab,
      newTab: this.ctaButtonLinkNewTab,
      ctaTag: this.ctaButtonCtaTag,
    };
  }

  get hasCta(): boolean {
    return (
      (this.ctaButtonLabel && this.ctaButtonLabel !== null && this.ctaButtonLabel.length > 0) ||
      (this.ctaButtonLink && this.ctaButtonLink !== null && this.ctaButtonLink.length > 0)
    );
  }

  get rsaImageUrl(): string {
    if (this.data.rsaImage[0] && this.data.rsaImage[0].url) {
      if (this.data.rsaImage[0].url?.includes('auto=compress,format')) {
        return this.data.rsaImage[0].url;
      }
      return `${this.data.rsaImage[0].url}&auto=compress,format`;
    }
    return this.defaultImage.url;
  }

  get rsaTopButtonText(): string {
    return 'Sign up today and enjoy 1 FREE month of service. Only $5/month after your free trial. Cancel anytime.';
  }

  get rsaTopListText(): string {
    return "Aceable's Exclusive Offer Includes:";
  }

  get rsaImageAltText(): string {
    if (this.data.rsaImage[0]) {
      return this.data.rsaImage[0].altText;
    }
    return this.defaultImage.altText;
  }

  get rsaImageFooterText(): string {
    if (this.data.rsaImageFooterText) {
      return this.data.rsaImageFooterText;
    }
    return 'Allstate has helped over 6,000 Aceable drivers get safely back on the road this year.';
  }

  ngOnInit(): void {
    if (this.ps.isProductValid(this.data.product)) {
      const product = this.ps.extractProduct(this.data.product);
      this.ps.getProductData(product).then((product: XGritCompleteProduct) => {
        this.product = product;
        this.data.rsaTitle = `<${this.data.titleHeader}>Save ${this.percentDiscount}% ${this.defaultTitle} </${this.data.titleHeader}>`;
      });
    } else {
      this.ps.currentData.pipe(takeWhile((x) => x === null || Object.keys(x).length > 1)).subscribe((data): void => {
        if (data) {
          this.product = data;
          this.data.rsaTitle = `<${this.data.titleHeader}>Save ${this.percentDiscount}% ${this.defaultTitle} </${this.data.titleHeader}>`;
        }
      });
    }
  }
}
