import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterModule as AAFootermodule } from '@core-mkt/brands/aa/src/app/components/footer/footer.module';
import { AmbassadorReferralModule } from '@core-mkt/shared/components/ambassador-referral/ambassador-referral.module';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';
import { MultiColumnModule } from '@core-mkt/shared/components/multi-column/multi-column.module';
import { ProductCardModule } from '@core-mkt/shared/components/product-card/product-card.module';
import { ResourcesModule } from '@core-mkt/shared/components/resources/resources.module';
import { ReviewsModule } from '@core-mkt/shared/components/reviews/reviews.module';
import { HeroHomepageModule } from '../../components/hero-homepage/hero-homepage.module';
import { TemplateHomepageComponent } from './template-component/template-homepage.component';
import { TemplateHomepageRoutingModule } from './template-homepage-routing.module';

@NgModule({
  declarations: [TemplateHomepageComponent],
  exports: [TemplateHomepageComponent],
  imports: [
    CommonModule,
    MarketingModule,
    InsertableModule,
    TemplateHomepageRoutingModule,
    HeroHomepageModule,
    ProductCardModule,
    ResourcesModule,
    ReviewsModule,
    MultiColumnModule,
    AmbassadorReferralModule,
    AAFootermodule,
  ],
})
export class TemplateHomepageModule {}
