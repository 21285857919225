/* eslint-disable prettier/prettier */
import { CategoryFragment } from '@core-mkt/shared/craft/category-fragment';
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOCategoryFragment } from './fragments/seo-fragment-query';

export const TemplateCategoryQuery = {
  name: 'TemplateCategoryQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceBaseTemplates_categoryTemplate_Entry {
          hideStickyBanner
          categoryTemplate {
            ...categoryTemplate_fragment
          }
        }
        ... on aceRealEstateTemplates_categoryTemplate_Entry {
          hideStickyBanner
          categoryTemplate {
            ...categoryTemplate_fragment
          }
        }
      }
      seoContent: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_categoryTemplate_Entry {
          ...BaseSEOFragmentEntry
        }
        ... on aceRealEstateTemplates_categoryTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }
      styles: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_categoryTemplate_Entry {
          embedStyles
        }
        ... on aceRealEstateTemplates_categoryTemplate_Entry {
          embedStyles
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aceBaseTemplates_categoryTemplate_Entry {
          optimizelyEnabled
        }
        ... on aceRealEstateTemplates_categoryTemplate_Entry {
          optimizelyEnabled
        }
      }
      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: categoryAATemplateOpti
            }
          }
        }
      }
      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_categoryTemplate_Entry {
          structuredData
        }
      }
      overrides: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_categoryTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
        ... on aceBaseTemplates_categoryTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }
    fragment productEntry_fragment on EntryInterface {
      ... on aceRealEstateProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        aceRealEstateProductCategory {
          ... on realEstateCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOCategoryFragment.seoContent}
    ${CategoryFragment.categoryTemplate}
  `,
};
