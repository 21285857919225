import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DecQueryParamModule } from '@dec/core/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LinkSectionComponent } from './link-section/link-section.component';

@NgModule({
  declarations: [LinkSectionComponent],
  exports: [LinkSectionComponent],
  imports: [CommonModule, FontAwesomeModule, DecQueryParamModule],
})
export class LinkSectionModule {}
