import { Component, Input, OnInit } from '@angular/core';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { BottomRightContent, CategoryWidgets, TopSection } from '../top-section';

@Component({
  selector: 'top-section-wysiwyg',
  templateUrl: './top-section-wysiwyg.component.html',
  styleUrls: ['./top-section-wysiwyg.component.scss'],
})
export class TopSectionWysiwygComponent implements OnInit {
  @Input() rightContent: string;
  @Input() bottomRightContent: BottomRightContent[];
  @Input() categoryWidgets: CategoryWidgets[];
  @Input() data: TopSection;

  constructor(private redactor: WysiwygRedactorService, private imgixService: ImgixService) {}

  ngOnInit() {
    this.rightContent = this.redactor.bypassSanitizer(this.rightContent, 'lazy');
  }
}
