import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EnvService } from '../env/env.service';
import type { ScriptLoaderInlineScript, ScriptLoaderScript } from './script-loader-interfaces';
import { ServerScriptLoaderService } from './server-script-loader.service';

/**
 * The ScriptLoaderService allows for dynamic insertion of marketing scripts on the server or the browser.
 * This is accomplished by using the injectScriptByType function and by passing in a script of type ScriptLoaderScriptType.
 *
 * @example
 *   service.injectScriptByType('gtm');
 */

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService extends ServerScriptLoaderService {
  constructor(@Inject(DOCUMENT) public doc: Document, public env: EnvService) {
    super(doc, env);
  }

  public returnScriptByType(type: string, idOverride?: string): ScriptLoaderScript | ScriptLoaderInlineScript {
    switch (type) {
      case 'segment':
        return {
          // TODO: Replace segmentId.driving with segmentId.homepage after cutover. There are 2 instances to be replaced in the text below.
          text:
            `
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="${
            this.env.get.segmentCdnProxy.length > 0
              ? `https://${this.env.get.segmentCdnProxy}/analytics.js/v1/`
              : `https://cdn.segment.com/analytics.js/v1/`
          }" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${
              idOverride ? idOverride : this.env.get.segmentId
            }";;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load("${idOverride ? idOverride : this.env.get.segmentId}" ${
              this.env.get.segmentApiProxy.length > 0
                ? `, { integrations: { "Segment.io": { apiHost: "${this.env.get.segmentApiProxy}/v1" }}}`
                : ''
            });
          analytics.page({
              url: location.href,
              path: location.pathname,
          });
          }}();
    function XGRITfireConsentEvent() {
      analytics.track('Segment Consent Preference Updated', {
        "context": {
          "consent": {
            "categoryPreferences": {
              "DataSharing": false,
            }
          }
        }
      });
    }
  
    function XGRITgetCookie(name) {
      const match = document.cookie.match(new RegExp('(^| )consent-ad-personalization=([^;]+)'));
      return match ? match[2] : null;
    }
            
    if (window.navigator && window.navigator.globalPrivacyControl) {
      if (!XGRITgetCookie('consent-ad-personalization')) {
          XGRITfireConsentEvent();
        document.cookie = 'consent-ad-personalization=1; expires=` +
            new Date(new Date().setDate(new Date().getDate() + 3700)).toUTCString() +
            `; path=/; domain=${window.location.hostname.split('.').slice(-2).join('.')}';
      }
    }
          `,
          type: 'segment',
          location: 'head',
        };
      case 'optimizely':
        return {
          src: `https://cdn.optimizely.com/js/${idOverride ? idOverride : this.env.get.optimizelyId}.js`,
          type: 'optimizely',
          location: 'head',
        };
      case 'trustpilot':
        return {
          src: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
          type: 'trustpilot',
          location: 'head',
          attrs: ['async'],
        };
      case 'hubspot':
        return {
          src: 'https://js.hsforms.net/forms/v2.js',
          type: 'hubspot',
          location: 'head',
        };
      case 'feefo':
        return {
          src: `https://api.feefo.com/api/javascript/${this.env.get.feefoMarchantId}`,
          type: 'feefo',
          location: 'head',
          attrs: ['async'],
        };
      default:
        return undefined;
    }
  }
}
