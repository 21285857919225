import { Component, Input, OnInit } from '@angular/core';
import { LinkSection } from './link-section';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FooterLink } from '../../footer/footer-link';

@Component({
  selector: 'dec-link-section',
  templateUrl: './link-section.component.html',
  styleUrls: ['./link-section.component.scss']
})
export class LinkSectionComponent implements OnInit {
  @Input() data: LinkSection;
  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;
  sectionOpen: false;
  sectionIconStyle = {'width': '6px', 'height': '16px', 'display': 'inline-block'};

  constructor() {}

  get headlineText(): string {
    return this.data.headlineText;
  }

  get headlineLink(): string {
    return this.data.headlineLink;
  }

  get subLinks(): FooterLink[] {
    return this.data.sublinks;
  }

  shouldHaveNofollow(noFollow: boolean): string {
    return noFollow ? "nofollow" : null
  }

  ngOnInit() {
  }

}
