import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionDropdownModule } from '@core-mkt/shared/components/vision-dropdown/vision-dropdown.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MultiColumnComponent } from './muti-column/multi-column.component';

@NgModule({
  declarations: [MultiColumnComponent],
  exports: [MultiColumnComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, VisionDropdownModule],
})
export class MultiColumnModule {}
