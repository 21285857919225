import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class CalculatorService {
  NASDAQ_API_KEY = 'Qr_NsHKSV6_McpxvJdPM';
  INDICATOR_ID = 'ZSFH';
  COLUMN_TO_SHOW = 'value';
  VALUE_ROWS_PER_REQ = 1;
  url = '';

  constructor(readonly httpClient: HttpClient) {}

  getRegions(): Observable<any> {
    this.url = `https://data.nasdaq.com/api/v3/datatables/ZILLOW/REGIONS?region_type=zip&api_key=${this.NASDAQ_API_KEY}&qopts.columns=region_id,region`;
    return this.httpClient.get(this.url);
  }

  getMedianValueByRegion(region: number): Observable<any> {
    this.url = `https://data.nasdaq.com/api/v3/datatables/ZILLOW/DATA?api_key=${this.NASDAQ_API_KEY}&indicator_id=${this.INDICATOR_ID}&qopts.columns=${this.COLUMN_TO_SHOW}&qopts.per_page=${this.VALUE_ROWS_PER_REQ}&region_id=${region}`;
    return this.httpClient.get(this.url);
  }
}
