import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { CustomDecimalModule } from '@ecom/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BundleComponent } from './bundle/bundle.component';

@NgModule({
  declarations: [BundleComponent],
  exports: [BundleComponent],
  imports: [CommonModule, QueryParamModule, LazyImgModule, FontAwesomeModule, CustomDecimalModule, VisionButtonModule],
})
export class BundleModule {}
