<!-- Columns Type 2 Reviews -->
<div
  [class]="
    backgroundInfo && backgroundInfo.url ? textColorOverride + ' bg-no-repeat bg-center bg-cover' : backgroundInfo
  "
  [style.backgroundImage]="backgroundInfo && backgroundInfo.url && 'url(' + backgroundInfo.url + ')'"
>
  <div class="container-920 mx-auto py-8 w-full max-w-[920px]  {{ containerClasses }}">
    <div *ngIf="title" class="wysiwyg" [innerHTML]="title"></div>
    <div class="flex flex-row justify-center">
      <div
        *ngFor="let review of column2Reviews"
        class="review-col mx-auto max-w-[385px]"
        [ngClass]="{
          'py-v-spacing tab:px-v-spacing max-w-vision': column2Reviews.length === 1,
          'py-v-spacing sm:px-v-spacing ': column2Reviews.length > 1
        }"
      >
        <div class="user-img-wrapper">
          <img
            class="mx-auto mb-v-spacing"
            width="100"
            height="100"
            [src]="review.image.src"
            [alt]="review.image.altText ? review.image.altText : ''"
          />
        </div>
        <div class="text-lg leading-tight font-medium mb-v-spacing">{{ review.review }}</div>
        <div class="mb-v-spacing font-light">{{ review.name }}</div>
      </div>
    </div>
  </div>
</div>
