import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { SwiperModule } from 'swiper/angular';
import { SliderComponent } from './slider/slider.component';

@NgModule({
  declarations: [SliderComponent],
  exports: [SliderComponent],
  imports: [CommonModule, QueryParamModule, LazyImgModule, SwiperModule],
})
export class SliderModule {}
