<div class="footer__{{ footerLocation }}-container {{ alignClass }} {{ flexDirection }} flex">
  <a
    class="{{ footerLocation === 'bottom' ? (first ? 'mr-3 md:w-1/2 lg:w-auto' : 'md:w-1/2 lg:w-auto') : '' }} py-1 text-sm leading-6 hover:text-ace-teal-500 flex {{ layout }} {{ linkClass }}"
    *ngFor="let link of links; let first = first"
    [href]="link.linkUrl"
    [attr.target]="link.newTab ? '_blank' : null"
    [attr.nofollow]="link.nofollow"
    [attr.aria-label]="link.linkText"
  >
      <fa-icon
      *ngIf="link.icon"
      class="mr-3 h-4 w-4 self-center -mt-1 justify-self-end fa-1x"
      [icon]="[iconPrefix, link.icon]"
    ></fa-icon>
    <span class="xs:inline mt-2" [class.hidden]="footerLocation === 'social'">{{ link.linkText }}</span>
  </a>
</div>