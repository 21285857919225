import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { IterableServiceModule } from '@core-mkt/services/iterable/iterable.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { EmailFormComponent } from './email-form/email-form.component';

@NgModule({
  declarations: [EmailFormComponent],
  exports: [EmailFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IterableServiceModule, QueryParamModule, FontAwesomeModule],
})
export class EmailFormModule {}
