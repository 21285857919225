import type { FooterNavLink } from './footer';

export const footerData: FooterNavLink[] = [
  {
    linkUrl: '/sitemap/',
    linkLabel: 'Site Map',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-FTR-SITE_MAP',
    isDropDown: false,
    openNewTab: true,
  },
  {
    linkUrl: 'https://www.aarp.org/about-aarp/privacy-policy/',
    linkLabel: 'Privacy Policy',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-FTR-PRIVACY_POLICY',
    isDropDown: false,
    openNewTab: true,
  },
  {
    linkUrl: 'https://www.aarp.org/about-aarp/terms-of-service/',
    linkLabel: 'Terms of Service',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-FTR-TOS',
    isDropDown: false,
    openNewTab: true,
  },
  {
    linkUrl: 'https://www.aarp.org/about-aarp/aarp-website-copyright-information/',
    linkLabel: 'Copyright Information',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-FTR-COPYRIGHT_INFO',
    isDropDown: false,
    openNewTab: true,
  },
  {
    linkUrl: '/sales/contactus/',
    linkLabel: 'Contact Us',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-FTR-CONTACT_US',
    isDropDown: false,
    openNewTab: true,
  },
  {
    linkUrl: 'https://www.aarp.org/',
    linkLabel: 'More from AARP',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-FTR-MORE_FROM_AARP',
    isDropDown: false,
    openNewTab: true,
  },
  {
    linkUrl: 'https://help.aarp.org/s/ccpa-request-page',
    linkLabel: 'Your Privacy Choices',
    formElementId: 'DRIVER-SAFETY-LNK-CLK-FTR-PRIVACY_CHOICES',
    isDropDown: false,
    openNewTab: true,
  },
];
