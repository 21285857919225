/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const StandAloneCtaFragment = {
  StandAloneCta: gql`
    fragment StandAloneCtaQuery on baseComponents_standAloneCta_BlockType {
      ctaLabel
      ctaLink
      ctaNewTab
      ctaNofollow
      ctaType
      displayPrice
      lineBreak_content
      lineBreak_style
      pricingText
      styles
      typeHandle
    }
  `,
};
