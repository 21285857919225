/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const HeaderFragment = {
  header: gql`
    fragment HeaderFragmentEntry on aceHeader_GlobalSet {
      aceGraphicPromoBanner {
        ... on aceGraphicPromoBanner_graphicBanner_BlockType {
          showHideGraphicPromoBanner
          backgroundColor
          backgroundImage {
            url
            ... on aceImagesS3_Asset {
              altText
            }
          }
          textColor
          image {
            url
            ... on aceImagesS3_Asset {
              altText
            }
          }
          imageAlignment
          graphicBannerContent
          promotionEndsDateTime
          promotionStartDateTime
        }
      }
      aceHeaderPromoBanner {
        ... on aceHeaderPromoBanner_promoBanner_BlockType {
          promotionEndsDateTime
          promotionStartDateTime
          promotionText
          promotionReset {
            ... on promotionReset_BlockType {
              promotionEndsDateTime
              resetText
            }
          }
          showHidePromoBanner
          overwriteCoupon
        }
      }
    }
  `,
};

export const SettingsFragment = {
  settings: gql`
    fragment SettingsFragment on aceHeaderPageSettings_overrides_BlockType {
      customNav {
        ... on customNav_BlockType {
          linkText
          linkUrl
          newTab
          nofollow
        }
      }
      hideNav
      isNavSticky
      navAlignment
      replacementLogos {
        ... on replacementLogos_BlockType {
          logoLink
          newTab
          nofollow
          logoImage {
            url
            ... on aceImagesS3_Asset {
              altText
            }
          }
        }
      }
    }
  `,
};
