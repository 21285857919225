import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { PracticeTestComponent } from './practice-test/practice-test.component';

@NgModule({
  declarations: [PracticeTestComponent],
  exports: [PracticeTestComponent],
  imports: [CommonModule, QueryParamModule, LazyImgModule],
})
export class PracticeTestModule {}
