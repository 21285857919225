<div class="container">
  <div class="flex flex-col md:flex-row">
    <div class="w-full py-v-spacing tab:px-v-spacing max-w-vision mx-auto">
      <div *ngIf="hubspotContent" class="wysiwyg" [innerHtml]="hubspotContent"></div>
    </div>
    <div class="w-full py-v-spacing tab:px-v-spacing max-w-vision mx-auto">
      <div *ngIf="isAceHubspotType" class="lg:w-4/6 mx-auto w-full" id="hubspot"></div>
      <ng-template *ngIf="!isAceHubspotType" ngFor let-form [ngForOf]="multiform" let-i="index">
        <div [hidden]="form.id !== 'hubspot' + currentStep" class="lg:w-4/6 mx-auto w-full" id="{{ form.id }}"></div>
      </ng-template>
    </div>
  </div>
</div>
