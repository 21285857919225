import { CommonModule } from '@angular/common';
import type { ComponentFactory } from '@angular/core';
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { QuizComponent } from './quiz/quiz.component';

@NgModule({
  declarations: [QuizComponent],
  exports: [QuizComponent],
  imports: [CommonModule, QueryParamModule, LazyImgModule],
})
export class QuizModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(): ComponentFactory<QuizComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(QuizComponent);
  }
}
