import type { OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { PrivacyCheckService } from '@core-mkt/services/privacy-check/privacy-check.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'privacy-manager',
  templateUrl: './privacy-manager.component.html',
  styleUrls: ['./privacy-manager.component.scss'],
})
export class PrivacyManagerComponent implements OnInit {
  @Output() hideModal = new EventEmitter<boolean>();
  @ViewChild('privacyManager') privacyManager: ElementRef;
  @ViewChild('privacyManagerOverlay') privacyManagerOverlay: ElementRef;
  doNotSell = 0;
  faTimes = faTimes;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (
      this.privacyManagerOverlay.nativeElement.contains(event.target) &&
      !this.privacyManager.nativeElement.contains(event.target)
    ) {
      this.close();
    }
  }

  constructor(public privacyCheckService: PrivacyCheckService) {}

  get title(): string {
    return 'Privacy Manager';
  }

  get privacyContent(): string {
    return 'Under certain US state laws, you have the right to opt out of the sale or sharing of your personal information. While we do not sell your personal information, we do share it with our partners to deliver personalized ads across different websites and apps.';
  }

  get privacyContentDirections(): string {
    return 'Your privacy is a priority for us. You can manage your privacy preferences below. Switching the toggle to “OFF” or gray indicates “Do not sell or share my personal information”';
  }

  ngOnInit(): void {
    const privacyCookie: string = this.privacyCheckService.getPrivacyCookie();

    if (privacyCookie) {
      this.doNotSell = parseInt(privacyCookie);
      this.updateLightSwitch(this.doNotSell === 0);
    } else {
      this.doNotSell = 1;
      this.updateLightSwitch(this.doNotSell === 1);
    }
  }

  updateLightSwitch(checked: boolean): void {
    this.doNotSell = checked ? 0 : 1;
  }

  savePrivacySetting() {
    this.privacyCheckService.updatePrivacyCheck(this.doNotSell);

    (window as any).analytics.track('Segment Consent Preference Updated', {
      // TODO: 🔥 Waiting for Segment rep on final format of this call
      context: {
        consent: {
          categoryPreferences: {
            DataSharing: this.doNotSell === 0,
          },
        },
      },
    });
    this.close();
  }

  close() {
    this.hideModal.emit(false);
  }
}
