import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { CustomDecimalModule } from '@ecom/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VisionButtonModule } from '../vision-button/vision-button.module';
import { CardItemComponent } from './product-card/card-item/card-item.component';
import { NewCardItemComponent } from './product-card/new-card-item/new-card-item.component';
import { ProductCardComponent } from './product-card/product-card.component';

@NgModule({
  declarations: [ProductCardComponent, CardItemComponent, NewCardItemComponent],
  exports: [ProductCardComponent],
  imports: [CommonModule, QueryParamModule, FontAwesomeModule, VisionButtonModule, CustomDecimalModule],
})
export class ProductCardModule {}
