import gql from 'graphql-tag';
import fetch from 'node-fetch';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@core-mkt/environments/environment';
import { CraftQueries } from '@core-mkt/craft/craft-queries';
import { CraftTemplateType } from '@core-mkt/craft/craft-template-types';
import { CraftQuery } from '@core-mkt/craft/craft-query';
import { SingleEntryQuery } from '@core-mkt/craft/single-entry-query';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { CraftSiteTemplateMapping } from '@core-mkt/craft/craft-site-template-mapping';

@Injectable({
  providedIn: 'root',
})
export class CraftQueryService {
  craftApiUrl: string;
  httpLink: ApolloLink;
  cache: InMemoryCache;
  apolloClient: ApolloClient<unknown>;

  constructor(
    @Inject(CraftQueries) @Optional() private craftQueries: CraftSiteTemplateMapping[],
    private bcs: BrandConfigurationService,
  ) {
    this.craftQueries = CraftQueries;

    this.craftApiUrl = environment.craftApiUrl;

    this.httpLink = createHttpLink({
      uri: `${this.craftApiUrl}`,
      fetch: fetch as any,
    });

    this.cache = new InMemoryCache();

    this.apolloClient = new ApolloClient({
      link: this.httpLink,
      cache: this.cache,
    });
  }

  /**
   * pathToSlug takes the forward slashes, "/", in a string and converts them to double dashes, "--".
   * texas/drivers-ed/adult = texas--drivers-ed--adult.
   *
   * @param {string} path - The path to convert to a slug
   * @return {string} slug
   *
   */
  public pathToSlug(path: string): string {
    return path.replace(/\//g, '--');
  }

  /**
   * lookupQuery takes in a CraftTemplateType and returns the corresponding query.
   *
   * @param {CraftTemplateType} craftTemplateType - a supported craft template type
   * @returns {CraftQuery}
   *
   */
  public lookupQuery(
    craftTemplateType: CraftTemplateType,
    craftQueries: CraftSiteTemplateMapping[] = this.craftQueries,
  ): CraftQuery {
    const queryMapping = craftQueries.filter((obj) => {
      return obj.site == this.bcs.id;
    });
    const query = queryMapping[0].queries.get(craftTemplateType);
    if (query) {
      return query;
    } else {
      return null;
    }
  }

  /**
   * getQuery takes a path, a token, and an optional slug and makes a call to crafts graphQL API to get an entry's page data from the slug / path.
   * The option to pass in a slug instead of always deriving it from the path is in the event that this function is being used for a page preview.
   *
   * @param {string} path - The path of the current page being rendered
   * @param {string} token - The craft preview token
   * @param {string} site - The lowercase brand ID
   * @param {string} [slug] - An optional slug in the case of a preview render where the path is not supplied.
   * @return {Promise<any>} The page's craft data
   *
   */
  public async getQuery(path: string, token: string, site: string, slug?: string): Promise<any> {
    if (!slug) {
      slug = this.pathToSlug(path);
    }
    const entryData = await this.queryCraftLookup(path, token, site, slug);
    let templateType = entryData?.data?.entries[0]?.typeHandle;

    if (!templateType) {
      templateType = '404Template';
    }

    const query = this.lookupQuery(templateType);

    // If a preview token is present, then this page is in preview mode.
    // We need to recreate our apolloClient in order to include this token for Craft.
    // Apollo docs are the freaking worst and you would think there is a better way to do this.
    if (token) {
      this.httpLink = createHttpLink({
        uri: `${this.craftApiUrl}?token=${token}`,
        fetch: fetch as any,
      });

      this.apolloClient = new ApolloClient({
        link: this.httpLink,
        cache: this.cache,
      });
    }

    // If query is not found, return an observable of null.
    if (!query) {
      // Return promise of null.
      return new Promise((resolve) => {
        resolve(null);
      });
    }

    // Query Craft
    const result = this.apolloClient.query({
      fetchPolicy: 'no-cache', // Let's us do 'fragmenting' without definition. This is fine because Craft defines its own structure.
      query: gql`
        ${query.query}
      `,
      variables: {
        site: site,
        slug: slug,
      },
    });

    return result;
  }

  /**
   * queryCraftLookup takes a path, a token, and an optional slug and makes a call to crafts graphQL API to get an entry's template data from the slug / path.
   * The option to pass in a slug instaed of always deriving it from the path is in the event that this function is being used for a page preview.
   *
   * @param {string} path - The path of the current page being rendered
   * @param {string} token - The craft preview token
   * @param {string} site - The lowercase brand ID
   * @param {string} [slug] - An optional slug in the case of a preview render where the path is not supplied.
   * @return {Promise<any>} The page's craft data
   *
   */
  public queryCraftLookup(path: string, token: string, site: string, slug?: string): Promise<any> {
    if (!slug) {
      slug = this.pathToSlug(path);
    }
    // If a preview token is present, then this page is in preview mode.
    // We need to recreate our apolloClient in order to include this token for Craft.
    // Apollo docs are the freaking worst and you would think there is a better way to do this.
    if (token) {
      this.httpLink = createHttpLink({
        uri: `${this.craftApiUrl}?token=${token}`,
        fetch: fetch as any,
      });

      this.apolloClient = new ApolloClient({
        link: this.httpLink,
        cache: this.cache,
      });
    }
    // Query Craft
    const result = this.apolloClient.query({
      fetchPolicy: 'no-cache', // Let's us do 'fragmenting' without definition. This is fine because Craft defines its own structure.
      query: gql`
        ${SingleEntryQuery.query}
      `,
      variables: {
        site: site,
        slug: slug,
      },
    });
    return result;
  }
}
