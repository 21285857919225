import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import axios from 'axios';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import type { PublicBusinessUnitsResponse, SkuProductReviewsResponse } from './trustpilot-response';

const BRAND_STARS_RATING = makeStateKey<Promise<PublicBusinessUnitsResponse>>('brandStarsRating');
const PRODUCT_STARS_RATING = makeStateKey<Promise<SkuProductReviewsResponse>>('productStarsRating');

@Injectable({
  providedIn: 'root', // Ensure the service is provided in the root module
})
export class ServerTrustpilotService {
  private brandStarsRating: Promise<PublicBusinessUnitsResponse>;
  private productStarsRating: Promise<SkuProductReviewsResponse>;

  constructor(public sls: ScriptLoaderService, public bfs: BrandConfigurationService, public state: TransferState) {}

  async getBrandStarsRating(): Promise<PublicBusinessUnitsResponse> {
    this.brandStarsRating = this.state.get<Promise<PublicBusinessUnitsResponse>>(BRAND_STARS_RATING, null);
    if (this.brandStarsRating) {
      return this.brandStarsRating;
    } else {
      const headers = {
        'Content-Type': 'application/json',
        apikey: this.bfs.trustpilotApiKey,
      };
      const baseURL = 'https://api.trustpilot.com/v1/business-units/find';
      const response = await axios.get(baseURL, { headers, params: { name: this.bfs.url } });
      this.state.set<Promise<PublicBusinessUnitsResponse>>(BRAND_STARS_RATING, response.data);
      return response.data;
    }
  }

  async getProductStarsRating(sku: string): Promise<SkuProductReviewsResponse> {
    this.productStarsRating = this.state.get<Promise<SkuProductReviewsResponse>>(PRODUCT_STARS_RATING, null);
    if (this.productStarsRating) {
      return this.productStarsRating;
    } else {
      const headers = {
        'Content-Type': 'application/json',
        apikey: this.bfs.trustpilotApiKey,
      };
      const baseURL = 'https://api.trustpilot.com/v1/product-reviews/business-units/' + this.bfs.trustpilotId;
      const response = await axios.get(baseURL, { headers, params: { sku } });
      this.state.set<Promise<SkuProductReviewsResponse>>(PRODUCT_STARS_RATING, response.data);
      return response.data;
    }
  }

  loadTrustpilot = (container: any): any => {
    return;
  };
}
