/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const GladlyFragment = {
  GHC: gql`
    fragment GladlyHCQuery on baseComponents_gladlyHelpCenter_BlockType {
      gladlyBrandIdOverride
      showGladlyHelpCenter
      typeHandle
    }
  `,
};
