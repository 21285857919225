import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalculatorComponent } from './calculator/calculator.component';

@NgModule({
  declarations: [CalculatorComponent],
  exports: [CalculatorComponent],
  imports: [CommonModule, QueryParamModule, LazyImgModule, FormsModule, ReactiveFormsModule, FontAwesomeModule],
})
export class CalculatorModule {}
