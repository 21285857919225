import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AmbassadorInviteComponent } from './ambassador-invite/ambassador-invite.component';

@NgModule({
  declarations: [AmbassadorInviteComponent],
  exports: [AmbassadorInviteComponent],
  imports: [CommonModule, QueryParamModule, LazyImgModule, FontAwesomeModule, FormsModule, ReactiveFormsModule],
})
export class AmbassadorInviteModule {}
