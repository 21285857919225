import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VisionButtonComponent } from './vision-button/vision-button.component';

@NgModule({
  declarations: [VisionButtonComponent],
  exports: [VisionButtonComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule],
})
export class VisionButtonModule {}
