import { Injectable } from '@angular/core';
import axios from 'axios';
import { FormPayload } from '@core-mkt/shared/components/email-form/email-form/email-form';
import { VerticalService } from '../vertical/vertical.service';

@Injectable({
  providedIn: 'any',
})
export class IterableService {
  constructor(private readonly vertical: VerticalService) {}
  async addToMailList(data: FormPayload): Promise<any> {
    const baseURL = 'https://3qm6hbevde.execute-api.us-east-1.amazonaws.com/default/iterable-list-subscribe';
    const response = await axios.post(baseURL, data);
    return response;
  }

  async updateUser(data: any): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      api_key: this.vertical.getVerticalInfo().iterableApiKey,
    };
    const baseURL = 'https://api.iterable.com/api/users/update';
    const response = await axios.post(baseURL, data, { headers });
    return response;
  }

  async getUser(data: any): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      api_key: this.vertical.getVerticalInfo().iterableGetApiKey,
    };
    const baseURL = `https://api.iterable.com/api/users/${data}`;
    const response = await axios.get(baseURL, { headers });
    return response;
  }
}
