type ScriptLoaderScriptType =
  | 'optimizely'
  | 'segment'
  | 'trustpilot'
  | 'feefo'
  | 'quizWidget'
  | 'hubspot'
  | 'schema'
  | 'wistia'
  | 'aarp'
  | 'gladly'
  | 'structuredData'
  | 'dynamicStructuredData';
type ScriptLoaderDOMType = 'text/javascript' | 'application/ld+json';

interface ScriptLoaderScript {
  src: string;
  type: ScriptLoaderScriptType;
  location: 'head' | 'body';
  attrs?: Array<string>;
}

interface ScriptLoaderInlineScript {
  text: string;
  type: ScriptLoaderScriptType;
  location: 'head' | 'body';
  attrs?: Array<string>;
  scriptDOMType?: ScriptLoaderDOMType;
}

interface SeoConfig {
  pageTitle?: string;
  pageUrl?: string;
  pageDescription?: string;
}

interface StructuredData {
  '@context': string;
  '@type': string;
  headline?: string;
  url?: string;
  description?: string;
}

function isScriptLoaderScript(script: ScriptLoaderScript | ScriptLoaderInlineScript): boolean {
  return (script as ScriptLoaderScript).src !== undefined;
}

function isScriptLoaderInlineScript(script: ScriptLoaderScript | ScriptLoaderInlineScript): boolean {
  return (script as ScriptLoaderInlineScript).text !== undefined;
}

export { isScriptLoaderInlineScript, isScriptLoaderScript };
export type { ScriptLoaderInlineScript, ScriptLoaderScript, ScriptLoaderScriptType, SeoConfig, StructuredData };

