<div class="footer bg-ace-grey-900" *ngIf="showHideFooter">
  <div class="flex flex-col md:flex-row !py-12 md:!py-6 !px-0 container md:gap-6">
    <a href="https://www.aarpdriversafety.org/">
      <img
        class="m-6 md:mr-12 lg:mr-[90px] self-start max-w-none"
        src="https://xgrit-ecom.imgix.net/aarp/AARP-logo-white.png"
        width="153px"
        height="32px"
        alt="AARP Logo"
      />
    </a>
    <div class="flex flex-row lg:flex-col p-6 text-white gap-6 w-full">
      <nav aria-label="footer-nav" class="footer-nav">
        <a
          *ngFor="let navLink of footerData"
          [href]="navLink.linkUrl"
          [attr.data-formelementid]="navLink.formElementId ? navLink.formElementId : null"
          >{{ navLink.linkLabel }}</a
        >
      </nav>
    </div>
  </div>
</div>
