/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { params } from '@core-mkt/shared/craft/seo-fragment-query';

export const SEOAarpFragment = {
  seoContent: gql`
    fragment SEOAarpFragmentEntry on aarpTemplates_baseTemplate_Entry {
      ${params}
    }
  `,
};
