/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const ReviewsFragment = {
  Reviews: gql`
    fragment ReviewsQuery on baseComponents_reviews_BlockType {
      backgroundImage {
        url
      }
      reviewTitle
      reviewType
      styles
      typeHandle
    }
  `,
};
