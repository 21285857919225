import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VisionButtonModule } from '../vision-button/vision-button.module';
import { ResourceCardComponent } from './resource-card/resource-card.component';
import { ResourcesComponent } from './resources/resources.component';

@NgModule({
  declarations: [ResourcesComponent, ResourceCardComponent],
  exports: [ResourcesComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, LazyImgModule, VisionButtonModule],
})
export class ResourcesModule {}
