import { Component, Input, OnInit } from '@angular/core';
import Swiper, { SwiperOptions, Pagination, Navigation, Autoplay } from 'swiper';
import { reviewData } from '../review-data';

@Component({
  selector: 'carousel-reviews',
  templateUrl: './carousel-reviews.component.html',
  styleUrls: ['./carousel-reviews.component.scss'],
})
export class CarouselReviewsComponent implements OnInit {
  @Input() backgroundInfo: any;
  @Input() backupColor: string;
  @Input() backgroundColorValue: string;
  @Input() textColorValue: string;
  @Input() textColorOverride: string;
  @Input() containerClasses: string;
  @Input() title: string;
  sliderReviews = reviewData[1];
  // Swiper pagination and navigation require unique names in case there is more than 1 slider of the same type on a page
  nextSlide = 'swiper-button-next-' + Math.random().toString(16).slice(2);
  prevSlide = 'swiper-button-prev-' + Math.random().toString(16).slice(2);
  public swiperConfig: SwiperOptions = {
    pagination: {
      type: 'fraction',
      renderFraction: function (currentClass, totalClass) {
        return '<span class="' + currentClass + '"></span>' + ' of ' + '<span class="' + totalClass + '"></span>';
      },
    },
    navigation: {
      nextEl: `.${this.nextSlide}`,
      prevEl: `.${this.prevSlide}`,
    },
    loop: true,
  };

  constructor() {}

  ngOnInit() {
    Swiper.use([Pagination, Navigation, Autoplay]);
  }
}
