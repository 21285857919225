import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  getFullUrl(): string {
    return '';
  }

  getSeoUrl(): string {
    return '';
  }

  getProtocol(): string {
    return '';
  }

  getHost(): string {
    return '';
  }

  getHostname(): string {
    return '';
  }

  getPort(): string {
    return '';
  }

  getPathname(): string {
    return '';
  }

  getQueryParams(): { [key: string]: string } {
    return {};
  }

  getQueryParam(key: string): string | null {
    return null;
  }

  getHash(): string {
    return '';
  }
}
