/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const params = `
pageTitle
description
keywords
canonical
nofollow
noindex
ogUrl
ogTitle
ogType
ogImage
`;

export const v3Params = `
canonicalUrl
pageTitle
description
keywords
nofollow
noindex
ogImage
ogTitle
ogType
ogUrl`;

export const v3SEOFragment = {
  seoContent: gql`
  fragment v3SEOFragment on pageEntries_visionTemplateEntry_Entry {
    ${v3Params}
  }
`,
};

export const SEOHomeFragment = {
  seoContent: gql`
  fragment SEOFragmentEntry on aceBaseTemplates_aceHomepageTemplate_Entry {
    ${params}
  }
`,
};
