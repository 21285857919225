import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductCardModule } from '@core-mkt/shared/components/product-card/product-card.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingIndicatorModule } from './../loading-indicator/loading-indicator.module';
import { PriceSelectorComponent } from './price-selector/price-selector.component';

@NgModule({
  declarations: [PriceSelectorComponent],
  exports: [PriceSelectorComponent],
  imports: [CommonModule, FontAwesomeModule, ProductCardModule, LoadingIndicatorModule],
})
export class PriceSelectorModule {}
