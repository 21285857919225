import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';
import { CalculatorService } from './calculator.service';
import { faInfoCircle, faPercent } from '@fortawesome/free-solid-svg-icons';
import { Calculator } from './calculator';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';

@Component({
  selector: 'calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
  @Input() data: Calculator;
  theme: ThemeInterface;
  faInfoCircle = faInfoCircle;
  faPercentege = faPercent;
  selectedComission = 5;
  calculatorForm: FormGroup;
  regions: any;
  commission = '0.00';
  zip = '';
  showNoDataError = false;
  showZipError = false;
  showPercentageError = false;

  constructor(
    private themeParser: ThemeParserService,
    private readonly formBuilder: FormBuilder,
    private readonly service: CalculatorService,
    private redactor: WysiwygRedactorService,
  ) {
    this.calculatorForm = this.formBuilder.group({
      zip: ['', []],
      percentage: ['3', []],
    });
  }

  get showCalculator(): boolean {
    return this.data.showCalculator;
  }

  get sideContent(): string {
    if (this.data.sideContent) return this.data.sideContent;
    return `<h2>How much money do real estate agents make? </h2> 
    <p>Enter a zip code, a commission rate, and the number of commissions you'd hope to make. We'll show you how much you could make when you get your real estate license with AceableAgent! </p>`;
  }

  ngOnInit(): void {
    this.data.sideContent = this.redactor.bypassSanitizer(this.data.sideContent);
    const themeInput: ComponentTheme = {
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    this.service.getRegions().subscribe((res: any) => {
      this.regions = res.datatable.data;
    });
  }

  formChange(comission: number = this.selectedComission): void {
    this.showNoDataError = false;
    this.showZipError = false;
    this.showPercentageError = false;
    this.selectedComission = comission;
    this.zip = this.calculatorForm.value.zip;
    const percentage = this.calculatorForm.value.percentage;

    const numbers = /^[0-9]+$/;
    const decimals = /^\d+(\.\d+)?$/;
    this.showZipError = this.zip.length !== 5 || !this.zip.match(numbers);
    this.showPercentageError =
      !percentage.match(decimals) || parseFloat(percentage) < 0.0 || parseFloat(percentage) > 100.0;
    if (this.showPercentageError || this.showZipError) {
      this.commission = '0.00';
      return;
    }

    if (!this.regions) {
      return;
    }

    const region = this.searchRegion(parseInt(this.zip));
    this.service.getMedianValueByRegion(region).subscribe((res: any) => {
      if (!res.datatable.data.length) {
        this.showNoDataError = true;
        this.commission = '0.00';
      } else {
        const median = res.datatable?.data[0];
        this.commission = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(median * (percentage / 100) * this.selectedComission);
      }
    });
  }

  searchRegion(zip: number): number {
    let regionId = 0;
    this.regions.forEach((region) => {
      if (region.length === 2 && region[1].includes(zip)) {
        regionId = region[0];
      }
    });
    return regionId;
  }
}
