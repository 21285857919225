<div
  *ngIf="theme"
  class="breadcrumb-bg {{ theme?.root }} {{ hideOnMobile ? 'hidden md:block' : '' }}"
  [class.tb-border]="border"
>
  <ul
    class="breadcrumb container list-none py-v-spacing tab:px-v-spacing tab:px-v-spacing
  max-w-vision mx-auto flex flex-wrap items-center {{ theme?.container }} "
  >
    <div *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <li class="inline text-xs md:text-sm breadcrumb-color">
        <a
          *ngIf="breadcrumb.breadcrumbLink"
          class="breadcrumb-link underline mr-0.5 md:mr-1 {{ tealLink ? '!text-ace-teal-800' : '' }}"
          href="{{ breadcrumb.breadcrumbLink }}"
          >{{ breadcrumb.breadcrumbText }}</a
        >
        <span *ngIf="!breadcrumb.breadcrumbLink" class="breadcrumb-no-link">{{ breadcrumb.breadcrumbText }}</span>
      </li>
      <span
        *ngIf="!last"
        class="li-chevron w-4 md:w-5 h-4 md:h-5 text-center inline-block mr-1 md:mr-2 text-xs {{
          tealLink ? '!text-ace-teal-800' : ''
        }}"
      >
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </span>
    </div>
  </ul>
</div>
