import { ElementRef, Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class HeaderOverrideService {
  overrideH1Tag(renderer: Renderer2, el: ElementRef, h1Override: string): void {
    const h1 = el.nativeElement.querySelector('h1');

    if (h1Override) {
      // if the h1's text content is nested within a child element (like span), which can happen in Redactor
      if (h1.children[0]) {
        renderer.setProperty(h1.children[0], 'textContent', h1Override);
      } else {
        renderer.setProperty(h1, 'textContent', h1Override);
      }
    }
  }
}
