import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { TrustpilotComponent } from './trustpilot/trustpilot.component';

@NgModule({
  declarations: [TrustpilotComponent],
  exports: [TrustpilotComponent],
  imports: [CommonModule, QueryParamModule],
})
export class TrustpilotModule {}
