import { Location, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { BrandConfigurationService } from '@core-mkt/services/configuration/brand-configuration.service';
import { SeoDataModule } from '@core-mkt/services/seo-data/seo-data.module';
import { WindowModule } from '@ecom/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import type { BrandConfig } from './interfaces/brand-config';
import { TemplateDynamicModule } from './templates/template-dynamic/template-dynamic.module';

import { ErrorHandlerService } from '@core-mkt/services/error-handler/error-handler.service';
import { IconService } from '@core-mkt/services/iconService/icon.service';

const _orig_prepareExternalUrl = PathLocationStrategy.prototype.prepareExternalUrl;

PathLocationStrategy.prototype.prepareExternalUrl = function (internal) {
  const url = _orig_prepareExternalUrl.call(this, internal);
  const urlSplit = url.split('/');
  const splitLast = urlSplit[urlSplit.length - 1];

  if (url === '') {
    return url;
  }

  if (url.endsWith('.html')) {
    return url;
  }

  if (url.endsWith('/')) {
    return url;
  }

  if (splitLast[0] === '?') {
    return url;
  }

  if (splitLast[0] === '#') {
    return url;
  }

  return url + '/';
};

Location.stripTrailingSlash = function (url) {
  const /** @type {?} */ match = url.match(/#|\?|$/);
  const /** @type {?} */ pathEndIdx = (match && match.index) || url.length;
  const /** @type {?} */ droppedSlashIdx = pathEndIdx - (url[pathEndIdx - 1] === '/' ? 1 : 0);
  const first = url.slice(0, droppedSlashIdx);
  const last = url.slice(pathEndIdx);

  if (first.endsWith('.html')) {
    return first + last;
  }

  return first + '/' + last;
};

export function ConfigLoader(injector: Injector): () => Promise<BrandConfig> {
  return () => injector.get(BrandConfigurationService).loadConfiguration();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    WindowModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    TemplateDynamicModule,
    SeoDataModule,
  ],
  providers: [
    Map,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [Injector],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerService,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: IconService,
      useClass: IconService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
