import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { CareersComponent } from './careers/careers.component';

@NgModule({
  declarations: [CareersComponent],
  exports: [CareersComponent],
  imports: [CommonModule, QueryParamModule],
})
export class CareersModule {}
