import { NgModule } from '@angular/core';
import {
  GladlyHelpCenterComponent
} from './gladly-help-center/gladly-help-center.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [GladlyHelpCenterComponent],
  exports: [GladlyHelpCenterComponent],
  imports: [CommonModule],
})
export class GladlyHelpCenterModule { }
