<div
  *ngIf="theme"
  class="resources__container {{ theme?.root }} bg-cover bg-no-repeat bg-center"
  [style]="theme?.bgImage"
>
  <div class="container {{ theme.container }}">
    <div
      *ngIf="resourceTitle"
      class="py-v-spacing tab:lg:px-v-spacing max-w-vision mx-auto wysiwyg"
      [innerHTML]="resourceTitle"
    ></div>
    <div class="flex justify-center items-stretch flex-col lg:flex-row">
      <ng-template [ngIf]="resourceCards && resourceCards.length % 2 == 0" [ngIfElse]="oddNumberOfResourceCards">
        <resource-card
          *ngFor="let card of resourceCards; let last = last"
          [data]="card"
          class="card card-even flex items-start lg:mb-v-spacing lg:py-v-spacing lg:mb-0"
          [ngClass]="{
            'lg:mr-v-spacing pt-v-spacing': !last,
            'py-v-spacing': last
          }"
        >
        </resource-card>
      </ng-template>
      <ng-template #oddNumberOfResourceCards>
        <resource-card
          *ngFor="let card of resourceCards; let last = last"
          [data]="card"
          class="card flex items-start lg:py-v-spacing"
          [ngClass]="{
            'card-odd mb-v-spacing lg:mb-0': resourceCards && resourceCards.length > 1,
            'mb-v-spacing lg:mb-0': !resourceCards || resourceCards.length === 0,
            'lg:mr-v-spacing pt-v-spacing': !last,
            'py-v-spacing': last
          }"
        >
        </resource-card>
      </ng-template>
    </div>
  </div>
</div>
