<form [formGroup]="emailForm">
  <div class="w-full fixed bottom-0 z-50 text-white transition-all pointer-events-none" 
    [class]="isMinimized ? 'transform translate-y-[80%]  sm:translate-y-[72%]' : ''" 
    *ngIf="showEmailCapture" 
    >
    <div class="mx-auto bg-ace-pink-600 w-full sm:max-w-email flex flex-col p-v-spacing rounded-t-md relative pb-8 banner pointer-events-auto" [class.minimized]="isMinimized">
      <canvas id="canvas" *ngIf="displayConfetti" style="position: absolute; width: 575px; height: 306px; left: 0px; bottom: 0px;"></canvas>
      <div class="absolute right-6 cursor-pointer transition-all delay-100" [class]="!isMinimized ? 'rotate-180 top-5' : 'rotate-0  top-3'" (click)="!isSubmited ? toggleSize() : null">
        <svg *ngIf="!isSubmited" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.12998 15.2901L12.01 11.4101L15.89 15.2901C16.28 15.6801 16.91 15.6801 17.3 15.2901C17.69 14.9001 17.69 14.2701 17.3 13.8801L12.71 9.29006C12.32 8.90006 11.69 8.90006 11.3 9.29006L6.70998 13.8801C6.31998 14.2701 6.31998 14.9001 6.70998 15.2901C7.09998 15.6701 7.73998 15.6801 8.12998 15.2901Z" fill="#FFFFFF"/>
          </svg>  
          <svg *ngIf="isSubmited" (click)="close()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.37142 18.7071C6.98251 19.096 6.34612 19.096 5.95721 18.7071C5.5683 18.3182 5.5683 17.6818 5.95721 17.2929L17.2709 5.97918C17.6598 5.59027 18.2962 5.59027 18.6851 5.97918C19.074 6.36809 19.074 7.00449 18.6851 7.3934L7.37142 18.7071Z" fill="#FFFFFF"/>
            <path d="M17.2709 18.7071C17.6598 19.096 18.2962 19.096 18.6851 18.7071C19.074 18.3182 19.074 17.6818 18.6851 17.2929L7.37142 5.97918C6.98251 5.59027 6.34612 5.59027 5.95721 5.97918C5.5683 6.36809 5.5683 7.00449 5.95721 7.3934L17.2709 18.7071Z" fill="#FFFFFF"/>
            </svg>
      </div>

      <div class="captureBody">
        <div class="pb-4 mt-0 pr-8" *ngIf="!isSubmited" [innerHtml]="displayContent"></div>
        <div class="pb-4 -mt-8 pr-8" *ngIf="!isSubmited && !displayContent">
          <h4 class="text-lg font-extrabold">{{ emailCaptureTitle }}</h4>
          <p class="text-sm" [innerHTML]="emailCaptureContent"></p>
        </div>

        <div *ngIf="isSubmited" class="mb-v-spacing mt-0 pr-8" [innerHtml]="displayContentAfterSubmit"></div>
        <div *ngIf="isSubmited && !displayContentAfterSubmit" class="mb-v-spacing -mt-8 pr-8">
          <h4 class="text-lg font-extrabold">{{ emailCaptureTitle }}</h4>
          <p class="text-sm" [innerHTML]="emailCaptureContent"></p>
        </div>

        <div class="sm:flex sm:flex-row" *ngIf="!isSubmited">
          <input
            type="text"
            placeholder="Email Address"
            class="outline-none h-12 rounded-md p-1 pl-3 border border-gray-300 text-base mr-2 text-gray-900 w-full sm:w-2/3 mb-4 sm:mb-0"
            formControlName="email"
            required
          />
          <button
            class="sm:w-1/3 w-full bg-ace-blue-600 cursor-pointer hover:bg-ace-blue-700 inline-flex items-center justify-center rounded-full text-white"
            (click)="submit()"
          >
            {{ buttonLabel }}
          </button>
        </div>
        <p *ngIf="isEmailInvalid" class="text-xs">{{ emailError }}</p>
      </div>
    </div>
  </div>
</form>

