<div class="dropdown-section sm:pb-0 pb-3 w-[366px]" [class]="dropdownAlignment">
    <p class="dropdown-label font-extrabold">{{dropdownTopLabel}}</p>
    <div class="dropdown inline-block relative text-center hidden sm:block">
        <button
        class="dropdown-button relative leading-6"
        (keyup.enter)="dropdownCollapse = !dropdownCollapse"
        (click)="toggleDropdown()"
        >
            <span class="mr-2 dropdown-text">{{ dropdownPlaceholder }}</span>
            <div class="dropdown-items">
                <span class="dropdown-item-line"></span>
                <fa-icon [icon]="faCaretDown" class="fa-xs dropdown-icon dropdown-caret"> </fa-icon>
                <fa-icon [icon]="faChevronDown" class="fa-xs dropdown-chevron"> </fa-icon>
            </div>
        </button>
        <div class="h-2"></div>
        <ul
        class="dropdown-menu dropdown-section absolute text-left overflow-y-auto bg-white rounded-md  w-[366px] shadow-sm drop-shadow-sm z-50 w-full"
        [ngClass]="{ hidden: !dropdownCollapse, block: dropdownCollapse }"
        >
            <ng-template ngFor let-d [ngForOf]="dropdownItems">
                <li class="dropdown-item">
                <a
                    *ngIf="d.dropdownLink"
                    class="block py-2.5 px-6"
                    href="{{ d.dropdownLink }}"
                    [attr.target]="d.dropdownLinkNewTab ? '_blank' : ''"
                    [attr.data-formelementid]="d.dropdownCtaTag ? d.dropdownCtaTag : null"
                    [attr.rel]="
                    d.dropdownLinkNewTab && d.dropdownLinkNofollow
                        ? 'nofollow noopener noreferrer'
                        : d.dropdownLinkNewTab && !d.dropdownLinkNofollow
                        ? 'noopener noreferrer'
                        : d.dropdownLinkNofollow
                        ? 'nofollow'
                        : ''
                    "
                >
                    {{ d.dropdownLabel }}
                </a>
                    <label *ngIf="!d.dropdownLink" class="text-ace-grey-900">
                        {{ d.dropdownLabel }}
                    </label>
                </li>
            </ng-template>
        </ul>
    </div>
    <select (change)="onChange($event)" id="mobile_select" class="sm:hidden xs:inline-flex relative">
        <option selected disabled [value]="dropdownPlaceholder">{{ dropdownPlaceholder }}</option>
        <option *ngFor="let d of dropdownItems" value="{{ d.dropdownLink }}" [class.new-tab]="d.dropdownLinkNewTab">
           {{ d.dropdownLabel }}
        </option>
    </select>
</div>
