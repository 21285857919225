export const footerNavigation = [
  {
    headlineText: 'Customer Support',
    headlineLink: '/support/',
  },
  {
    text: 'General Questions',
    subLink: '/support/faqs/',
    nofollow: false,
  },
  {
    text: 'Payment Info',
    subLink: '/support/payment-help/',
    nofollow: false,
  },
  {
    text: 'System Requirements',
    subLink: '/support/system-requirements/',
    nofollow: false,
  },
  {
    text: 'Course Requirements',
    subLink: '/support/course-requirements/',
    nofollow: false,
  },
  {
    text: 'Contact Us',
    subLink: 'https://driversed.com/support/',
    nofollow: false,
  },
  {
    text: 'Privacy Policy',
    subLink: '/about-us/privacy-policy/',
    nofollow: false,
  },
  {
    headlineText: 'About DriversEd.com',
    headlineLink: 'https://driversed.com/about-us/',
  },
  {
    text: 'Terms and Conditions',
    subLink: '/terms-and-conditions/',
    nofollow: false,
  },
  {
    text: 'Privacy Policy',
    subLink: '/about-us/privacy-policy/',
    nofollow: false,
  },
  {
    headlineText: 'In-Car Lessons',
    headlineLink: '/driving-lessons/',
  },
  {
    text: 'California Driving Lessons',
    subLink: '/california/driving-lessons/',
    nofollow: false,
  },
  {
    text: 'Georgia Driving Lessons',
    subLink: '/georgia/driving-lessons/',
    nofollow: false,
  },
  {
    text: 'Ohio Driving Lessons',
    subLink: '/ohio/driving-lessons/',
    nofollow: false,
  },
  {
    text: 'Texas Driving Lessons',
    subLink: '/texas/driving-lessons/',
    nofollow: false,
  },
  {
    headlineText: 'Traffic School',
    headlineLink: '/traffic-school/',
  },
  {
    text: 'California Traffic School',
    subLink: '/california/traffic-school/',
    nofollow: false,
  },
  {
    text: 'Michigan BDIC',
    subLink: '/michigan/traffic-school/',
    nofollow: false,
  },
  {
    text: 'Texas Defensive Driving',
    subLink: '/texas/defensive-driving/',
    nofollow: false,
  },
  {
    text: 'Texas DPS Driving Record',
    subLink: '/texas/driving-record/',
    nofollow: false,
  },
  {
    text: 'More States',
    subLink: '/traffic-school/',
    nofollow: false,
  },
  {
    headlineText: 'Teen Drivers Ed',
    headlineLink: '/teen-drivers-ed/',
  },
  {
    text: 'Arizona Drivers Ed',
    subLink: '/arizona/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'California Drivers Ed',
    subLink: '/california/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Colorado Drivers Ed',
    subLink: '/colorado/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Florida Drivers Ed',
    subLink: '/florida/learners-permit/',
    nofollow: false,
  },
  {
    text: 'Florida Permit Test',
    subLink: '/florida/permit-test/',
    nofollow: false,
  },
  {
    text: 'Florida Drug & Alcohol Course',
    subLink: '/florida/drug-and-alcohol/',
    nofollow: false,
  },
  {
    text: 'Georgia Drivers Ed',
    subLink: '/georgia/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Idaho Drivers Ed',
    subLink: '/idaho/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Indiana Drivers Ed',
    subLink: '/indiana/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Minnesota Drivers Ed',
    subLink: '/minnesota/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Nebraska Drivers Ed',
    subLink: '/nebraska/',
    nofollow: false,
  },
  {
    text: 'Ohio Drivers Ed',
    subLink: '/ohio/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Oklahoma Drivers Ed',
    subLink: '/oklahoma/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Pennsylvania Drivers Ed',
    subLink: '/pennsylvania/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Texas Drivers Ed',
    subLink: '/texas/drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Texas Parent-Taught Drivers Ed',
    subLink: '/texas/parent-taught-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Utah Drivers Ed',
    subLink: '/utah/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Virginia Drivers Ed',
    subLink: '/virginia/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Wisconsin Drivers Ed',
    subLink: '/wisconsin/drivers-ed/',
    nofollow: false,
  },
  {
    text: 'More States',
    subLink: '/drivers-ed/',
    nofollow: false,
  },
  {
    headlineText: 'Adult Drivers Ed',
    headlineLink: '/adult-drivers-ed/',
  },
  {
    text: 'Colorado 55+ Mature Drivers Ed',
    subLink: '/colorado/mature-driver-safety/',
    nofollow: false,
  },
  {
    text: 'Nationwide 18+ Adult Drivers Ed',
    subLink: '/adult-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Illinois 18-21 Adult Drivers Course',
    subLink: '/illinois/adult-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Texas 18+ Adult Drivers Course',
    subLink: '/texas/adult-drivers-ed/',
    nofollow: false,
  },
  {
    headlineText: 'Driver Community',
    headlineLink: '/community/',
  },
  {
    text: 'Become a Partner',
    subLink: '/affiliates/',
    nofollow: false,
  },
  {
    text: 'Facebook FanPage',
    subLink: 'https://www.facebook.com/DriversEd',
    nofollow: false,
  },
  {
    text: 'Twitter Feed',
    subLink: 'https://twitter.com/driversedcom',
    nofollow: false,
  },
  {
    text: 'Blog',
    subLink: '/trending/',
    nofollow: false,
  },
  {
    headlineText: 'Driver Resources',
    headlineLink: '/resources/',
  },
  {
    text: 'Auto Central',
    subLink: '/resources/auto-central/',
    nofollow: false,
  },
  {
    text: 'Useful Driving Terms',
    subLink: '/resources/terms/',
    nofollow: false,
  },
  {
    text: 'Driving Information',
    subLink: '/driving-information/',
    nofollow: false,
  },
  {
    text: 'DMV Information',
    subLink: '/dmv/',
    nofollow: false,
  },
  {
    headlineText: 'Mobile Apps',
    headlineLink: '/apps/',
  },
  {
    text: 'Drivers Ed App',
    subLink: '/apps/drivers-ed/',
    nofollow: false,
  },
  {
    headlineText: 'Become a Partner',
    headlineLink: '/affiliates/',
  },
];
