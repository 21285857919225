import { Component, Input } from '@angular/core';
import { HeaderNavLink } from '../header/header';
import { faChevronDown, faChevronUp, faArrowRight, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'collapse-link',
  templateUrl: './collapse-link.component.html',
})
export class CollapseLinkComponent {
  @Input() link: HeaderNavLink;
  collapsed = true;
  faCaretDown = faChevronDown;
  faCaretUp = faChevronUp;
  faArrowRight = faArrowRight;
  faCaretDownLegacy = faCaretDown;
  faCaretUpLegacy = faCaretUp;

  constructor() {}
}
