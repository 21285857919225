<div class="container">
  <div
    *ngIf="showHideTrustpilot"
    #trustpilot
    id="trustpilot"
    class="trustpilot-widget py-v-spacing tab:px-v-spacing max-w-vision mx-auto {{ pilotType }}"
    data-locale="en-US"
    [attr.data-template-id]="trustPilotDisplayType.template"
    [attr.data-businessunit-id]="businessId"
    [attr.data-style-height]="trustPilotDisplayType.height"
    [attr.data-sku]="dataSku"
    [attr.data-name]="dataName"
    data-style-width="100%"
    data-theme="light"
    data-stars="1,2,3,4,5"
    data-review-languages="en"
  >
    <a [attr.href]="brandUrl" target="_blank" rel="noopener">Trustpilot</a>
  </div>
</div>
