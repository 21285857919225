<!-- Columns Reviews -->
<div
  class="column-wrapper"
  [class]="
    backgroundInfo && backgroundInfo.url ? textColorOverride + ' bg-no-repeat bg-center bg-cover' : backgroundInfo
  "
  [style.backgroundImage]="backgroundInfo && backgroundInfo.url ? 'url(' + backgroundInfo.url + ')' : null"
>
  <div class="container {{ containerClasses }}">
    <div *ngIf="title" class="wysiwyg tab:px-v-spacing max-w-vision mx-auto" [innerHTML]="title"></div>
    <div class="flex flex-row justify-center">
      <div
        *ngFor="let review of columnReviews"
        [ngClass]="{
          'py-v-spacing sm:px-v-spacing': columnReviews.length > 1,
          'py-v-spacing tab:px-v-spacing max-w-vision mx-auto': columnReviews.length === 1
        }"
      >
        <div
          class="text-center md:text-left text-md font-normal"
          [class]="darkBold ? 'dark-bold' : 'teal-bold'"
          [innerHtml]="review.review"
        ></div>
        <div class="text-xs my-v-spacing text-center md:text-left">
          — <strong>{{ review.name }}</strong
          >, {{ review.description }}
        </div>
      </div>
    </div>
  </div>
</div>
