import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, HostListener, Inject, Input } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from '@core-mkt/services/cookie/cookie.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { IterableService } from '@core-mkt/services/iterable/iterable.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import { StorageService } from '@core-mkt/services/storage/storage.service';
import { VerticalService } from '@core-mkt/services/vertical/vertical.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { FormPayload } from '@core-mkt/shared/components/email-form/email-form/email-form';
import * as confetti from 'canvas-confetti';
import { EmailCapture } from '../footer/footer';

@Component({
  selector: 'email-capture',
  templateUrl: './email-capture.component.html',
  styleUrls: ['./email-capture.component.scss'],
})
export class EmailCaptureComponent implements OnInit {
  @Input() data: EmailCapture;
  emailForm: FormGroup;
  isSubmited = false;
  isEmailInvalid = false;
  window: Window;
  buttonLabelDefault = 'Get My Discount';
  display: string;
  displayConfetti = false;
  displayAfterSubmit: string;
  couponDefault = 'CAREER20';
  dismissedString = 'dismissed-';
  path: string;
  showEmailCapture = false;
  ambassador: boolean;
  emailMinimized = false;
  isMinimized = false;
  isMobile = false;
  now: number;
  saleActive = false;

  constructor(
    private vertical: VerticalService,
    private readonly formBuilder: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
    private redactor: WysiwygRedactorService,
    private iterable: IterableService,
    private readonly storageService: StorageService,
    private env: EnvService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private pricingService: PricingService,
    private viewPortScroller: ViewportScroller,
    private cookie: CookieService,
  ) {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.window = this.document.defaultView;
  }

  get coupon(): string {
    return this.data?.coupon || this.couponDefault;
  }

  get displayContent(): string {
    return this.display;
  }

  get emailError(): string {
    return 'Please enter a valid email';
  }

  get emailCaptureTitle(): string {
    return 'Limited-Time Sale: Save 20% Today!';
  }

  get emailCaptureContent(): string {
    if (!this.isSubmited && !this.displayContent) {
      return "You scored 20% off your course! There's never been a better time to join the real estate industry, so launch your new career today. Deal expires Sunday.";
    } else if (this.isSubmited && !this.displayContentAfterSubmit) {
      'Coupon code <strong>"' + this.coupon + '</strong>" will be automatically applied at checkout';
    } else {
      return '';
    }
  }

  get displayContentAfterSubmit(): string {
    return this.displayAfterSubmit;
  }

  get buttonLabel(): string {
    return this.data?.buttonLabel || this.buttonLabelDefault;
  }

  get saleStart(): Date {
    return new Date(this.data?.saleStart);
  }

  get saleEnd(): Date {
    return new Date(this.data?.saleEnd);
  }

  ngOnInit(): void {
    this.getEmailMinimizeCookie();
    const amb = this.route.snapshot.queryParamMap?.get('campaignid');
    this.now = new Date().getTime();
    if (amb === '33113') {
      this.ambassador = true;
    }
    this.path = this.vertical.getVerticalInfo().vertical;
    this.display = this.redactor.bypassSanitizer(this.data?.displayContent);
    this.displayAfterSubmit = this.redactor.bypassSanitizer(this.data?.displayContentAfterSubmit);
    this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
      }
    });

    // Detect if user has already submitted the form
    if (this.getEmailCouponCookie()?.toString()) {
      // Get coupon code from cookie
      const coupon = this.getEmailCouponCookie().toString();
      // Send coupon event if cookie exists
      this.pricingService.sendCouponEvent(coupon);
      // Set coupon code to be displayed
      this.isSubmited = true;
    }
  }

  /**
   * Retrieves the value of the 'emailCoupon' cookie.
   * @returns The value of the 'emailCoupon' cookie, or null if the cookie is not found.
   */
  public getEmailCouponCookie(): string | null {
    return this.cookie.getCookie('emailCoupon');
  }

  /**
   * Sets the email coupon cookie with the provided coupon value.
   */
  public setEmailCouponCookie(): void {
    this.cookie.setCookie('emailCoupon', this.coupon);
  }

  /**
   * Retrieves the value of the 'emailMinimize' cookie.
   * @returns The value of the 'emailMinimize' cookie, or null if the cookie is not found.
   */
  public getEmailMinimizeCookie(): void {
    const cookieValue = this.cookie.getCookie('emailMinimize');
    this.isMinimized = cookieValue === 'true';
  }

  /**
   * Sets the email form state cookie with the provided state value.
   * @param state The state of the email form (e.g., 'minimized' or 'expanded').
   */
  public setEmailMinimizeCookie(state: string): void {
    this.cookie.setCookie('emailMinimize', state);
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.data?.saleStart && this.data?.saleEnd) {
      const saleStartTime = this.saleStart.getTime();
      const saleEndTime = this.saleEnd.getTime();
      if (this.now < saleEndTime.valueOf() && this.now > saleStartTime.valueOf()) {
        this.saleActive = true;
      }
    }
    this.setEmailCapture();
  }

  setEmailCapture(overide?: boolean): void {
    const minimizedValue = this.cookie.getCookie('emailMinimize');
    if (overide !== undefined) {
      this.showEmailCapture = overide;
      return;
    }
    const isOnRealState = this.env.get.brandConfig.id === 'AA';
    const dismissed = this.storageService.get(`${this.dismissedString}-${this.coupon}`) === 'true';
    this.showEmailCapture =
      !this.saleActive &&
      !this.ambassador &&
      isOnRealState &&
      this.data?.showEmailCapture &&
      !dismissed &&
      this.window.scrollY > 100;

    if (minimizedValue !== 'true') {
      if (this.window.scrollY < 100) {
        this.isMinimized = false;
      }
    }
  }

  async submit(): Promise<void> {
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
    if (!emailRegEx.test(this.emailForm.value.email)) {
      this.isEmailInvalid = true;
      return;
    }
    this.isEmailInvalid = false;
    const iterableData: FormPayload = {
      listId: 94171,
      subscribers: [
        {
          dataFields: {},
          email: this.emailForm.value.email,
        },
      ],
      type: 'RE',
    };

    await this.iterable
      .addToMailList(iterableData)
      .catch(() => {
        this.isSubmited = false;
      })
      .then(() => {
        this.isSubmited = true;
        this.pricingService.sendCouponEvent(this.coupon);
        this.viewPortScroller.scrollToAnchor('bundle');
        this.setOffConfetti();
        // Set cookie to prevent form from showing again
        this.setEmailCouponCookie();
      });
  }

  setOffConfetti(): void {
    this.displayConfetti = true;

    setTimeout(() => {
      const canvas = document.getElementById('canvas');

      confetti.create(canvas)({
        shapes: ['square'],
        particleCount: 100,
        spread: 40,
        scalar: 0.5,
        origin: {
          y: 1,
          x: 0.5,
        },
      });
    }, 500);

    setTimeout(() => {
      this.displayConfetti = false;
    }, 5000);
  }

  //minimize function
  toggleSize(): void {
    this.isMinimized = !this.isMinimized;
    this.setEmailMinimizeCookie(this.isMinimized ? 'true' : 'false');
  }

  close(): void {
    this.storageService.set(`${this.dismissedString}-${this.coupon}`, 'true');
    this.showEmailCapture = false;
  }
}
