import { Directive, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { EnvService } from '../env/env.service';

@Directive({
  selector: 'a[href]',
})
export class QueryParamDirective implements OnInit, OnChanges {
  @HostBinding('attr.href') hrefAttr = '';
  @Input() href: string;
  queryParams;
  currentPathList: UrlSegment[];
  private ambassadorParams = ['mbsy', 'campaignid'];

  constructor(private route: ActivatedRoute, private env: EnvService) {}
  ngOnInit(): void {
    this.currentPathList = this.route.snapshot.url;
    this.setHref();
  }

  ngOnChanges() {
    this.setHref();
  }
  addQueryParams(href): string {
    const pubSite = this.env.get.appURL;
    if (!href) return;
    if (href.includes('mailto:') || href.includes('tel:')) return href;
    if (!href.includes(pubSite) && href.charAt(0) !== '/') {
      return href;
    }
    let parsedLink = href;
    if (!href.includes('?') && Object.keys(this.route.snapshot.queryParams).length > 0) {
      parsedLink += '?';
      Object.entries(this.route.snapshot.queryParams).forEach(([key, value], index) => {
        if (index === 0) {
          parsedLink += `${key}=${value}`;
        } else {
          parsedLink += `&${key}=${value}`;
        }
      });
      return parsedLink;
    }
    Object.entries(this.route.snapshot.queryParams).forEach(([key, value]) => {
      if (!href.includes(key)) {
        if(href.includes('checkout')) {
          if(this.ambassadorParams.some(param => key.includes(param))){
            parsedLink += `&${key}=${value}`;
          }
        }else{
          parsedLink += `&${key}=${value}`;
        }
      }
    });
    return parsedLink;
  }

  setHref(): void {
    const parsedLink = this.addQueryParams(this.href);
    this.hrefAttr = parsedLink;
  }
}
