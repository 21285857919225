/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { AdobeAarpFragment } from './fragments/adobe-fragment-query';
import { HeaderFragment } from './fragments/header-fragment-query';
import { SEOAarpFragment } from './fragments/seo-fragment-query';

export const TemplateAarpQuery = {
  name: 'TemplateAarpQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aarpTemplates_baseTemplate_Entry {
          hideStickyBanner
          baseTemplate {
            ... on baseTemplate_gladlyHelpCenter_BlockType {
              showGladlyHelpCenter
              typeHandle
            }
            ... on baseTemplate_aarpTopSectionSlider_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              contentAlignment
              ctaArrowDirection
              ctaLabel
              ctaLink
              ctaNewTab
              ctaNofollow
              ctaType
              ctaTag
              hideGuaranteeLink
              modalContent
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              pricingText
              product {
                ...productEntry_fragment
              }
              productSeal {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              sectionContent
              eyebrow
              sectionTitle
              showOfferTimer
              showPricing
              slideControlStyle
              slideIndicatorStyle
              slideAutoplay
              autoplayTransitionDelay
              aarpTopSectionSlides {
                ... on aarpTopSectionSlides_BlockType {
                  slideContent
                  slideImage {
                    url
                    ... on aarpImagesS3_Asset {
                      altText
                    }
                  }
                  slideImageLink
                  slideLinkNewTab
                  slideLinkNofollow
                }
              }
              starCaption
              starRating
              textColor
              typeHandle
            }
            ... on baseTemplate_aarpHomeHero_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              heroContent
              heroImage {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              imageAlignment
              textColor
              typeHandle
              aarpButtonList {
                ... on aarpButtonList_BlockType {
                  buttonIcon {
                    url
                    ... on aarpImagesS3_Asset {
                      altText
                    }
                  }
                  buttonTitle
                  buttonSubtitle
                  buttonLink
                  buttonLinkNewTab
                  buttonLinkNofollow
                  buttonCtaTag
                }
              }
            }
            ... on baseTemplate_aarpSliderCard_BlockType {
              typeHandle
              backgroundColor
              textColor
              sliderTitle
              sliderDescription
              displayAllCardMobile
              slidesCards {
                ... on slidesCards_BlockType {
                  slideImage {
                    url
                    ... on aarpImagesS3_Asset {
                      altText
                      height
                      width
                    }
                  }
                  slideContent
                  slideButtonStyle
                  slideButtonLabel
                  slideButtonLink
                  slideButtonNewTab
                  slideButtonNofollow
                }
              }
            }
            ... on baseTemplate_aarpMultiColumn_BlockType {
              backgroundColor
              backgroundImage {
                url
              }
              textColor
              componentTitle
              columnList: columnList {
                ... on columnList_BlockType {
                  columnContent
                  dropdownTitle
                  dropdownAlignment
                  dropdown {
                    dropdownLabel
                    dropdownLink
                    dropdownLinkNewTab
                    dropdownLinkNofollow
                  }
                }
              }
              twoColumnWidthDistribution
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              typeHandle
            }
            ... on baseTemplate_aarpMultiCard_BlockType {
              backgroundColor
              backgroundImage {
                url
              }
              componentTitle
              aarpCardList {
                ... on aarpCardList_BlockType {
                  cardButtonLabel
                  cardButtonLink
                  cardButtonLinkNewTab
                  cardButtonLinkNofollow
                  cardButtonType
                  cardButtonCtaTag
                  cardContent
                  cardImage {
                    url
                    ... on aarpImagesS3_Asset {
                      altText
                    }
                  }
                  imageShape
                }
              }
              typeHandle
            }
            ... on baseTemplate_aarpReviews_BlockType {
              backgroundColor
              backgroundImage {
                url
              }
              textColor
              reviewType
              reviewTitle
              typeHandle
            }
            ... on baseTemplate_aarpRsaProduct_BlockType {
              darkMode
              titleHeader
              rsaCtaLabel
              rsaCtaLink
              rsaCtaLinkNewTab
              rsaCtaLinkNofollow
              rsaCtaTag
              rsaCtaStyle
              typeHandle
              rsaImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
        }
              rsaImageFooterText
            }
            ... on baseTemplate_aarpLearnMore_BlockType {
              typeHandle
              backgroundColor
              textColor
              learnMoreContent
              componentPadding
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              ctaStyle
              ctaLabel
              ctaNofollow
              ctaNewTab
              ctaLink
              ctaTag
              layout
            }
            ... on baseTemplate_aarpLayout_BlockType {
              typeHandle
              backgroundColor
              backgroundImage {
                url
              }
              textColor
              image {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              imageAlignment
              layoutTitle
              layoutContent
              layoutRightContent
              dropdownPlaceholder
              dropdownButtonType
              dropdown {
                ... on dropdown_BlockType {
                  dropdownLabel
                  dropdownLink
                  dropdownLinkNewTab
                  dropdownLinkNofollow
                }
              }
              buttonLayout
              buttonAlignment
              aarpButtons {
                ... on aarpButtons_BlockType {
                  buttonLinkNewTab
                  buttonLinkNofollow
                  buttonLabel
                  buttonLink
                  buttonType
                  buttonCtaTag
                }
              }
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
            }
            ... on baseTemplate_aarpAccordion_BlockType {
              typeHandle
              accordionTitle
              accordionLeftContent
              buttonType
              buttonLabel
              buttonLink
              buttonLinkNewTab
              buttonLinkNofollow
              buttonCtaTag
              accordionDisplayType
              accordionItems {
                ... on accordionItems_BlockType {
                  itemDescription
                  itemTitle
                  accordionSectionTitle {
                    ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                      sectionTitle
                    }
                  }
                  accordionImage {
                    url
                    ... on aarpImagesS3_Asset {
                      altText
                    }
                  }
                }
              }
            }

            ... on baseTemplate_aarpResources_BlockType {
              typeHandle
              resourcesBackgroundColor
              resourcesBackgroundImage {
                url
              }
              resourcesTextColor
              resourcesTitle
              aarpResourceCards {
                ... on aarpResourceCards_BlockType {
                  resourceImage {
                    url
                    ... on aarpImagesS3_Asset {
                      altText
                    }
                  }
                  resourceBackgroundColor
                  resourceCtaLabel
                  resourceCtaLink
                  resourceCtaLinkNewTab
                  resourceCtaLinkNofollow
                  resourceCtaStyle
                  resourceCtaTag
                  resourceTextContent
                }
              }
            }
            ... on baseTemplate_aarpLayoutCard_BlockType {
              cardType
              cardAlignment
              backgroundColor
              textColor
              cardImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              contentCardTitle
              layoutContent
              cardContent
              cardButtonType
              cardButtonLabel
              cardButtonLink
              cardButtonLinkNewTab
              cardButtonLinkNofollow
              cardButtonCtaTag
              typeHandle
            }
            ... on baseTemplate_aarpAnchorButton_BlockType {
              backgroundColor
              buttonAlignment
              buttonLabel
              typeHandle
              scrollTo
              buttonStyle
              buttonArrowDirection
            }
            ... on baseTemplate_aarpAnchor_BlockType {
              typeHandle
              anchorName
            }
            ... on baseTemplate_aarpBreadcrumbs_BlockType {
              typeHandle
              backgroundColor
              textColor
              hideOnMobile
              breadcrumbsList {
                ... on breadcrumbsList_BlockType {
                  breadcrumbLink
                  breadcrumbText
                }
              }
            }
            ... on baseTemplate_aarpStandAloneCta_BlockType {
              typeHandle
              backgroundColor
              ctaLabel
              ctaLink
              ctaNewTab
              ctaNofollow
              ctaType
              ctaTag
              displayPrice
              pricingText
              textColor
            }
            ... on baseTemplate_aarpFaq_BlockType {
              typeHandle
              backgroundColor
              backgroundImage {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              textColor
              faqTitle
              faqSubTitle
              answerOverride {
                ... on answerOverride_BlockType {
                  state
                  questionNumber
                  override
                }
              }
            }
            ... on baseTemplate_aarpTabComponent_BlockType {
              backgroundColor
              tabbedContentTitle
              typeHandle
              tab {
                ... on tab_BlockType {
                  isActive
                  tabContentList {
                    ... on tabContentList_accordionItem_BlockType {
                      itemDescription
                      itemTitle
                      showDescription
                      accordionSectionTitle {
                        ... on accordionSectionTitle_BlockType {
                          sectionTitle
                        }
                      }
                    }
                  }
                  tabTitle
                }
              }
            }
            ... on baseTemplate_aarpPriceSelector_BlockType {
              typeHandle
              selectorInstructions
              productCardBG
              productCardColor
              cardText
              cardTitle
              selectorList {
                ... on selectorList_BlockType {
                  product {
                    ... on aarpProducts_xgritProduct_Entry {
                      productPageUrl
                      productState
                      xgritBundleCoupon
                      xgritCoupon
                      xgritProductDisableRsa
                      xgritProductId
                    }
                  }
                  state
                  disclaimer
                }
              }
            }
            ... on baseTemplate_aarpProductCards_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              hideCta
              productCtaType
              sectionTitle
              textColor
              useCompactCards
              aarpProductCardList {
                ... on aarpProductCardList_BlockType {
                  cardBackgroundColor
                  cardTextColor
                  ctaLabel
                  ctaLink
                  ctaTag
                  ctaNewTab
                  ctaNofollow
                  learnMoreLink
                  productEntry {
                    ...productEntry_fragment
                  }
                  textContent
                  learnMoreLabel
                  learnMoreNewTab
                  learnMoreNofollow
                  starRating
                  starCaption
                  showSavings
                  cardTitle
                }
              }
              typeHandle
            }
            ... on baseTemplate_aarpProductFilterCards_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              filterType
              ctaType
              useCompactCards
              aarpProductCardList {
                ... on aarpProductCardList_BlockType {
                  cardBackgroundColor
                  cardTextColor
                  ctaLabel
                  ctaLink
                  ctaNewTab
                  ctaTag
                  ctaNofollow
                  learnMoreLink
                  productEntry {
                    ...productEntry_fragment
                  }
                  textContent
                  learnMoreLabel
                  learnMoreNewTab
                  learnMoreNofollow
                  starRating
                  starCaption
                  showSavings
                  cardTitle
                }
              }
              sectionTitle
              textColor
              typeHandle
            }
            ... on baseTemplate_aarpTopSectionWysiwyg_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              contentAlignment
              ctaLabel
              ctaLink
              ctaNewTab
              ctaNofollow
              ctaType
              ctaTag
              aarpVideoButton {
                ... on aarpVideoButton_BlockType {
                  id
                  ctaType
                  ctaLabel
                  wistiaId
                  buttonType
                  ctaOtherlink
                }
              }
              hideGuaranteeLink
              modalContent
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              product {
                ...productEntry_fragment
              }
              productSeal {
                url
                ... on aarpImagesS3_Asset {
                  altText
                }
              }
              sectionContent
              eyebrow
              sectionTitle
              showOfferTimer
              showPricing
              rightContent
              categoryWidgets {
                ... on categoryWidgets_Category {
                  categoryWidgetContent {
                    ... on categoryWidgetContent_BlockType {
                      categoryContent
                      categoryImage {
                        url
                        ... on aarpImagesS3_Asset {
                          altText
                        }
                      }
                      categoryTitle
                    }
                  }
                }
              }
              aarpBottomRightContent {
                ... on aarpBottomRightContent_BlockType {
                  sealIcon {
                    url
                    ... on aarpImagesS3_Asset {
                      altText
                    }
                  }
                  sealIconText
                }
              }
              starCaption
              starRating
              textColor
              typeHandle
            }
          }
        }
      }
      adobe: entries(site: $site, slug: $slug) {
        ... on aarpTemplates_baseTemplate_Entry {
          ...AdobeAarpFragmentEntry
        }
      }
      seoContent: entries(site: $site, slug: $slug) {
        ... on aarpTemplates_baseTemplate_Entry {
          ...SEOAarpFragmentEntry
        }
      }
      styles: entries(site: $site, slug: $slug) {
        ... on aarpTemplates_baseTemplate_Entry {
          embedStyles
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aarpTemplates_baseTemplate_Entry {
          optimizelyEnabled
        }
      }
      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAarpTemplates {
            ... on optimizelyAarpTemplates_aarpTemplateTypes_BlockType {
              optimizelyEnabled: baseTemplateOptimizely
            }
          }
        }
      }
      structuredData: entries(site: $site, slug: $slug) {
        ... on aarpTemplates_baseTemplate_Entry {
          structuredData
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }
    fragment productEntry_fragment on EntryInterface {
      ... on aarpProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        aarpProductCategory {
          ... on aarpCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SEOAarpFragment.seoContent}
    ${AdobeAarpFragment.adobe}
  `,
};
