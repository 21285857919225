/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const AccordionFragment = {
  Accordion: gql`
    fragment AccordionQuery on baseComponents_accordion_BlockType {
      accordionDisplayType
      accordionItems {
        ... on accordionItems_BlockType {
          itemDescription
          itemTitle
          showDescription
          accordionImage {
            alt
            url
          }
          accordionSectionTitle {
            ... on accordionSectionTitle_accordionSectionTitle_BlockType {
              sectionTitle
            }
          }
        }
      }
      accordionLeftContent
      accordionTitle
      buttonLabel
      buttonLink
      buttonLinkNewTab
      buttonLinkNofollow
      buttonType
      linebreak_mainContent
      typeHandle
    }
  `,
};
