import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ServerWistiaService } from './server-wistia.service';
import { EnvService } from '../env/env.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WistiaService extends ServerWistiaService {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    private envService: EnvService,
    readonly httpClient: HttpClient,
  ) {
    super(document, envService, httpClient);
  }

  private insertWistiaScript = (location) => {
    if (this.scriptAlreadyExists(location)) return;
    const script = this.document.createElement('script');
    script.setAttribute('src', location);
    script.setAttribute('async', '');
    this.document.getElementsByTagName('head')[0].appendChild(script);
  };

  private scriptAlreadyExists = (src) => {
    const scripts = this.document.getElementsByTagName('script');
    for (let i = scripts.length; i--; ) {
      if (scripts[i].src.includes(src)) return true;
    }
    return false;
  };

  /**
   * Wait for an element before resolving a promise
   * @param {String} querySelector - Selector of element to wait for
   * @param {String} wistiaId - ID for wistia video
   */
  waitForElement(querySelector, wistiaId) {
    const observer = new MutationObserver(() => {
      if (document.querySelectorAll(querySelector).length > 0) {
        observer.disconnect();
        setTimeout(() => {
          if (
            this.document.getElementById(`video-cta-spinner`) &&
            !this.document.getElementById(`video-cta-spinner`).classList.contains('hidden')
          ) {
            this.document.getElementById(`video-cta-spinner`)?.classList.add('hidden');
          } else {
            this.document.getElementById(`svg_${wistiaId}-container`)?.classList.add('hidden');
            this.document.getElementById(`img_${wistiaId}`)?.classList.add('hidden');
            const wistiaPaddingElements = this.document.querySelectorAll('[id="wistia-padding"]');
            wistiaPaddingElements.forEach(element => {
              element.classList.remove("invisible");
              if (element.classList.contains("!absolute")) {
                element.classList.replace("!absolute", "relative");
              }
            });
          }
        }, 1000);
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  insertVideoScript = (wistidId) => {
    return null;
  };

  loadVideoScripts = (wistiaId: string) => {
    this.waitForElement('[id*="wistia-' + wistiaId + '"]', wistiaId);
    //At least once instance was found, insert global script
    this.insertWistiaScript(`//fast.wistia.com/assets/external/E-v1.js`);
    const wistiaJsonp = `//fast.wistia.com/embed/medias/${wistiaId}.jsonp`;
    //Insert video script
    this.insertWistiaScript(wistiaJsonp);
    //player script
    const playerScript = `
    setTimeout(()=>{
      window._wq = window._wq || [];
      _wq.push({ id: "_all", onReady: function(video) {
        if(video.container.id.includes(wistiaId)) {video.play();}
      }});
    }, 1000);
    `;
    eval(playerScript);
  };

  /**
   * parseStuctureData parses wistia api data into data structure expected by google
   *
   * @param {string} wistiaId - Wistia Id
   *
   */
   parseStuctureData(wistiaId: string) {}

}
