import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { EnvService } from '@core-mkt/services/env/env.service';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { XGritApiBase, XGritApiConfig } from './xgrit-api-config';
import { XGritPurchaseReceiptPriceResponse, XgritPurchaseReceiptMultipriceResponse, ProductCodeLists } from './xgrit-purchase-receipt-price-response';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class XGritApiService {
  checkoutUrl: string;
  apiConfig: XGritApiConfig;
  apiBaseParams: XGritApiBase;

  constructor(
    readonly httpClient: HttpClient,
    private bfs: BrandConfigurationService,
    private errorHandler: ErrorHandlerService,
  ) {
    const env = new EnvService();

    if (env.get.xgritCheckoutUrl) {
      this.checkoutUrl = env.get.xgritCheckoutUrl;
    }

    if (env.get.xgritApiConfig) {
      this.apiConfig = env.get.xgritApiConfig;

      this.apiBaseParams = {
        appType: this.apiConfig.baseParams.appType,
        platform: this.apiConfig.baseParams.platform,
        brandId: this.apiConfig.baseParams.brandId,
      };
    }
  }

  /**
   * constructParamString computes query string parameters based on the keys and values of an object
   *
   * @param {object} params - An object where each key and value pair will be turned into a query parameter
   * @return {string} - an object containing information about the product, or an error if one occurred
   *
   * @example
   *
   *     constructParamString({ productId: 'ufZk2XuZeNr8kAB', courseId: '4PleoXKYfKEhquja', coupon: 'CP10', state 'GA' })
   *
   *     returns, productId=FufZk2XuZeNr8kAB&courseId=4PleoXKYfKEhquja&coupon=CP10&state=GA
   *
   */
  public constructParamString(params: object): string {
    const paramString = Object.keys(params).reduce((paramAccu, paramKey) => {
      if (params[paramKey]) {
        if (paramAccu === '') {
          paramAccu += `${paramKey}=${params[paramKey]}`;
        } else {
          paramAccu += `&${paramKey}=${params[paramKey]}`;
        }
      }
      return paramAccu;
    }, '');

    return paramString;
  }

  /**
   * getCheckoutUrl computes an xGrit checkout url for an xGrit product.
   *
   * @param {string} productId - The id of the product
   * @param {string} courseId - The id of the course
   * @param {string} appType - A string declaring whether the product is DE, DD, RE, etc.
   * @param {boolean} disableRsa - A boolean value that determines if RSA should be disabled on a product checkout
   * @param {string} coupon - A coupon that supports this course
   * @param {string} state - The state that the checkout should display on the checkout
   * @param {string} bundleCoupon - A special kind of coupon for bundling upsells
   * @return {string} - An xGrit checkout url
   *
   * @example
   *
   *     getCheckoutUrl('ufZk2XuZeNr8kAB', '4PleoXKYfKEhquja', 'DRV', 'CP10', 'GA')
   *
   *     returns, https://checkout-stage.driversed.com/checkout?productId=FufZk2XuZeNr8kAB&courseId=4PleoXKYfKEhquja&coupon=CP10&state=GA&ace=cd99
   */
  public getCheckoutUrl(
    productId: string,
    courseId: string,
    appType?: string,
    disableRsa = false,
    coupon?: string,
    state?: string,
    bundleCoupon?: string,
  ): string {
    const env = new EnvService();

    const cdiParamKey = this.bfs.cdiParamKey;
    const cdiParamValue = this.bfs.cdiParamValueWhenEnabled;

    const brand = env.get.xgritApiConfig.baseParams.brandId;
    let params;
    if (brand === 'AA') {
      params = {
        productId,
        courseId,
        couponCode: coupon,
        bundleCoupon,
        state,
      };
    } else {
      params = {
        productId,
        courseId,
        coupon,
        bundleCoupon,
        state,
      };
    }

    if (!disableRsa && (appType === 'DE' || appType === 'DD')) {
      params[cdiParamKey] = cdiParamValue;
    }

    const paramString = this.constructParamString(params);

    return `${this.checkoutUrl}?${paramString}`;
  }

  /**
   * handleError an error handler for an Observable.
   *
   * @param {any} error - an error
   *
   */
  public handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      this.errorHandler.handleError(error);
      return of(result as T);
    };
  }

  /**
   * getProductsInfo calls xGrit's 'product-course' endpoint which gets information on products.
   *
   * @param {string[]} productIdList - an array of productIds, not courseIds, to get product information on.
   * @return {Observable<any>} - an object containing information about the product, or an error if one occurred
   *
   * @example
   *
   *     getProductsInfo(['B93xTL1FzpYhKdJ1'])
   */
  public getProductsInfo(productIdList: string[]): Observable<any> {
    productIdList.push(''); // Always push an extra empty string at the end of productIdList since xGrit's 'product-course' endpoint requires an array of products.

    const callParams = {
      ...this.apiBaseParams,
      productIdList,
    };

    return this.httpClient
      .get<any>(this.apiConfig.url + 'product-course', {
        params: callParams,
      })
      .pipe(timeout(5000), catchError(this.handleError<any>({})));
  }

  /**
   * getProductPriceInfo calls xGrit's 'purchase-receipt/price' endpoint. This implementation of 'purchase-receipt/price' is meant to be a convenience method for getting a product's price information with a coupon applied.
   *
   * @param {string} productId - the product id, not a course id, relating to the product that you would like to get pricing information on.
   * @param {string} coupon - the coupon id of a coupon that can be a applied to the supplied product.
   * @return {Observable<XGritPurchaseReceiptPriceResponse>} - an object containing information about the product, or an error if one occurred
   *
   * @example
   *
   *     getProductPriceInfo('B93xTL1FzpYhKdJ1')
   */

  public getProductPriceInfo(productId: string, coupon?: string): Observable<XGritPurchaseReceiptPriceResponse> {
    let productIdList = [];
    let couponCodeList = [];
    productIdList = productIdList.concat([productId]);
    if (coupon) {
      couponCodeList = couponCodeList.concat([coupon]);
    }

    const callParams = {
      ...this.apiBaseParams,
      'productIdList[]': productIdList,
      'couponCodeList[]': couponCodeList,
    };

    return this.httpClient
      .get<XGritPurchaseReceiptPriceResponse>(this.apiConfig.url + 'purchase-receipt/price', {
        params: {
          ...callParams,
        },
        headers: {
          Authorization: this.apiConfig.aceauth,
        },
      })
      .pipe(catchError(this.handleError<XGritPurchaseReceiptPriceResponse>({} as XGritPurchaseReceiptPriceResponse)));
  }

  /**
   * queryBuilder translate a complex object to a query parameter form.
   *
   * @param {object} objectParams - complex object to be sent over an http get request.
   * @returns {string} - a query parameters string representation of the complex object.
   */
  private queryBuilder(objectParams, prefix = undefined) {
    const queryParams: string[] = [];
    for (const property in objectParams) {
      if (objectParams.hasOwnProperty(property)) {
        const key = prefix ? `${prefix}[${property}]` : property;
        const value = objectParams[property];
        queryParams.push(
          value !== null && typeof value === 'object'
            ? this.queryBuilder(value, key)
            : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        );
      }
    }
    return queryParams.join('&');
  }

  /**
   * getMultiProductPriceInfo calls xgrit's 'purchase-receipt/multiprice' endpoint.
   * This implementation of 'purchase-receipt/multiprice' is meant to be a convience method for getting a product's price information with a coupon applied.
   *
   * @param {ProductCodeLists[]} priceSetList - array with objects containing product id and coupon code, both stored as unique elements of an array.
   * @return {Observable<XgritPurchaseReceiptMultipriceResponse>} - an object containing information about the product, or an error if one occurred
   *
   * @example
   *
   *   priceSetList = [
   *     {
   *       "productIdList": ["B93xTL1FzpYhKdJ1"],
   *       "couponCodeList": ["CP7"]
   *     },
   *     {
   *       "productIdList": ["jj51YsV07s"],
   *       "couponCodeList": ["ADEAW0"]
   *     },
  }
   *   ];
   *   getMultiProductPriceInfo(priceSetList);
   */
  public getMultiProductPriceInfo(priceSetList: ProductCodeLists[]): Observable<XgritPurchaseReceiptMultipriceResponse> {
    const callParams = {
      ...this.apiBaseParams,
      priceSetList,
    };
    const params = decodeURIComponent(this.queryBuilder(callParams));

    return this.httpClient
      .get<XgritPurchaseReceiptMultipriceResponse>(this.apiConfig.url + 'purchase-receipt/multiprice?' + params, {
        headers: {
          Authorization: this.apiConfig.aceauth,
        },
      })
      .pipe(catchError(this.handleError<XgritPurchaseReceiptMultipriceResponse>({} as XgritPurchaseReceiptMultipriceResponse)));
  }
}
