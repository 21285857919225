import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { CraftXGritProductEntry } from '@core-mkt/interfaces/craft-xgrit-product-entry';
import { StateAbbrevDictionary } from '@core-mkt/interfaces/state-abbrev-dictionary.enum';
import { EnvService } from '@core-mkt/services/env/env.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { ProductFilter, ProductItem } from './product-card';
import { ProductSection } from './product-card';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.scss'],
})
export class ProductCardComponent implements OnInit, OnChanges {
  @Input() data: ProductSection;
  @Input() horizontalAlignment: string;
  hasContent = false;
  filterType = '';
  filterList: ProductFilter[] = [];
  products: ProductItem[] = [];
  unfilteredProducts: ProductItem[] = [];
  stateEnum = StateAbbrevDictionary;
  theme: ThemeInterface;
  categoryField = '';
  learnMore: boolean;
  hideSectionTitle = false;
  activeCoupon: string; // Used to store active coupon from the email cookie

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private ps: ProductService,
    private pricing: PricingService,
    private env: EnvService,
  ) {
    this.categoryField = this.env.get.brandConfig.productCategoryHandle;
    /**
     * subscribe to coupon code event to update pricing on the product
     *  cards if a coupon is applied
     */
    this.pricing.couponCodeEvent.subscribe((coupon: string) => {
      this.activeCoupon = coupon;
      this.getProducts();
    });
  }

  get ctaType(): string {
    return this.data.ctaStyle === null ? '' : this.data.ctaStyle;
  }

  get hideCta(): boolean {
    return this.data.hideCta;
  }

  get useCompactCards(): boolean {
    return this.data.useCompactCards;
  }

  get cardContainerWidth(): string {
    if (this.filterType.length > 0) {
      return 'lg:w-5/6';
    }
    return '';
  }

  get cardWidth(): string {
    switch (true) {
      case this.products.length < 2:
        return '';
      case this.products.length < 3:
        return 'w-full sm:w-1/2';
      default:
        return 'w-full sm:w-1/2 lg:w-1/3';
    }
  }

  ngOnInit(): void {
    this.setContent();
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue !== changes.data.previousValue) {
      this.setContent();
    }
  }

  private setContent() {
    if (this.data['aarpProductCardList']) {
      this.data.productCardList = this.data['aarpProductCardList'];
    }
    this.learnMore = this.data.productCardList.some((obj) => obj.learnMoreLink !== null);
    if (this.data.sectionTitle === null) {
      this.data.sectionTitle = '';
      this.hideSectionTitle = true;
    } else {
      this.hasContent = true;
      this.data.sectionTitle = this.redactor.bypassSanitizer(String(this.data.sectionTitle));
    }
    if (this.data.filterType && this.data.filterType !== null) {
      this.filterType = this.data.filterType;
    }
    this.getProducts();
  }

  addToFilterList(product: XGritCompleteProduct): void {
    if (product && this.filterType.length > 0) {
      const filterItem: ProductFilter = { value: '', label: '' };
      if (this.filterType === 'filterByState') {
        filterItem.value = product.craftData.productState;
        filterItem.label = this.stateEnum[product.craftData.productState];
      } else {
        if (product.craftData[this.categoryField].length > 0) {
          filterItem.value = product.craftData[this.categoryField][0].aceCategoryCode;
          filterItem.label = product.craftData[this.categoryField][0].title;
          if (product.craftData[this.categoryField].length > 1) {
            for (let i = 1; i < product.craftData[this.categoryField].length; i++) {
              const category = product.craftData[this.categoryField][i];
              const filterItem: ProductFilter = { value: category.aceCategoryCode, label: category.title };
              this.pushFilter(filterItem);
            }
          }
        }
      }
      this.pushFilter(filterItem);
    }
  }

  pushFilter(filterItem: ProductFilter): void {
    if (this.filterList.findIndex((filter) => filter.label === filterItem.label) === -1) {
      this.filterList.push(filterItem);
      this.filterList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }
  }

  doFiltering(value: string) {
    this.products = this.unfilteredProducts;
    if (value !== 'none' && this.filterType === 'filterByState') {
      this.products = this.products.filter(
        (product) => product.productEntry.length > 0 && product.productEntry[0].productState === value,
      );
    }
    if (value !== 'none' && this.filterType === 'filterByCategory') {
      this.products = this.products.filter((product) => {
        return product.xGritProduct.craftData[this.categoryField].find(
          (category) => category.aceCategoryCode === value,
        );
      });
    }
  }

  getFilterKey(filterType: string): string {
    const baseType = filterType.match(/PTDE|DE|AMI|FTA|DD|PL|COURSE|BUNDLE/);
    return baseType[0];
  }

  getProducts() {
    const craftProducts: CraftXGritProductEntry[] = [];
    this.data.productCardList.forEach((product) => {
      if (this.ps.isProductValid(product.productEntry)) {
        // Add coupon to product if it exists
        if (this.activeCoupon) {
          product.productEntry[0].xgritCoupon = this.activeCoupon;
        }
        // Extract product data from the product entry and add to craftProducts array
        craftProducts.push(this.ps.extractProduct(product.productEntry));
      }
    });

    if (craftProducts.length === 0) {
      this.parseProducts();
    } else {
      this.ps.getMultiProductData(craftProducts).then((xgritProducts) => {
        this.parseProducts(xgritProducts);
      });
    }
  }

  parseProducts(xgritProducts?: XGritCompleteProduct[]) {
    this.products = [];
    this.unfilteredProducts = [];
    this.data.productCardList.forEach((card, index) => {
      if (card.cardTitle !== null) {
        card.cardTitle = this.redactor.bypassSanitizer(card.cardTitle);
        card.showTitle = true;
        this.hasContent = true;
      } else {
        card.cardTitle = '';
        card.showTitle = false;
      }
      card.productIdx = index;
      if (card.productEntry.length > 0) {
        const entry = this.ps.extractProduct(card.productEntry);
        const currentProduct = xgritProducts.find(
          (product) => product.craftData.xgritProductId === entry.xgritProductId,
        );
        card.xGritProduct = currentProduct;
        card.showPricing = true;
        if (card.textContent !== null && card.xGritProduct !== undefined) {
          this.hasContent = true;
          if (
            card.textContent['changingThisBreaksApplicationSecurity'] === undefined &&
            (card.textContent.includes('${this.crossedOutPrice}') || card.textContent.includes('${this.finalPrice}'))
          ) {
            card.textContent = this.pricing.getPricingText(card.xGritProduct, card.textContent);
            card.showPricing = false;
          }
          if (
            card.textContent['changingThisBreaksApplicationSecurity'] &&
            card.textContent['changingThisBreaksApplicationSecurity'].includes('$')
          ) {
            card.showPricing = false;
          }
        } else {
          card.textContent = '';
          card.showContent = false;
        }
        this.products.splice(card.productIdx, 0, card);
        this.unfilteredProducts.splice(card.productIdx, 0, card);
        this.addToFilterList(currentProduct);
        //force sort of filters, in case splice does not work as expected.
        this.products.sort((a, b) => {
          return a.productIdx - b.productIdx;
        });
        this.unfilteredProducts.sort((a, b) => {
          return a.productIdx - b.productIdx;
        });
      } else {
        if (card.textContent !== null) {
          if (/$[0-9]+/g.test(card.textContent)) {
            card.showPricing = false;
          }
          card.textContent = this.redactor.bypassSanitizer(card.textContent);
          card.showContent = true;
          this.hasContent = true;
        } else {
          card.textContent = '';
          card.showContent = false;
        }
        this.products.splice(card.productIdx, 0, card);
        this.unfilteredProducts.splice(card.productIdx, 0, card);
      }
    });
  }
}
