/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const QuizWidgetFragment = {
  Quiz: gql`
    fragment QuizWidgetQuery on baseComponents_quizWidget_BlockType {
      styles
      quizWidgetId
      typeHandle
    }
  `,
};
