import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor() {}

  /**
   * Sets a cookie with a specified name, value, optional domain, and path.
   * @param name The name of the cookie.
   * @param value The value of the cookie.
   * @param days The number of days until the cookie expires (default is 7).
   * @param path The path where the cookie is accessible (default is '/').
   * @param domain The domain where the cookie is accessible (optional).
   */
  public setCookie(name: string, value: string, days: number = 7, path: string = '/', domain?: string): void {
    let cookieString = `${name}=${encodeURIComponent(value)}; expires=${this.getCookieExpirationDate(
      days,
    )}; path=${path}`;
    if (domain) {
      cookieString += `; domain=${domain}`;
    }
    document.cookie = cookieString;
  }

  /**
   * Retrieves the value of a cookie by its name.
   * @param name The name of the cookie.
   * @returns The value of the cookie if found; otherwise, null.
   */
  public getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  /**
   * Deletes a cookie by its name, path (defaults to '/'), and optional domain.
   * @param name The name of the cookie to delete.
   * @param path The path where the cookie was accessible (default is '/').
   * @param domain The domain where the cookie was accessible (optional).
   */
  public deleteCookie(name: string, path: string = '/', domain?: string): void {
    let cookieString = `${name}=; expires=${this.getCookieExpirationDate(-1)}; path=${path}`;
    if (domain) {
      cookieString += `; domain=${domain}`;
    }
    document.cookie = cookieString;
  }

  /**
   * Helper function to calculate the expiration date of a cookie.
   * @param days Number of days until the cookie should expire.
   * @returns A string representing the UTC expiration date.
   */
  public getCookieExpirationDate(days: number): string {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toUTCString();
  }
}
