import { Component, Input } from '@angular/core';
import { Anchor } from './anchor';

@Component({
  selector: 'anchor',
  templateUrl: './anchor.component.html',
})
export class AnchorComponent {
  @Input() data: Anchor;

  get name(): string {
    return this.data?.anchorName;
  }
}
