/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const params = `
pageName
var1
var2
var3
var4
var5
appStep
productId
transactionId
registrationType
paymentType
orderAmount
`;

export const AdobeAarpFragment = {
  adobe: gql`
    fragment AdobeAarpFragmentEntry on aarpTemplates_baseTemplate_Entry {
      ${params}
    }
  `,
};
