import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeoDataService } from './seo-data.service';
import { ServerSeoDataService } from './server-seo-data.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [SeoDataService, ServerSeoDataService]
})
export class SeoDataModule {
}
