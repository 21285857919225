<swiper #swiperEl [config]="config" (swiper)="onSwiper($event)" effect="fade">
  <ng-template *ngFor="let slide of slides" swiperSlide>
    <div *ngIf="slide.showContent" class="w-3/4 sm:w-4/5 mx-14">
      <div class="wysiwyg py-v-spacing sm:px-v-spacing" [innerHtml]="slide.slideContent"></div>
    </div>
    <a
      *ngIf="showLinkedImage(slide)"
      class="w-full h-full"
      [href]="slide.slideImageLink"
      [attr.target]="slide.slideLinkNewTab ? '_blank' : ''"
      [attr.rel]="
        slide.slideLinkNewTab && slide.slideLinkNofollow
          ? 'nofollow noopener noreferrer'
          : slide.slideLinkNewTab && !slide.slideLinkNofollow
          ? 'noopener noreferrer'
          : slide.slideLinkNofollow
          ? 'nofollow'
          : ''
      "
      ><img
        class="w-full"
        [src]="imgixService.buildImgixUrl(slide.slideImage[0].url)"
        [alt]="slide.slideImage[0].altText ? slide.slideImage[0].altText : ''"
        [height]="slide.slideImage[0].height"
        [width]="slide.slideImage[0].width"
    /></a>
    <img
      *ngIf="showImage(slide)"
      class="w-full"
      [src]="imgixService.buildImgixUrl(slide.slideImage[0].url)"
      [alt]="slide.slideImage[0].altText ? slide.slideImage[0].altText : ''"
      [height]="slide.slideImage[0].height"
      [width]="slide.slideImage[0].width"
    />
  </ng-template>
</swiper>
