// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envMode: 'local',
  craftApiUrl: 'http://localhost/api/',
  decApiUrl: 'https://beta4.driversed.com/wsrest/',
  xgritCheckoutUrl: 'https://checkout-stage.driversed.com/checkout',
  xgritApiConfig: {
    url: 'https://stg.xgritapi.com/api/1/',
    appType: 'DR',
    aceauth: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
    platform: 'MKT',
    brandId: 'DEC',
  },
  optimizelyId: '15026010286',
  segmentId: 'gs9wwjhOA8vxmIf4JpzvjHPhEYOay1mT',
  sentryDsn: 'https://435704d14ca2462493d46af148829e3d@o1007654.ingest.sentry.io/6239871',
  appName: 'DriversEd.com',
  appURL: 'driversed.com',
  wistiaVerification: 'Bearer f70f44da17d8dd9a2a09df5c84c1ea2ba9f23d1b5edea43f9a2862cfe1fc99fd',
  gladlyHelpCenterEnvironment: {
    api: 'https://aceable.us-1.gladly.com',
    orgId: 'VJOTwOvcQ6WJr85bp0qL0g',
    brandId: 'bRr4v1PrRhmADjOoJ02MTA',
    cdn: 'https://cdn.gladly.com',
    selector: 'gladly-help-center',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js';  // Included with Angular CLI.
