/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const AnchorFragment = {
  Anchor: gql`
    fragment AnchorQuery on baseComponents_anchor_BlockType {
      anchorName
      anchortip
      typeHandle
    }
  `,
};
