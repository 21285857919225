import { Component, Input, OnInit } from '@angular/core';
import { Breadcrumb, BreadcrumbItem } from './breadcrumb';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() data: Breadcrumb;
  theme: ThemeInterface;
  faChevronRight = faChevronRight;
  border: boolean;
  tealLink: boolean;

  constructor(private themeParser: ThemeParserService) {}

  get breadcrumbs(): BreadcrumbItem[] {
    return this.data.breadcrumbsList;
  }

  get backgroundColor(): string {
    if (
      this.data.backgroundColor &&
      (this.data.backgroundColor.includes('bg-white') || this.data.backgroundColor.includes('bg-ace-grey-100'))
    ) {
      this.tealLink = true;
    }
    return this.data.backgroundColor;
  }

  get textColor(): string {
    return this.data.textColor;
  }

  get hideOnMobile(): boolean {
    return this.data.hideOnMobile;
  }

  ngOnInit(): void {
    if (this.data.backgroundColor && this.backgroundColor.includes('bg-white')) {
      this.border = true;
    }
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor ?? 'bg-ace-grey-900 text-white',
      backgroundImage: null,
      textColor: this.data.textColor,
      columnDistribution: null,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }
}
