import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { IterableService } from './iterable.service';
import { ServerIterableService } from './server-iterable.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [IterableService, ServerIterableService],
})
export class IterableServiceModule {}
