<marketing [includeOptimizely]="includeOptimizely"></marketing>
<aarp-header></aarp-header>
<ng-container *ngIf="formattedData">
  <div *ngFor="let options of formattedData" class="flex flex-col mx-auto">
    <ng-template aceInsertableHost [options]="options" [registry]="registryService"></ng-template>
  </div>
</ng-container>
<aarp-footer></aarp-footer>


