import { Injectable, ErrorHandler } from '@angular/core';
import { ServerErrorHandlerService } from './server-error-handler.service';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ServerErrorHandlerService implements ErrorHandler {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  /**
   * handleError catches any unhandled runtime error on the client side
   * Note: This stops execution of any code
   *
   * @param {object} error - Native error throwned by Angular
   */
  handleError(error: any): void {
    try {
      throw new Error();
    } catch {
      console.error(error.message, error);
    }
  }

  /**
   * user initiated client side log
   *
   * @param {any} log - Data passed in by user
   */
  log = (msg) => {
    this.visionData.userInitiatedLog = JSON.stringify(msg);
    console.log(this.visionData);
  };

  /**
   * user initiated client side log
   *
   * @param {any} log - Data passed in by user
   */
  warning = (warn) => {
    this.visionData.userInitiatedLog = JSON.stringify(warn);
    console.warn(this.visionData);
  };

  /**
   * user initiated clint side log
   *
   * @param {any} log - Data passed in by user
   */
  error = (err) => {
    this.visionData.userInitiatedLog = JSON.stringify(err);
    console.error(this.visionData);
  };
}
