import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProductPickerModule } from '../product-picker/product-picker.module';
import { HeroHomepageComponent } from './hero-homepage/hero-homepage.component';

@NgModule({
  declarations: [HeroHomepageComponent],
  exports: [HeroHomepageComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, ProductPickerModule],
})
export class HeroHomepageModule {}
