import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { MenuItem, Menu } from '../../header/header';
import { stateList } from '../../header/state-nav-data';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dec-desktop-menu',
  templateUrl: './desktop-menu.component.html',
  styleUrls: ['./desktop-menu.component.scss'],
})
export class DesktopMenuComponent implements OnInit {
  @Input() data: Menu;
  @Input() _showLogoHref = true;
  _menuGroups: MenuItem[][];
  @ViewChild('currentStateToggler') currentStateToggler;
  @ViewChild('statesDropdown') statesDropdown;
  @ViewChild('stateArrow') stateArrow;
  faCaretRight = faCaretRight;
  faCaretRightStyles = { width: '6px', height: '24px' };
  faCaretDown = faCaretDown;
  faCaretDownStyles = { width: '10px', height: '24px' };
  menuOpen = false;
  tabShift: boolean;

  get menuGroupList(): MenuItem[][] {
    return this._menuGroups;
  }

  get stateSelectionList(): MenuItem[] {
    return stateList;
  }

  get showLogoHref(): boolean {
    return this._showLogoHref;
  }

  constructor() {}

  ngOnInit() {
    this.formatMenuGroups();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  displayMenu(): boolean {
    if (this.menuGroupList === undefined) {
      return false;
    }
    const menuGroupListLength = this.menuGroupList.length;
    return menuGroupListLength === 0 ? false : true;
  }

  onEnter(): void {
    this.toggleMenu();
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    if (this.displayMenu()) {
      if (
        event.target !== this.statesDropdown.nativeElement &&
        !this.statesDropdown.nativeElement.contains(event.target)
      ) {
        if (
          event.target !== this.currentStateToggler.nativeElement &&
          !this.currentStateToggler.nativeElement.contains(event.target)
        ) {
          if (event.target !== this.stateArrow.nativeElement && !this.stateArrow.nativeElement.contains(event.target)) {
            this.menuOpen = false;
          }
        }
      }
    }
  }

  /**
    * Hide dropdown menus after the last item is unfocused/blurred and tabbed forward.
    * If the last item is tabbed backwards, the menu stays open.
    * If the first item is tabbed backwards, the menu closes.
    @param event The blur event that triggered the function.
    */
  onBlur(event): void {
    const isFirstMenuItem = event.target.classList.contains('first');
    const isLastMenuItem = event.target.classList.contains('last');

    // Update the visibility of the menus based on the tabbing direction and item position.
    if ((isLastMenuItem && !this.tabShift) || (isFirstMenuItem && this.tabShift)) {
      this.menuOpen = false;
    }
    if ((isLastMenuItem && !this.tabShift) || (isFirstMenuItem && this.tabShift)) {
      this.menuOpen = false;
    }
  }

  /**
   * Checks if user is tabbing backwards
   * @param event The keydown KeyboardEvent that triggers the function
   */
  onKeyDown(event: KeyboardEvent): void {
    this.tabShift = event.shiftKey && event.key === 'Tab' ? true : false;
  }

  formatMenuGroups(): void {
    let menuGroupList: MenuItem[][] = [];
    let groupIndex = -1;
    // Build the list of menu groups by pushing to the same index until a new position of "top" is seen;
    // until "top" is seen, the menu item is assumed to be a sub-item ("bottom") so is pushed to the same
    // group. Once a new "top" is seen, a new group gets formed at the following index and any sub-items
    // will be pushed there, etc., until the whole array has been iterated over.
    for (let i = 0; i < this.data.menuItems.length; i++) {
      if (this.data.menuItems[i].position === 'top') {
        groupIndex++;
        menuGroupList[groupIndex] = [];
      }
      menuGroupList[groupIndex].push(this.data.menuItems[i]);
    }

    this._menuGroups = menuGroupList;
  }
}
