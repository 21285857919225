<form [formGroup]="emailForm">
  <div class="w-full fixed bottom-0 z-50 text-white" *ngIf="showEmailCapture">
    <div class="mx-auto bg-ace-pink-600 w-full md:max-w-xl flex flex-col py-v-spacing sm:px-v-spacing">
      <div class="text-right">
        <fa-icon class="text-lg cursor-pointer" [icon]="faTimes" (click)="close()"></fa-icon>
      </div>
      <div *ngIf="!isSubmited" [innerHtml]="displayContent"></div>
      <div *ngIf="!isSubmited && !displayContent">
        <p class="text-lg font-medium">Limited-Time Sale: Save 20% Today!</p>
        <p class="text-sm">
          You scored 20% off your course! There's never been a better time to join the real estate industry, so launch
          your new career today. Deal expires Sunday.
        </p>
        <p></p>
      </div>
      <div *ngIf="isSubmited" class="pb-2" [innerHtml]="displayContentAfterSubmit"></div>
      <div *ngIf="isSubmited && !displayContentAfterSubmit">
        <p class="text-lg font-medium">Limited-Time Sale: Save 20% Today!</p>
        <p class="text-sm">
          Use code "{{ coupon }}" at checkout to save 20% (Also sent to your email: check your spam folder if you don't
          see it!)
        </p>
        <p></p>
      </div>
      <div class="py-2 flex flex-row" *ngIf="!isSubmited">
        <input
          type="text"
          placeholder="Email Address"
          class="outline-none p-1 border border-gray-300 text-xs mr-2 text-gray-900 w-1/2 md:w-2/3"
          formControlName="email"
          required
        />
        <button
          class="
            w-1/2
            md:w-1/3
            bg-ace-blue-600
            cursor-pointer
            hover:bg-ace-blue-400
            inline-flex
            items-center
            justify-center
            rounded-full
            text-white
          "
          (click)="submit()"
        >
          {{ buttonLabel }}
        </button>
      </div>
      <p *ngIf="isEmailInvalid" class="text-xs">Valid email required.</p>
    </div>
  </div>
</form>
