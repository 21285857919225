import { Component, Input } from '@angular/core';

@Component({
  selector: 'single-review',
  templateUrl: './single-review.component.html',
})
export class SingleReviewComponent {
  @Input() backgroundInfo: any;
  @Input() textColorOverride: string;
  @Input() containerClasses: string;
  @Input() title: string;
}
