import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { OneThreeColumnComponent } from './one-three-column/one-three-column.component';

@NgModule({
  declarations: [OneThreeColumnComponent],
  exports: [OneThreeColumnComponent],
  imports: [CommonModule, LazyImgModule],
})
export class OneThreeColumnModule {}
