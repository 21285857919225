<div class="flex flex-col h-full md:m-0 card-border bg-white rounded-md" (click)="articleRoute()">
  <div class="w-full career-center-image h-48">
    <img
      *ngIf="image && image.url"
      class="h-48 object-cover career-center-image rounded-t-md"
      src="{{ imgixService.buildImgixUrl(image.url) }}"
      [alt]="image.altText ? image.altText : ''"
      width="800"
      height="500"
    />
  </div>
  <div class="content-card w-full top-3/4 z-10 rounded h-full py-v-spacing px-v-spacing">
    <div *ngIf="relatedResourceContent" class="mx-auto flex flex-col justify-between h-full">
      <span class="eyebrow text-ace-grey-600">{{ relatedResourcesCategory }}</span>
      <div class="wysiwyg mb-v-spacing" [innerHTML]="relatedResourceContent"></div>
      <div class="mt-auto">
        <vision-button defaultStyle="tertiary" *ngIf="relatedResourceLink" [data]="ctaButtonData"></vision-button>
      </div>
    </div>
  </div>
</div>
