import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { reviewData } from '../review-data';

@Component({
  selector: 'column-reviews',
  templateUrl: './column-reviews.component.html',
  styleUrls: ['column-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColumnReviewsComponent implements OnInit {
  @Input() backgroundInfo: any;
  @Input() textColorValue: string;
  @Input() textColorOverride: string;
  @Input() backgroundColorValue: string;
  @Input() containerClasses: string;
  @Input() title: string;
  columnReviews = reviewData[2];
  darkBold: boolean;

  constructor() {}

  ngOnInit(): void {
    if (this.backgroundColorValue.includes('ace-teal') || this.backgroundColorValue.includes('ace-pink')) {
      this.darkBold = true;
    }
  }
}
