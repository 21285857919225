import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'any',
})
export class ElementVisibilityService {
  isElVisible = true;
  private window: Window;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  checkElementVisiblity(element): void {
    if (!element) {
      return;
    }
    const position = element.getBoundingClientRect();
    if (position.top >= window.innerHeight) {
      this.isElVisible = true;
      return;
    }
    if (position.top < window.innerHeight && position.bottom >= 0) {
      this.isElVisible = true;
      return;
    }
    this.isElVisible = false;
  }
}
