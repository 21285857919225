import { CommonModule } from '@angular/common';
import type { ComponentFactory } from '@angular/core';
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { QuizWidgetComponent } from './quiz-widget/quiz-widget.component';

@NgModule({
  declarations: [QuizWidgetComponent],
  exports: [QuizWidgetComponent],
  imports: [CommonModule],
})
export class QuizWidgetModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(): ComponentFactory<QuizWidgetComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(QuizWidgetComponent);
  }
}
