import type { FAQData, FAQStates } from './faq';

export const FAQQuestionData: FAQData = {
  sections: [
    {
      title: 'Payment FAQs',
      questions: [
        {
          id: 1,
          question: 'What is the cost of the course, and how do I pay?',
          answer:
            'The AARP Smart Driver Online Course is $26.95 for members and $29.95 for non-members. Participants taking the New York course will also be charged an additional fee, which is a mandatory charge to report your completion to the New York Department of Motor Vehicles. This fee will be reflected in your Shopping Cart prior to purchase. You may pay using any major debit/credit cards, Stripe Link, Apple Pay, or Google Pay.',
        },
        {
          id: 2,
          question: "If I can't finish the course or don't wish to continue it, will I be able to receive a refund?",
          answer:
            'You are able to receive a refund if for any reason you do not complete the course or do not wish to continue it. You will have 60 days from the date of registration to request that a refund be credited back to your credit card account. Unfortunately, we are unable to issue refunds after 60 days and/or after the course completion certificate has been processed. To request a refund, contact Customer Service at <a href="tel:800-350-7025" class="underline">1-800-350-7025</a> or email  <a href="mailto:customerservice@aarpdriversafety.org" class="underline" >customerservice@aarpdriversafety.org</a>. All refunds will be issued within 30 days following receipt of a permissible request for cancellation.',
        },
      ],
    },
    {
      title: 'General Course FAQs',
      questions: [
        {
          id: 3,
          question: 'What information do I need handy for registering?',
          answer:
            'You will need the following information when registering for this course: <ul><li>AARP Member number (required for AARP discount)</li><li>Driver’s license number (required for certificate of course completion)</li><li>Credit card account information</li></ul>',
        },
        {
          id: 4,
          question:
            "Why does AARP Driver Safety ask for my driver's license number and date of birth when I register for the AARP Smart Driver course?",
          answer:
            "It is the national procedure of AARP Driver Safety to collect the driver's license information and date of birth of each course participant. This information is required to guard against fraudulent use of the Certificates of Completion and to maintain the integrity of the program in the view of the insurance industry, state departments of motor vehicles, and state insurance commissions.",
        },
        {
          id: 5,
          question: 'How do I log in to start the course after I have paid?',
          answer:
            'Follow this link: <a class="underline" href="https://app.aarpdriversafety.org/user/signin">https://app.aarpdriversafety.org/user/signin</a>',
        },
        {
          id: 6,
          question: 'How long is this course? What if I cannot finish it in one sitting?',
          answer:
            'The AARP Smart Driver™ online course is four (4) hours in length. You may complete the course all in one sitting or you can choose to complete the course at your own pace, logging on and off at your convenience. The course will automatically keep track of your progress so that when you log back on, your course will resume from where you left off. You will have 60 days from the time of your registration to complete the course in order to receive a certificate.',
        },
        {
          id: 7,
          question:
            'Can I start the online version of the AARP Smart Driver course at one location and continue at another?',
          answer:
            'If you started the course at a computer in one location and want to continue at another, simply log into the course at the new location using your username and password. The online version of the AARP Smart Driver course is designed to be convenient. You may take the course at any time from any location on a computer with an Internet connection.',
        },
        {
          id: 8,
          question: 'Is this online version of the AARP Smart Driver course narrated or just text?',
          answer:
            'The online version of the AARP Smart Driver course is professionally narrated. The course contains both videos and graphic illustrations of the concepts being taught.',
        },
        {
          id: 9,
          question: 'Quizzes and Exams: Will those be recorded and graded? Can I fail the course?',
          answer:
            'While there are a number of quizzes contained in the course, they are not graded and will not prevent you from moving forward through the course. However, the quiz results are recorded and shown to you to ensure you are progressing and learning the material presented in the course. The main purpose of these quizzes is to help you feel comfortable understanding the information being presented.</br></br>There will also be an ungraded exam at the end of the course, which will also be recorded and summarized for you. Again, the purpose of the exam is to help you feel comfortable with the material.',
        },
        {
          id: 10,
          question:
            'Will I get an auto insurance discount and/or a reduction in points for completing the course online?',
          answer:
            'Please consult your insurance agent for applicability in your state.</br></br>For AARP members with Hartford Insurance: AARP Auto Insurance from the Hartford recognizes the online course for an auto insurance discount in eligible states. Please contact your agent for details',
        },
        {
          id: 11,
          question: 'When will I receive my certificate?',
          answer:
            'Your Certificate of Completion will be immediately available for download in PDF format at the conclusion of the course. If you would like to receive a hard copy of your certificate in the mail, you may select your desired shipping method and pay the corresponding fee. (Standard U.S. Mail, FedEx 2Day, Standard Overnight, Priority Overnight.)',
        },
        {
          id: 12,
          question: "Is the Smart Driver course the same as the '55 Alive' course?",
          answer:
            "As rules of the road have changed over the years, so has this course. When AARP began this program in 1979, it was referred to as '55 Alive.' But, over time – and with the development of new content – we felt the name 'Smart Driver' was more appropriate, so a change was made.",
        },
      ],
    },
    {
      title: 'Technical FAQs',
      questions: [
        {
          id: 13,
          question: 'What happens if I get disconnected?',
          answer:
            'If you get disconnected from your Internet provider for any reason, simply reconnect, then go to <a class="underline" href="http://app.aarpdriversafety.org" target="_blank">http://app.aarpdriversafety.org</a>  and log back into your course. You can continue the course from where you left off.',
        },
        {
          id: 14,
          question:
            'What happens if I have problems while taking the course or the system malfunctions for any reason?',
          answer:
            'Our expertly trained Customer Support staff is ready to help you 7 days per week. You can contact our friendly representatives by phone or email, whichever works better for you.</br></br>Speak to a representative: <a href="tel:1-800-350-7025" class="underline">1-800-350-7025</a></br>Email: <a href="mailto:customerservice@aarpdriversafety.org" class="underline">customerservice@aarpdriversafety.org</a>',
        },
        {
          id: 15,
          question: 'System requirements for taking the AARP Smart Driver online course',
          answer:
            '<p class="font-bold">Browser:</p><ul><li>Edge, Firefox, and Google Chrome (The AARP Smart Driver online course is compatible with the current and previous version of each browser above.)</li></ul><p class="font-bold">Operating System:</p><ul><li>Windows 7, Windows 8, or Windows 10</li><li>Mac OS X 10.9 or greater</li></ul><p class="font-bold">Hardware:</p><ul><li>Sound card</li><li>Speakers or headphones</li><li>Mouse or other pointing device</li><li>Screen resolution 1024x768</li><li>Broadband Internet connection (DSL, T1, cable modem, ISDN)</li></ul>',
        },
      ],
    },
    {
      title: 'Additional FAQs',
      questions: [
        {
          id: 16,
          question: 'Helpful Safety Information',
          answer: '<a class="underline" href="/schoolbusfines" target="_blank">School Bus Fines By State</a>',
        },
        {
          id: 17,
          question: 'Need to contact someone for support?',
          answer:
            'Follow <a class="underline" href="https://aarpdriversafety.zendesk.com/hc/en-us/" target="_blank">this link to our Help Center</a> where you can find our call center\'s email address, phone number and hours of operation.',
        },
      ],
    },
  ],
};

export const faqStates: FAQStates = {
  states: [
    { name: 'Alabama', abbv: 'AL' },
    { name: 'Alaska', abbv: 'AK' },
    { name: 'Arizona', abbv: 'AZ' },
    { name: 'Arkansas', abbv: 'AR' },
    { name: 'California', abbv: 'CA' },
    { name: 'Colorado', abbv: 'CO' },
    { name: 'Connecticut', abbv: 'CT' },
    { name: 'Delaware', abbv: 'DE' },
    { name: 'District of Columbia', abbv: 'DC' },
    { name: 'Florida', abbv: 'FL' },
    { name: 'Georgia', abbv: 'GA' },
    { name: 'Guam', abbv: 'GU' },
    { name: 'Hawaii', abbv: 'HI' },
    { name: 'Idaho', abbv: 'ID' },
    { name: 'Illinois', abbv: 'IL' },
    { name: 'Indiana', abbv: 'IN' },
    { name: 'Iowa', abbv: 'IA' },
    { name: 'Kansas', abbv: 'KS' },
    { name: 'Kentucky', abbv: 'KY' },
    { name: 'Louisiana', abbv: 'LA' },
    { name: 'Maine', abbv: 'ME' },
    { name: 'Maryland', abbv: 'MD' },
    { name: 'Massachusetts', abbv: 'MA' },
    { name: 'Michigan', abbv: 'MI' },
    { name: 'Minnesota', abbv: 'MN' },
    { name: 'Mississippi', abbv: 'MS' },
    { name: 'Missouri', abbv: 'MO' },
    { name: 'Montana', abbv: 'MT' },
    { name: 'Nebraska', abbv: 'NE' },
    { name: 'Nevada', abbv: 'NV' },
    { name: 'New Hampshire', abbv: 'NH' },
    { name: 'New Jersey', abbv: 'NJ' },
    { name: 'New Mexico', abbv: 'NM' },
    { name: 'New York', abbv: 'NY' },
    { name: 'North Carolina', abbv: 'NC' },
    { name: 'North Dakota', abbv: 'ND' },
    { name: 'Ohio', abbv: 'OH' },
    { name: 'Oklahoma', abbv: 'OK' },
    { name: 'Oregon', abbv: 'OR' },
    { name: 'Pennsylvania', abbv: 'PA' },
    { name: 'Puerto Rico', abbv: 'PR' },
    { name: 'Rhode Island', abbv: 'RI' },
    { name: 'South Carolina', abbv: 'SC' },
    { name: 'South Dakota', abbv: 'SD' },
    { name: 'Tennessee', abbv: 'TN' },
    { name: 'Texas', abbv: 'TX' },
    { name: 'Utah', abbv: 'UT' },
    { name: 'Vermont', abbv: 'VT' },
    { name: 'Virgin Islands', abbv: 'VI' },
    { name: 'Virginia', abbv: 'VA' },
    { name: 'Washington', abbv: 'WA' },
    { name: 'West Virginia', abbv: 'WV' },
    { name: 'Wisconsin', abbv: 'WI' },
    { name: 'Wyoming', abbv: 'WY' },
  ],
};
