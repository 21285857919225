import type { OnInit } from '@angular/core';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ProductItem } from '../product-card';

import { EnvService } from '@core-mkt/services/env/env.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'new-card-item',
  templateUrl: './new-card-item.component.html',
  styleUrls: ['./new-card-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewCardItemComponent implements OnInit {
  @Input() product: ProductItem;
  @Input() ctaType: string;
  theme: ThemeInterface;
  xgritProduct: XGritCompleteProduct;
  priceText = '';
  altPriceText = '';
  faArrowRight = faArrowRight;
  isMouseOver = false;

  constructor(private themeParser: ThemeParserService, private env: EnvService) {}

  ngOnInit(): void {
    this.priceText = this.env.get.brandConfig.pricingText;
    if (this.env.get.brandConfig.altPricingText) {
      this.altPriceText = this.env.get.brandConfig.altPricingText;
    }
    this.xgritProduct = this.product.xGritProduct;
    if (!this.showOriginal && this.altPriceText.length > 0) {
      this.priceText = this.altPriceText;
    }
    if (this.product.cardBackgroundColor === null || this.product.cardBackgroundColor.length === 0) {
      this.product.cardBackgroundColor = 'bg-white text-ace-grey-900';
    }
    const themeInput: ComponentTheme = {
      styles: this.product.styles,
      backgroundColor: this.product.cardBackgroundColor,
      backgroundImage: undefined,
      textColor: this.product.cardTextColor,
      columnCount: 0,
      columnDistribution: undefined,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }

  get showOriginal(): boolean {
    return (
      this.xgritProduct &&
      this.xgritProduct.apiData &&
      this.xgritProduct.apiData.discountPrice < this.xgritProduct.apiData.originalPrice
    );
  }

  get ctaLink(): string | undefined {
    if (this.product.ctaLink) {
      return this.product.ctaLink;
    } else {
      if (this.xgritProduct) {
        return this.xgritProduct.apiData.checkoutLink;
      } else {
        return undefined;
      }
    }
  }

  get target(): string {
    return this.product.ctaNewTab ? '_blank' : '_self';
  }

  get ctaStyle(): string {
    return this.ctaType;
  }

  get hoverClass(): string {
    return this.isMouseOver ? `${this.ctaType}-button-hover` : `${this.ctaType}-button`;
  }

  handleMouseOver() {
    this.isMouseOver = true;
  }

  handleMouseOut() {
    this.isMouseOver = false;
  }
}
