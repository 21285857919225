import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from '../accordion/accordion.module';
import { TabComponent } from './tabbed-content/tabbed-content.component';

@NgModule({
  declarations: [TabComponent],
  exports: [TabComponent],
  imports: [CommonModule, FontAwesomeModule, AccordionModule, FormsModule],
})
export class TabContentModule {}
