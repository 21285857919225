export const reviewData = [
  [
    // Stars Review Data
    {
      name: 'Jesse',
      header: 'Passed the national/state test the first time!',
      review:
        'I did not use any other study material/courses, I only used Aceable Real Estate School. I took the national/state test today and passed both of them on my first attempt.',
    },
    {
      name: 'Emily',
      header: 'Mom of 2',
      review:
        'AceableAgent makes learning easy and manageable! Being a mom of two under two is hard enough and Aceable make it convenient to pull out my laptop or cellphone any time I have a few minutes!',
    },
    {
      name: 'Debra',
      header: 'Loving Aceable SO much',
      review:
        'Loving Aceable SO much. I started with another online training company and I was miserable with how difficult and dry they made it. Thanks to Aceable for making getting my license (FINALLY!) an actual reality!',
    },
  ],
  [
    // Carousel Review Data
    {
      name: 'Jonathan',
      review:
        'This was one of the best online courses that I have taken. It kept me interested and reading. Thank you!',
      date: 'October 30, 2021',
    },
    {
      name: 'Brekden',
      review: 'It is interactive and engaging, it gets the point across very well and I can do it from anywhere.',
      date: 'September 25, 2021',
    },
    {
      name: 'Bridget',
      review:
        'So clear and straight forward!!! Made it easy to ace the course. Appreciate that I could go at my own pace, which also contributed to my ability to ace it the first time, no problem!!!',
      date: 'September 21, 2021',
    },
  ],
  [
    // Column Reviews
    {
      name: 'Chris Heller',
      description:
        'CEO of mello Home, formerly the CEO of Keller Williams, and the #1 Keller Williams associate in North America',
      review:
        '"This is the <span class="bold-col-text text-ace-teal-600 font-bold">only pre-license course that’s developed by Harvard education experts</span>."',
    },
    {
      name: 'Kyle Ferris',
      description: 'Sprout Realty',
      review:
        '"If you work hard and you’re with the right brokerage, or the right team, <span class="bold-col-text text-ace-teal-600 font-bold">you can easily make $100,000 in your first year</span>."',
    },
    {
      name: 'Magdeli Carreon',
      description: 'Keller Williams',
      review:
        '"There is no limit to how much you can make as a real estate agent. <span class="bold-col-text text-ace-teal-600 font-bold">The limit is set by you</span>."',
    },
    {
      name: 'A.J. Abrams',
      description: 'JB Goodwin',
      review:
        '"<span class="bold-col-text text-ace-teal-600 font-bold">Being your own boss is the absolute best</span>. I tell people I am the CEO, COO, and CFO of my own business."',
    },
  ],
  [
    // Column Type 2 Reviews
    {
      name: 'Nasir',
      review:
        "Best course ever!! It's so easy to use and they teach you properly. I loved the app too and what's good about it is that it's online so you can do it whenever you want and it's super affordable.",
      image: {
        src: 'https://ace.imgix.net/uploads/sites/4/2020/07/nasir.png?auto=format,compress',
        altText: 'reviewer photo',
      },
    },
    {
      name: 'Taniyah',
      review:
        "Honestly Aceable was the best choice I've ever made. It's so easy and fun and the best part about it is you can do it on your own time. I learned so much and saved so much money.",
      image: {
        src: 'https://ace.imgix.net/uploads/sites/4/2020/07/Taniyah.png?auto=format,compress',
        altText: 'reviewer photo',
      },
    },
    {
      name: 'Rose',
      review:
        'I love this program it works very well, user friendly and the learning tools provided to learn the material make it wasier to learn as an older adult.',
      image: {
        src: 'https://ace.imgix.net/uploads/sites/4/2020/07/Rose.png?auto=format,compress',
        altText: 'reviewer photo',
      },
    },
    {
      name: 'Rafael',
      review:
        'Each page progresses through topics without being too wordy. Subject matter is lean and to the point with a sprinkle of humor. Very well presented.',
      image: {
        src: 'https://ace.imgix.net/uploads/sites/4/2020/07/Rafael.png?auto=format,compress',
        altText: 'reviewer photo',
      },
    },
  ],
  [
    // Card Reviews
    {
      name: 'Susana Orendain',
      review:
        'Defensive driving courses are never fun but Aceable actually made it a much more pleasant experience. I really enjoyed the animation and the videos. Really impressed on the importance they put on how dangerous it is to speed, to text and to drink while driving. Thank you for making a fun course.',
      image: {
        src: 'https://dec-mkt.imgix.net/tx_dd-susana_orendain.jpg',
        altText: 'reviewer photo',
      },
    },

    {
      name: 'Abhi Kallur',
      review:
        "Defensive driving usually sucks but Aceable gave it a unique spin that makes it quick and simple. The stories that were shown were powerful and it really opened up your perspective to little things you do when you drive, such as texting. Normally, people don't take away much from a DD course, but those stories of people who got into horrible accidents really left a strong impact on me. The course definitely forces you to rethink how you drive and how to use the rules to help you.",
      image: {
        src: 'https://ace.imgix.net/manual/driving/courses/defensive-driving/tx_dd-abhi_kallur.jpg?auto=compress&w=100',
        altText: 'reviewer photo',
      },
    },
    {
      name: 'Jonah Ballesteros',
      review:
        "This is by far the best defensive driving course ever! Convenient and not boring! Excellent customer service! Nicole assisted me with my concern; not only Nicole resolved my concern, but she took the extra steps. It's the extras that really count! You guys are the best!",
      image: {
        src: 'https://ace.imgix.net/uploads/sites/4/2021/09/user-solid-2.png?auto=compress&w=100',
        altText: 'reviewer photo',
      },
    },
  ],
];
