import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber',
})
export class CustomDecimalPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (Number.isInteger(value)) return value;

    return super.transform(value, args);
  }
}
