import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageDataResolver } from '@core-mkt/resolvers/page-data/page-data.resolver';
import { PreviewBrokenComponent } from '@core-mkt/shared/components/preview-broken/preview-broken.component';
import { PageNotFoundComponent } from './templates/page-not-found/page-not-found.component';
import { TemplateDynamicComponent } from './templates/template-dynamic/template-component/template-dynamic.component';

let appRoutes: Routes = [];
appRoutes = appRoutes.concat(
  {
    // Exception for homepage since its slug and path cannot line up.
    path: '',
    children: [
      {
        path: '',
        resolve: { pageData: PageDataResolver },
        pathMatch: 'full',
        component: TemplateDynamicComponent,
      },
    ],
  },
  {
    path: 'preview/',
    resolve: { pageData: PageDataResolver },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: TemplateDynamicComponent,
      },
    ],
  },
  {
    path: 'preview-broken/',
    component: PreviewBrokenComponent,
  },
  {
    path: '404/',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    resolve: { pageData: PageDataResolver },
    component: TemplateDynamicComponent,
  },
);

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [PageDataResolver],
})
export class AppRoutingModule {}
