import { Component } from '@angular/core';

@Component({
  selector: 'preview-broken',
  templateUrl: './preview-broken.component.html',
  styleUrls: ['./preview-broken.component.scss'],
})
export class PreviewBrokenComponent {
  constructor() {}
}
