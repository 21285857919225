import { CommonModule } from '@angular/common';
import type { ComponentFactory } from '@angular/core';
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { DurationModule } from '@core-mkt/services/duration/duration.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { GraphicBannerComponent } from './graphic-banner/graphic-banner.component';

@NgModule({
  declarations: [GraphicBannerComponent],
  exports: [GraphicBannerComponent],
  imports: [CommonModule, DurationModule, QueryParamModule],
})
export class GraphicBannerModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(): ComponentFactory<GraphicBannerComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(GraphicBannerComponent);
  }
}
