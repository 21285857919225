import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Template404RoutingModule } from './template-404-routing.module';
import { Template404Component } from './template-component/template-404.component';

@NgModule({
  declarations: [Template404Component],
  exports: [Template404Component],
  imports: [CommonModule, InsertableModule, Template404RoutingModule, FontAwesomeModule, BannerModule],
})
export class Template404Module {}
