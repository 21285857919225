import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePreloadModule } from '@core-mkt/services/image-preload/image-preload.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { ProductPickerModule } from '@core-mkt/shared/components/product-picker/product-picker.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { VisionDropdownModule } from '@core-mkt/shared/components/vision-dropdown/vision-dropdown.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VisionSelectModule } from '../vision-select/vision-select.module';
import { LayoutComponent } from './layout/layout.component';

@NgModule({
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    QueryParamModule,
    ImagePreloadModule,
    VisionButtonModule,
    VisionDropdownModule,
    ProductPickerModule,
    VisionSelectModule,
  ],
})
export class LayoutModule {}
