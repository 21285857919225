<div #careerCenter *ngIf="theme" class="{{ theme?.root }}" [style]="theme?.bgImage">
  <div class="container {{ theme?.container }}">
    <div
      *ngIf="careerCenterTitle"
      class="p-v-spacing pb-4 pt-v-spacing wysiwyg hidden"
      [innerHTML]="careerCenterTitle"
    ></div>
    <div class="flex flex-col lg:flex-row lg:flex-row-reverse lg:mb-v-spacing">
      <div
        class="flex flex-col mx-0 bg-gray-200 p-v-spacing rounded-md mb-v-spacing lg:items-start lg:bg-transparent lg:pt-0 lg:w-sidebar lg:min-w-sidebar"
      >
        <div class="px-0 flex w-full lg:bg-gray-200 lg:pt-v-spacing lg:px-v-spacing pb-2 rounded-t-md lg:ml-v-spacing">
          <p class="mb-2 md:mb-0 font-bold filter-title">{{ filterTitle }}</p>
        </div>
        <div
          class="flex flex-col px-0 align-items-center w-full lg:flex-row lg:bg-gray-200 lg:px-v-spacing lg:pb-v-spacing rounded-b-md lg:ml-v-spacing"
        >
          <select
            class="dropdown-native mr-v-spacing lg:mr-0 text-base font-light appearance-none w-full font-bold whitespace-nowrap text-left cursor-pointer select-none rounded-md leading-6 h-full"
            (change)="onCategoryFilterChange($event.target.value)"
            [(ngModel)]="selectedCategoryFilter"
          >
            <option value="">
              <h2 class="filter-default-label">{{ filterDefaultLabel }}</h2>
            </option>
            <option *ngFor="let category of categoryList" [value]="category">
              <h2>{{ category }}</h2>
            </option>
          </select>
        </div>
        <div
          *ngIf="careerCenterContent"
          class="py-v-spacing sm:px-v-spacing lg:pt-0 wysiwyg hidden lg:block"
          [class.mt-v-spacing]="!careerCenterTitle"
          [innerHTML]="careerCenterContent"
        ></div>
      </div>
      <div class="w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-x-6 lg:w-auto">
        <ng-template [ngIf]="pages[activeIndex] && pages[activeIndex].length > 0">
          <career-center-article-card
            *ngFor="let card of pages[activeIndex]"
            [data]="card"
            class="card w-100 my-v-spacing sm:mt-0"
          >
          </career-center-article-card>
        </ng-template>
      </div>
    </div>
    <nav *ngIf="pages.length > 1" role="pagination" class="w-full py-v-spacing px-0 mt-v-spacing text-center">
      <ul class="flex justify-center">
        <li class="page-item cursor-pointer" [ngClass]="{ hidden: activeIndex === 0 }">
          <fa-icon
            class="mr-2 fa-1x page-link"
            (click)="goToPreviousPage(); scroll(careerCenter)"
            [icon]="faArrowLeft"
          ></fa-icon>
        </li>
        <li
          class="page-item cursor-pointer"
          [ngClass]="{
            hidden: pages.length < 4 || (pages.length >= 4 && activeIndex < 2),
            'sm:hidden': pages.length < 4 || (pages.length >= 4 && activeIndex < 3)
          }"
        >
          <fa-icon class="fa-1x page-link" (click)="skip('back'); scroll(careerCenter)" [icon]="faEllipsisH"></fa-icon>
        </li>
        <li
          class="page-item page-number cursor-pointer"
          *ngFor="let page of pages; let i = index"
          [ngClass]="{
            'paging-end': i === deskPageIndex + 3,
            'mobile-end': i === mobilePageIndex + 2,
            'pre-page': i < deskPageIndex && deskPageIndex !== 0,
            'mobile-start': i === deskPageIndex - 1 && deskPageIndex !== 0
          }"
        >
          <span
            class="button page-link"
            [ngClass]="{ active: i === activeIndex }"
            (click)="changePage(i); scroll(careerCenter)"
          >
            {{ i + 1 }}
          </span>
        </li>
        <li
          class="page-item cursor-pointer"
          [ngClass]="{
            hidden: pages.length < 4 || (pages.length >= 4 && activeIndex > pages.length - 3),
            'sm:hidden': pages.length < 4 || (pages.length >= 4 && activeIndex >= pages.length - 4)
          }"
        >
          <fa-icon
            class="fa-1x page-link"
            (click)="skip('forward'); scroll(careerCenter)"
            [icon]="faEllipsisH"
          ></fa-icon>
        </li>
        <li class="page-item cursor-pointer" [ngClass]="{ hidden: activeIndex === pages.length - 1 }">
          <fa-icon
            class="fa-1x page-link"
            (click)="goToNextPage(); scroll(careerCenter)"
            [icon]="faArrowRight"
          ></fa-icon>
        </li>
      </ul>
    </nav>
    <hr class="md:hidden my-v-spacing" />
    <div
      *ngIf="careerCenterContent"
      class="py-v-spacing lg:px-v-spacing wysiwyg block lg:hidden"
      [innerHTML]="careerCenterContent"
    ></div>
  </div>
</div>
