import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { MultiCard } from './multi-card';

@Component({
  selector: 'multi-card',
  templateUrl: './multi-card.component.html',
  styleUrls: ['./multi-card.component.scss'],
})
export class MultiCardComponent implements OnInit {
  @Input() data: MultiCard;
  constructor(private redactor: WysiwygRedactorService, private themeParser: ThemeParserService) {}
  hasContent = false;
  hasTitle = false;
  theme: ThemeInterface;

  ngOnInit() {
    this.setCraftData();
    this.setTheme();
  }

  setTheme() {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }

  setCraftData() {
    this.hasTitle = this.data.componentTitle !== null && this.data.componentTitle.length > 0;
    this.data.componentTitle = this.redactor.bypassSanitizer(this.data.componentTitle);
    this.data.cardList = this.data.cardList.filter((card) => card.cardContent !== null);
    this.hasContent = this.data.cardList.length > 0 || this.hasTitle;
    this.data.cardList.forEach((card) => {
      card.cardContent = this.redactor.bypassSanitizer(card.cardContent);
      card.cardButtonData = {
        bgTheme: '',
        buttonLink: card.cardButtonLink,
        buttonText: card.cardButtonLabel,
        buttonStyle: card.cardButtonType,
        newTab: card.cardButtonLinkNewTab,
        nofollow: card.cardButtonLinkNofollow,
        noopener: card.cardButtonLinkNewTab,
        noreferrer: card.cardButtonLinkNewTab,
        ctaTag: card.cardButtonCtaTag !== null ? card.cardButtonCtaTag : '',
        fullWidth: false,
        buttonAlignment: 'center',
      };
    });
  }
}
