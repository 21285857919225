import { DOCUMENT } from '@angular/common';
import type { AfterViewInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { CountdownService } from '@core-mkt/services/countdown/countdown.service';
import { PrivacyCheckService } from '@core-mkt/services/privacy-check/privacy-check.service';
import { ScriptLoaderService } from '@core-mkt/services/script-loader/script-loader.service';
import { cloneDeep } from 'lodash-es';
import type { EmailCapture, Footer, FooterLinkGroup, FooterLogo, ShareButtons } from './footer';
import { footerData } from './footer-data';

import { Router } from '@angular/router';
import type { Subscription } from 'rxjs';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterViewInit {
  pageData: Footer;
  resolverData: PageDataResponse;
  showStickyEmailCapture = true;
  displayRE = true;
  promoBannerActive = true;
  offerEndTime: number = null;
  offerStartTime: number = null;
  graphicBanner: HTMLElement;
  showPrivacyManagerModal = false;
  private readonly copyrightYear_ = new Date().getFullYear();
  routerEventSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private countdownService: CountdownService,
    public scriptLoaderService: ScriptLoaderService,
    public privacyCheckService: PrivacyCheckService,
    @Inject(DOCUMENT) private document: Document,
    private _router: Router,
  ) {
    this.pageData = footerData;

    if (this.route.snapshot.data.pageData) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
      if (this.resolverData.craftData.header) {
        let aaGraphicPromoBanner;
        let realEstateHeaderPromoBanner;
        if (this.resolverData.craftData.header?.aaGraphicPromoBanner) {
          aaGraphicPromoBanner = this.resolverData.craftData.header?.aaGraphicPromoBanner[0];
        }
        if (this.resolverData.craftData.header?.realEstateHeaderPromoBanner) {
          realEstateHeaderPromoBanner = this.resolverData.craftData.header?.realEstateHeaderPromoBanner[0];
          this.promoBannerActive = realEstateHeaderPromoBanner.showHidePromoBanner;
        }
        if (
          aaGraphicPromoBanner &&
          aaGraphicPromoBanner.showHideGraphicPromoBanner &&
          realEstateHeaderPromoBanner &&
          realEstateHeaderPromoBanner.showHidePromoBanner
        ) {
          const startTime = aaGraphicPromoBanner.startTime;
          const endTime = aaGraphicPromoBanner.endTime;

          if (startTime == null && endTime == null) {
            this.displayRE = true;
          } else if (startTime && endTime) {
            this.displayRE = this.countdownService.displayBanner(startTime, endTime);
          }
        }
      }
      if (this.resolverData.craftData.banners && this.resolverData.craftData.banners[0]) {
        const promoData = this.resolverData.craftData.banners[0].topBanner[0];
        if (promoData.showHidePromoBanner) {
          const startTime = promoData.promotionStartDateTime;
          const endTime = promoData.promotionEndsDateTime;

          if (startTime == null && endTime == null) {
            this.displayRE = true;
          } else if (startTime && endTime) {
            const startTimeStamp = new Date(startTime).getTime();
            const endTimeStamp = new Date(endTime).getTime();
            this.displayRE = this.countdownService.displayBanner(startTimeStamp, endTimeStamp);
          }
        }
      }
    } else {
      this.pageData.emailCapture[0];
    }
  }

  ngAfterViewInit(): void {
    this.graphicBanner = this.document.getElementById('graphicBanner');
  }

  get copyrightYear(): number {
    return this.copyrightYear_;
  }

  get showHideFooter(): boolean {
    if (this.route.snapshot.data?.pageData?.craftData?.pageOptions) {
      return !this.route.snapshot.data?.pageData?.craftData?.pageOptions?.hideFooter;
    }
    return this.pageData.aceShowHideFooterNav;
  }

  get footerLogos(): FooterLogo[] {
    return this.pageData.aceFooterLogo;
  }

  get generalLinks(): FooterLinkGroup[] {
    return this.pageData.aceGeneralLinkGroup;
  }

  get socialLinks(): FooterLinkGroup[] {
    return this.pageData.aceSocialLinkGroup;
  }

  get verticalLinks(): FooterLinkGroup[] {
    return this.pageData.aceVerticalLinkGroup;
  }

  get bottomLinks(): FooterLinkGroup[] {
    return this.pageData.bottomLinkGroup;
  }

  get emailCaptureData(): EmailCapture {
    return this.pageData.emailCapture[0];
  }

  get shareButtons(): ShareButtons[] {
    return this.pageData.aceShareGroup;
  }

  itemLabel(button: ShareButtons): string {
    return button.label;
  }

  stripLink(rawLink: string): string {
    return rawLink.replace(/\w+:\/\//, '');
  }

  openPrivacyManagerModal() {
    this.showPrivacyManagerModal = true;
  }
}
