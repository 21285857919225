<marketing [includeOptimizely]="includeOptimizely"></marketing>
<ng-template #head></ng-template>
<ng-container *ngIf="formattedData">
  <div *ngFor="let options of formattedData" class="flex flex-col mx-auto">
    <ng-template aceInsertableHost [options]="options" [registry]="registryService"></ng-template>
  </div>
  <ambassador-modal></ambassador-modal>
</ng-container>
<ng-template #foot></ng-template>

