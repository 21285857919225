import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Image } from '@core-mkt/interfaces/image';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { FeaturedContent } from '../top-section';

@Component({
  selector: 'featured-content',
  templateUrl: './featured-content.component.html',
  styleUrls: ['./featured-content.component.scss'],
})
export class FeaturedContentComponent implements OnInit {
  @Input() data: FeaturedContent;

  constructor(private redactor: WysiwygRedactorService) {}

  get featuredContentIcon(): Image {
    if (this.data.featuredContentIcon && this.data.featuredContentIcon.length > 0)
      return this.data.featuredContentIcon[0];
  }

  ngOnInit(): void {
    if (this.data.featuredContentText !== null) {
      this.data.featuredContentText = this.redactor.bypassSanitizer(this.data.featuredContentText, 'lazy');
    }
  }
}
