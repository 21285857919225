<div class="footer text-base text-ace-grey-900" *ngIf="showHideFooter">
  <div class="flex flex-wrap container">
    <!-- Logo -->
    <div class="footer__logo-container w-full lg:w-1/2 pb-v-spacing lg:pb-0 flex flex-col md:flex-row lg:flex-col justify-start md:justify-between lg:justify-start align-top">
      <a
        class="hover:text-ace-teal-500"
        *ngFor="let footerLogo of footerLogos"
        [href]="footerLogo.logoLink"
        aria-label="Aceable Logo"
      >
        <img
          width="230"
          height="40"
          *ngIf="footerLogo.logoImage.length > 0"
          [src]="footerLogo.logoImage[0].url"
          [alt]="footerLogo.logoImage[0].altText ? footerLogo.logoImage[0].altText : ''"
        />
        {{ footerLogo.logoImage.length === 0 ? stripLink(footerLogo.logoLink) : '' }}
      </a>
      <!-- Refer and Partner Buttons -->
      <div *ngIf="shareButtons" class="flex flex-col sm:flex-row footer-buttons mt-6 sm:mt-8 md:mt-0 lg:mt-12">
          <a *ngFor="let b of shareButtons; let last = last; trackBy: itemLabel" 
            class="
              footer-button
              text-ace-teal-700
              hover:text-ace-teal-800
              active:text-ace-teal-900
              inline-flex
              cursor-pointer
              items-center
              justify-center
              py-3.5
              px-6
              w-fit
              border-[1.5px]
              border-solid
              border-ace-teal-700
              hover:border-ace-teal-800
              active:border-ace-teal-950
              rounded-[100px]
              {{ !last ? 'mb-2 sm:mb-0' : '' }}" 
            href="{{b.link}}"
          >
            <span class="label-primary" [innerHTML]="b.label"></span>
          </a>
      </div>
    </div>
    <!-- Vertical Links: Driving, Real Estate -->
    <footer-link
      class="w-1/3 pt-v-spacing lg:pt-0 md:mt-1 lg:mt-0 mb-v-spacing md:w-1/3 lg:w-1/6"
      [links]="verticalLinks"
      footerLocation="vertical"
      linkClass="w-full lg:w-full"
    ></footer-link>
    <!-- General Links: Careers, Partnerships, Help Center, Contact, Our Blog -->
    <footer-link
      class="w-2/3 md:mt-1 pt-v-spacing lg:pt-0 lg:mt-0 mb-v-spacing md:w-1/3 lg:w-1/6"
      [links]="generalLinks"
      footerLocation="general"
      alignClass="md:items-end md:justify-end"
      linkClass="w-full lg:w-full"
    ></footer-link>
    <!-- Social Links: Twitter, Facebook, Instagram, LinkedIn, YouTube -->
    <footer-link
      class="
        md:pl-0
        lg:mt-0
        w-full
        md:w-1/3
        mx-0
        pt-v-spacing
        md:mt-1
        md:mb-v-spacing
        lg:py-0 lg:px-v-spacing lg:w-1/6
        py-3
        md:px-v-spacing
      "
      [links]="socialLinks"
      footerLocation="social"
      alignClass="lg:items-start justify-between lg:justify-end w-full sm:w-3/4 md:w-full"
      linkClass=""
    ></footer-link>
    <!-- Copyright -->
    <div class="pt-v-spacing text-sm self-end lg:text-left footer__copyright py-1 w-full md:w-1/3 lg:w-1/3">
      <p>© {{ copyrightYear }} Aceable, Inc. <br class="hidden md:block lg:hidden">All rights reserved.</p>
    </div>
    <!-- Bottom Links: Privacy Policy, Terms & Conditions -->
    <div class="w-full sm:w-2/3 flex flex-col sm:flex-row justify-end self-end">
      <footer-link
        class="w-full md:w-1/2 lg:w-2/3"
        footerLocation="bottom"
        [links]="bottomLinks"
        alignClass="items-end lg:justify-evenly md:mt-v-spacing"
        linkClass="lg:mx-v-spacing"
      >
      </footer-link>
      <button (click)="openPrivacyManagerModal()" class="self-end leading-7 text-left text-sm w-full sm:w-1/3">Do not sell or share my information</button>
    </div>
  </div>
</div>
<email-capture *ngIf="emailCaptureData && !(promoBannerActive && ((!!graphicBanner) && displayRE))" [data]="emailCaptureData"></email-capture>

<privacy-manager *ngIf="showPrivacyManagerModal" (hideModal)="showPrivacyManagerModal = false;"></privacy-manager>
