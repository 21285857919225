/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const BannerFragment = {
  Banner: gql`
    fragment BannerQuery on topBanner_promoBanner_BlockType {
      overwriteCoupon
      promotionEndsDateTime
      promotionReset {
        ... on promotionReset_BlockType {
          promotionEndsDateTime
          resetText
        }
      }
      promotionStartDateTime
      promotionText
      showHidePromoBanner
    }
  `,
};
