import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateSingleComponent } from './template-component/template-single.component';

const routes: Routes = [{ path: '', component: TemplateSingleComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateSingleTemplateRoutingModule {}
