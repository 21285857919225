import { Injectable } from '@angular/core';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
declare var hbspt: any;

@Injectable({
  providedIn: 'any',
})
export class HubspotService {
  constructor(private sls: ScriptLoaderService) {}

  multiform = [
    {
      formId: '8812b0a9-131e-4f90-8139-043c3fb1d357',
      id: 'hubspot0',
      step: 0,
    },
    {
      formId: '0ab9d914-749a-43dd-902e-2f85b09f7bb2',
      id: 'hubspot1',
      step: 1,
    },
    {
      formId: 'a504b816-979a-47be-a4fb-14cdf56d84d0',
      id: 'hubspot2',
      step: 2,
    },
  ];

  initForms(hubspotType: string): void {
    this.sls.injectScriptByType('hubspot');
    this.sls.scriptLoadedSubject.subscribe((data: any) => {
      if (data.loaded && data.type === 'hubspot') {
        this.create(hubspotType);
      }
    });
  }

  create(hubspotType: string): void {
    if (hubspotType !== '8812b0a9-131e-4f90-8139-043c3fb1d357') {
      hbspt.forms.create({
        portalId: '4906327',
        formId: hubspotType,
        target: '#hubspot',
      });
    } else {
      this.multiform.forEach((form) => {
        hbspt.forms.create({
          portalId: '4906327',
          formId: form.formId,
          target: '#' + form.id,
        });
      });
    }
  }
}
