<div *ngIf="hasContent" class="product-card font-body bg-cover {{ theme?.root }}" [style]="theme?.bgImage">
  <div class="container {{ theme?.container }}">
    <div
      *ngIf="!hideSectionTitle"
      class="py-v-spacing tab:px-v-spacing max-w-vision mx-auto wysiwyg product-card__title"
      [innerHTML]="data.sectionTitle"
    ></div>
    <div class="mx-auto flex flex-col lg:flex-row py-v-spacing sm:px-v-spacing">
      <div
        class="w-full lg:w-1/6 mr-v-spacing mb-v-spacing self-start bg-ace-grey-300 p-v-spacing text-ace-grey-900 flex flex-col"
        *ngIf="filterType.length > 0"
      >
        <p class="text-sm text-ace-grey-700 font-bold mb-2">
          Filter by {{ filterType.includes('State') ? 'State' : 'Category' }}
        </p>
        <span class="hidden lg:block my-1 cursor-pointer text-ace-grey-800" (click)="doFiltering('none')">All</span>
        <span
          *ngFor="let filterItem of filterList"
          class="hidden lg:block my-1 cursor-pointer text-ace-grey-800"
          (click)="doFiltering(filterItem.value)"
        >
          {{ filterItem.label }}
        </span>
        <select class="block lg:hidden cursor-pointer p-2" (change)="doFiltering($event.target.value)">
          <option value="none">All</option>
          <option [value]="filter.value" *ngFor="let filter of filterList">{{ filter.label }}</option>
        </select>
      </div>
      <div
        *ngIf="!useCompactCards"
        class="grid gap-x-6 grid-cols-1 justify-center justify-items-stretch w-full {{ cardContainerWidth }}"
        [class.sm:justify-items-start]="filterType?.length > 0"
        [class.lg:grid-cols-3]="unfilteredProducts.length > 2"
        [class.sm:grid-cols-2]="unfilteredProducts.length > 1"
        [class.justify-items-stretch]="!horizontalAlignment"
        [class.justify-items-center]="horizontalAlignment && horizontalAlignment === 'center'"
        [class.justify-items-end]="horizontalAlignment && horizontalAlignment === 'right'"
      >
        <card-item
          *ngFor="let product of products; let idx = index"
          class="mb-v-spacing flex product-card__item max-w-[364px]"
          [data]="product"
          [ctaType]="ctaType"
          [hideCta]="hideCta"
          [learnMore]="learnMore"
        ></card-item>
      </div>
      <div
        *ngIf="useCompactCards"
        class="grid grid-cols-1 md:grid-cols-2 p-6 gap-6 w-full"
        [class]="{
          'lg:grid-cols-3': filterType?.length === 0,
          'xl:grid-cols-4': filterType?.length === 0,
          'lg:grid-cols-2': filterType?.length > 0,
          'xl:grid-cols-3': filterType?.length > 0
        }"
      >
        <new-card-item *ngFor="let product of products; let idx = index" [product]="product" [ctaType]="ctaType">
        </new-card-item>
      </div>
    </div>
  </div>
</div>
