import { ProductTypes } from './product-types';
import { XgritPricingSuccessful } from './pricing-response';
import { Brand } from '../config/brand.enum';

export const mockPricingResponse: XgritPricingSuccessful = {
  lineItemList: [
    {
      chargeAmount: 20,
      couponList: [],
      hiddenModifierPrice: 30,
      infoList: [],
      product: {
        schoolInformation: 'Approval',
        _id: 'product1234',
        brandId: Brand.IDS,
        variantList: [],
        asset: {},
        content: {
          description: 'desc',
          title: 'title',
        },
        courseId: 'course1234',
        infoList: [],
        key: 'key',
        pricing: {
          min: 10,
          current: 20,
          max: 30,
        },
        type: ProductTypes.COURSE,
        filter: {
          app: 'ids',
          course: 'TEST.COURSE',
          minAge: 18,
          state: 'XX',
          type: 'course',
        },
        is: {
          active: true,
          free: false,
          inApp: true,
          parentTool: false,
          purchased: true,
          requireTerms: true,
          upsell: false,
          shipped: true,
          trial: false,
        },
        productList: [],
        progressId: '',
        termsUrl: 'fakeUrl',
      },
      courseId: 'course1234',
      description: 'some desc',
      displayCouponList: [],
      productList: [],
      title: 'test course',
    },
  ],
  display: {
    grossTotal: 20,
    grossDiscount: 10,
    total: 20,
  },
  total: 20,
  grossDiscount: 10,
  grossTotal: 20,
};
