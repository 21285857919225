import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [FaqComponent],
  exports: [FaqComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, LazyImgModule, LoadingIndicatorModule],
})
export class FaqModule {}
