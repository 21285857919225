import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { ResourceCard } from './resources';
import { Resources } from './resources';

@Component({
  selector: 'resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  @Input() data: Resources;
  theme: ThemeInterface;
  resourcesTitle = undefined;

  constructor(
    private themeParser: ThemeParserService,
    private redactor: WysiwygRedactorService,
    private imgixService: ImgixService,
  ) {}

  get resourceTitle(): string | undefined {
    return this.resourcesTitle;
  }

  get resourceCards(): ResourceCard[] {
    return this.data.resourceCards ? this.data.resourceCards : this.data.aarpResourceCards;
  }

  ngOnInit(): void {
    if (this.data?.resourcesTitle) {
      this.resourcesTitle = this.redactor.bypassSanitizer(this.data.resourcesTitle);
    }

    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.resourcesBackgroundColor,
      backgroundImage: this.data.resourcesBackgroundImage?.[0],
      textColor: this.data.resourcesTextColor,
      columnCount: this.data.resourceCards ? this.data.resourceCards.length : this.data.aarpResourceCards.length,
      columnDistribution: undefined,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }
}
