import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';

import { CardReviewsComponent } from './card-reviews/card-reviews.component';
import { CarouselReviewsComponent } from './carousel-reviews/carousel-reviews.component';
import { ColumnReviewsComponent } from './column-reviews/column-reviews.component';
import { ColumnType2ReviewsComponent } from './column-type2-reviews/column-type2-reviews.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { SingleReviewComponent } from './single-review/single-review.component';
import { StarReviewsComponent } from './star-reviews/star-reviews.component';

const reviewComponents = [
  ReviewsComponent,
  StarReviewsComponent,
  SingleReviewComponent,
  ColumnType2ReviewsComponent,
  ColumnReviewsComponent,
  CarouselReviewsComponent,
  CardReviewsComponent,
];

@NgModule({
  declarations: [reviewComponents],
  exports: [reviewComponents],
  imports: [CommonModule, FontAwesomeModule, LazyImgModule, SwiperModule],
})
export class ReviewsModule {}
