import { isPlatformServer } from '@angular/common';
import type { OnInit } from '@angular/core';
import {
  Compiler,
  Component,
  Inject,
  InjectionToken,
  Injector,
  Optional,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { EmbedStylesService } from '@core-mkt/services/embed-styles/embed-styles.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'template-404',
  templateUrl: './template-404.component.html',
})
export class Template404Component implements OnInit {
  @ViewChild('head', { read: ViewContainerRef })
  public head: ViewContainerRef;
  @ViewChild('foot', { read: ViewContainerRef })
  public foot: ViewContainerRef;
  @ViewChild('notFound', { read: ViewContainerRef })
  public notFound: ViewContainerRef;
  resolverData: PageDataResponse;
  pageData: any;
  includeOptimizely: boolean;

  constructor(
    private route: ActivatedRoute,
    private embedStylesService: EmbedStylesService,
    private env: EnvService,
    private compiler: Compiler,
    private injector: Injector,
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<any>,
  ) {
    if (this.route.snapshot.data.pageData) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
      this.pageData = this.resolverData.craftData;
      if (this.pageData.styles && this.pageData.styles.embedStyles) {
        this.embedStylesService.addEmbedStyles(this.pageData.styles.embedStyles);
      }
    }
    this.includeOptimizely = false;
  }

  async ngOnInit(): Promise<void> {
    if (isPlatformServer(this.platformId)) {
      this.response.statusCode = 404;
      this.response.statusMessage = '404 - Page Not Found';
    }
    const brand = this.env.get.brandConfig.id;
    switch (brand) {
      case 'AA': {
        await import(
          /* webpackChunkName: "aa-banner-module" */
          '@core-mkt/shared/components/banner/banner.module'
        ).then(({ BannerModule }) => this.compileLazyModule(BannerModule, 'head'));
        await import(
          /* webpackChunkName: "aa-header-module" */
          '@core-mkt/brands/aa/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "aa-footer-module" */
          '@core-mkt/brands/aa/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        await import(
          /* webpackChunkName: "aa-not-found-module" */
          '@core-mkt/brands/aa/src/app/components/not-found/not-found.module'
        ).then(({ NotFoundModule }) => this.compileLazyModule(NotFoundModule, 'notFound'));
        break;
      }

      case 'ACE': {
        await import(
          /* webpackChunkName: "ace-banner-module" */
          '@core-mkt/shared/components/banner/banner.module'
        ).then(({ BannerModule }) => this.compileLazyModule(BannerModule, 'head'));
        await import(
          /* webpackChunkName: "ace-header-module" */
          '@core-mkt/brands/ace/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "ace-footer-module" */
          '@core-mkt/brands/ace/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        await import(
          /* webpackChunkName: "ace-not-found-module" */
          '@core-mkt/brands/ace/src/app/components/not-found/not-found.module'
        ).then(({ NotFoundModule }) => this.compileLazyModule(NotFoundModule, 'notFound'));
        break;
      }

      case 'AARP': {
        await import(
          /* webpackChunkName: "aarp-header-module" */
          '@core-mkt/brands/aarp/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "aarp-footer-module" */
          '@core-mkt/brands/aarp/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        await import(
          /* webpackChunkName: "aarp-not-found-module" */
          '@core-mkt/brands/aarp/src/app/components/not-found/not-found.module'
        ).then(({ NotFoundModule }) => this.compileLazyModule(NotFoundModule, 'notFound'));
        break;
      }

      case 'DEC': {
        await import(
          /* webpackChunkName: "aarp-header-module" */
          '@core-mkt/brands/dec/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "aarp-footer-module" */
          '@core-mkt/brands/dec/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        await import(
          /* webpackChunkName: "aarp-not-found-module" */
          '@core-mkt/brands/dec/src/app/components/not-found/not-found.module'
        ).then(({ NotFoundModule }) => this.compileLazyModule(NotFoundModule, 'notFound'));
        break;
      }
    }
  }

  private compileLazyModule(module, target: string): void {
    this.compiler.compileModuleAsync(module).then((moduleFactory) => {
      // Create a moduleRef, resolve an entry component, create the component
      const moduleRef = moduleFactory.create(this.injector);
      const componentFactory = (moduleRef.instance as any).resolveComponent();
      if (target === 'head') {
        this.head.createComponent(componentFactory);
      } else if (target === 'foot') {
        this.foot.createComponent(componentFactory);
      } else {
        this.notFound.createComponent(componentFactory);
      }
    });
  }
}
