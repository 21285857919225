<div *ngIf="theme" class="one-three bg-no-repeat bg-center bg-cover {{ theme?.root }}" [style]="theme?.bgImage">
  <div class="container">
    <div *ngIf="oneThreeTitle" class="wysiwyg tab:px-v-spacing max-w-vision mx-auto" [innerHTML]="oneThreeTitle"></div>
    <div class="flex {{ columnLayout }}">
      <div
        class="flex flex-col {{ itemAlign }} mb-auto py-v-spacing sm:px-v-spacing md:pb-0 md:w-4/12 lg:w-3/12 w-full"
        [class.md:mr-v-spacing]="!columnLayout.includes('flex-row-reverse')"
      >
        <div [class]="textAlign + ' wysiwyg'" [innerHtml]="oneColumnText"></div>
        <img
          *ngIf="oneColumnImage"
          width="185"
          height="250"
          class="mt-4 one-col-img max-h-[250px]"
          [src]="imgixService.buildImgixUrl(oneColumnImage.url)"
          [alt]="oneColumnImage.altText ? oneColumnImage.altText : ''"
        />
      </div>
      <div
        class="flex flex-col justify-center py-v-spacing sm:px-v-spacing md:pt-0 md:w-8/12 lg:w-9/12 w-full"
        [class.md:mr-v-spacing]="columnLayout.includes('flex-row-reverse')"
      >
        <div class="wysiwyg" [innerHtml]="threeColumnsContent"></div>
      </div>
    </div>
  </div>
</div>
