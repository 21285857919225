<!-- Stars Reviews -->
<div
  [class]="backgroundInfo.url ? textColorOverride + ' bg-no-repeat bg-center bg-cover' : backgroundInfo"
  [style.backgroundImage]="backgroundInfo.url && 'url(' + backgroundInfo.url + ')'"
>
  <div class="container {{ containerClasses }}">
    <div *ngIf="title" class="wysiwyg tab:px-v-spacing max-w-vision mx-auto" [innerHTML]="title"></div>
    <div
      class="flex flex-wrap flex-col lg:flex-row text-center justify-center items-center mb-v-spacing py-v-spacing sm:px-v-spacing"
    >
      <div class="text-lg font-bold mb-v-spacing md:mb-0 leading-tight">What students say about AceableAgent</div>
      <div class="flex flex-col xs:flex-row items-center">
        <div class="stars pb-v-spacing xs:pb-0 px-v-spacing">
          <fa-icon class="star text-md fa-1x" [icon]="faStar" *ngFor="let star of fiveStars"></fa-icon>
        </div>
        <div class="text-base"><strong>4.9</strong>/5 <span class="mx-v-spacing">|</span> Over 6,000 reviews</div>
      </div>
    </div>
    <div class="flex md:flex-row flex-col justify-center">
      <div
        *ngFor="let review of starsReviews"
        class="md:w-1/3 w-full bg-white mx-0 mb-8 md:mr-v-spacing p-v-spacing text-ace-grey-900"
        [ngClass]="{
          'max-w-vision mx-auto': starsReviews.length === 1
        }"
      >
        <div class="inline-flex">
          <fa-icon class="star text-base fa-1x" [icon]="faStar" *ngFor="let star of fiveStars"></fa-icon>
        </div>
        <div class="inline-flex text-xs my-v-spacing ml-v-spacing font-semibold">{{ review.name }}</div>
        <div class="text-base text-ace-grey-900 font-extrabold mb-v-spacing">{{ review.header }}</div>
        <div class="text-xs font-light">{{ review.review }}</div>
      </div>
    </div>
  </div>
</div>
