import { Component, Input } from '@angular/core';
import { reviewData } from '../review-data';

@Component({
  selector: 'card-reviews',
  templateUrl: './card-reviews.component.html',
  styleUrls: [],
})
export class CardReviewsComponent {
  @Input() backgroundInfo: any;
  @Input() textColorOverride: string;
  @Input() containerClasses: string;
  @Input() title: string;

  cardReviews = reviewData[4];

  constructor() {}
}
