import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CjAffiliateService {
  private cjevent: string;
  private cjeventid: string;
  private utmSource: string;

  constructor(private route: ActivatedRoute) {
    this.cjevent = this.route.snapshot.queryParamMap.get('cjevent');
    this.cjeventid = this.route.snapshot.queryParamMap.get('cjeventid');
    this.utmSource = this.route.snapshot.queryParamMap.get('utm_source');
  }

  /**
   * The cjevent query param is deprecated, so we'll be supporting cjeventid long-term instead.
   * As a precaution we'll check for both cjevent and cjeventid query params.
   */
  get cjEventId(): string {
    return this.cjeventid || this.cjevent;
  }

  /**
   * Conditionally create the CJEVENTID cookie if both utm_source and cjeventid query params are present.
   */
  conditionallySetCookie(): void {
    if (this.cjEventId != null && this.utmSource === 'cj') {
      this.createCookie();
    }
  }

  /**
   * Create the CJEVENTID cookie.
   */
  private createCookie(): void {
    const value = this.cjEventId;
    const expirationLength = 2592000000; // 30 days in milliseconds
    const dateNow = new Date();
    dateNow.setTime(dateNow.getTime() + expirationLength); // Sets expiration time (Time now + 30 days)
    const exp = dateNow.toUTCString();
    document.cookie = `CJEVENTID=${value}; expires=${exp}; path=/; domain=.aceable.com`;
  }
}
