import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterModule as AAFooterModule } from '@core-mkt/aa/src/app/components/footer/footer.module';
import { FooterModule } from '@core-mkt/brands/ace/src/app/components/footer/footer.module';
import { HeaderModule } from '@core-mkt/brands/ace/src/app/components/header/header.module';
import { HeaderModule as AAHeader } from '@core-mkt/brands/aa/src/app/components/header/header.module';
import { HeaderModule as DecHeader } from '@core-mkt/brands/dec/src/app/components/header/header.module';
import { FooterModule as DecFooterModule } from '@core-mkt/brands/dec/src/app/components/footer/footer.module';
import { HeaderModule as AarpHeader } from '@core-mkt/brands/aarp/src/app/components/header/header.module';
import { FooterModule as AarpFooterModule } from '@core-mkt/brands/aarp/src/app/components/footer/footer.module';
import { FaqModule } from '@core-mkt/shared/components/faq/faq.module';
import { AccordionModule } from '@core-mkt/shared/components/accordion/accordion.module';
import { AnchorButtonModule } from '@core-mkt/shared/components/anchor-button/anchor-button.module';
import { AnchorModule } from '@core-mkt/shared/components/anchor/anchor.module';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { BreadcrumbModule } from '@core-mkt/shared/components/breadcrumb/breadcrumb.module';
import { GladlyHelpCenterModule } from '@core-mkt/shared/components/gladly-help-center/gladly-help-center.module';
import { HeroHomepageModule } from '@core-mkt/shared/components/hero-homepage/hero-homepage.module';
import { LayoutModule } from '@core-mkt/shared/components/layout/layout.module';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';
import { MultiCardModule } from '@core-mkt/shared/components/multi-card/multi-card.module';
import { MultiColumnModule } from '@core-mkt/shared/components/multi-column/multi-column.module';
import { ResourcesModule } from '@core-mkt/shared/components/resources/resources.module';
import { ReviewsModule } from '@core-mkt/shared/components/reviews/reviews.module';
import { TopSectionModule } from '@core-mkt/shared/components/top-section/top-section.module';
import { PriceSelectorModule } from '@core-mkt/shared/components/price-selector/price-selector.module';
import { TemplateSingleTemplateRoutingModule } from './template-st-routing.module';
import { TemplateSingleComponent } from './template-component/template-single.component';
import { SliderCardModule } from '@core-mkt/shared/components/slider-card/slider-card.module';
import { BundleModule } from '@core-mkt/shared/components/bundle/bundle.module';
import { SliderModule } from '@core-mkt/shared/components/slider/slider.module';

@NgModule({
  declarations: [TemplateSingleComponent],
  exports: [TemplateSingleComponent],
  imports: [
    CommonModule,
    InsertableModule,
    TemplateSingleTemplateRoutingModule,
    HeroHomepageModule,
    MarketingModule,
    BannerModule,
    AnchorButtonModule,
    MultiCardModule,
    MultiColumnModule,
    LayoutModule,
    AccordionModule,
    ResourcesModule,
    ReviewsModule,
    TopSectionModule,
    AnchorModule,
    BreadcrumbModule,
    FaqModule,
    PriceSelectorModule,
    GladlyHelpCenterModule,
    SliderCardModule,
    SliderModule,
    BundleModule,
    HeaderModule,
    FooterModule,
    AAHeader,
    AAFooterModule,
    DecHeader,
    DecFooterModule,
    AarpHeader,
    AarpFooterModule,
  ],
})
export class TemplateSingleModule {}
