import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { GraphicBanner } from './graphic-banner';
import { Observable } from 'rxjs';
import { CountdownService } from '@core-mkt/services/countdown/countdown.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { mockRoute } from './graphic-banner-mock-data';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';

@Component({
  selector: 'graphic-banner',
  templateUrl: './graphic-banner.component.html',
  styleUrls: ['./graphic-banner.component.scss'],
})
export class GraphicBannerComponent implements OnInit {
  resolverData: PageDataResponse;
  pageData: GraphicBanner = null;
  now: number;
  countdown: Observable<number>;
  offerEndTime: number = null;
  offerStartTime: number = null;
  displayRE = true;
  daysRemaining = false;
  theme: ThemeInterface;

  constructor(
    private redactor: WysiwygRedactorService,
    private route: ActivatedRoute,
    private countdownService: CountdownService,
    private themeParser: ThemeParserService,
  ) {
    if (!this.route.snapshot) {
      this.resolverData = mockRoute.snapshot.data.pageData;
    } else {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }
    if (this.resolverData.craftData.header) {
      this.pageData = this.resolverData.craftData.header?.aceGraphicPromoBanner
        ? this.resolverData.craftData.header.aceGraphicPromoBanner[0]
        : this.resolverData.craftData.header?.aaGraphicPromoBanner[0];
    }
    if (this.resolverData.craftData.banners && this.resolverData.craftData.banners.graphicPromoBanner.length > 0) {
      this.pageData = this.resolverData.craftData.banners.graphicPromoBanner[0];
    }
  }

  get content(): string {
    return this.pageData.graphicBannerContent;
  }

  get image(): boolean {
    return this.pageData.image.length > 0 && this.pageData.image[0].url ? true : false;
  }

  get imageAlignment(): string {
    return this.pageData.imageAlignment;
  }

  get startTime(): string {
    return this.pageData.promotionStartDateTime;
  }

  get endTime(): string {
    return this.pageData.promotionEndsDateTime;
  }

  get displayPromo(): boolean {
    return this.pageData && this.pageData.showHideGraphicPromoBanner;
  }

  ngOnInit() {
    if (this.pageData) {
      this.pageData.graphicBannerContent = this.redactor.bypassSanitizer(this.pageData.graphicBannerContent);

      if (this.startTime && this.endTime) {
        this.offerEndTime = this.countdownService.getOfferEndTime(this.startTime, this.endTime);
        this.displayRE = this.countdownService.displayBanner(
          new Date(this.startTime).getTime(),
          new Date(this.endTime).getTime(),
        );
        this.offerStartTime = this.countdownService.getOfferStartTime(new Date(this.startTime).getTime());
      }

      const themeInput: ComponentTheme = {
        styles: this.pageData.styles,
        backgroundColor: this.pageData.backgroundColor,
        backgroundImage: this.pageData.backgroundImage?.[0],
        textColor: this.pageData.textColor,
        columnCount: 0,
        columnDistribution: '',
      };
      this.theme = this.themeParser.getThemeClasses(themeInput);
    }
    this.countdown = this.countdownService.create(this.offerEndTime, this.offerStartTime);

    this.countdown.subscribe((data) => {
      if (data > 0) {
        const milli = data;
        const hours = milli / 1000 / 60 / 60;
        this.daysRemaining = hours > 24;
      }
    });
  }
}
