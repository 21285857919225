import { Component, Input, OnInit } from '@angular/core';
import { LinkSection } from '../link-section/link-section/link-section';
import { Footer } from './footer';
import { FooterLink } from './footer-link';
import { footerNavigation } from './nav-data';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dec-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() data: Footer;
  reorganizedFooterLinks: LinkSection[];
  showHideFooter = true;

  private readonly copyrightYear_ = new Date().getFullYear();

  get copyrightYear(): number {
    return this.copyrightYear_;
  }

  get linkSections(): LinkSection[] {
    return this.reorganizedFooterLinks;
  }

  /**
   * Takes in an array of type FooterLink and returns an array of type LinkSection.
   * Takes FooterLinks with the key headlineText, a headline link, and puts all adjacent links into the headline link's 'sublinks' array until the next headline link is found.
   *
   * @param {FooterLink[]} footerLinks - An array of type FooterLink
   * @return {LinkSection[]} An array of type LinkSection
   *
   */
  reorganizeFooterLinks(footerLinks: FooterLink[]): LinkSection[] {
    const reorganizedFooterLinks = [];
    let lastHeadlineIndex = 0;
    for (let i = 0; i < footerLinks.length; i++) {
      // if current index is a header link
      if (footerLinks[i].headlineText) {
        // grab the header link
        const headlineLink = Object.assign({}, footerLinks[i]);
        // push header link to reorganized footer links
        reorganizedFooterLinks.push(headlineLink);
        lastHeadlineIndex = reorganizedFooterLinks.length - 1;
        reorganizedFooterLinks[lastHeadlineIndex].sublinks = new Array();
      } else {
        // if current index is not a header link, place in 'sublinks' key of header link.
        if (!reorganizedFooterLinks[lastHeadlineIndex]) {
          reorganizedFooterLinks[lastHeadlineIndex] = {};
          reorganizedFooterLinks[lastHeadlineIndex].sublinks = new Array();
        }
        reorganizedFooterLinks[lastHeadlineIndex].sublinks.push(footerLinks[i]);
      }
    }
    return reorganizedFooterLinks;
  }

  constructor(private route: ActivatedRoute) {
    if (this.route.snapshot.data.pageData) {
      this.showHideFooter = !this.route.snapshot.data.pageData.craftData.pageOptions.hideFooter;
    }
  }

  ngOnInit() {
    this.reorganizedFooterLinks = this.reorganizeFooterLinks(footerNavigation);
  }
}
