import { DisambigFragment } from '@core-mkt/shared/craft/disambig-fragment';
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEODisambigFragment } from './fragments/seo-fragment-query';

export const TemplateDisambigQuery = {
  name: 'TemplateDisambigQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceRealEstateTemplates_disambigTemplate_Entry {
          hideStickyBanner
          disambigTemplate {
            ...disambigTemplate_fragment
          }
        }
      }

      seoContent: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_disambigTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }

      styles: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_disambigTemplate_Entry {
          embedStyles
        }
      }

      optimizely: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_disambigTemplate_Entry {
          optimizelyEnabled
        }
      }
      overrides: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_disambigTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }

      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: disambigAATemplateOpti
            }
          }
        }
      }

      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_disambigTemplate_Entry {
          structuredData
        }
      }

      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }

    fragment productEntry_fragment on EntryInterface {
      ... on aceRealEstateProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        aceRealEstateProductCategory {
          ... on realEstateCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEODisambigFragment.seoContent}
    ${DisambigFragment.disambigTemplate}
  `,
};
