import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';

import type { Image } from '@core-mkt/interfaces/image';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { OneThreeColumn } from './one-three-column';

@Component({
  selector: 'one-three-column',
  templateUrl: './one-three-column.component.html',
})
export class OneThreeColumnComponent implements OnInit {
  @Input() data: OneThreeColumn;
  theme: ThemeInterface;
  textAlign: string;
  itemAlign: string;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    public imgixService: ImgixService,
  ) {}

  get backgroundImage(): Image {
    return this.data.backgroundImage?.[0];
  }

  get background(): string {
    return this.data.background;
  }

  get textColor(): string {
    return this.data.textColor;
  }

  get oneThreeTitle(): string {
    return this.data.oneThreeTitle;
  }

  get oneColumnText(): string {
    return this.data.oneColumnText;
  }

  get oneColumnImage(): Image {
    return this.data.oneColumnImage?.[0];
  }

  get threeColumnsContent(): string {
    return this.data.threeColumnsContent;
  }

  get columnLayout(): string {
    return this.data.columnLayout;
  }

  ngOnInit(): void {
    this.columnLayout === 'md:flex-row-reverse flex-col-reverse'
      ? ((this.textAlign = 'text-right'), (this.itemAlign = 'items-end'))
      : ((this.textAlign = 'text-left'), (this.itemAlign = 'items-start'));
    this.data.oneThreeTitle = this.redactor.bypassSanitizer(this.data.oneThreeTitle);
    this.data.threeColumnsContent = this.redactor.bypassSanitizer(this.data.threeColumnsContent);
    const themeInput: ComponentTheme = {
      backgroundColor: this.data.background,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: null,
      columnDistribution: null,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }
}
