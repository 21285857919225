import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { SwiperOptions } from 'swiper';
import Swiper, { Autoplay, Navigation, Pagination, Virtual } from 'swiper';

import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { Slide } from './top-section-slider';

// install Swiper modules
Swiper.use([Virtual, Navigation, Pagination, Autoplay]);

@Component({
  selector: 'top-section-slider',
  templateUrl: './top-section-slider.component.html',
  styleUrls: ['./top-section-slider.scss'],
})
export class TopSectionSliderComponent implements OnInit {
  @Input() slides: Slide[];
  @Input() controlStyle: string;
  @Input() indicatorStyle: string;
  @Input() slideAutoplay: boolean;
  @Input() transitionTime: number;

  config: SwiperOptions = {
    effect: 'slide',
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    pagination: true,
    navigation: true,
    slidesPerView: 1,
    loop: true,
  };

  constructor(private redactor: WysiwygRedactorService, private imgixService: ImgixService) {}

  ngOnInit(): void {
    if (this.controlStyle !== 'hidden' || this.indicatorStyle !== 'hidden') {
      //always turn on autoplay when controls are hidden
      if (!this.slideAutoplay) {
        //turn off autoplay when toggle is off.
        this.config.autoplay = false;
      }
    }
    if (this.config.autoplay['delay']) {
      this.config.autoplay['delay'] = this.transitionTime;
    }
    this.slides.forEach((slide: Slide) => {
      if (slide.slideContent !== null) {
        slide.showContent = true;
        slide.slideContent = this.redactor.bypassSanitizer(slide.slideContent, 'lazy');
      } else {
        slide.showContent = false;
      }
    });
    // install Swiper modules
    Swiper.use([Virtual, Navigation, Pagination, Autoplay]);
  }

  onSwiper(swiper: Swiper): void {
    if (this.controlStyle && this.indicatorStyle) {
      const craftNavClasses = this.controlStyle.split(' ');
      const craftIndClasses = this.indicatorStyle.split(' ');
      //this is a cheat to add svg requested by design
      swiper.navigation.prevEl.innerHTML = `<svg class="swiper-button-left" viewBox="0 0 100 100">
       <path fill="white" d="M 0,50 L 30,10 L 35,20 L 15,45 L 100,45 L 100,55 L 15,55 L 35,80 L 30,90 Z" class="arrow" />
      </svg>`;
      swiper.navigation.nextEl.innerHTML = `<svg class="swiper-button-right" viewBox="0 0 100 100">
        <path fill="white" d="M 0,50 L 30,10 L 35,20 L 15,45 L 100,45 L 100,55 L 15,55 L 35,80 L 30,90 Z" class="arrow" transform="translate(100, 100) rotate(180) " />
      </svg>`;
      swiper.navigation.nextEl.classList.add(...craftNavClasses);
      swiper.navigation.prevEl.classList.add(...craftNavClasses);
      swiper.pagination.el.querySelectorAll('.swiper-pagination-bullet').forEach((bullet) => {
        bullet.classList.add(...craftIndClasses);
      });
    }
  }

  showImage(slide: Slide): boolean {
    return !slide.showContent && slide.slideImage.length > 0 && slide.slideImage[0].url !== null;
  }

  showLinkedImage(slide: Slide): boolean {
    return (
      !slide.showContent &&
      slide.slideImageLink !== null &&
      slide.slideImage.length > 0 &&
      slide.slideImage[0].url !== null
    );
  }
}
