/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const GraphicBannerFragment = {
  GraphicBanner: gql`
    fragment GraphicBannerQuery on baseComponents_graphicBanner_BlockType {
      typeHandle
    }
  `,
};

export const GlobalGraphicBannerFragment = {
  GlobalGraphicBanner: gql`
    fragment GlobalGraphicBannerQuery on graphicPromoBanner_graphicBanner_BlockType {
      backgroundImage {
        url
      }
      graphicBannerContent
      image {
        alt
        url
      }
      imageAligment
      lineBreak_content
      lineBreak_styles
      promotionEndsDateTime
      promotionStartDateTime
      showHidePromoBanner
      styles
    }
  `,
};
