import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AdobeAnalyticsData } from './adobe-analytics';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import type { CraftData, AdobeData } from '@core-mkt/resolvers/page-data/page-data-response';

@Injectable({
  providedIn: 'root',
})
export class AdobeAnalyticService {
  renderer: Renderer2;
  currentPath: string;
  window: Window;

  constructor(@Inject(DOCUMENT) public document: Document, private sls: ScriptLoaderService) {
    this.window = this.document.defaultView;
  }

  /**
   * initializeAnalytics initializes adobe analytics
   *
   * @param {CraftData} craftData - Entry data from craft
   */
  initializeAnalytics(craftData: CraftData) {
    const { adobe } = craftData;
    this.sls.injectScriptByType('aarp');
    this.createDataLayer(adobe, craftData);
  }

  /**
   * createDataLayer creates an adobe analytics object
   *
   * @param {Adobe} adobeData - Adobe Analytic data from craft entry
   * @param {CraftData} craftData - Entry data from craft
   */
  createDataLayer(adobeData: AdobeData, craftData: CraftData) {
    const slug: string[] = craftData.entryData.slug.split(/--/);
    const adobeDataLayerString = `
    if (typeof AARP == "undefined" || AARP === null) {
      AARP = {};
    };
    AARP.MetaInfo = {
        pagedata: {
          pagename: '${adobeData.pageName ?? ''}',
          var1: '${adobeData.var1 ?? 'driver safety'}',
          var2: '${adobeData.var2 ?? 'main'}',
          var3: '${adobeData.var3 ?? (slug && slug[0]) ?? ''}',
          var4: '${adobeData.var4 ?? (slug && slug[1]) ?? ''}',
          var5: '${adobeData.var5 ?? this.window.location.pathname}',
          pagetype: 'aarpdriversafety.org',
          language: '${this.window.navigator.language || 'en-us'}',
          publisher: 'ADS',
          userdata: {
            appstep: '${adobeData.appStep ?? ''}',
            zipcode: '',
            productid: '${adobeData.productId ?? ''}',
            productCode: '${adobeData.productCode ?? ''}',
          },
          errors: {
            errorcode: '',
            errormessage: '',
          },
          webdata: {
            transactionid: '${adobeData.transactionId ?? ''}',
            registrationtype: '${adobeData.registrationType ?? ''}',
            pymttype: '${adobeData.paymentType ?? ''}',
            orderamount: '${adobeData.orderAmount ?? ''}',
          },
        }
    }`;
    this.insertAdobeDataLayer(adobeDataLayerString);
  }

  /**
   * insertAdobeDataLayer inserts structued data into application <head>
   *
   * @param {string} adobeDataLayer - Adobe Analytic data layer as a string
   *
   */
  insertAdobeDataLayer(adobeDataLayer: string) {
    const { head } = this.document;
    const s: HTMLScriptElement = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.text = adobeDataLayer;
    head.appendChild(s);
  }
}
