import { MenuItem } from './header';

export const headerData: MenuItem[] = [
  {
    text: 'Drivers Ed',
    href: 'https://driversed.com/drivers-ed/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Teens',
    href: 'https://driversed.com/teen-drivers-ed/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Adults',
    href: 'https://driversed.com/adult-drivers-ed/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'In-Car Driving Lessons',
    href: 'https://driversed.com/driving-lessons/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Traffic School',
    href: 'https://driversed.com/traffic-school/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Practice Permit Test',
    href: 'https://driversed.com/practice-permit-test/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'DMV Information',
    href: 'https://driversed.com/dmv/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Community',
    href: 'https://driversed.com/community/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Apps',
    href: 'https://driversed.com/apps/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Blog',
    href: 'https://driversed.com/trending/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Partners',
    href: '/affiliates/',
    position: 'bottom',
    isCurrentItem: false,
  },
];
