/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import type { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import type { FooterLinkGroup } from '../footer/footer';

@Component({
  selector: 'footer-link',
  templateUrl: './footer-link.component.html',
  styleUrls: ['./footer-link.component.scss'],
})
export class FooterLinkComponent {
  @Input() links: FooterLinkGroup[];
  @Input() footerLocation: string;
  @Input() alignClass = '';
  @Input() linkClass = '';
  iconPrefix: IconPrefix = 'fab';

  constructor(library: FaIconLibrary) {
    library.addIcons(faXTwitter, faTiktok, faYoutube, faFacebookSquare, faLinkedin, faInstagram);
  }

  get flexDirection(): string {
    if (this.footerLocation !== 'bottom' && this.footerLocation !== 'social') {
      return 'flex-wrap flex-col';
    }
    if (this.footerLocation === 'bottom') {
      return 'md:flex-row';
    }
    return 'md:flex-wrap md:flex-col';
  }

  get layout(): string {
    if (this.footerLocation === 'bottom') {
      return 'lg:justify-end';
    }
    return 'tablet-halved';
  }
}
