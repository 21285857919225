import { Component, OnInit, Optional, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { RESPONSE, REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { Request, Response } from 'express';
import { BrandConfigurationService } from '@core-mkt/services/configuration/brand-configuration.service';

// This component is mostly in charge of returning a 404 when a page or file is not found.
// Tutorial for 404 functionality found here, https://azizwrites.xyz/post/setting-status-code-and-handling-404-pages-in-angular-universal-0232
@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<any>,
    private bfs: BrandConfigurationService,
  ) {}

  get url(): string | undefined {
    return this.bfs.url;
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.response.statusCode = 404;
      this.response.statusMessage = '404 - Page Not Found';
    }
  }
}
