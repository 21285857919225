/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const HubspotFragment = {
  Hubspot: gql`
    fragment HubspotQuery on baseComponents_hubspot_BlockType {
      hubspotContent
      hubspotType
      typeHandle
    }
  `,
};
