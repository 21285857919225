import type { OnInit } from '@angular/core';
import { Component, HostBinding, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { cloneDeep } from 'lodash-es';
import { headerData } from './header-data';
import { Menu } from './header';

@Component({
  selector: 'ace-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.sticky-header') headerSticky = false;
  resolverData: PageDataResponse;
  hideNav = false;
  showDefaultNav = true;
  menuData: Menu = {
    state: 'CA',
    menuItems: headerData,
  };

  constructor(private route: ActivatedRoute, private renderer: Renderer2) {
    if (this.route.snapshot) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }
  }

  ngOnInit(): void {
    if (this.resolverData.craftData?.pageOptions) {
      this.hideNav = this.resolverData.craftData.pageOptions.hideNav;
      //needs to be added to the graphql query
      this.headerSticky = this.resolverData.craftData.pageOptions.isNavSticky;
    }
    if (this.hideNav) {
      this.showDefaultNav = false;
    }
  }
}
