<div class="flex flex-col md:m-0 rounded-md" [ngClass]="image && image.url ? 'card-border h-full ' + backgroundColor : 'self-center'" (click)="ctaClick()">
  <div class="w-full career-center-image h-48" *ngIf="image && image.url">
    <img
      src="{{ imgixService.buildImgixUrl(image.url) }}"
      [alt]="image.altText ? image.altText : ''"
      width="800"
      height="500"
      class="h-48 object-cover career-center-image rounded-t-md"
    />
  </div>
  <div class="content-card max-w-[800px] w-full top-3/4 z-10 rounded h-full py-v-spacing px-v-spacing">
    <div *ngIf="textContent" class="mx-auto flex flex-col justify-between h-full">
      <div class="wysiwyg" [class.mb-v-spacing]="ctaLabel && ctaLink" [innerHTML]="textContent"></div>
      <div class="mt-auto">
        <vision-button defaultStyle="tertiary" *ngIf="ctaLabel && ctaLink && textContent" [data]="buttonData"></vision-button>
      </div>
    </div>
  </div>
</div>