import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EnvService } from '../env/env.service';
import type {
  ScriptLoaderInlineScript,
  ScriptLoaderScript,
  ScriptLoaderScriptType,
  SeoConfig,
  StructuredData,
} from './script-loader-interfaces';
import { isScriptLoaderInlineScript, isScriptLoaderScript } from './script-loader-interfaces';

/**
 * The ScriptLoaderService allows for dynamic insertion of marketing scripts on the server or the browser.
 * This is accomplished by using the injectScriptByType function and by passing in a script of type ScriptLoaderScriptType.
 *
 * @example
 *   service.injectScriptByType('gtm');
 */

@Injectable({
  providedIn: 'root',
})
export class ServerScriptLoaderService {
  private attributePrefix = 'script-loader';

  /**
   * scriptLoadedSubject subscribe to know when the script finish to load into DOM
   */
  scriptLoadedSubject = new Subject();

  constructor(@Inject(DOCUMENT) public doc: Document, public env: EnvService) {}

  public returnScriptByType(
    type: string,
    idOverride?: string,
    structuredData?: string,
    seoConfig?: SeoConfig,
  ): ScriptLoaderScript | ScriptLoaderInlineScript {
    switch (type) {
      case 'optimizely':
        return {
          src: `https://cdn.optimizely.com/js/${idOverride ? idOverride : this.env.get.optimizelyId}.js`,
          type: 'optimizely',
          location: 'head',
        };
      case 'trustpilot':
        return {
          src: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
          type: 'trustpilot',
          location: 'head',
          attrs: ['async'],
        };
      case 'hubspot':
        return {
          src: 'https://js.hsforms.net/forms/v2.js',
          type: 'hubspot',
          location: 'head',
        };
      case 'feefo':
        return {
          src: `https://api.feefo.com/api/javascript/${this.env.get.feefoMarchantId}`,
          type: 'feefo',
          location: 'head',
          attrs: ['async'],
        };
      case 'quizWidget':
        return {
          src: `https://cdn.commoninja.com/sdk/latest/commonninja.js`,
          type: 'quizWidget',
          location: 'head',
          attrs: ['defer'],
        };
      case 'schema':
        return {
          text: `{
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${this.env.get.brandConfig.name}",
              "url": "${this.env.get.brandConfig.url}",
              "@id": "${this.env.get.brandConfig.url}/#website",
              "publisher": {
                "@type": "Organization",
                "name": "${this.env.get.brandConfig.name}",
                "url": "${this.env.get.brandConfig.url}",
                "@id": "${this.env.get.brandConfig.url}/#organization"
              }
          }`,
          type: 'schema',
          location: 'head',
          scriptDOMType: 'application/ld+json',
        };
      case 'structuredData':
        return {
          text: structuredData,
          type: 'schema',
          location: 'head',
          scriptDOMType: 'application/ld+json',
        };
      case 'dynamicStructuredData':
        // Make sure at least one property exists before creating the structured data
        if (seoConfig.pageTitle || seoConfig.pageUrl || seoConfig.pageDescription) {
          // Initialize an object to hold the structured data
          const structuredData: StructuredData = {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
          };

          // Conditionally add properties if they have valid values
          if (seoConfig.pageTitle) {
            structuredData.headline = seoConfig.pageTitle;
          }

          if (seoConfig.pageUrl) {
            structuredData.url = seoConfig.pageUrl;
          }

          if (seoConfig.pageDescription) {
            structuredData.description = seoConfig.pageDescription;
          }

          // Check if structuredData has more than just the context and type
          if (Object.keys(structuredData).length > 2) {
            // Make JSON pretty for readability in HTML
            const beautifiedJson = JSON.stringify(structuredData, null, 2);

            return {
              text: beautifiedJson,
              type: 'schema',
              location: 'head',
              scriptDOMType: 'application/ld+json',
            };
          }
        }
        break;
      case 'gladly':
        const gladlyEnv = this.env.get.gladlyHelpCenterEnvironment;
        return {
          text: `
            window.gladlyHCConfig = { api: '${gladlyEnv.api}', orgId: '${gladlyEnv.orgId}', brandId: '${gladlyEnv.brandId}', cdn: '${gladlyEnv.cdn}', selector: '#${gladlyEnv.selector}' };
            function l() {
              var t = document,
                e = t.createElement('script');
              (e.type = 'text/javascript'), (e.async = !0), (e.src = 'https://cdn.gladly.com/help-center/hcl.js');
              var a = t.getElementsByTagName('script')[0];
              a.parentNode.insertBefore(e, a);
            }
            var w = window;
            w.attachEvent ? w.attachEvent('onload', l) : w.addEventListener('DOMContentLoaded', l, !1);
          `,
          type: 'gladly',
          location: 'body',
          scriptDOMType: 'text/javascript',
        };
      case 'aarp':
        return {
          src: `//assets.adobedtm.com/launch-${this.env.get.adobeAnalytics}.min.js`,
          type: 'aarp',
          location: 'head',
          attrs: ['async'],
        };
      default:
        return undefined;
    }
  }

  /**
   * injectScriptByType allows a supported marketing script from ./scripts.ts to be injected into the document.
   *
   * @param {ScriptLoaderScriptType} type - A type of marketing script
   * @param {string} idOverride - A supplied ID to override the ID returned by the env service
   * @return {boolean}
   *
   * @example
   *     service.injectScriptByType('gtm');
   */
  injectScriptByType(
    type: ScriptLoaderScriptType,
    idOverride?: string,
    structuredData?: string,
    seoConfig?: SeoConfig,
  ): boolean {
    // Look to see if script is supported by its inclusion in scripts
    const script = this.returnScriptByType(type, idOverride, structuredData, seoConfig);

    // Check to see if the script has already been injected into the head of the document
    // This check is done in case this service is run server side and browser side
    const scriptFromDom = this.scriptExists(type);

    // Return success if the work was already done for us in the past
    if (scriptFromDom) {
      return true;
    }

    if (script) {
      if (isScriptLoaderScript(script)) {
        this.injectScript(script as ScriptLoaderScript);
      } else if (isScriptLoaderInlineScript(script)) {
        this.injectInlineScript(script as ScriptLoaderInlineScript);
      }

      return true;
    }

    return false;
  }

  /**
   * scriptExists queries the document and determines if a script of type ScriptLoaderScriptType exists in the document or not.
   *
   * @param {ScriptLoaderScriptType} scriptType - A type of marketing script
   * @return {boolean}
   *
   * @example
   *     service.scriptExists('gtm');
   */
  scriptExists(scriptType: ScriptLoaderScriptType): Element | null {
    return this.doc.querySelector(`script[${this.attributePrefix}\\:${scriptType}]`);
  }

  /**
   * injectScript injects a script of type ScriptLoaderScript into the document.
   *
   * @param {ScriptLoaderScript} script - A type of marketing script
   * @return {void}
   *
   */

  private injectScript(script: ScriptLoaderScript): void {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.src = script.src;
    s.onload = () => this.scriptLoadedSubject.next({ type: script.type, loaded: true });
    const scriptAttribute = this.scriptAttribute(script.type);
    const additionalAttributes = script.attrs;

    if (additionalAttributes) {
      for (let i = 0; i < additionalAttributes.length; i++) {
        const attr = additionalAttributes[i];
        s.setAttribute(attr, '');
      }
    }

    if (scriptAttribute) {
      s.setAttribute(scriptAttribute, '');

      if (script.location === 'head') {
        const { head } = this.doc;
        this.injectElementIntoHead(head, s);
      } else if (script.location === 'body') {
        const { body } = this.doc;
        this.injectElementIntoBody(body, s);
      }
    }
  }

  /**
   * injectInlineScript injects a script of type ScriptLoaderInlineScript into the document.
   *
   * @param {ScriptLoaderInlineScript} script - A type of marketing script
   * @return {void}
   *
   */

  private injectInlineScript(script: ScriptLoaderInlineScript): void {
    const s = this.doc.createElement('script');
    s.type = script.scriptDOMType ?? 'text/javascript';
    s.text = script.text;
    s.onload = () => this.scriptLoadedSubject.next({ type: script.type, loaded: true });
    const scriptAttribute = this.scriptAttribute(script.type);
    const additionalAttributes = script.attrs;

    if (additionalAttributes) {
      for (let i = 0; i < additionalAttributes.length; i++) {
        const attr = additionalAttributes[i];
        s.setAttribute(attr, '');
      }
    }

    if (scriptAttribute) {
      s.setAttribute(scriptAttribute, '');

      if (script.location === 'head') {
        const { head } = this.doc;
        this.injectElementIntoHead(head, s);
      } else if (script.location === 'body') {
        const { body } = this.doc;
        this.injectElementIntoBody(body, s);
      }
    }
  }

  /**
   * injectElementIntoHead injects a script Element into the head of the document.
   *
   * @param {Node} head - The head DOM Node
   * @param {Element} script - A script
   * @return {void}
   *
   */

  private injectElementIntoHead(head: Node, script: Element) {
    head.appendChild(script);
  }

  /**
   * injectElementIntoBody injects a script Element into the body of the document.
   *
   * @param {Node} body - The body DOM Node
   * @param {Element} script - A script
   * @return {void}
   *
   */

  private injectElementIntoBody(body: Node, script: Element) {
    const firstChild = body.firstChild;
    body.insertBefore(script, firstChild);
  }

  /**
   * scriptAttribute constructs the attribute of a script by its type.
   * This attribute is used to determine if a script already exists from a previous injection.
   *
   * @param {ScriptLoaderScriptType} scriptType - A script of ScriptLoaderScriptType
   * @return {string | false}
   *
   */

  private scriptAttribute(scriptType: ScriptLoaderScriptType): string | false {
    return scriptType ? `${this.attributePrefix}:${scriptType}` : false;
  }
}
