<div class="{{ theme?.root }} bg-no-repeat bg-center bg-cover parent-form" [style]="theme?.bgImage">
  <div
    class="container flex md:items-center flex-col {{ theme.container }} {{
      thinForm ? 'thin-container md:flex-row' : ''
    }}"
    [class.justify-center]="!hasContent"
  >
    <div
      *ngIf="hasContent"
      class="parent-form__content py-v-spacing wysiwyg"
      [class.xs:p-v-spacing]="!thinForm"
      [ngClass]="{ 'lg:w-1/2 thin-parent-form__content': thinForm, 'hidden md:block': hideContentOnMobile }"
      [innerHTML]="formContent"
    ></div>

    <div
      class="parent-form__form w-full lg:w-1/2 p-0"
      [class.thin-parent-form__form]="thinForm"
      [ngClass]="{
        'flex flex-col items-center py-v-spacing tab:px-v-spacing max-w-vision mx-auto': !hasContent,
        textHidden: hideContentOnMobile
      }"
    >
      <div
        *ngIf="formSubmitted && !thinForm"
        class="parent-form__content py-v-spacing sm:px-v-spacing md:mr-v-spacing font-bold text-center"
      >
        Thank you for subscribing!
      </div>
      <div *ngIf="formSubmitted && thinForm" class="parent-form__content md:mr-v-spacing font-bold">
        <p class="text-left lg:text-right">Thank you for subscribing!</p>
      </div>
      <form
        class="w-full p-v-spacing px-0 xs:px-v-spacing flex items-center text-ace-grey-900 mx-auto flex-col mx-auto {{
          formClasses
        }}"
        [class.hidden]="formSubmitted"
        [formGroup]="signUpForm"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <div class="w-full relative flex mb-3" [class.sm:mb-0]="thinForm" *ngIf="data.showNameField">
          <input
            class="py-v-spacing outline-none text-sm"
            [class.sm:w-auto]="thinForm || (!thinForm && !data.showNameField && !data.showParentEmailField)"
            [class.sm:mr-3]="thinForm"
            [class.smush]="thinForm && data.showNameField && data.showParentEmailField"
            id="input__name"
            placeholder="First Name"
            formControlName="name"
          />
        </div>
        <div class="w-full relative flex mb-3" [class.sm:mb-0]="thinForm">
          <input
            class="py-v-spacing outline-none text-sm"
            [class.sm:w-auto]="thinForm || (!thinForm && !data.showNameField && !data.showParentEmailField)"
            [class.sm:mr-3]="thinForm && data.showParentEmailField"
            [class.smush]="thinForm && data.showNameField && data.showParentEmailField"
            [class.stretch]="thinForm && !data.showNameField && !data.showParentEmailField"
            [class.self-center]="!data.showNameField && !data.showParentEmailField"
            [ngClass]="{
              '!border-ace-red-600 text-ace-red-600 !mb-5 form-error': (formErr || postErr) && !canSubmitEmail
            }"
            id="input__email"
            placeholder="Your Email"
            formControlName="email"
            type="email"
            required
          />
          <span
            *ngIf="(formErr || postErr) && !canSubmitEmail"
            class="text-ace-red-600 absolute text-xs email-error"
            [class.thin]="thinForm"
            [class.long-cta]="ctaLabel.length > 4"
            [class.solo]="thinForm && !data.showNameField && !data.showParentEmailField"
            [class.duo]="thinForm && (data.showNameField || data.showParentEmailField)"
            [class.all]="thinForm && data.showNameField && data.showParentEmailField"
          >
            Please enter a valid email address.
          </span>
          <span *ngIf="(formErr || postErr) && !canSubmitEmail" class="flex w-auto grow">
            <fa-icon
              class="text-sm text-ace-red-600 pointer-events-none absolute email-error-icon"
              [class.thin]="thinForm"
              [icon]="faTimesCircle"
            ></fa-icon>
          </span>
        </div>
        <div class="w-full relative flex" *ngIf="data.showParentEmailField">
          <input
            class="py-v-spacing outline-none text-sm"
            [class.smush]="thinForm && data.showNameField && data.showParentEmailField"
            [class.sm:w-auto]="thinForm || (!thinForm && !data.showNameField && !data.showParentEmailField)"
            [ngClass]="{
              '!border-ace-red-600 text-ace-red-600 form-error': (formErr || postErr) && !canSubmitParent
            }"
            id="input__parentEmail"
            placeholder="Parent's Email (Optional)"
            formControlName="parentEmail"
          />
          <span
            *ngIf="(formErr || postErr) && !canSubmitParent"
            class="text-ace-red-600 absolute text-xs parent-error"
            [class.thin]="thinForm"
            [class.long-cta]="ctaLabel.length > 4"
            [class.solo]="thinForm && !data.showNameField && !data.showParentEmailField"
            [class.duo]="thinForm && (data.showNameField || data.showParentEmailField)"
            [class.all]="thinForm && data.showNameField && data.showParentEmailField"
          >
            Please enter a valid email address.
          </span>
          <span *ngIf="(formErr || postErr) && !canSubmitParent" class="flex w-auto grow">
            <fa-icon
              class="text-sm text-ace-red-600 pointer-events-none absolute parent-error-icon"
              [class.thin]="thinForm"
              [icon]="faTimesCircle"
            ></fa-icon>
          </span>
        </div>
        <span class="mt-v-spacing sm:mt-0 w-full sm:w-auto text-center">
          <input
            type="submit"
            class="{{ ctaType }}-button cursor-pointer"
            [class.w-auto]="ctaType === 'tertiary'"
            [class.sm:!w-auto]="thinForm || (!thinForm && !data.showNameField && !data.showParentEmailField)"
            [class.sm:mt-0]="thinForm || (!thinForm && !data.showNameField && !data.showParentEmailField)"
            [class.sm:ml-3]="thinForm || (!thinForm && !data.showNameField && !data.showParentEmailField)"
            [class.!mt-8]="!thinForm && (data.showNameField || data.showParentEmailField)"
            [value]="ctaLabel"
            [class.!mb-5]="(formErr || postErr) && !canSubmitEmail && (data.showNameField || data.showParentEmailField)"
          />
        </span>
        <span class="text-ace-red-600 text-xs absolute post-error" *ngIf="postErr"
          >There was an issue with your subscription. Please try again later.</span
        >
      </form>
    </div>
  </div>
</div>
