import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { QuizWidget } from './quiz-widget';

import { ScriptLoaderService } from '@core-mkt/services/script-loader/script-loader.service';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';

@Component({
  selector: 'quiz-widget',
  templateUrl: './quiz-widget.component.html',
})
export class QuizWidgetComponent implements OnInit {
  @Input() data: QuizWidget;
  theme: ThemeInterface;

  public constructor(private sls: ScriptLoaderService, private tps: ThemeParserService) {}

  get widgetID(): string {
    return this.data.quizWidgetId;
  }

  ngOnInit(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: null,
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.tps.getThemeClasses(themeInput);
    if (this.widgetID) {
      this.sls.injectScriptByType('quizWidget', this.widgetID);
    }
  }
}
