import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConditionalClassDirective } from './conditional-class.directive';

@NgModule({
  declarations: [ConditionalClassDirective],
  exports: [ConditionalClassDirective],
  imports: [CommonModule],
})
export class ConditionalClassModule {}
