import { Component, Input } from '@angular/core';
import { faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { HeaderNavLink } from '../header/header';

@Component({
  selector: 'collapse-link',
  templateUrl: './collapse-link.component.html',
  styleUrls: ['./collapse-link.component.scss'],
})
export class CollapseLinkComponent {
  @Input() link: HeaderNavLink;
  collapsed = true;
  faCaretRight = faChevronRight;
  faArrowLeft = faArrowLeft;

  constructor() {}

  /**
   * Conditionally appends ' Courses' to a label containing specific keywords.
   *
   * @param {string} label The label to check and possibly modify.
   * @returns {string} The label with ' Courses' appended if it includes 'Drivers Ed' or 'Defensive Driving', otherwise unmodified.
   */
  addCoursesSuffixIfNeeded(label: string): string {
    const courses = ['Drivers Ed', 'Defensive Driving'];
    return courses.some((keyword) => label.includes(keyword)) ? `${label} Courses` : label;
  }
}
