import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { CountdownService } from '@core-mkt/services/countdown/countdown.service';
import { VerticalService } from '@core-mkt/services/vertical/vertical.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { cloneDeep } from 'lodash-es';
import type { Observable } from 'rxjs';
import type { Banner, EntryData, PageOptions } from './banner';
import { mockRoute } from './banner-mock-data';
import { GraphicBanner } from '../../graphic-banner/graphic-banner/graphic-banner';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  resolverData: PageDataResponse;
  pageData: Banner = null;
  pageOptions: PageOptions;
  entryData: EntryData;
  now: number;
  countdown: Observable<number>;
  offerEndTime: number = null;
  offerStartTime: number = null;
  offerResetTime: number = null;
  displayRE = false;
  daysRemaining = false;

  constructor(
    private redactor: WysiwygRedactorService,
    private route: ActivatedRoute,
    private countdownService: CountdownService,
    private vertical: VerticalService,
  ) {
    if (!this.route.snapshot) {
      this.resolverData = mockRoute.snapshot.data.pageData;
    } else {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }
    if (this.resolverData.craftData.header) {
      this.pageData = this.resolverData.craftData.header?.aceHeaderPromoBanner
        ? this.resolverData.craftData.header.aceHeaderPromoBanner[0]
        : this.resolverData.craftData.header?.realEstateHeaderPromoBanner[0];
    }
    if (this.resolverData.craftData.pageOptions) {
      this.pageOptions = this.resolverData.craftData.pageOptions;
    }
    if (this.resolverData.craftData.banners && this.resolverData.craftData.banners.topBanner.length > 0) {
      this.pageData = this.resolverData.craftData.banners.topBanner[0];
    }
  }

  get afterMainPromo(): boolean {
    return this.offerResetTime && this.now > this.offerEndTime && this.now < this.offerResetTime;
  }

  get promotionText(): string {
    return this.afterMainPromo ? this.pageData.promotionReset[0].resetText : this.pageData.promotionText;
  }

  get displayPromo(): boolean {
    return this.pageData && this.pageData !== null && this.pageData.showHidePromoBanner;
  }

  get startTime(): string {
    return this.pageData.promotionStartDateTime;
  }

  get endTime(): string {
    return this.afterMainPromo
      ? this.pageData.promotionReset[0].promotionEndsDateTime
      : this.pageData.promotionEndsDateTime;
  }

  get hideStickyBanner(): boolean {
    if (this.pageOptions) {
      return this.pageOptions.hideStickyBanner;
    }
    return this.entryData?.hideStickyBanner ?? false;
  }

  ngOnInit(): void {
    this.offerStartTime = this.getOfferStartTime();
    this.offerEndTime = this.getOfferEndTime();
    if (!this.startTime && !this.endTime) {
      this.displayRE = true;
    }
    if (this.afterMainPromo) {
      this.countdown = this.countdownService.create(this.offerResetTime, this.offerStartTime);
    } else {
      this.countdown = this.countdownService.create(this.offerEndTime, this.offerStartTime);
    }

    this.countdown.subscribe((data) => {
      if (data > 0) {
        const milli = data;
        const hours = milli / 1000 / 60 / 60;
        this.daysRemaining = hours > 24;
      }
    });
  }

  private getOfferEndTime = (): number => {
    let endTime = null;
    // If we receive start and end dates for promotion, we need to calculate the range of time between them.
    if (this.pageData) {
      const endDate = new Date(this.pageData.promotionEndsDateTime);
      endTime = endDate.valueOf();
    }
    return endTime;
  };

  private getOfferStartTime = (): number => {
    let startTime = null;
    // If we receive start and end dates for promotion, we need to calculate the range of time between them.
    if (this.pageData && this.startTime && this.endTime) {
      this.now = new Date().getTime();
      const startDateTime = new Date(this.startTime).getTime();
      const fiveMins = 5 * 60 * 1000;
      const startDate = new Date(this.startTime);
      let endDateTime = new Date(this.endTime).getTime();
      if (this.pageData.promotionReset.length > 0) {
        this.offerResetTime = new Date(this.pageData.promotionReset[0].promotionEndsDateTime).valueOf();
        if (this.now > this.offerEndTime && this.now < this.offerResetTime) {
          endDateTime = this.offerResetTime;
        }
      }
      startTime = startDate.valueOf();
      // Check if within 5 min window of start time
      if (this.now > startDateTime - fiveMins) {
        this.countdownService.startObs.subscribe((start) => {
          if (start <= 0) {
            this.countdownService.setSaleValue(true);
            this.displayRE = true;
          }
        });
      }
      // Check if within 5 min window of end time
      if (this.now > endDateTime - fiveMins) {
        this.countdownService.endObs.subscribe((end) => {
          if (end < 0) {
            this.countdownService.setSaleValue(false);
            this.displayRE = false;
            this.pageData.showHidePromoBanner = false;
          }
        });
      }
    }
    return startTime;
  };
}
