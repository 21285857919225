import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePreloadModule } from '@core-mkt/services/image-preload/image-preload.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LearnMoreComponent } from './learn-more/learn-more.component';

@NgModule({
  declarations: [LearnMoreComponent],
  exports: [LearnMoreComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule, ImagePreloadModule, VisionButtonModule],
})
export class LearnMoreModule {}
