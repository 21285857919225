import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  // Sentry should be disabled when working locally.
  enabled: environment.envMode !== 'local',

  /**
   * Temporarily having separate DSNs (Data Source Name) for driving and real estate verticals.
   * TODO: Once RE and DRV are fully migrated, switch to only using the aceable-vision DSN.
   */
  dsn: environment.sentryDsn,

  // Environment name to pass to Sentry.
  environment: environment.envMode === 'prod' ? 'production' : 'dev',

  integrations: [
    // Used to monitor its performance.
    new BrowserTracing({
      tracingOrigins: ['localhost', /^\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // The sample rate for error events, 0.2 corresponds to 20% of errors will be sent
  sampleRate: 1.0,

  // The percentage chance a given transaction will be sent to Sentry
  tracesSampleRate: environment.envMode === 'prod' ? 0.2 : 1.0,

  /**
   * Used to tag releases.
   * TODO: Update to use the Github SHA.
   */
  release: new Date().toISOString().slice(0, 10),
});

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
