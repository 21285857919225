import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
  providedIn: 'any',
})
export class StorageService {
  _isBrowser: any;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  get = (key: string): string => {
    if (this._isBrowser) {
      return localStorage.getItem(key);
    }
  };

  set = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };
}
