import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrivacyCheckService {
  constructor() {}

  /**
   * a function to check if user has global privacy control .
   * @returns void.
   */
  public updatePrivacyCheck(doNotSell: number): void {
    const date = new Date();
    const expiresDate = new Date(date.setDate(date.getDate() + 3700));
    const expiresDateString = expiresDate.toUTCString();

    document.cookie = `consent-ad-personalization=${doNotSell}; expires=${expiresDateString}; path=/; domain=${window.location.hostname
      .split('.')
      .slice(-2)
      .join('.')}`;
  }

  public getPrivacyCookie(): string {
    const match = document.cookie.match(new RegExp(`(^| )consent-ad-personalization=([^;]+)`));
    return match ? match[2] : null;
  }
}
