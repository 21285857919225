/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const CareersFragment = {
  Careers: gql`
    fragment CareersQuery on baseComponents_careers_BlockType {
      showHideCareers
      typeHandle
    }
  `,
};
