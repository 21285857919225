/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { params } from '@core-mkt/shared/craft/seo-fragment-query';

export const SEODisambigFragment = {
  seoContent: gql`
    fragment SEODisambigFragmentEntry on aceDrivingTemplates_disambigTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOGenericFragment = {
  seoContent: gql`
    fragment BaseSEOFragmentEntry on aceBaseTemplates_genericTemplate_Entry {
      ${params}
    }
    fragment DrivingSEOFragmentEntry on aceDrivingTemplates_genericTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOCategoryFragment = {
  seoContent: gql`
    fragment BaseSEOFragmentEntry on aceBaseTemplates_categoryTemplate_Entry {
      ${params}
    }
    fragment DrivingSEOFragmentEntry on aceDrivingTemplates_categoryTemplate_Entry {
      ${params}
    }
  `,
};

export const SEOProductFragment = {
  seoContent: gql`
    fragment SEOFragmentEntry on aceDrivingTemplates_productTemplate_Entry {
      ${params}
    }
  `,
};
