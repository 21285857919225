import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { CustomDecimalModule } from '@ecom/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VisionButtonModule } from '../vision-button/vision-button.module';
import { PrivacyManagerComponent } from './privacy-manager/privacy-manager.component';

@NgModule({
  declarations: [PrivacyManagerComponent],
  exports: [PrivacyManagerComponent],
  imports: [CommonModule, QueryParamModule, FontAwesomeModule, VisionButtonModule, CustomDecimalModule],
})
export class PrivacyManagerModule {}
