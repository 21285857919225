import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VisionDropdownComponent } from './vision-dropdown/vision-dropdown.component';

@NgModule({
  declarations: [VisionDropdownComponent],
  exports: [VisionDropdownComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule],
})
export class VisionDropdownModule {}
