import { CommonModule } from '@angular/common';
import type { ComponentFactory } from '@angular/core';
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { DurationModule } from '@core-mkt/services/duration/duration.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { BannerComponent } from './banner/banner.component';

@NgModule({
  declarations: [BannerComponent],
  exports: [BannerComponent],
  imports: [CommonModule, DurationModule, QueryParamModule],
})
export class BannerModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(): ComponentFactory<BannerComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(BannerComponent);
  }
}
