import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnchorComponent } from './anchor/anchor.component';

@NgModule({
  declarations: [AnchorComponent],
  exports: [AnchorComponent],
  imports: [CommonModule],
})
export class AnchorModule {}
