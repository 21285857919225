import { StateList } from '../header/header';
export const stateList: StateList[] = [
  {
    text: 'Alabama',
    href: '/alabama/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Alaska',
    href: '/alaska/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Arizona',
    href: '/arizona/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Arkansas',
    href: '/arkansas/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'California',
    href: '/california/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Colorado',
    href: '/colorado/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Connecticut',
    href: '/connecticut/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Delaware',
    href: '/delaware/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Florida',
    href: '/florida/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Georgia',
    href: '/georgia/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Hawaii',
    href: '/hawaii/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Idaho',
    href: '/idaho/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Illinois',
    href: '/illinois/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Indiana',
    href: '/indiana/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Iowa',
    href: '/iowa/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Kansas',
    href: '/kansas/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Kentucky',
    href: '/kentucky/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Louisiana',
    href: '/louisiana/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Maine',
    href: '/maine/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Maryland',
    href: '/maryland/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Massachusetts',
    href: '/massachusetts/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Michigan',
    href: '/michigan/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Minnesota',
    href: '/minnesota/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Mississippi',
    href: '/mississippi/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Missouri',
    href: '/missouri/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Montana',
    href: '/montana/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Nebraska',
    href: '/nebraska/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Nevada',
    href: '/nevada/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'New Hampshire',
    href: '/new-hampshire/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'New Jersey',
    href: '/new-jersey/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'New Mexico',
    href: '/new-mexico/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'New York',
    href: '/new-york/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'North Carolina',
    href: '/north-carolina/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'North Dakota',
    href: '/north-dakota/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Ohio',
    href: '/ohio/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Oklahoma',
    href: '/oklahoma/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Oregon',
    href: '/oregon/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Pennsylvania',
    href: '/pennsylvania/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Rhode Island',
    href: '/rhode-island/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'South Carolina',
    href: '/south-carolina/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'South Dakota',
    href: '/south-dakota/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Tennessee',
    href: '/tennessee/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Texas',
    href: '/texas/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Utah',
    href: '/utah/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Vermont',
    href: '/vermont/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Virginia',
    href: '/virginia/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Washington',
    href: '/washington/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Washington D.C.',
    href: '/washington-dc/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'West Virginia',
    href: '/west-virginia/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Wisconsin',
    href: '/wisconsin/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Wyoming',
    href: '/wyoming/',
    position: 'bottom',
    isCurrentItem: false,
  },
];
