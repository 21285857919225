/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const AnchorButtonFragment = {
  AnchorButton: gql`
    fragment AnchorButtonQuery on baseComponents_anchorButton_BlockType {
      buttonAlignment
      buttonArrowDirection
      buttonLabel
      buttonStyle
      styles
      typeHandle
    }
  `,
};
