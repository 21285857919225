import { Component, OnInit, Input } from '@angular/core';
import { Careers} from './careers';
import { GreenhouseService } from '@core-mkt/services/greenhouse/greenhouse.service';

@Component({
  selector: 'careers',
  templateUrl: './careers.component.html',
  styleUrls: [],
})
export class CareersComponent implements OnInit {
  @Input() data: Careers;
  private _department = 'all';

  constructor(private gh: GreenhouseService) {}

  get showHideCareers(): boolean {
    return this.data.showHideCareers;
  }

  get departments(): Array<string> | null {
    return this.data.greenhouse ? this.data.greenhouse.deps : null;
  }

  get department(): string {
    return this._department;
  }

  set department(val: string) {
    this._department = val;
  }

  ngOnInit(): void {
    this.gh.getJobs().subscribe((res) => {
      this.data.greenhouse = res;
      this.data.greenhouse.deps = [...new Set(this.data.greenhouse.jobs.map((item) => item.departments[0].name))];
    });
  }
}
