import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { SwiperModule } from 'swiper/angular';
import { SliderCardComponent } from './slider-card/slider-card.component';

@NgModule({
  declarations: [SliderCardComponent],
  exports: [SliderCardComponent],
  imports: [CommonModule, QueryParamModule, LazyImgModule, SwiperModule, VisionButtonModule],
})
export class SliderCardModule {}
