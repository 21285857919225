import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';

@NgModule({
  declarations: [MobileMenuComponent],
  exports: [MobileMenuComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule],
})
export class MobileMenuModule {}
