<div
  [class]="backgroundInfo.url ? textColorOverride + ' bg-no-repeat bg-center bg-cover' : backgroundInfo"
  [style.backgroundImage]="backgroundInfo.url && 'url(' + backgroundInfo.url + ')'"
>
  <div class="container {{ containerClasses }}">
    <div *ngIf="title" class="wysiwyg tab:px-v-spacing max-w-vision mx-auto" [innerHTML]="title"></div>
    <div class="flex flex-col justify-center items-center py-v-spacing tab:px-v-spacing max-w-vision mx-auto">
      <div class="md:text-[1.75rem] text-lg text-center mb-v-spacing">
        "I love Aceable I was able to do it whenever and wherever after school or after practice."
      </div>
      <p class="font-light">Iliana B. | San Antonio, TX</p>
    </div>
  </div>
</div>
