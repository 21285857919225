/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const ProductFragment = {
  productTemplate: gql`
    fragment productTemplate_fragment on productTemplate_MatrixField {
      ... on productTemplate_topSectionWysiwyg_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        contentAlignment
        ctaLabel
        ctaLink
        ctaNewTab
        ctaNofollow
        ctaType
        videoButton {
          ... on videoButton_BlockType {
            id
            ctaType
            ctaLabel
            wistiaId
            buttonType
            ctaOtherlink
          }
        }
        showTrustpilotStars
        hideGuaranteeLink
        modalContent
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
        product {
          ... on aceDrivingProducts_xgritProduct_Entry {
            productPageUrl
            productState
            xgritBundleCoupon
            xgritCoupon
            xgritProductDisableRsa
            xgritProductId
          }
          ... on aceRealEstateProducts_xgritProduct_Entry {
            productPageUrl
            productState
            xgritBundleCoupon
            xgritCoupon
            xgritProductDisableRsa
            xgritProductId
          }
        }
        productSeal {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        sectionContent
        featuredContent {
          ... on featuredContent_BlockType {
            featuredContentIcon {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            featuredContentText
          }
        }
        eyebrow
        sectionTitle
        showOfferTimer
        showPricing
        categoryWidgets {
          ... on categoryWidgets_Category {
            categoryWidgetContent {
              ... on categoryWidgetContent_BlockType {
                categoryContent
                categoryImage {
                  url
                  ... on aceImagesS3_Asset {
                    altText
                  }
                }
                categoryTitle
              }
            }
          }
        }
        rightContent
        bottomRightContent {
          ... on bottomRightContent_BlockType {
            sealIcon {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            sealIconText
          }
        }
        starCaption
        starRating
        textColor
        typeHandle
      }
      ... on productTemplate_graphicBanner_BlockType {
        typeHandle
      }
      ... on productTemplate_topSectionSlider_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        contentAlignment
        ctaArrowDirection
        ctaLabel
        ctaLink
        ctaNewTab
        ctaNofollow
        ctaType
        showTrustpilotStars
        hideGuaranteeLink
        modalContent
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
        pricingText
        product {
          ... on aceDrivingProducts_xgritProduct_Entry {
            productPageUrl
            productState
            xgritBundleCoupon
            xgritCoupon
            xgritProductDisableRsa
            xgritProductId
          }
          ... on aceRealEstateProducts_xgritProduct_Entry {
            productPageUrl
            productState
            xgritBundleCoupon
            xgritCoupon
            xgritProductDisableRsa
            xgritProductId
          }
        }
        productSeal {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        sectionContent
        featuredContent {
          ... on featuredContent_BlockType {
            featuredContentIcon {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            featuredContentText
          }
        }
        eyebrow
        sectionTitle
        showOfferTimer
        showPricing
        slideControlStyle
        slideIndicatorStyle
        slideAutoplay
        autoplayTransitionDelay
        topSectionSlides {
          ... on topSectionSlides_BlockType {
            slideContent
            slideImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            slideImageLink
            slideLinkNewTab
            slideLinkNofollow
          }
        }
        starCaption
        starRating
        textColor
        typeHandle
      }
      ... on productTemplate_emailForm_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        ctaType
        ctaLabel
        formContent
        formVertical
        subscriptionListIds
        typeHandle
        showParentEmailField
        showNameField
        formType
        textColor
      }
      ... on productTemplate_standAloneCta_BlockType {
        typeHandle
        backgroundColor
        ctaLabel
        ctaLink
        ctaNewTab
        ctaNofollow
        ctaType
        displayPrice
        pricingText
        textColor
      }
      ... on productTemplate_tabComponent_BlockType {
        backgroundColor
        tabbedContentTitle
        typeHandle
        tab {
          ... on tab_BlockType {
            isActive
            tabContentList {
              ... on tabContentList_accordionItem_BlockType {
                itemDescription
                itemTitle
                showDescription
                accordionSectionTitle {
                  ... on accordionSectionTitle_BlockType {
                    sectionTitle
                  }
                }
              }
            }
            tabTitle
          }
        }
      }
      ... on productTemplate_topProduct_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        ctaLabel
        ctaLink
        ctaType
        displayPrice
        displayOfferTimer
        pricingText
        ctaNewTab
        ctaNofollow
        hideGuaranteeLink
        imageAlignment
        modalContent
        productSeal {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        starCaption
        starRating
        textColor
        topProductContent
        topProductImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        topProductWistiaVideoId
        topProductTitle
        typeHandle
      }
      ... on productTemplate_breadcrumbs_BlockType {
        typeHandle
        backgroundColor
        textColor
        hideOnMobile
        breadcrumbsList {
          ... on breadcrumbsList_BlockType {
            breadcrumbLink
            breadcrumbText
          }
        }
      }
      ... on productTemplate_multiColumn_BlockType {
        tailwindClasses
        typeHandle
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        textColor
        componentTitle
        columnList: columnList {
          ... on columnList_BlockType {
            columnContent
            dropdownTitle
            dropdownAlignment
            dropdown {
              dropdownLabel
              dropdownLink
              dropdownLinkNewTab
              dropdownLinkNofollow
            }
          }
        }
        twoColumnDistribution
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on productTemplate_reviews_BlockType {
        typeHandle
        reviewTitle
        reviewType
        textColor
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
      }
      ... on productTemplate_accordion_BlockType {
        typeHandle
        accordionDisplayType
        accordionItems {
          ... on accordionItems_BlockType {
            itemTitle
            itemDescription
            showDescription
            accordionSectionTitle {
              ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                sectionTitle
              }
            }
            accordionImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
          }
        }
        accordionTitle
        accordionLeftContent
        buttonLabel
        buttonLink
        buttonLinkNewTab
        buttonLinkNofollow
        buttonType
      }

      ... on productTemplate_learnMore_BlockType {
          typeHandle
          backgroundColor
          componentPadding
          backgroundImage {
            url
            ... on aceImagesS3_Asset {
              altText
            }
          }
          ctaLabel
          ctaLink
          ctaNewTab
          ctaNofollow
          ctaStyle
          layout
          textColor
          learnMoreContent
        }

      ... on productTemplate_anchor_BlockType {
        typeHandle
        anchorName
      }

      ... on productTemplate_anchorButton_BlockType {
        typeHandle
        backgroundColor
        buttonAlignment
        buttonLabel
        scrollTo
        buttonStyle
        buttonArrowDirection
      }

      ... on productTemplate_multiCard_BlockType {
        typeHandle
        textColor
        backgroundColor
        textColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        componentTitle
        cardList {
          ... on cardList_BlockType {
            cardImage {
              url
            }
            imageShape
            cardContent
            cardButtonType
            cardButtonLabel
            cardButtonLink
            cardButtonLinkNewTab
            cardButtonLinkNofollow
          }
        }
      }
      ... on productTemplate_layout_BlockType {
        typeHandle
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        textColor
        image {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        layoutTitle
        layoutContent
        layoutRightContent
        dropdownPlaceholder
        dropdownTopLabel
        dropdown {
          ... on dropdown_BlockType {
            dropdownLabel
            dropdownLink
            dropdownLinkNewTab
            dropdownLinkNofollow
          }
        }
        buttonLayout
        buttonAlignment
        buttons {
          ... on buttons_BlockType {
            buttonLabel
            buttonLink
            buttonLinkNewTab
            buttonLinkNofollow
            buttonType
          }
        }
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on productTemplate_layoutCard_BlockType {
        typeHandle
        backgroundColor
        textColor
        layoutContent
        cardAlignment
        cardType
        cardImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        contentCardTitle
        cardContent
        cardButtonType
        cardButtonLabel
        cardButtonLink
        cardButtonLinkNewTab
        cardButtonLinkNofollow
      }
      ... on productTemplate_trustpilot_BlockType {
        id
        showHideTrustpilot
        trustpilotDisplayType
        dataSku
        dataName
        typeHandle
      }
      ... on productTemplate_resources_BlockType {
        typeHandle
        resourcesBackgroundColor
        resourcesBackgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        resourcesTextColor
        resourcesTitle
        resourceCards {
          ... on resourceCards_BlockType {
            resourceImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            resourceBackgroundColor
            resourceTextContent
            resourceCtaStyle
            resourceCtaLabel
            resourceCtaLink
            resourceCtaLinkNewTab
            resourceCtaLinkNofollow
          }
        }
      }
      ... on productTemplate_rsaProduct_BlockType {
        darkMode
        titleHeader
        rsaCtaLabel
        rsaCtaLink
        rsaCtaLinkNewTab
        rsaCtaLinkNofollow
        rsaCtaStyle
        typeHandle
        rsaImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        rsaImageFooterText
      }
      ... on productTemplate_slider_BlockType {
        typeHandle
        backgroundColor
        textColor
        sliderAlignment
        sliderTitle
        asideContent
        sideContentStateSeal {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        sliderSlides {
          ... on sliderSlides_BlockType {
            slideImage {
              url
              ... on aceImagesS3_Asset {
                altText
                height
                width
              }
            }
            slideImageLink
            slideContent
            slideLinkNewTab
            slideLinkNofollow
          }
        }
      }
      ... on productTemplate_sliderCard_BlockType {
        typeHandle
        backgroundColor
        textColor
        sliderTitle
        sliderDescription
        displayAllCardMobile
        slidesCards {
          ... on slidesCards_BlockType {
            slideImage {
              url
              ... on aceImagesS3_Asset {
                altText
                height
                width
              }
            }
            slideContent
            slideButtonStyle
            slideButtonLabel
            slideButtonLink
            slideButtonNewTab
            slideButtonNofollow
          }
        }
      }
      ... on productTemplate_calculator_BlockType {
        typeHandle
        sideContent
        showCalculator
        backgroundColor
        textColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
      }
    }
  `,
};
