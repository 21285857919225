import { Component, Input, OnInit } from '@angular/core';
import {
  ComponentTheme,
  ThemeInterface,
  ThemeParserService,
} from '@core-mkt/services/theme-parser/theme-parser.service';
import { Answer, PracticeTest, Question, ResultButtonAceProps } from './practice-test';

@Component({
  selector: 'practice-test',
  templateUrl: './practice-test.component.html',
})
export class PracticeTestComponent implements OnInit {
  @Input() data: PracticeTest;
  currentQuestion = 0;
  selectedAnswer = 0;
  currentQuestionResultText = 'CORRECT!';
  currentQuestionResult = true;
  correctAnwsersCount = 0;
  questions: Question[];
  theme: ThemeInterface;

  constructor(private themeParser: ThemeParserService) {}

  ngOnInit(): void {
    this.questions = [];
    if (this.data && this.data.jsonQuestions && this.data.jsonQuestions.length > 0) {
      const url = this.data.jsonQuestions[0]['url'];
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.questions = data || [];
        })
        .catch(error => console.error('Error:', error));
    }
      const themeInput: ComponentTheme = {
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }

  get resultButtonAceProps(): ResultButtonAceProps {
    return {
      link: this.data.resultsButtonAceLink,
      label: this.data.resultsButtonAceLabel || 'Learn More',
      nofollow: this.data.resultsButtonAceLinkNofollow,
      newTab: this.data.resultsButtonAceLinkNewTab,
    };
  }

  get resultButtonPrepAgentProps(): ResultButtonAceProps {
    return {
      link: this.data.resultsButtonPrepAgentLink || 'https://www.prepagent.com/sign-up',
      label: this.data.resultsButtonPrepAgentLabel || 'Learn More',
      nofollow: this.data.resultsButtonPrepAgentLinkNofollow,
      newTab: this.data.resultsButtonPrepAgentLinkNewTab,
    };
  }

  get showPracticeTest(): boolean {
    return this.data.showPracticeTest;
  }

  get contrastColorClassWithHover(): string {
    return this.data.contrastColor === 'pink'
      ? 'bg-ace-pink-600 hover:bg-ace-pink-400'
      : 'bg-ace-blue-600 hover:bg-ace-blue-400';
  }

  get contrastColorClass(): string {
    return this.data.contrastColor === 'pink' ? 'bg-ace-pink-600' : 'bg-ace-blue-600';
  }

  get title(): string {
    return `Can You Answer These ${this.data.state} Real State Exam Questions?`;
  }

  get subTitle(): string {
    return `See how many answers you can get right in this sampling of ${this.data.state} real estate exam questions.`;
  }

  get q(): Question {
    if (this.questions.length > this.currentQuestion) return this.questions[this.currentQuestion];
    return null;
  }

  get qMarker(): string {
    return this.questions.length > this.currentQuestion
      ? `${this.currentQuestion}/${this.questions.length}`
      : 'Complete';
  }

  get getWidthPercentage(): string {
    const percentage = 100 / this.questions.length;
    return `width: ${this.currentQuestion * percentage}%`;
  }

  selectAnswerChange(id: number) {
    this.selectedAnswer = id;
  }

  checkAnswer(question: Question) {
    this.currentQuestionResult = question.answers.find((a: Answer) => a.id == this.selectedAnswer).correct;
    if (this.currentQuestionResult) {
      this.currentQuestionResultText = 'CORRECT!';
      this.correctAnwsersCount++;
    } else {
      this.currentQuestionResultText = 'INCORRECT!';
    }
    question.isAnswer = true;
  }

  nextQuestion(): void {
    this.currentQuestion++;
    this.selectedAnswer = 0;
  }
}
