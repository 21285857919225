<div *ngIf="hasContent" class="top-product font-body bg-cover {{ theme?.root }}" [style]="theme?.bgImage">
  <div class="container mx-auto flex flex-col items-center {{ theme?.container }} {{ showContent ? imgAlign : '' }}">
    <div class="lg:w-1/2 w-full flex-col lg:mr-v-spacing">
      <div *ngIf="showTitle" class="mb-v-spacing lg:mb-8 top-product__title flex flex-col">
        <div class="flex items-center">
          <span class="inline-block">
            <img
              *ngIf="data.productSeal.length > 0 && data.productSeal[0] && data.productSeal[0].url !== null"
              [src]="data.productSeal[0].url"
              [alt]="data.productSeal[0].alt"
              class="mb-3 mr-4 top-product__seal"
              width="48"
              height="48"
            />
          </span>
          <span class="inline-block" *ngIf="data.eyebrow" [innerHTML]="data.eyebrow"></span>
        </div>
        <div class="wysiwyg" [innerHTML]="data.sectionTitle"></div>
      </div>
      <div *ngIf="data.showTrustpilotStars" class="my-v-spacing">
        <div class="flex items-center cursor-pointer text-xs xxsm:text-sm xs:text-base" (click)="openTruspilot()">
          <img
            class="h-[28px]"
            height="28"
            width="150"
            src="https://xgrit-ecom.imgix.net/ace/trustpilot-star-rating.svg?v=1706889351"
            alt=""
          />
          <span *ngIf="trustpilotVerified" class="mx-2.5"
            ><span class="font-extrabold">{{ trustpilotTrustScore }}/5</span> Stars</span
          >
          <span *ngIf="trustpilotVerified"
            ><span class="font-extrabold">{{ trustpilotNumberOfReviews | number }}</span> Reviews</span
          >
        </div>
      </div>
      <featured-content *ngIf="featuredContent" [data]="featuredContent"></featured-content>
      <div
        *ngIf="showContent"
        class="wysiwyg mb-0 top-product__content text-baselg leading-[25px] w-full {{
          theme?.linkColor === 'text-white' ? 'darkLink' : ''
        }}"
        [innerHTML]="data.sectionContent"
      ></div>
      <div class="flex flex-col">
        <div class="flex flex-col md:flex-row mt-v-spacing" *ngIf="showPricing">
          <div class="{{ displayOfferTimer ? '' : 'lg:flex-col' }} flex pricing top-product__pricing">
            <div class="flex items-center prices" [innerHtml]="pricingText"></div>
          </div>
          <div
            *ngIf="displayOfferTimer"
            class="timer flex lg:justify-start md:justify-center items-center ml-0 md:ml-2 font-bold"
          >
            <div class="mr-1">OFFER ENDS</div>
            <div class="tracking-wide">
              <span class="hour">{{ countdown | async | durationText : 'daysToHours' }}:</span>
              <span class="minute">{{ countdown | async | durationText : 'minutes' }}:</span>
              <span class="second">{{ countdown | async | durationText : 'seconds' }}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="secondButtonData">
          <div
            class="flex xl:flex-row lg:flex-col md:flex-row flex-col my-v-spacing vision-button-container"
            #topSectionCta
          >
            <vision-button
              class="w-full pt-3 pr-1  {{ videoButton ? 'mb-3 sm:mb-0 sm:mr-3' : '' }}"
              defaultStyle="tertiary"
              *ngIf="showCTA"
              [data]="ctaButtonData"
            ></vision-button>
            <vision-button
              class="w-full pt-3 pl-1 {{ videoButton ? 'mb-3 sm:mb-0 sm:mr-3' : '' }}"
              defaultStyle="tertiary"
              [data]="secondButtonData"
            ></vision-button>
          </div>
          <div class="flex sm:flex-row flex-col mx-auto">
            <button
              *ngIf="!data.hideGuaranteeLink"
              (click)="activateModal()"
              class="{{ modalCtaClass() }} {{ moneyGuaranteeLinkStyle }} font-bold mt-0 ml-0 pt-2"
              [class.cursor-pointer]="data.modalContent"
            >
              {{ moneyGuaranteeLabel }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="wistiaButtonData">
          <div
            class="flex xl:flex-row lg:flex-col md:flex-row flex-col my-v-spacing vision-button-container"
            #topSectionCta
          >
            <vision-button
              class="w-full pt-3 pr-1 {{ videoButton ? 'mb-3 sm:mb-0 sm:mr-3' : '' }}"
              defaultStyle="tertiary"
              *ngIf="showCTA"
              [data]="ctaButtonData"
            ></vision-button>
            <!-- Wistia div for initialization and modal activation -->
            <div
              class="wistia-cta-{{ wistiaCtaData?.wistiaId }} wistia_embed wistia_async_{{
                wistiaCtaData?.wistiaId
              }} popover=true videoFoam=true autoPlay=true"
            ></div>
            <vision-video-button
              *ngIf="this.wistiaButtonData.wistiaId"
              (click)="ctaVideoPlay($event)"
              class="w-full pt-3 pl-1 videoCta-{{ this.wistiaCtaData?.wistiaId }}"
              defaultStyle="secondary"
              [data]="wistiaButtonData"
            >
            </vision-video-button>
          </div>
        </ng-container>
        <ng-container *ngIf="!wistiaButtonData && !secondButtonData">
          <div class="flex sm:flex-row flex-col my-v-spacing vision-button-container" #topSectionCta>
            <vision-button
              class="w-full pt-3 sm:w-auto {{ videoButton ? 'mb-3 sm:mb-0 sm:mr-3' : '' }}"
              defaultStyle="tertiary"
              *ngIf="showCTA"
              [data]="ctaButtonData"
            ></vision-button>
            <!-- Wistia div for initialization and modal activation -->
            <div
              class="wistia-cta-{{ wistiaCtaData?.wistiaId }} wistia_embed wistia_async_{{
                wistiaCtaData?.wistiaId
              }} popover=true videoFoam=true autoPlay=true"
            ></div>
            <button
              *ngIf="!data.hideGuaranteeLink && !(this.wistiaButtonData?.wistiaId && this.ctaButtonData)"
              (click)="activateModal()"
              class="{{ modalCtaClass() }} {{
                moneyGuaranteeLinkStyle
              }} flex justify-center font-bold mt-4 sm:mt-0 ml-0 sm:ml-v-spacing pt-3"
              [class.cursor-pointer]="data.modalContent"
            >
              {{ moneyGuaranteeLabel }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="lg:w-1/2 w-full flex flex-col items-center mx-auto justify-center py-v-spacing sm:px-v-spacing"
      *ngIf="data.topSectionSlides && data.topSectionSlides.length > 0"
    >
      <top-section-slider
        class="block w-full"
        [slides]="data.topSectionSlides"
        [controlStyle]="data.slideControlStyle"
        [indicatorStyle]="data.slideIndicatorStyle"
        [slideAutoplay]="data.slideAutoplay"
        [transitionTime]="transitionTime"
      ></top-section-slider>
    </div>
    <div
      class="lg:w-1/2 w-full flex flex-col mx-auto justify-center py-v-spacing sm:px-v-spacing lg:ml-v-spacing"
      *ngIf="data.rightContent || categoryWidgets"
    >
      <top-section-wysiwyg
        [rightContent]="data.rightContent"
        [bottomRightContent]="bottomRightContent"
        [categoryWidgets]="categoryWidgets"
        [data]="data"
      ></top-section-wysiwyg>
    </div>
  </div>

  <div
    *ngIf="showModal"
    class="top-product__modal fixed inset-0 bg-ace-grey-900/50 z-50 flex justify-center items-center"
  >
    <div
      class="top-product__modal-body bg-white text-ace-grey-900 shadow-md px-8 py-v-spacing wysiwyg relative rounded flex"
    >
      <div class="top-product__modal-body-content wysiwyg" [innerHTML]="modalContent"></div>
      <fa-icon
        class="top-product__modal-close text-ace-grey-500 absolute right-v-spacing top-v-spacing fa-1x"
        (click)="showModal = false"
        [icon]="faTimes"
      ></fa-icon>
    </div>
  </div>
</div>
<div
  *ngIf="!ctaDisplay && showPriceCta"
  cond-class
  [target]="'body'"
  [addClass]="'mb-20'"
  class="w-full fixed bottom-0 bg-white z-50 top-product__bottom-banner"
>
  <div
    class="top-product__bottom-banner py-v-spacing mx-v-spacing md:ml-auto flex flex-row items-center justify-between md:justify-end"
  >
    <div class="flex flex-row">
      <div
        class="text-lg md:text-xl text-ace-grey-900 decoration-ace-grey-900 font-extrabold pr-1 xs:pr-2 line-through"
        *ngIf="discountPrice < originalPrice"
      >
        ${{ originalPrice }}
      </div>
      <div
        class="text-lg md:text-xl text-ace-tela-900 font-extrabold pr-1 xs:pr-2"
        [ngClass]="{ 'md:pr-v-spacing': discountPrice >= originalPrice }"
      >
        ${{ discountPrice }}
      </div>
    </div>
    <vision-button defaultStyle="primary" [data]="ctaButtonData"></vision-button>
  </div>
</div>
