import { Component, Input, OnInit } from '@angular/core';
import { ScriptLoaderService } from '@core-mkt/services/script-loader/script-loader.service';
import { GladlyHelpCenter } from './gladly-help-center';
import { EnvService } from '@core-mkt/services/env/env.service';

@Component({
  selector: 'gladly-help-center',
  templateUrl: './gladly-help-center.component.html',
  styleUrls: [],
})
export class GladlyHelpCenterComponent implements OnInit {
  @Input() data: GladlyHelpCenter;
  private defaultDivSelector = 'gladly-help-center';

  constructor(private scriptLoaderService: ScriptLoaderService, private env: EnvService) {}

  ngOnInit() {
    if (this.data && this.data.showGladlyHelpCenter) {
      this.scriptLoaderService.injectScriptByType('gladly');
    }
  }

  get gladlyDivSelector(): string {
    return this.env.get.gladlyHelpCenterEnvironment.selector ?? this.defaultDivSelector;
  }

  get gladlyHelpCenterEnabled(): boolean {
    return this.data && this.data.showGladlyHelpCenter ? this.data.showGladlyHelpCenter : false;
  }
}
