export const mockRoute = {
  snapshot: {
    queryParams: {
      testing: true,
    },
    data: {
      pageData: {
        apiData: {},
        productsData: {},
        craftData: {
          entryData: {
            hideStickyBanner: false,
            slug: 'slug',
          },
          header: {
            aceHeaderPromoBanner: [
              {
                promotionEndsDateTime: '2099-12-31T04:00:00+00:00',
                promotionStartDateTime: '2021-10-18T07:00:00+00:00',
                promotionText: 'OFF OR MORE WITH OFFER!',
                promotionReset: [
                  {
                    promotionEndsDateTime: '2099-12-31T04:00:00+00:00',
                    resetText: 'OFF OR MORE',
                  },
                ],
                showHidePromoBanner: true,
              },
            ],
          },
        },
      },
    },
  },
};
