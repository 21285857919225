/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOBundlesREFragment } from './fragments/seo-fragment-query';

export const TemplateBundlesQuery = {
  name: 'TemplateBundlesQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          hideStickyBanner
          bundlesTemplate {
            ... on bundlesTemplate_topSectionSlider_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              contentAlignment
              ctaArrowDirection
              ctaLabel
              ctaLink
              ctaNewTab
              ctaNofollow
              ctaType
              showTrustpilotStars
              hideGuaranteeLink
              modalContent
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              pricingText
              product {
                ...productEntry_fragment
              }
              productSeal {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              sectionContent
              eyebrow
              sectionTitle
              showOfferTimer
              showPricing
              slideControlStyle
              slideIndicatorStyle
              slideAutoplay
              autoplayTransitionDelay
              topSectionSlides {
                ... on topSectionSlides_BlockType {
                  slideContent
                  slideImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  slideImageLink
                  slideLinkNewTab
                  slideLinkNofollow
                }
              }
              starCaption
              starRating
              textColor
              typeHandle
            }
            ... on bundlesTemplate_graphicBanner_BlockType {
              typeHandle
            }
            ... on bundlesTemplate_topSectionWysiwyg_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              contentAlignment
              ctaLabel
              ctaLink
              ctaNewTab
              ctaNofollow
              ctaType
              videoButton {
                ... on videoButton_BlockType {
                  id
                  ctaType
                  ctaLabel
                  wistiaId
                  buttonType
                  ctaOtherlink
                }
              }
              showTrustpilotStars
              hideGuaranteeLink
              modalContent
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              product {
                ...productEntry_fragment
              }
              productSeal {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              featuredContent {
                ... on featuredContent_BlockType {
                  featuredContentIcon {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  featuredContentText
                }
              }
              sectionContent
              eyebrow
              sectionTitle
              showOfferTimer
              showPricing
              rightContent
              categoryWidgets {
                ... on categoryWidgets_Category {
                  categoryWidgetContent {
                    ... on categoryWidgetContent_BlockType {
                      categoryContent
                      categoryImage {
                        url
                        ... on aceImagesS3_Asset {
                          altText
                        }
                      }
                      categoryTitle
                    }
                  }
                }
              }
              bottomRightContent {
                ... on bottomRightContent_BlockType {
                  sealIcon {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  sealIconText
                }
              }
              starCaption
              starRating
              textColor
              typeHandle
            }
            ... on bundlesTemplate_sliderCard_BlockType {
              typeHandle
              backgroundColor
              textColor
              sliderTitle
              sliderDescription
              displayAllCardMobile
              slidesCards {
                ... on slidesCards_BlockType {
                  slideImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                      height
                      width
                    }
                  }
                  slideContent
                  slideButtonStyle
                  slideButtonLabel
                  slideButtonLink
                  slideButtonNewTab
                  slideButtonNofollow
                }
              }
            }
            ... on bundlesTemplate_multiColumn_BlockType {
              tailwindClasses
              backgroundColor
              backgroundImage {
                url
              }
              textColor
              componentTitle
              columnList: columnList {
                ... on columnList_BlockType {
                  columnContent
                  dropdownTitle
                  dropdownAlignment
                  dropdown {
                    dropdownLabel
                    dropdownLink
                    dropdownLinkNewTab
                    dropdownLinkNofollow
                  }
                }
              }
              twoColumnWidthDistribution
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              typeHandle
            }
            ... on bundlesTemplate_layoutCard_BlockType {
              backgroundColor
              cardType
              cardAlignment
              contentCardTitle
              cardImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              cardButtonLabel
              cardButtonLink
              cardButtonLinkNewTab
              cardButtonLinkNofollow
              cardButtonType
              cardContent
              textColor
              layoutContent
              typeHandle
            }
            ... on bundlesTemplate_multiCard_BlockType {
              backgroundColor
              backgroundImage {
                url
              }
              componentTitle
              cardList {
                ... on cardList_BlockType {
                  cardButtonLabel
                  cardButtonLink
                  cardButtonLinkNewTab
                  cardButtonLinkNofollow
                  cardButtonType
                  cardContent
                  cardImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  imageShape
                }
              }
              typeHandle
            }
            ... on bundlesTemplate_reviews_BlockType {
              backgroundColor
              backgroundImage {
                url
              }
              textColor
              reviewType
              reviewTitle
              typeHandle
            }
            ... on bundlesTemplate_trustpilot_BlockType {
              showHideTrustpilot
              trustpilotDisplayType
              dataSku
              dataName
              typeHandle
            }
            ... on bundlesTemplate_layout_BlockType {
              typeHandle
              backgroundColor
              backgroundImage {
                url
              }
              textColor
              image {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              imageAlignment
              layoutTitle
              layoutContent
              layoutRightContent
              dropdownTopLabel
              dropdownPlaceholder
              dropdown {
                ... on dropdown_BlockType {
                  dropdownLabel
                  dropdownLink
                  dropdownLinkNewTab
                  dropdownLinkNofollow
                }
              }
              buttonLayout
              buttonAlignment
              buttons {
                ... on buttons_BlockType {
                  buttonLinkNewTab
                  buttonLinkNofollow
                  buttonLabel
                  buttonLink
                  buttonType
                }
              }
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
            }
            ... on bundlesTemplate_accordion_BlockType {
              typeHandle
              accordionTitle
              accordionLeftContent
              buttonType
              buttonLabel
              buttonLink
              buttonLinkNewTab
              buttonLinkNofollow
              accordionDisplayType
              accordionItems {
                ... on accordionItems_BlockType {
                  itemDescription
                  itemTitle
                  accordionSectionTitle {
                    ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                      sectionTitle
                    }
                  }
                  accordionImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                }
              }
            }
            ... on bundlesTemplate_resources_BlockType {
              typeHandle
              resourcesBackgroundColor
              resourcesBackgroundImage {
                url
              }
              resourcesTextColor
              resourcesTitle
              resourceCards {
                ... on resourceCards_BlockType {
                  resourceImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  resourceBackgroundColor
                  resourceCtaLabel
                  resourceCtaLink
                  resourceCtaLinkNewTab
                  resourceCtaLinkNofollow
                  resourceCtaStyle
                  resourceTextContent
                }
              }
            }
            ... on bundlesTemplate_anchorButton_BlockType {
              backgroundColor
              buttonAlignment
              buttonLabel
              typeHandle
              scrollTo
              buttonStyle
              buttonArrowDirection
            }
            ... on bundlesTemplate_topBundle_BlockType {
              typeHandle
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              hideGuaranteeLink
              modalContent
              productSeal {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              starCaption
              starRating
              textColor
              topProductContent
              topProductTitle
              product {
                ...productEntry_fragment
              }
              buttonScrollLabel
              buttonScrollStyle
              buttonScrollTo
              buttonScrollArrowDirection
              topBundleTopRightContent
              topBundleBottomRightContent
              displayPrice
            }
            ... on bundlesTemplate_bundle_BlockType {
              typeHandle
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              bundles {
                ... on bundles_BlockType {
                  product {
                    ...productEntry_fragment
                  }
                  topContent
                  topBackgroundColor
                  topTextColor
                  principalContent
                  ctaStyle
                  ctaLabel
                  ctaLink
                  ctaLinkNewTab
                  ctaLinkNofollow
                  bottomContent
                }
              }
              bundleAccordionItems1 {
                ... on bundleAccordionItems1_BlockType {
                  itemIcon {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  itemTitle
                  itemBadge
                  itemDescription
                  showDescription
                }
              }
              bundleAccordionItems2 {
                ... on bundleAccordionItems2_BlockType {
                  itemIcon {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  itemTitle
                  itemBadge
                  itemDescription
                  showDescription
                }
              }
              bundleAccordionItems3 {
                ... on bundleAccordionItems3_BlockType {
                  itemIcon {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  itemTitle
                  itemBadge
                  itemDescription
                  showDescription
                }
              }
            }
            ... on bundlesTemplate_anchor_BlockType {
              typeHandle
              anchorName
            }
            ... on bundlesTemplate_emailForm_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              ctaLabel
              ctaType
              formContent
              formVertical
              showNameField
              formType
              showParentEmailField
              subscriptionListIds
              textColor
              typeHandle
            }
            ... on bundlesTemplate_breadcrumbs_BlockType {
              typeHandle
              backgroundColor
              textColor
              hideOnMobile
              breadcrumbsList {
                ... on breadcrumbsList_BlockType {
                  breadcrumbLink
                  breadcrumbText
                }
              }
            }
            ... on bundlesTemplate_productFilterCards_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              filterType
              ctaStyle
              useCompactCards
              productCardList {
                ... on productCardList_BlockType {
                  cardBackgroundColor
                  cardTextColor
                  ctaLabel
                  ctaLink
                  ctaNewTab
                  ctaNofollow
                  learnMoreLink
                  productEntry {
                    ...productEntry_fragment
                  }
                  textContent
                  learnMoreLabel
                  learnMoreNewTab
                  learnMoreNofollow
                  starRating
                  starCaption
                  showSavings
                  cardTitle
                }
              }
              sectionTitle
              textColor
              typeHandle
            }
            ... on bundlesTemplate_standAloneCta_BlockType {
              typeHandle
              backgroundColor
              ctaLabel
              ctaLink
              ctaNewTab
              ctaNofollow
              ctaType
              displayPrice
              pricingText
              textColor
            }
            ... on bundlesTemplate_tabComponent_BlockType {
              backgroundColor
              tabbedContentTitle
              typeHandle
              tab {
                ... on tab_BlockType {
                  isActive
                  tabContentList {
                    ... on tabContentList_accordionItem_BlockType {
                      itemDescription
                      itemTitle
                      showDescription
                      accordionSectionTitle {
                        ... on accordionSectionTitle_BlockType {
                          sectionTitle
                        }
                      }
                    }
                  }
                  tabTitle
                }
              }
            }
          }
        }
      }
      feefo: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          feefoProductId
        }
      }
      seoContent: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }
      styles: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          embedStyles
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          optimizelyEnabled
        }
      }
      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: bundlesAATemplateOpti
            }
          }
        }
      }
      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          structuredData
        }
      }
      overrides: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }
    fragment productEntry_fragment on EntryInterface {
      ... on aceRealEstateProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        aceRealEstateProductCategory {
          ... on realEstateCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOBundlesREFragment.seoContent}
  `,
};
