import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConditionalClassModule } from '@core-mkt/services/conditional-class/conditional-class.module';
import { DurationModule } from '@core-mkt/services/duration/duration.module';
import { ImagePreloadModule } from '@core-mkt/services/image-preload/image-preload.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TopProductComponent } from './top-product/top-product.component';

@NgModule({
  declarations: [TopProductComponent],
  exports: [TopProductComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    DurationModule,
    QueryParamModule,
    ConditionalClassModule,
    ImagePreloadModule,
    VisionButtonModule,
  ],
})
export class TopProductModule {}
